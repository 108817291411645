// 依据临时地址下载
const downloadByURL = (
  url: string,
  fileName?: string,
  target = "_blank"
): boolean => {
  const isChrome =
    window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
  const isSafari =
    window.navigator.userAgent.toLowerCase().indexOf("safari") > -1;

  if (/(iP)/g.test(window.navigator.userAgent)) {
    console.error("你的浏览器不支持下载!");
    return false;
  }
  if (isChrome || isSafari) {
    const link = document.createElement("a");
    link.href = url;
    link.target = target;

    if (link.download !== undefined) {
      link.download =
        fileName || url.substring(url.lastIndexOf("/") + 1, url.length);
    }

    if (document.createEvent) {
      const e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      link.dispatchEvent(e);
      return true;
    }
  }
  if (url.indexOf("?") === -1) {
    url += "?download";
  }

  window.open(url);
  return true;
};

// 依据文件流下载
const downloadByBlob = (data: Blob | File, fileName?: string) => {
  return downloadByURL(URL.createObjectURL(data), fileName);
};

export { downloadByURL, downloadByBlob };
