import req from "../configs/axios";

// 查询院系列表
const queryYuanListToSelect = (data?: any) => {
  return req.post("auth/NewDeptController/queryYuanListToSelect", data);
};

// 查询专业列表
const queryZhuanListToSelect = (data?: any) => {
  return req.post("auth/NewDeptController/queryZhuanListToSelect", data);
};

// 查询班级列表
const queryBanListToSelect = (data?: any) => {
  return req.post("auth/NewDeptController/queryBanListToSelect", data);
};

// 查询职务列表
const queryZWListToSelect = (data?: any) => {
  return req.post("auth/NewDeptController/queryZWListToSelect", data);
};

// 查询院系-专业-班级树
const queryDeptTree = (data?: any) => {
  return req.post("auth/NewDeptController/queryDeptTree", data, {
    timeout: 0,
  });
};

// 查询院系-专业-班级树（申报页）
const queryDeptTreeByDeclare = (data?: any) => {
  return req.post("auth/NewDeptController/queryDeptTreeByDeclare", data, {
    timeout: 0,
  });
};

// 查询组织下的直接子节点
const listDeptDtoByLevel = (data?: { dId: number; level: number }) => {
  return req.post("/auth/NewDeptController/listDeptDtoByLevel", data);
};

// 查询注册院系-专业-班级树
const showDeptTree = (data?: any) => {
  return req.post("auth/NewDeptController/showDeptTree", data);
};

// 删除院系/专业/班级
const deleteOneDeptOrDepartment = (data?: { level: number; id: React.Key }) => {
  return req.post("auth/NewDeptController/deleteOneDeptOrDepartment", data);
};

// 新增院系/专业/班级
const addOneDeptOrDepartment = (data?: any) => {
  return req.post("auth/NewDeptController/addOneDeptOrDepartment", data);
};

// 编辑院系/专业/班级
const updateOneDeptOrDepartment = (data?: any) => {
  return req.post("auth/NewDeptController/updateOneDeptOrDepartment", data);
};

export {
  queryYuanListToSelect,
  queryZhuanListToSelect,
  queryBanListToSelect,
  queryZWListToSelect,
  queryDeptTree,
  queryDeptTreeByDeclare,
  listDeptDtoByLevel,
  showDeptTree,
  deleteOneDeptOrDepartment,
  addOneDeptOrDepartment,
  updateOneDeptOrDepartment,
};
