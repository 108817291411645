import { Form, message, Spin, InputNumber, Radio, Button } from "antd";
import { useState, useCallback, useEffect } from "react";
import {
  findBasicScoreByLabId,
  addBasicScore,
} from "../../../../api/NewLabInfoController";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

const ManualScorePanel = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const getSetting = useCallback(() => {
    setLoading(true);
    findBasicScoreByLabId({ tLabId: labId })
      .then(({ data }) => {
        if (data.code) form.setFieldsValue(data.data[0]);
      })
      .finally(() => setLoading(false));
  }, [form, labId]);

  const save = () => {
    form.validateFields().then((raw) => {
      setSaving(true);
      addBasicScore({ ...raw, tLabId: labId })
        .then(({ data }) => {
          if (data.code) {
            message.success(t("Setupanels.item.savedscore"));
            return;
          }
          data?.msg && message.error(data.msg);
        })
        .finally(() => setSaving(false));
    });
  };

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Form.Item
          label={t("Setupanels.item.maximum")}
          name="tLabBasicScoreBeiyong1"
          rules={[
            { required: true, message: t("Setupanels.item.entermaximum") },
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Form.Item
          label={t("Setupanels.item.basisscore")}
          name="tLabBasicScore"
          rules={[
            { required: true, message: t("Setupanels.item.enterbasisscore") },
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Form.Item
          label={t("Setupanels.item.effective")}
          name="tLabScorePerStep"
          rules={[
            { required: true, message: t("Setupanels.item.entereffective") },
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Form.Item
          label={t("Setupanels.item.submitbtn")}
          name="tLabBasicScoreCircuit"
          rules={[{ required: true, message: t("Setupanels.item.selectbtn") }]}
        >
          <Radio.Group>
            <Radio value={1}>{t("Setupanels.item.display")}</Radio>
            <Radio value={2}>{t("Setupanels.item.hide")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" loading={saving} onClick={save}>
          {t("Setupanels.item.savechange")}
        </Button>
      </Form>
    </Spin>
  );
};

export default ManualScorePanel;
