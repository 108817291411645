import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Progress, Upload } from "antd";
import { useState, useEffect } from "react";
import {
  FileAcceptList,
  uploadManually,
} from "../../../api/NewUpLoadController";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";
interface UploaderProps {
  src?: string;
  onChange?: (data: string) => {};
  keyName?: string;
}

type StatusType = "idle" | "loading" | "error" | "done";

const Uploader = (prop: UploaderProps) => {
  const { t } = useLocale(i18n);
  const { src, onChange, keyName = "schoolLogo" } = prop;
  const [status, setStatus] = useState<StatusType>("idle");
  const [progress, setProgress] = useState<number>(0);
  const [screenBackImg, setScreenBackImg] = useState<string>();

  const beforeImgUpload = (file: File) =>
    new Promise<boolean>((resolve, reject) => {
      if (!FileAcceptList.image.includes(file.type)) {
        message.error(t("experiment.item.uploadimage"));
        return reject(false);
      }
      if (file.size / 1024 / 1024 > 5) {
        message.error(t("experiment.item.uploadpic"));
        return reject(false);
      }
      return resolve(true);
    });

  const manuallyUpload = (options: any) => {
    setStatus("loading");

    const data = new FormData();
    data.append("file", options.file);
    data.append("keyName", keyName);

    uploadManually(data, (e) =>
      setProgress(Math.floor((e.loaded / e.total) * 100))
    ).then(({ data }) => {
      if (data.code) {
        setScreenBackImg(data.data);
        setStatus("done");
        onChange?.(data.data);
        message.success(t("experiment.item.uploadsuccess"));
      } else {
        setStatus("error");
      }
    });
  };

  useEffect(() => {
    if (src) {
      setStatus("done");
      setScreenBackImg(src);
    }
  }, [src]);

  const dragStatus = {
    idle: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "cloud-arrow-up"]}
            size="3x"
            className="text-gray-300"
          />
        </p>
        <p className="text-gray-500">{t("experiment.item.uploadhere")}</p>
      </>
    ),

    loading: (
      <Progress
        strokeColor={{
          "0%": "#f492f0",
          "100%": "#a18dce",
        }}
        percent={progress}
        status="active"
        type="circle"
      />
    ),

    error: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "circle-xmark"]}
            size="3x"
            className="text-red-500"
          />
        </p>
        <p className="ant-upload-text">{t("experiment.item.uploadfail")}</p>
      </>
    ),

    done: (
      <img
        src={screenBackImg}
        alt=""
        className="w-full max-h-full object-contain"
      />
    ),
  } as const;

  return (
    <div className="w-35 h-35 bg-gray-100 overflow-hidden">
      <Upload.Dragger
        maxCount={1}
        showUploadList={false}
        accept={FileAcceptList.image.join()}
        beforeUpload={beforeImgUpload}
        customRequest={manuallyUpload}
        className="uploader"
      >
        {dragStatus[status]}
      </Upload.Dragger>
    </div>
  );
};

export default Uploader;
