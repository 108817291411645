import { useCallback, useContext } from "react";
import { LocaleContext } from "../context/LocaleContext";
import i18nDefault from "../i18n/default";
import type { defaultI18nKeys } from "../i18n/default";

export const useLocale = <Keys extends string = defaultI18nKeys>(
  i18n?: I18nType<Keys>
) => {
  const ctx = useContext(LocaleContext);
  if (!ctx)
    throw new Error(
      "LocaleContext不在父状态树中.是否将Provider挂载在了错误的位置?"
    );

  const t = useCallback(
    (key: Keys | defaultI18nKeys): string => {
      return i18n
        ? i18n[ctx.lang][key as Keys] ||
            i18nDefault[ctx.lang][key as defaultI18nKeys]
        : i18nDefault[ctx.lang][key as defaultI18nKeys];
    },
    [ctx.lang, i18n]
  );

  return { ...ctx, t };
};
