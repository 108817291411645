import { Alert } from "antd";
import { useEffect, useState } from "react";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import RichEditor from "../../components/RichEditor";

const NoticeManagePage = () => {
  /**
   * 这里演示编辑器组件的直接用法，嵌套在表单的用法参照 src\views\system\article\index.tsx
   */
  const [rich, setRich] = useState<string>();
  const { t } = useLocale();

  useEffect(() => {
    // 异步设置编辑器内容
    setTimeout(() => {
      setRich("<h1>标题🖊</h1>");
    }, 1500);
  }, []);

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.system"), t("menu.system.notice")]} />
      <div>通知管理</div>
      <RichEditor value={rich} onChange={setRich} />
      <div>{rich}</div>
    </div>
  );
};

export default NoticeManagePage;
