import req from "../configs/axios";

// 查询站点基础信息
const findAllSystemMessage = (data?: any) => {
  return req.post("SystemMessageController/findAllSystemMessage", data);
};

// 更新站点基础信息
const updAllSystemMessage = (data?: any) => {
  return req.post("auth/SystemMessageController/updAllSystemMessage", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export { findAllSystemMessage, updAllSystemMessage };
