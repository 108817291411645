import { durationFormat, isVoid } from "@/utils/format";
import { Alert, Modal, Space, Table, Tag } from "antd";
import { type FC } from "react";
// import type { RecordProps } from "./stageRecord";
// import duration from "dayjs/plugin/duration";
// import dayjs from "dayjs";
// import { ContestRecordProps } from "./contestRecord";

// dayjs.extend(duration);

interface RecordDetailProps {
  open?: boolean;
  onClose?: () => void;
  record?: any;
}

const RecordDetail: FC<RecordDetailProps> = ({ open, onClose, record }) => {
  return (
    <Modal
      open={open}
      onCancel={() => onClose?.()}
      title={`${record?.userName} 的答题记录`}
      width={860}
      destroyOnClose
      footer={null}
    >
      <Alert
        className="mb-4"
        description={
          <>
            <Space>
              <span className="font-bold">开始答题时间：</span>
              {record?.createTime}
              <span className="font-bold">结束答题时间：</span>
              {record?.endTime}
            </Space>
            <div>
              <span className="font-bold">总用时：</span>
              {durationFormat(record?.useTime || 0)}
            </div>
          </>
        }
      />
      <Table
        bordered
        dataSource={record?.contentList}
        pagination={false}
        rowKey="questionId"
        scroll={{ y: 550 }}
        columns={[
          {
            title: "序号",
            width: 70,
            align: "center",
            render: (_val, _row, index) => index + 1,
          },
          {
            title: "题干",
            dataIndex: "questionName",
          },
          {
            title: "分值",
            dataIndex: "fPoints",
            width: 70,
            align: "center",
          },
          {
            title: "标准答案",
            dataIndex: "mtAnswer",
            align: "center",
          },
          {
            title: "用户答案",
            dataIndex: "userAnswer",
            align: "center",
            render: (val) => (val ? val : <Tag>未作答</Tag>),
          },
          {
            title: "作答时间",
            dataIndex: "createTime",
            align: "center",
          },
          {
            title: "判定结果",
            dataIndex: "watchDuration",
            align: "center",
            width: 100,
            render(_val, row) {
              if (!isVoid(row.answerResult)) {
                return row.answerResult ? (
                  <Tag color="#87d068">回答正确</Tag>
                ) : (
                  <Tag color="#cd201f">回答错误</Tag>
                );
              }
              return <Tag>未作答</Tag>;
            },
          },
        ]}
      />
    </Modal>
  );
};

export default RecordDetail;
