import { ClusterOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState, useCallback } from "react";
import { useLocale } from "../../../hooks/useLocale";
import useToggle from "../../../hooks/useToggle";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import DepartmentTree, {
  type NodeDataType,
} from "../../components/DepartmentTree";
import {
  queryYuanListToSelect,
  queryZhuanListToSelect,
  queryBanListToSelect,
  queryZWListToSelect,
} from "../../../api/NewDeptController";
import { showuserfull, saveuser } from "../../../api/RegisterController";
import { Rule } from "antd/lib/form";
import { mediumPuzzle, mobile, strongPuzzle } from "../../../utils/regExp";
import { array2Tree, keyEncode } from "../../../utils/format";
import {
  listAllRightByUserId,
  listRoleByParams,
} from "../../../api/NewRightRoleController";
import { type RoleInstance } from "../../system/authority/RoleManageModal";
import i18n from "./i18n";

const formRoles: { [key: string]: Rule[] } = {
  loginID: [{ required: true, message: "请输入工(学)号" }],
  name: [{ required: true, message: "请输入姓名" }],
  password: [
    { required: false },
    {
      validator(_, value) {
        if (!value) return Promise.resolve();
        if (value.length < 8) {
          return Promise.reject(new Error("口令过短。请设置至少8位的密码"));
        }
        if (mediumPuzzle.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            new Error("密码必须包含数字和大、小写字母、符号中的一种")
          );
        }
      },
    },
  ],
  tel: [
    { required: false },
    {
      message: "手机号码格式不正确",
      validator: (_rule, val) =>
        mobile.test(val) || !val ? Promise.resolve(val) : Promise.reject(),
    },
  ],
  mail: [{ required: false }, { type: "email", message: "邮箱格式不正确" }],
};

const RegisterCenterPage = () => {
  const { t } = useLocale(i18n);
  const [queryForm] = useForm();
  const [treeData, setTreeData] = useState<any[]>([]);
  const [treeMap, setTreeMap] = useState<Map<number, any>>();
  const [chvalid, setChvalid] = useState<string>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [yuanMap, setYuanMap] = useState<Map<number, any>>();
  const [zhuanMap, setZhuanMap] = useState<Map<number, any>>();
  const [banMap, setBanMap] = useState<Map<number, any>>();
  const [zwMap, setZwMap] = useState<Map<number, any>>();
  const [roles, setRoles] = useState<RoleInstance[]>();

  // 用户组织树
  const [tableSiderShow, setTableSiderShow] = useToggle(false);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  const onEntitySelect = (node?: NodeDataType) => {
    const key = {
      1: "yid",
      2: "zid",
      3: "bid",
      4: "uid",
    };

    getTableData(node?.level ? { [key[node.level]]: node.id } : {});
  };

  const getTableData = useCallback(
    async (depart?: any) => {
      setLoading(true);
      showuserfull({
        ...queryForm.getFieldsValue(),
        ...tablePagenation,
        ...depart,
      })
        .then(({ data }) => {
          if (data.code) {
            setTableData(data.data.rows);
            setTotal(data.data.records);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [queryForm, tablePagenation]
  );

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  const onSearch = () => {
    setTablePagenation({ page: 1, rows: 10 });
  };

  useEffect(() => {
    let queryYuan = queryYuanListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setYuanMap(map);
        return map;
      }
    });

    let queryZhuan = queryZhuanListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setZhuanMap(map);
        return map;
      }
    });

    let queryBan = queryBanListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setBanMap(map);
        return map;
      }
    });

    queryZWListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setZwMap(map);
      }
    });

    Promise.allSettled([queryYuan, queryZhuan, queryBan]).then((reslist) => {
      const settled = (
        reslist.filter(
          (promise) => promise.status === "fulfilled"
        ) as PromiseFulfilledResult<any>[]
      ).map((item) => item.value);

      const merged = new Map();
      for (let i of settled) {
        for (let part of i) {
          merged.set(part[0], part[1]);
        }
      }

      setTreeMap(merged);
      const tree = array2Tree(Array.from(merged.values()));
      setTreeData(tree);
    });
  }, []);

  //通过
  const passTableRows = (loginId: any) => {
    Modal.confirm({
      title: t("register.item.confirmpass"),
      content: t("register.item.surepass"),
      async onOk() {
        const { data } = await saveuser({ loginId: loginId, valid: 3 });
        console.log(data);
        if (data.code) {
          message.success(t("register.item.passed"));
          setTablePagenation({ page: 1, rows: 10 });
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  //标记
  const signTableRows = (loginId: any) => {
    Modal.confirm({
      title: t("register.item.confirmmark"),
      content: t("register.item.suremark"),
      async onOk() {
        const { data } = await saveuser({ loginId: loginId, valid: 2 });
        console.log(data);
        if (data.code) {
          message.success(t("register.item.marked"));
          setTablePagenation({ page: 1, rows: 10 });
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  //取消标记
  const qusignTableRows = (loginId: any) => {
    Modal.confirm({
      title: t("register.item.confirmunmark"),
      content: t("register.item.sureunmark"),
      async onOk() {
        const { data } = await saveuser({ loginId: loginId, valid: 1 });
        console.log(data);
        if (data.code) {
          message.success(t("register.item.canceled"));
          setTablePagenation({ page: 1, rows: 10 });
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  //拒绝
  const refuseTableRows = (loginId: any) => {
    Modal.confirm({
      title: t("register.item.confirmreject"),
      content: t("register.item.surereject"),
      async onOk() {
        const { data } = await saveuser({ loginId: loginId, valid: 0 });
        console.log(data);
        if (data.code) {
          message.success(t("register.item.rejected"));
          setTablePagenation({ page: 1, rows: 10 });
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  //编辑
  const [editform] = useForm();
  const [progress, setProgress] = useState({ percent: 0, strokeColor: "" });
  const [editingRegisterId, setEditingRegisterId] = useState<number>();
  const [editModalShow, setEditModalShow] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const openToEdit = (rowData?: any) => {
    if (rowData) {
      rowData._org = rowData.bid || rowData.zid || rowData.yid;
      editform.setFieldsValue(rowData);
      formRoles.password[0] = { required: false };
      setEditingRegisterId(rowData.id);
    } else {
      formRoles.password[0] = {
        required: true,
        message: t("register.item.enterpassword"),
      };
      setEditingRegisterId(undefined);
      editform.resetFields();
    }
    setEditModalShow(true);
  };

  const onEditFormValuesChange = (crt: any) => {
    const { password } = crt;
    if (password === "") setProgress({ percent: 0, strokeColor: "" });
    if (password) {
      if (strongPuzzle.test(password)) {
        setProgress({ percent: 100, strokeColor: "#52c41a" });
      } else if (mediumPuzzle.test(password)) {
        setProgress({ percent: 66, strokeColor: "#ffc903" });
      } else {
        setProgress({ percent: 33, strokeColor: "#ff0303" });
      }
    }
  };

  const onUpdateRegister = () => {
    editform.validateFields().then(async (d) => {
      const { _org } = d;

      let yid, zid, bid;
      let current = _org;

      while (current) {
        let tar = treeMap?.get(current);
        if (tar.nLevel === 2) {
          bid = tar.id;
        } else if (tar.nLevel === 1) {
          zid = tar.id;
        } else {
          yid = tar.id;
        }

        current = tar.pid;
      }

      d = { ...d, yid, zid, bid };

      setEditLoading(true);
      if (editingRegisterId) {
        d.id = editingRegisterId;
        if (d.password) {
          d.password = keyEncode(d.password);
        }

        const { data } = await saveuser(d).finally(() => {
          setEditLoading(false);
        });
        if (data.code) {
          message.success(t("register.item.modifysuccess"));
          setTablePagenation({ page: 1, rows: 10 });
          setEditModalShow(false);
          return;
        }
        data?.msg && message.error(data.msg);
        return;
      }
    });
  };

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.users"), t("menu.users.registers")]} />
      <div className="flex flex-grow">
        {tableSiderShow ? (
          <DepartmentTree depth={2} onNodeSelect={onEntitySelect} />
        ) : null}
        <div className="overflow-hidden flex-grow">
          <div className="table-query-bar">
            <Form layout="inline" form={queryForm}>
              <Space wrap>
                <Form.Item label={t("register.item.nameorwork")} name="search">
                  <Input
                    className="w-50"
                    allowClear
                    placeholder={t("register.item.enternameornum")}
                  />
                </Form.Item>
                <Form.Item label={t("register.item.state")} name="valid">
                  <Select
                    allowClear
                    placeholder={t("register.item.viewall")}
                    style={{ width: "100px" }}
                    onChange={(value: { value: string }) => {
                      if (!value) {
                        setTablePagenation({ page: 1, rows: 10 });
                      }
                    }}
                  >
                    <Select.Option value="1">
                      {t("register.item.newregister")}
                    </Select.Option>
                    <Select.Option value="2">
                      {t("register.item.mark")}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Button onClick={() => queryForm.resetFields()}>
                  {t("register.item.clear")}
                </Button>
                <Button type="primary" htmlType="submit" onClick={onSearch}>
                  {t("register.item.query")}
                </Button>
              </Space>
            </Form>
          </div>
          <div className="table-wrapper h-full">
            <Space className="table-tool-bar">
              <Space>
                <Tooltip
                  title={`${
                    tableSiderShow
                      ? t("register.item.putaway")
                      : t("register.item.develop")
                  }${t("register.item.organizationtree")}`}
                  placement="top"
                >
                  <Button
                    icon={<ClusterOutlined />}
                    onClick={setTableSiderShow}
                  ></Button>
                </Tooltip>
              </Space>
            </Space>
            <Table
              size="small"
              rowKey="id"
              bordered
              scroll={{ x: 1700 }}
              loading={loading}
              dataSource={tableData}
              pagination={{
                size: "default",
                showQuickJumper: true,
                showSizeChanger: true,
                position: ["bottomCenter"],
                current: tablePagenation.page,
                pageSize: tablePagenation.rows,
                total,
                showTotal: (total) =>
                  `${t("table.pagination.all")}${total}${t(
                    "table.pagination.total"
                  )}`,
                onChange: (page, rows) => {
                  setTablePagenation({ page, rows });
                },
              }}
              columns={[
                {
                  title: t("table.index"),
                  width: 50,
                  fixed: "left",
                  align: "center",
                  render: (_val, _row, index) => index + 1,
                },
                {
                  title: t("register.item.loginid"),
                  dataIndex: "loginId",
                  width: 120,
                  fixed: "left",
                },
                {
                  title: t("user.attr.name"),
                  dataIndex: "name",
                  width: 120,
                  fixed: "left",
                },
                {
                  title: t("user.attr.sex"),
                  dataIndex: "sex",
                  width: 50,
                  align: "center",
                  render: (val) =>
                    val === 1 ? (
                      <Tag color="blue">♂</Tag>
                    ) : (
                      <Tag color="magenta">♀</Tag>
                    ),
                },
                {
                  title: t("user.attr.tel"),
                  dataIndex: "tel",
                  width: 120,
                },
                {
                  title: t("user.attr.mail"),
                  dataIndex: "mail",
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: t("user.attr.yid"),
                  dataIndex: "yid",
                  width: 120,
                  render: (val) => yuanMap?.get(val)?.cName,
                },
                {
                  title: t("user.attr.zid"),
                  dataIndex: "zid",
                  width: 120,
                  render: (val) => zhuanMap?.get(val)?.cName,
                },
                {
                  title: t("user.attr.bid"),
                  dataIndex: "bid",
                  width: 120,
                  render: (val) => banMap?.get(val)?.cName,
                },
                {
                  title: t("user.attr.zwid"),
                  dataIndex: "zwid",
                  width: 120,
                  render: (val) => zwMap?.get(val)?.cName,
                },
                {
                  title: t("register.item.currentstate"),
                  dataIndex: "valid",
                  width: 150,
                  render(val) {
                    if (val === 1) {
                      return t("register.item.newregister");
                    } else if (val === 2) {
                      return t("register.item.mark");
                    } else if (val === 0) {
                      return t("register.item.reject");
                    } else if (val === 3) {
                      return t("register.item.pass");
                    }
                  },
                },
                {
                  title: t("register.item.operation"),
                  dataIndex: "opt",
                  width: 80,
                  align: "center",
                  render(_text, row) {
                    return (
                      <Space>
                        <Button size="small" onClick={() => openToEdit(row)}>
                          {t("register.item.edit")}
                        </Button>
                      </Space>
                    );
                  },
                },
                {
                  title: t("register.item.audit"),
                  width: 180,
                  dataIndex: "regist",
                  align: "center",
                  fixed: "right",
                  render(val, row) {
                    if (row.valid === 1) {
                      return (
                        <Space>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => passTableRows(row.loginId)}
                          >
                            {t("register.item.apass")}
                          </Button>
                          <Button
                            size="small"
                            onClick={() => signTableRows(row.loginId)}
                          >
                            {t("register.item.amark")}
                          </Button>
                          <Button
                            size="small"
                            danger
                            type="primary"
                            onClick={() => refuseTableRows(row.loginId)}
                          >
                            {t("register.item.areject")}
                          </Button>
                        </Space>
                      );
                    } else if (row.valid === 2) {
                      return (
                        <Space>
                          <Button
                            onClick={() => passTableRows(row.loginId)}
                            type="primary"
                            size="small"
                          >
                            {t("register.item.apass")}
                          </Button>
                          <Button
                            size="small"
                            onClick={() => qusignTableRows(row.loginId)}
                          >
                            {t("register.item.unmark")}
                          </Button>
                          <Button
                            onClick={() => refuseTableRows(row.loginId)}
                            danger
                            size="small"
                            type="primary"
                          >
                            {t("register.item.areject")}
                          </Button>
                        </Space>
                      );
                    } else if (val === 0) {
                      return t("register.item.invalid");
                    }
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("register.item.modifyinf")}
        open={editModalShow}
        footer={null}
        destroyOnClose
        onCancel={() => {
          setEditModalShow(false);
        }}
      >
        <Form
          name="edit"
          form={editform}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          preserve={false}
          onValuesChange={onEditFormValuesChange}
        >
          <Form.Item
            label={t("register.item.worknum")}
            name="loginId"
            rules={formRoles.loginID}
          >
            <Input
              placeholder={t("register.item.modifyworknum")}
              maxLength={20}
              showCount
            />
          </Form.Item>
          <Form.Item
            label={t("user.attr.name")}
            name="name"
            rules={formRoles.name}
          >
            <Input
              placeholder={t("register.item.modifyname")}
              maxLength={15}
              showCount
            />
          </Form.Item>
          <Form.Item
            label={t("register.item.usertype")}
            name="type"
            initialValue={2}
          >
            <Radio.Group>
              <Radio value={2}>{t("register.item.student")}</Radio>
              <Radio value={1}>{t("register.item.teacher")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t("user.attr.sex")} name="sex">
            <Select placeholder={t("register.item.choosesex")}>
              <Select.Option value={1}>
                {t("user.attr.sex.male")} <Tag color="blue">♂</Tag>
              </Select.Option>
              <Select.Option value={2}>
                {t("user.attr.sex.female")} <Tag color="magenta">♀</Tag>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("register.item.organization")} name="_org">
            <TreeSelect
              placeholder={t("register.item.selorganization")}
              showSearch
              fieldNames={{ label: "cName", value: "id" }}
              treeDefaultExpandAll
              treeData={treeData}
            />
          </Form.Item>
          <Form.Item label={t("user.attr.zwid")} name="zwid">
            <Select
              placeholder={t("register.item.seljob")}
              options={Array.from(zwMap || [], (el) => ({
                value: el[1]?.id,
                label: el[1]?.cName,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={t("user.attr.tel")}
            name="tel"
            rules={formRoles.tel}
          >
            <Input placeholder={t("register.item.modifyphone")} />
          </Form.Item>
          <Form.Item
            label={t("register.item.state")}
            name="valid"
            className="hidden"
          >
            <span className="ant-form-text">{chvalid}</span>
          </Form.Item>
          <Form.Item
            label={t("user.attr.mail")}
            name="mail"
            rules={formRoles.mail}
          >
            <Input placeholder={t("register.item.mailbox")} />
          </Form.Item>
        </Form>
        <div style={{ textAlign: "right", marginTop: "30px" }}>
          <Space>
            <Button
              type="primary"
              loading={editLoading}
              onClick={onUpdateRegister}
            >
              {t("register.item.submit")}
            </Button>
            <Button onClick={() => setEditModalShow(false)}>
              {t("system.confirm.cancel")}
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterCenterPage;
