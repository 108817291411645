import { useCallback, useEffect, useState, type FC } from "react";
import CustomHeader from "../home/CustomHeader";
import DPlayer from "dplayer";
import { useLocale } from "../../hooks/useLocale";
import {
  Button,
  Checkbox,
  Form,
  message,
  Modal,
  Radio,
  Space,
  Tag,
  Typography,
  Image,
} from "antd";
import { useParams } from "react-router-dom";
import {
  compareAnswers,
  getScoreVideoById,
  updateWatchDurationById,
} from "../../api/LabSettingController";
import AnswerCard from "./AnswerCard";
import { getRandomCollection } from "../../utils/random";
import { shuffle } from "lodash-es";
import { type Question } from "../exam/library";
import { keyEncode } from "../../utils/format";
import i18n from "./i18n";

export interface AnswerSheet extends Question {
  answerSheet?: {
    answerResult: boolean;
    userAnswer: string;
    c_analysis: string;
    // createTime: string;
    // id: string;
    // questionId: string;
    mtAnswer: string;
  };
}

interface Ans {
  answerResult: boolean;
  question: Question;
  userAnswer: string;
}

const questionTypes: any = {
  1: "单选题",
  2: "多选题",
  3: "判断题",
};

const formRules: FormRules = {
  _answer: [{ required: true, message: "请选择答案" }],
};

const PlayPage: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [player, setPlayer] = useState<DPlayer>();
  const [lab, setLab] = useState<any>();
  const [questionList, setQuestionList] = useState<AnswerSheet[]>();
  const [askList, setAskList] = useState<Question[]>();
  const [askOpen, setAskOpen] = useState(false);
  const [ask, setAsk] = useState<Question>();
  const [ans, setAns] = useState<Ans>();
  const [duration, setDuration] = useState<number>();
  const [timeline, setTimeline] = useState<number[]>();
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const { lang, t } = useLocale(i18n);
  const { recordId } = useParams();

  const initPlayer = useCallback(
    (url: string) => {
      const dp = new DPlayer({
        container: document.getElementById("player-container"),
        hotkey: false,
        video: { url },
        lang: lang === "en-US" ? "en-us" : "zh-cn",
      });

      setPlayer(dp);
    },
    [lang]
  );

  const getVideoInfo = useCallback(
    (id?: string) => {
      if (!id) {
        message.error(t("play.item.invalid"));
        return;
      }

      getScoreVideoById({ id }).then(({ data }) => {
        if (data.code) {
          const { lab, questionList, scoreVideoQuestionList } = data.data;

          for (let i of scoreVideoQuestionList) {
            const item = (questionList as AnswerSheet[]).find(
              (x) => x.n_question_id === i.questionId
            );
            if (item) {
              const { answerResult, userAnswer, c_analysis, mtAnswer } = i;
              item.answerSheet = {
                answerResult,
                userAnswer,
                c_analysis,
                mtAnswer,
              };
            }
          }

          const askedQidList: string[] = scoreVideoQuestionList.map(
            (x: any) => x.questionId
          );

          let forAskList = (questionList as AnswerSheet[]).filter(
            (q) => !askedQidList.includes(q.n_question_id)
          );
          forAskList = shuffle(forAskList);

          setLab(lab);
          setQuestionList(questionList);
          setAskList(forAskList);
          initPlayer(data.data.lab.cZyhome);
        }
      });
    },
    [initPlayer, t]
  );

  const commitWatchDuration = useCallback(
    (progress: number) => {
      const id = keyEncode(recordId || "");
      const watchDuration = keyEncode(progress.toString());
      updateWatchDurationById({ id, watchDuration });
    },
    [recordId]
  );

  const commitAnwser = () => {
    form.validateFields().then((d) => {
      if (Array.isArray(d._answer)) {
        d.userAnswer = d._answer.join("#&");
        delete d._answer;
      }
      d.userAnswer = keyEncode(d.userAnswer);

      setFormLoading(true);
      compareAnswers({
        ...d,
        n_question_id: ask?.n_question_id,
        scoreVideoId: recordId,
      })
        .then(({ data }) => {
          if (data.code) {
            const { answerResult, userAnswer, question } = data.data;
            setAns(data.data);
            setQuestionList((old) => {
              if (Array.isArray(old)) {
                const copy = [...old];
                const item = copy.find(
                  (x) =>
                    x.n_question_id ===
                    (data.data.question as Question).n_question_id
                );

                if (item) {
                  item.answerSheet = {
                    answerResult,
                    userAnswer,
                    c_analysis: question.c_analysis,
                    mtAnswer: question.mt_answer,
                  };
                }
                return copy;
              }
              return old;
            });
          }
        })
        .finally(() => setFormLoading(false));
    });
  };

  const moveTimeline = () => {
    setAskOpen(false);
    setAskList((list) => {
      list?.shift();
      return list;
    });

    setTimeline((line) => {
      line?.shift();
      return line;
    });
    setAns(undefined);
    player?.play();
  };

  const checkTimeline = useCallback(
    (crt: number) => {
      if (!player) return;

      if (timeline && timeline.length > 0) {
        if (crt === timeline[0]) {
          commitWatchDuration(crt);
          player.video.pause();
          player.fullScreen.cancel("browser");
          player.fullScreen.cancel("web");
          setAsk(askList?.[0]);
          setAskOpen(true);
        }
      }
    },
    [askList, commitWatchDuration, player, timeline]
  );

  useEffect(() => {
    // 获取视频信息，初始化播放器
    getVideoInfo(recordId);
  }, [getVideoInfo, recordId]);

  useEffect(() => {
    // 播放器事件绑定
    if (!player) return;
    const { video } = player;
    let supposedCurrentTime = 0;

    video.oncanplay = () => {
      // 获取视频时长
      setDuration(Math.trunc(video.duration));
    };

    video.ontimeupdate = () => {
      // 阻止快进和快退 https://stackoverflow.com/questions/11903545/how-to-disable-seeking-with-html5-video-tag
      if (!video.seeking) {
        supposedCurrentTime = video.currentTime;
      }
      const crt = Math.trunc(video.currentTime);
      checkTimeline(crt);
    };

    video.onseeking = () => {
      // 防止无限递归
      var delta = video.currentTime - supposedCurrentTime;
      if (Math.abs(delta) > 0.01) {
        player.notice("Seeking is disabled", 2000, 0.8);
        video.currentTime = supposedCurrentTime;
      }
    };

    video.onended = () => {
      supposedCurrentTime = 0;
      player.notice(t("play.item.endvideo"), 2000, 0.8);
      commitWatchDuration(Math.trunc(video.currentTime));
    };
  }, [checkTimeline, commitWatchDuration, player, t]);

  useEffect(() => {
    // 创建试题时间轴
    if (duration && duration !== 0 && askList?.length) {
      // 不在前10秒内弹题
      const line = getRandomCollection(10, duration, askList.length);
      // console.log("timeline: ", line);
      setTimeline(line);
    }
  }, [askList, duration]);

  const getChooses = () => {
    if (!ask) return;
    const { n_question_type } = ask;

    if (n_question_type === 1) {
      return (
        <Form.Item name="userAnswer" rules={formRules._answer}>
          <Radio.Group>
            <Space direction="vertical">
              {ask?.questionContent?.choiceList &&
                Object.keys(ask.questionContent.choiceList).map((symbol) => (
                  <Radio
                    value={ask?.questionContent?.choiceList?.[symbol]}
                    key={symbol}
                  >
                    {symbol}. {ask?.questionContent?.choiceList?.[symbol]}
                    <div className="">
                      <Image
                        src={ask?.questionContent?.choiceImgList?.[symbol]}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      );
    }

    if (n_question_type === 2) {
      return (
        <Form.Item name="_answer" rules={formRules._answer}>
          <Checkbox.Group>
            <Space direction="vertical">
              {ask?.questionContent?.choiceList &&
                Object.keys(ask.questionContent.choiceList).map((symbol) => (
                  <Checkbox
                    value={ask?.questionContent?.choiceList?.[symbol]}
                    key={symbol}
                  >
                    {symbol}. {ask?.questionContent?.choiceList?.[symbol]}
                    <div>
                      <Image
                        src={ask?.questionContent?.choiceImgList?.[symbol]}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Checkbox>
                ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      );
    }

    if (n_question_type === 3) {
      return (
        <Form.Item name="userAnswer" rules={formRules._answer}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="T">{t("play.item.correct")}</Radio>
              <Radio value="F">{t("play.item.error")}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      );
    }

    return t("play.item.notsupport");
  };

  return (
    <div className="play-page">
      <CustomHeader
        onCollapse={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
        showCollapse={false}
        logo
      />
      <div className="page-main-wrap">
        <div className="page-main">
          <Typography.Text className="block mb-4 text-lg">
            {lab?.cName}
          </Typography.Text>
          <div className="player-wraper">
            <div className="player-left-container">
              <div id="player-container"></div>
              <Typography.Paragraph className="block mb-4 text-base text-gray-500 mt-4">
                {lab?.cNote}
              </Typography.Paragraph>
            </div>
            {Array.isArray(questionList) && questionList.length > 0 && (
              <div className="player-right-container">
                <AnswerCard questions={questionList} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={t("play.item.answer")}
        open={askOpen}
        footer={null}
        closable={false}
        width={800}
        forceRender
        centered
      >
        <Typography.Paragraph>
          {ask && <Tag>{questionTypes?.[ask.n_question_type]}</Tag>}
          {ask?.c_question_name}
        </Typography.Paragraph>
        {ask?.questionContent?.titleImg && (
          <Image src={ask?.questionContent?.titleImg} className="max-w-full" />
        )}
        <Form
          form={form}
          disabled={Boolean(ans)}
          className="mt-2 max-h-100 overflow-y-auto"
        >
          {getChooses()}
        </Form>
        {ans && (
          <div>
            {ans.answerResult ? (
              <div className="text-green-500">{t("play.item.correctly")}</div>
            ) : (
              <div className="text-red-500">{t("play.item.wrong")}</div>
            )}
            <div>
              {t("play.item.answercorrect")}
              {ans.question.mt_answer.split("#&").map((x) => (
                <div key={x}>{x}</div>
              ))}
            </div>
            {ans.question.c_analysis && (
              <div>
                {t("play.item.analysis")}
                {ans.question.c_analysis}
              </div>
            )}
          </div>
        )}
        <div className="mt-4 text-right">
          {ans ? (
            <Button type="primary" onClick={moveTimeline}>
              {t("play.item.continuelearn")}
            </Button>
          ) : (
            <Button type="primary" onClick={commitAnwser} loading={formLoading}>
              {t("play.item.submitanswer")}
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PlayPage;
