import { useEffect } from "react";
import { downloadByURL } from "../../../utils/download";
import { EscapeReverseHTML } from "../../../utils/format";

type RichDisplayProps = {
  html?: string;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * 展示RichEditor生成的富文本时，增加部分行为
 */
const RichDisplay = ({ html, ...props }: RichDisplayProps) => {
  useEffect(() => {
    // 附件下载
    document
      .querySelectorAll<HTMLSpanElement>('span[data-w-e-type="attachment"]')
      .forEach((ele) => {
        ele.onclick = (e: any) => downloadByURL(e.target.dataset.link);
      });
  }, [html]);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: EscapeReverseHTML(html || ""),
      }}
      {...props}
    />
  );
};

export default RichDisplay;
