import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import { Rule } from "antd/lib/form";
import { useEffect, useState } from "react";
import {
  listAllMailByParams,
  addMail,
  deleteMail,
} from "../../../api/MailController";
import { useLocale } from "../../../hooks/useLocale";
import { cleanObject } from "../../../utils/format";
import i18n from "./i18n";

const { confirm } = Modal;

const formRoles: { [key: string]: Rule[] } = {
  eMail: [{ required: true, type: "email", message: "邮箱格式不正确" }],
};

const MailList = () => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [allMailList, setAllMailList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [formloading, setFormloading] = useState(false);
  const [total, setTotal] = useState(0);
  //setVisible
  const [visible, setVisible] = useState(false);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  //邮箱-列表
  const mailList = async () => {
    const { data } = await listAllMailByParams({});
    if (data.code) {
      setAllMailList(data.data);
    }
  };

  useEffect(() => {
    mailList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation]);

  //新增邮箱
  const onCommit = () => {
    form
      .validateFields()
      .then(async (d) => {
        const formdata = cleanObject({
          ...d,
        });
        console.log(formdata);
        setFormloading(true);
        const { data } = await addMail(formdata);
        setVisible(false);
        if (data.code) {
          mailList();
          message.success(t("declare.item.addsuccess"));
          setFormloading(false);
          form.resetFields();
          return;
        } else {
          setFormloading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(t("declare.item.checkinvalid"));
      });
  };

  return (
    <div className="bg-white  rounded">
      <Space className="mb-4 flex items-center justify-between">
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            {t("declare.item.add")}
          </Button>
        </Space>
      </Space>
      <Table
        size="middle"
        rowKey="id"
        bordered
        dataSource={allMailList}
        loading={loading}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: ["bottomCenter"],
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          total,
          showTotal: (total) =>
            `${t("table.pagination.all")}${total}${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({
              page,
              rows,
            });
          },
        }}
        columns={[
          {
            title: t("table.index"),
            width: 50,
            fixed: "left",
            align: "center",
            render: (val, row, index) => index + 1,
          },
          {
            title: t("declare.item.name"),
            dataIndex: "mailName",
            width: 100,
          },
          {
            title: t("declare.item.mail"),
            dataIndex: "eMail",
            width: 200,
          },
          {
            title: t("declare.item.operation"),
            width: 200,
            align: "center",
            render(value, row: any) {
              return (
                <Space>
                  <Button
                    size="small"
                    danger
                    onClick={() => {
                      confirm({
                        title: t("declare.item.suredel"),
                        icon: <ExclamationCircleOutlined />,
                        content: t("declare.item.restored"),
                        centered: true,
                        async onOk() {
                          const { data } = await deleteMail({
                            id: row.id,
                          });
                          if (data.code) {
                            mailList();
                            message.success(t("declare.item.successdel"));
                          } else {
                            message.error(t("declare.item.latertry"));
                          }
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    {t("declare.item.delete")}
                  </Button>
                </Space>
              );
            },
          },
        ]}
      ></Table>
      <Modal
        title={t("declare.item.addmail")}
        footer={null}
        destroyOnClose
        open={visible}
        forceRender
        width={500}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Form labelCol={{ span: 3 }} form={form}>
          <Form.Item label={t("declare.item.name")} name="mailName">
            <Input
              placeholder={t("declare.item.entername")}
              maxLength={15}
              showCount
            />
          </Form.Item>
          <Form.Item
            label={t("declare.item.mail")}
            name="eMail"
            rules={formRoles.eMail}
          >
            <Input placeholder={t("declare.item.entermail")} />
          </Form.Item>
        </Form>
        <Row>
          <Col span="6" offset="9">
            <Button
              size="middle"
              block
              type="primary"
              className=""
              loading={formloading}
              onClick={onCommit}
            >
              {t("declare.item.submit")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default MailList;
