const i18n = {
  "en-US": {
    "query.search": "Search",
    "query.search.placeholder": "Account, Name, or Course",
    "query.search.clear": "Clear",
    "query.query": "Query",
    "table.head.kcname": "Course Title",
    "table.head.fscore": "Best Result",
    "table.head.labname": "Experiment Title",
    "table.head.labreport": "Experiment Report",
    "table.head.scorelist": "Score List",
    "table.tools.statistics": "Statistics",
    "table.tools.summary": "Summary",
    "table.tools.export": "Export",
    "table.tools.excle": "To Excel",
    "table.tools.csv": "To CSV",
    "declare.results.number": "Serial Number",
    "declare.results.experimentTimes": "Experiment Times",
    "declare.results.score": "Score",
    "declare.results.startingTime": "Starting Time",
    "declare.results.endingTime": "Ending Time",
    "declare.results.experimentTime": "Experiment Time",
    "declare.results.experimentreport": "Experiment Report",
    "declare.results.type": "Type",
    "declare.results.result": "Result",
    "declare.results.view": "Click to View",
    "declare.results.unfinished": "(Unfinished)",
    "declare.results.exercise": "Exercise",
    "declare.results.exam": "Exam",
    "declare.results.passed": "Passed",
    "declare.results.failed": "Failed",
    "declare.results.simulation": "Results of simulation experiment",
    "declare.results.exerciseresult": "Exercise assessment results",
    "declare.results.starttime": "start time",
    "declare.results.answer": "number of answers/total number of questions",
    "declare.results.duration": "Duration of viewing",
    "declare.results.effectiveness": "effectiveness",
    "declare.results.effective": "effective",
    "declare.results.invalid": "invalid",
    "declare.results.answerdetails": "answer details",
    "declare.results.exportrecord": "export Answer Records",
    "declare.results.learnnum": "number of learning",
    "declare.results.maximumduration": "maximum learning duration",
    "declare.results.maximumschedule": "maximum learning schedule",
    "declare.results.delearnrecord": "learning records",
    "declare.results.learnrecord": "learning records",
    "declare.results.stem": "question stem",
    "declare.results.correct": "correct answer",
    "declare.results.written": "written answer",
    "declare.results.decision": "decision result",
    "declare.results.correctly": "answer correctly",
    "declare.results.wrong": "answer error",
    "declare.results.noanswer": "no answer",
    "declare.results.answertime": "answer time",
  },
  "zh-CN": {
    "query.search": "搜索",
    "query.search.placeholder": "查询账号、姓名或课程名称",
    "query.search.clear": "清除",
    "query.query": "查询",
    "table.head.kcname": "课程名称",
    "table.head.fscore": "最优成绩",
    "table.head.labname": "实验名称",
    "table.head.labreport": "实验报告",
    "table.head.scorelist": "成绩列表",
    "table.tools.statistics": "统计数据",
    "table.tools.summary": "汇总",
    "table.tools.export": "导出成绩表",
    "table.tools.excle": "导出Excel",
    "table.tools.csv": "导出CSV",
    "declare.results.number": "序号",
    "declare.results.experimentTimes": "实验次数",
    "declare.results.score": "分数",
    "declare.results.startingTime": "开始时间",
    "declare.results.endingTime": "结束时间",
    "declare.results.experimentTime": "实验用时",
    "declare.results.experimentreport": "实验报告",
    "declare.results.type": "类型",
    "declare.results.result": "结果",
    "declare.results.view": "点击查看",
    "declare.results.unfinished": "（未填写）",
    "declare.results.exercise": "练习",
    "declare.results.exam": "考试",
    "declare.results.passed": "及格",
    "declare.results.failed": "不及格",
    "declare.results.simulation": "仿真实验成绩",
    "declare.results.exerciseresult": "习题考核成绩",
    "declare.results.starttime": "开始时间",
    "declare.results.answer": "已答题数/总题数",
    "declare.results.duration": "观看时长",
    "declare.results.effectiveness": "有效性",
    "declare.results.effective": "有效",
    "declare.results.invalid": "无效",
    "declare.results.answerdetails": "答题详情",
    "declare.results.exportrecord": "导出答题记录",
    "declare.results.learnnum": "学习次数",
    "declare.results.maximumduration": "最大学习时长",
    "declare.results.maximumschedule": "最长学习进度",
    "declare.results.delearnrecord": "的学习记录",
    "declare.results.learnrecord": "学习记录",
    "declare.results.stem": "题干",
    "declare.results.correct": "正确答案",
    "declare.results.written": "所填答案",
    "declare.results.decision": "判定结果",
    "declare.results.correctly": "回答正确",
    "declare.results.wrong": "回答错误",
    "declare.results.noanswer": "未作答",
    "declare.results.answertime": "答题时间",
  },
};

export default i18n;
