const i18n = {
  "en-US": {
    "authority.item.selectentity":
      "Select the organization entity before performing the operation",
    "authority.item.savepermission": "Save permission and role Settings",
    "authority.item.opreationsuccess": "Successful operation",
    "authority.item.collection": "A role is a collection of privileges.",
    "authority.item.roles": "Roles and permissions",
    "authority.item.editview": "Edit view",
    "authority.item.save": "Save",
    "authority.item.edit": "Edit",
    "authority.item.rolemanagement": "Role management",
    "authority.item.unableview":
      "Unable to view organization permissions. Because only the user entity has roles and permissions. But you can still edit",
    "authority.item.authority": "authority",
    "authority.item.norole": "No role data",
    "authority.item.npassign": "No assigned roles,",
    "authority.item.clickedit": "Click edit",
    "authority.item.role": "role",
    "authority.item.other": "other",
    "authority.item.createrole": "Role successfully created",
    "authority.item.entername": "Please enter a role name",
    "authority.item.modifyrolename": "Role Name Modified",
    "authority.item.delsuccess": "Delete succeeded",
    "authority.item.permissionsupdate": "Permissions updated",
    "authority.item.rolename": "Role name",
    "authority.item.createroles": "Create roles",
    "authority.item.createnewroles": "Create a new role",
    "authority.item.modifyname": "Modify Name",
    "authority.item.deleteroles": "Delete Roles",
    "authority.item.norolecreate": "No role created",
    "authority.item.allpermission": "all permissions",
    "authority.item.allpermissions": "all permissions",
    "authority.item.return": "return",
    "authority.item.selrole": "Select roles to view permissions",
    "authority.item.rightmap": "Rightmap did not complete initialization",
    "authority.item.notassign": "This role has not been assigned permissions.",
    "authority.item.suredel": "Do you want to delete roles",
    "authority.item.notchange":
      "? The user permissions associated with this role will not change until you modify them manually.",
    "authority.item.overwriteuser": "Are you sure you want to overwrite user",
    "authority.item.allpermissionset": "all permission settings?",
    "authority.item.cover": "Are you sure you want to cover the organization",
    "authority.item.all": "the roles and permissions set for all users in?",
    "authority.item.operationsuccess": "Operation succeeded, ",
    "authority.item.updated": "users has updated permission settings",
  },
  "zh-CN": {
    "authority.item.selectentity": "请先选择组织实体，再进行操作",
    "authority.item.savepermission": "保存权限和角色设置",
    "authority.item.opreationsuccess": "操作成功",
    "authority.item.collection": "角色是一组权限的集合。",
    "authority.item.roles": "角色与权限",
    "authority.item.editview": "编辑视图",
    "authority.item.save": "保存",
    "authority.item.edit": "编辑",
    "authority.item.rolemanagement": "角色管理",
    "authority.item.unableview":
      "无法查看组织的权限。因为仅用户实体拥有角色与权限。但您仍可进行编辑",
    "authority.item.authority": "权限",
    "authority.item.norole": "无角色数据",
    "authority.item.npassign": "无已分配的角色，",
    "authority.item.clickedit": "点击编辑",
    "authority.item.role": "角色",
    "authority.item.other": "其他",
    "authority.item.createrole": "已成功创建角色",
    "authority.item.entername": "请输入角色名称",
    "authority.item.modifyrolename": "已修改角色名称",
    "authority.item.delsuccess": "删除成功",
    "authority.item.permissionsupdate": "权限已更新",
    "authority.item.rolename": "角色名称",
    "authority.item.createroles": "创建角色",
    "authority.item.createnewroles": "创建新角色",
    "authority.item.modifyname": "修改名称",
    "authority.item.deleteroles": "删除角色",
    "authority.item.norolecreate": "没有已创建的角色",
    "authority.item.allpermission": "的所有权限",
    "authority.item.allpermissions": "所有权限",
    "authority.item.return": "返回",
    "authority.item.selrole": "选择角色以查看权限",
    "authority.item.rightmap": "rightmap未完成初始化",
    "authority.item.notassign": "此角色尚未分配权限，",
    "authority.item.suredel": "要删除角色",
    "authority.item.notchange":
      "吗？与此角色关联的用户权限不会变化，直到您手动修改它们。",
    "authority.item.overwriteuser": "确定覆盖用户",
    "authority.item.allpermissionset": "的所有权限设置吗？",
    "authority.item.cover": "确定覆盖组织",
    "authority.item.all": "中所有用户的角色与权限设置吗？",
    "authority.item.operationsuccess": "操作成功, 已为",
    "authority.item.updated": "个用户更新权限设置",
  },
};

export default i18n;
