import { DeleteOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  message,
  Row,
  Col,
  Spin,
  Input,
  Tree,
  Space,
  Popconfirm,
  Button,
  Divider,
  Empty,
  Upload,
  Image,
  type TreeProps,
} from "antd";
import { flatMapDeep } from "lodash-es";
import { useState, useCallback, useEffect, type FC } from "react";
import { getParentKey } from ".";
import {
  showTeachTree,
  queryjiaoxueimgBypid,
  deleteImgTableFile,
  restoreBackUp,
  addImgTableFile,
} from "../../../../api/NewLabInfoController";
import { FileAcceptList } from "../../../../api/NewUpLoadController";
import HighligntKeyNode from "../../../components/HighligntKeyNode";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

const TeachPanel = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [tree, setTree] = useState<any[]>([]);
  const [flattenTree, setFlattenTree] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [imglist, setImglist] = useState<any[]>([]);
  const [crtNode, setCrtNode] = useState<any>();
  const [treeLoading, setTreeLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const getTreeData = useCallback(() => {
    setTreeLoading(true);
    showTeachTree({ pmid: labId })
      .then(({ data }) => {
        if (data.code) {
          setTree(data.data);
          setFlattenTree(() =>
            flatMapDeep(data.data, (item) => [item, item.children])
          );
        }
      })
      .finally(() => setTreeLoading(false));
  }, [labId]);

  const getImglist = (id: string) => {
    setFormLoading(true);
    queryjiaoxueimgBypid({ pmid: labId, pid: id })
      .then(({ data }) => {
        if (data.code) {
          setImglist(data.data);
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setFormLoading(false));
  };

  const handleDeleteImg = (id: number) => {
    deleteImgTableFile({ id }).then(({ data }) => {
      if (data.code) {
        getImglist(crtNode.id);
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const initialSet = () => {
    setTreeLoading(true);
    restoreBackUp({ pmid: labId, backType: "backteach" })
      .then(({ data }) => {
        if (data.code) {
          setSelectedKeys([]);
          getTreeData();
          message.success(t("Setupanels.item.resetteach"));
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setTreeLoading(false));
  };

  const onInputSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    let newExpandedKeys: (React.Key | null)[] = [];

    if (value) {
      newExpandedKeys = flattenTree
        .map((item) =>
          item.name?.includes(value) ? getParentKey(item.id, tree) : null
        )
        .filter((item, i, self) => item && self.indexOf(item) === i);
    }

    setExpandedKeys(newExpandedKeys);
    setSearch(value);
    setAutoExpandParent(true);
  };

  const setTreeNode = (data: any[]): any =>
    data?.map((item) => ({
      ...item,
      title: <HighligntKeyNode label={item.name || ""} keyword={search} />,
      children: item.children?.length ? setTreeNode(item.children) : null,
    }));

  const onNodeSelected: TreeProps["onSelect"] = (
    keys,
    { node }: { node: any }
  ) => {
    setSelectedKeys(keys);
    setCrtNode(node);

    if (keys.length > 0 && node.level === 2) getImglist(node.id);
  };

  const onNodeExpand: TreeProps["onExpand"] = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  useEffect(() => {
    getTreeData();
  }, [getTreeData]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Spin spinning={treeLoading}>
          <Input.Search
            allowClear
            placeholder={t("Setupanels.item.searchprompt")}
            onChange={onInputSearch}
          />
          <Tree
            className="rounded bg-gray-100 mt-4 h-126 overflow-y-auto"
            fieldNames={{ key: "id" }}
            selectedKeys={selectedKeys}
            onSelect={onNodeSelected}
            onExpand={onNodeExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={setTreeNode(tree)}
          />
        </Spin>
      </Col>
      <Col span={18}>
        {selectedKeys.length > 0 && crtNode.level === 2 ? (
          <Spin spinning={formLoading}>
            <div className="mb-4 text-right">
              <Space>
                <Popconfirm
                  title={t("Setupanels.item.saveprompt")}
                  placement="left"
                  onConfirm={initialSet}
                >
                  <Button
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "arrow-rotate-right"]}
                        className="mr-2"
                      />
                    }
                  >
                    {t("Setupanels.item.resetinitial")}
                  </Button>
                </Popconfirm>
              </Space>
            </div>
            <div className="h-126 overflow-y-auto overflow-x-hidden">
              <div className="h-30">
                <Uploader
                  onChange={() => getImglist(crtNode.id)}
                  pid={crtNode.id}
                  pmid={labId}
                />
              </div>
              <Divider orientation="left" plain>
                {t("Setupanels.item.promptpic")}
              </Divider>
              <PhotoList list={imglist} onDelete={handleDeleteImg} />
            </div>
          </Spin>
        ) : (
          <div className="h-full flex justify-center items-center">
            <Empty description={t("Setupanels.item.promptnode")} />
          </div>
        )}
      </Col>
    </Row>
  );
};

interface UploaderProps {
  onChange?: (url: string) => void;
  pid: string;
  pmid: string | number;
}

const Uploader: FC<UploaderProps> = ({ onChange, pid, pmid }) => {
  const { t } = useLocale(i18n);
  const beforeImgUpload = (file: File) =>
    new Promise<boolean>((resolve, reject) => {
      if (!FileAcceptList.image.includes(file.type)) {
        message.error(t("Setupanels.item.uploadimage"));
        return reject(false);
      }
      if (file.size / 1024 / 1024 > 1) {
        message.error(t("Setupanels.item.uploadpic"));
        return reject(false);
      }
      return resolve(true);
    });

  const manuallyUpload = (options: any) => {
    const data = new FormData();
    data.append("imgFile", options.file);
    data.append("pid", pid);
    data.append("pmid", pmid as string);

    addImgTableFile(data).then(({ data }) => {
      if (data.code) {
        onChange?.(data.data);
        message.success(t("Setupanels.item.uploadsuccess"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  return (
    <Upload.Dragger
      maxCount={1}
      showUploadList={false}
      accept={FileAcceptList.image.join()}
      beforeUpload={beforeImgUpload}
      customRequest={manuallyUpload}
      // fileList={experimentXlsxList}
      // beforeUpload={beforeUpload}
    >
      <p className="ant-upload-drag-icon">
        <FontAwesomeIcon
          icon={["fas", "cloud-arrow-up"]}
          size="3x"
          className="text-gray-300"
        />
      </p>
      <p className="text-gray-500">{t("Setupanels.item.clickordrag")}</p>
    </Upload.Dragger>
  );
};

interface PhotoListProps {
  list?: {
    id: number;
    imgName: string;
    pid: string;
  }[]; // 图片列表
  onDelete?: (id: number) => void;
}

const PhotoList = ({ list = [], onDelete }: PhotoListProps) => {
  // size: 590px * 290px
  const { t } = useLocale(i18n);
  const handleDelete = (id: number) => {
    onDelete?.(id);
  };

  return (
    <div className="w-full bg-gray-100 rounded p-2 select-none">
      {list?.length > 0 ? (
        list.map((item, index) => (
          <div className="flex h-20 mb-2 last:mb-0" key={item.id}>
            <div className="rounded h-full w-10 bg-white mr-2 flex justify-center items-center">
              {index + 1}
            </div>
            <div className="rounded h-full bg-gray-200 flex-grow flex justify-evenly items-center">
              <Image src={item.imgName} height="100%" />
              <Popconfirm
                title={t("Setupanels.item.suredelprompt")}
                placement="left"
                onConfirm={() => handleDelete(item.id)}
              >
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  title={t("Setupanels.item.delete")}
                />
              </Popconfirm>
            </div>
          </div>
        ))
      ) : (
        <Empty description={t("Setupanels.item.promptnotupload")} />
      )}
    </div>
  );
};

export default TeachPanel;
