import req from "../configs/axios";

// 通知列表
const listAllByParams = (data?: any) => {
  return req.post("NoticeController/listAllByParams", data);
};

// 更新已读
const updateHaveReadByLogin = (data?: any) => {
  return req.post("NoticeController/updateHaveReadByLogin", data);
};

export { listAllByParams, updateHaveReadByLogin };
