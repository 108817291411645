import {
  Button,
  Form,
  List,
  Avatar,
  message,
  Row,
  Col,
  Popover,
  Tag,
  Dropdown,
  Menu,
} from "antd";
import { useEffect, useState } from "react";
import { EscapeReverseHTML } from "../../utils/format";
import RichEditor, { RichDisplay } from "../components/RichEditor";
import { getOneMailById, addOneCommentFirst } from "../../api/MailController";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import defaultAvatar from "../../assets/images/avatar-default.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocale } from "../../hooks/useLocale";
import i18n from "./i18n";
import defaultLocale from "../../i18n/default";

const MobileQaReply = () => {
  const [replyList, setReplyList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [rich, setRich] = useState<string>();
  const [isValid, setIsValid] = useState<number>();
  const [commitloading, setCommitLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [closeTime, setCloseTime] = useState<string>();
  const [isUrgent, setIsUrgent] = useState<string>();
  const [userName, setuserName] = useState<string>();
  const [yName, setYName] = useState<string>();
  const [qaContent, setQaContent] = useState<any>();
  const [createTime, setCreateTime] = useState<string>();
  const [qutitle, setQuTitle] = useState<string>();
  const [userAvatar, setUserAvatar] = useState<string>();
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const [searchParams] = useSearchParams();
  const qaId = searchParams.get("qaId");
  const token = searchParams.get("token");
  const { t, lang, setLang } = useLocale(i18n);

  const changeLanguage = (language: SupportedLangType) => {
    if (language !== lang) {
      setLang(language);
      const nextLang = defaultLocale[language];
      message.info(`${nextLang["system.msg.switchlang"]} ${language}`);
    }
  };

  const getReplyList = async () => {
    const { data } = await getOneMailById({
      qaId: qaId,
      token: token,
    });
    if (data.code) {
      setReplyList(data.data.commentList);
      setTotal(data.data.commentList.length);
      if (data.data.isValid === 1) {
        data.data.isValid = t("mobile.item.resolved");
      } else if (data.data.isValid === 2) {
        data.data.isValid = t("mobile.item.processing");
      } else if (data.data.isValid === 3) {
        data.data.isValid = t("mobile.item.waitReply");
      }
      setIsValid(data.data.isValid);
      setuserName(data.data.userName);
      setYName(data.data.yName);
      setUserAvatar(data.data.userAvatar);
      setCreateTime(data.data.createTime);
      setQaContent(data.data.qaContent);
      setCloseTime(data.data.closeTime);
      if (data.data.isUrgent === 0) {
        data.data.isUrgent = (
          <Tag color="green">{t("mobile.item.ordinary")}</Tag>
        );
      } else if (data.data.isUrgent === 1) {
        data.data.isUrgent = <Tag color="red">{t("mobile.item.urgency")}</Tag>;
      }
      setIsUrgent(data.data.isUrgent);
      setQuTitle(data.data.qaTitle);
    }
  };
  useEffect(() => {
    getReplyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation, lang]);

  //回复
  const commitReply = async () => {
    setCommitLoading(true);
    const { data } = await addOneCommentFirst({
      articleId: qaId,
      content: rich,
      beiYong1: 1,
      token: token,
    });
    if (data.code) {
      message.success(t("mobile.item.replysuccess"));
      getReplyList();
      form.resetFields();
    }
    setCommitLoading(false);
  };

  //复制
  const copyCot = (id: any) => {
    copy(id);
    message.success(t("mobile.item.copyMsg"));
  };

  return (
    <div>
      <div
        className="flex px-2 pt-3"
        style={{ justifyContent: "space-between" }}
      >
        <div className="flex">
          <div>{qutitle}</div>
          <div className="ml-3 text-sm">
            <Popover
              className="relative max-w-md"
              placement="bottom"
              title={t("mobile.item.personalDetails")}
              content={
                <div>
                  <p>
                    {t("mobile.item.questionNumber")}：{qaId}{" "}
                    <CopyOutlined
                      className="cursor-pointer hover:text-blue-500 "
                      onClick={() => copyCot(qaId)}
                    />
                  </p>
                  <p>
                    {" "}
                    {t("mobile.item.questioner")}： {userName}
                    <br />
                    {yName === null ? (
                      ""
                    ) : (
                      <span className="ml-15">{yName}</span>
                    )}
                  </p>
                  <p>
                    {t("mobile.item.questionTime")}： {createTime}
                  </p>
                  <p>
                    {t("mobile.item.state")}： {isUrgent}
                  </p>
                  <p>
                    {t("mobile.item.schedule")}： {isValid}
                  </p>
                </div>
              }
            >
              <InfoCircleOutlined className="text-blue-500 text-lg cursor-pointer" />
            </Popover>
          </div>
        </div>
        <div className="" style={{ marginTop: "-8px" }}>
          <Dropdown
            className="mr-3"
            arrow
            placement="bottomRight"
            menu={{
              items: [
                {
                  key: 1,
                  label: "简体中文",
                  onClick: () => changeLanguage("zh-CN"),
                },
                {
                  key: 2,
                  label: "English",
                  onClick: () => changeLanguage("en-US"),
                },
              ],
            }}
          >
            <Button
              shape="circle"
              id="header-language"
              className="border-none"
              icon={<FontAwesomeIcon icon={["fas", "language"]} />}
            />
          </Dropdown>
        </div>
      </div>
      <div>
        <Row className="py-6 pl-3 mb-4 border-solid border-1 border-gray-200">
          <Col span={2} className="text-center">
            <Avatar
              src={userAvatar}
              icon={<img src={defaultAvatar} alt="avatar" />}
            />
          </Col>
          <Col span={20} className="ml-4">
            <h4 className="text-sm text-gray-400">
              {userName} {createTime}
            </h4>
            <div className="flex">
              <RichDisplay
                html={qaContent}
                className="text-sm text-gray-800 break-all"
              />
            </div>
          </Col>
        </Row>
      </div>
      <List
        itemLayout="vertical"
        rowKey="forumId"
        className="news-list border-solid border-1 border-gray-200 p-4"
        dataSource={replyList}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: "bottom",
          total,
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          showTotal: (total) =>
            `${t("table.pagination.all")} ${total} ${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        renderItem={(item) => (
          <List.Item className="relative">
            <List.Item.Meta
              title={
                <div className="text-sm text-gray-400">
                  {item.beiYong1 === 1
                    ? t("mobile.item.footer")
                    : item.userName}
                  <span> </span>
                  {item.createTime}
                </div>
              }
              avatar={
                <Avatar
                  src={item.userAvatar}
                  icon={<img src={defaultAvatar} alt="avatar" />}
                />
              }
              description={
                <RichDisplay
                  className="text-sm text-gray-800 break-all"
                  html={item.content}
                />
              }
            />
          </List.Item>
        )}
      />
      <div className="mt-8 reply">
        {isValid !== 1 ? (
          <>
            <Form
              labelCol={{ span: 2 }}
              form={form}
              initialValues={{ type: "1" }}
            >
              <Form.Item label="" name="qacContent">
                <RichEditor maxLength={5000} value={rich} onChange={setRich} />
              </Form.Item>
            </Form>
            <Row className="pb-3">
              <Col span="6" offset="9">
                <Button
                  size="large"
                  block
                  type="primary"
                  className=""
                  loading={commitloading}
                  onClick={commitReply}
                >
                  {t("mobile.item.submit")}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default MobileQaReply;
