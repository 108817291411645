import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  message,
  Tag,
  Row,
  Col,
  Spin,
  Input,
  Tree,
  Empty,
  Space,
  Popconfirm,
  Button,
  InputNumber,
  Divider,
  Radio,
  type TreeProps,
} from "antd";
import { flatMapDeep } from "lodash-es";
import { useState, useCallback, useEffect } from "react";
import { getParentKey } from ".";
import { useExperimentManage } from "..";
import {
  showStepTree,
  showtishiinfoByPmidAndStepindex,
  updateStepInfoByPmid,
  restoreBackUp,
} from "../../../../api/NewLabInfoController";
import { isVoid } from "../../../../utils/format";
import HighligntKeyNode from "../../../components/HighligntKeyNode";
import QuestionPicker from "../../../components/QuestionPicker";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

interface StepTreeNodeData {
  id: string; // 步骤id
  pid: number; // 步骤父id
  stepinfo: string; // 步骤描述
  steptreetablevel: "smallStep" | "bigStep"; // 步骤级别
  children: StepTreeNodeChildData[];
}

interface StepTreeNodeChildData extends Omit<StepTreeNodeData, "children"> {
  scoreflicker: string; // 闪光提示扣分
  scoretishi: string; // 操作提示扣分
  smallstepid: string;
  stepindex: number; // 小步骤序号
  type: number; //
  wrongoption: string; // 语音提示文本
}

const stepFormRules: FormRules = {
  stepinfo: [{ required: true, message: "请输入步骤信息" }],
  wrongoption: [{ required: true, message: "请输入语音提示" }],
  qList: [
    { required: false },
    {
      validator(_, value: any[]) {
        if (value?.every((x) => !isVoid(x?.f_points))) return Promise.resolve();
        return Promise.reject(new Error("请输入试题分值"));
      },
    },
  ],
};

const StepPanel = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [tree, setTree] = useState<StepTreeNodeData[]>([]);
  const [flattenTree, setFlattenTree] = useState<
    (StepTreeNodeData | StepTreeNodeChildData)[]
  >([]);
  const [search, setSearch] = useState("");
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [form] = Form.useForm();
  const [crtNode, setCrtNode] = useState<any>();
  const [treeLoading, setTreeLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const { setQuestionPickerOpen, questionPicked, setQuestionPicked, qRef } =
    useExperimentManage();

  const getTreeData = useCallback(() => {
    setTreeLoading(true);
    showStepTree({ pmid: labId })
      .then(({ data }) => {
        if (data.code) {
          setTree(data.data);
          setFlattenTree(() =>
            flatMapDeep(data.data, (item) => [item, item.children])
          );
        }
      })
      .finally(() => setTreeLoading(false));
  }, [labId]);

  const onNodeSelected: TreeProps["onSelect"] = (
    keys,
    { node }: { node: any }
  ) => {
    setSelectedKeys(keys);
    setCrtNode(node);
    form.resetFields();
    if (node.steptreetablevel === "smallStep" && keys.length !== 0)
      getInfo(node.stepindex);
    form.setFieldsValue(node);
  };

  const onNodeExpand: TreeProps["onExpand"] = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const getInfo = (stepindex: number) => {
    setFormLoading(true);
    showtishiinfoByPmidAndStepindex({ pmid: labId, stepindex })
      .then(({ data }) => {
        if (data.code) {
          // console.log(data.data.questionList);
          setQuestionPicked(data.data.questionList);
          form.setFieldsValue({
            tishilist: data.data.tishiList,
            // qList: data.data.questionList,
          });

          return;
        }
      })
      .finally(() => setFormLoading(false));
  };

  const save = () => {
    form.validateFields().then((raw) => {
      // console.log(raw);
      setSaving(true);
      updateStepInfoByPmid({
        ...raw,
        pmid: labId,
        stepid: selectedKeys[0],
        stepindex: crtNode.stepindex,
        steptreetablevel: crtNode.steptreetablevel,
      })
        .then(({ data }) => {
          if (data.code) {
            getTreeData();
            message.success(t("Setupanels.item.savestep"));
            return;
          }
          data?.msg && message.error(data.msg);
        })
        .finally(() => setSaving(false));
    });
  };

  const initialSet = () => {
    setTreeLoading(true);
    restoreBackUp({ pmid: labId, backType: "backstep" })
      .then(({ data }) => {
        if (data.code) {
          setSelectedKeys([]);
          getTreeData();
          message.success(t("Setupanels.item.resetstep"));
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setTreeLoading(false));
  };

  const onInputSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    let newExpandedKeys: (React.Key | null)[] = [];

    if (value) {
      newExpandedKeys = flattenTree
        .map((item) =>
          item.stepinfo.includes(value) ? getParentKey(item.id, tree) : null
        )
        .filter((item, i, self) => item && self.indexOf(item) === i);
    }

    setExpandedKeys(newExpandedKeys);
    setSearch(value);
    setAutoExpandParent(true);
  };

  const setTreeNode = (data: any[]): any =>
    data?.map((item, index) => ({
      ...item,
      title: (
        <>
          {item?.steptreetablevel === "smallStep" && (
            <Tag color="#ff8484">{index + 1}</Tag>
          )}
          <HighligntKeyNode label={item.stepinfo} keyword={search} />
        </>
      ),
      children: item.children?.length ? setTreeNode(item.children) : null,
    }));

  useEffect(() => {
    getTreeData();
  }, [getTreeData]);

  useEffect(() => {
    const qList = questionPicked.map((x, i) => ({
      c_question_name: x.c_question_name,
      n_question_id: x.n_question_id,
      n_question_type: x.n_question_type,
      f_points: x.f_points,
      order: i,
    }));

    form.setFieldsValue({ qList });
  }, [form, questionPicked]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Spin spinning={treeLoading}>
          <Input.Search
            allowClear
            placeholder={t("Setupanels.item.searchstep")}
            onChange={onInputSearch}
          />
          <Tree
            className="rounded bg-gray-100 mt-4 h-126 overflow-y-auto"
            fieldNames={{ key: "id" }}
            selectedKeys={selectedKeys}
            onSelect={onNodeSelected}
            onExpand={onNodeExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={setTreeNode(tree)}
          />
        </Spin>
      </Col>
      <Col span={18}>
        {selectedKeys.length === 0 ? (
          <div className="h-full flex justify-center items-center">
            <Empty description={t("Setupanels.item.selectstep")} />
          </div>
        ) : (
          <Spin spinning={formLoading}>
            <div className="mb-4 text-right">
              <Space>
                <Popconfirm
                  title={t("Setupanels.item.delstep")}
                  placement="left"
                  onConfirm={initialSet}
                >
                  <Button
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "arrow-rotate-right"]}
                        className="mr-2"
                      />
                    }
                  >
                    {t("Setupanels.item.resetinitial")}
                  </Button>
                </Popconfirm>
                <Button type="primary" loading={saving} onClick={save}>
                  {t("Setupanels.item.savechange")}
                </Button>
              </Space>
            </div>
            <Form
              form={form}
              className="h-126 overflow-y-auto overflow-x-hidden"
            >
              <Form.Item
                name="stepinfo"
                label={t("Setupanels.item.stepinf")}
                rules={stepFormRules.stepinfo}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
              {crtNode?.steptreetablevel === "smallStep" ? (
                <>
                  <Form.Item
                    name="wrongoption"
                    label={t("Setupanels.item.voiceprompt")}
                    rules={stepFormRules.wrongoption}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                  <Form.Item
                    label={t("Setupanels.item.flashing")}
                    className="mb-0"
                  >
                    <Space>
                      <Form.Item name="scoreflicker">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item
                        label={t("Setupanels.item.deduct")}
                        name="scoretishi"
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Divider orientation="left" plain>
                    {t("Setupanels.item.stepprompt")}
                  </Divider>
                  <Form.List initialValue={[]} name="tishilist">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Row
                            key={field.key}
                            gutter={[16, 16]}
                            className="bg-gray-100 rounded p-2"
                            style={{ border: "1px dashed #ddd" }}
                          >
                            <Col span={20}>
                              <Form.Item
                                label={
                                  <>
                                    <Tag color="green">{field.name + 1}</Tag>
                                    {t("Setupanels.item.prompttiming")}
                                  </>
                                }
                                name={[field.name, "type"]}
                                initialValue={t("Setupanels.item.before")}
                              >
                                <Radio.Group>
                                  <Radio value={t("Setupanels.item.before")}>
                                    {t("Setupanels.item.stepbefore")}
                                  </Radio>
                                  <Radio value={t("Setupanels.item.after")}>
                                    {t("Setupanels.item.stepafter")}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                label={t("Setupanels.item.promptinf")}
                                className="mb-0"
                                name={[field.name, "tishitext"]}
                              >
                                <Input.TextArea rows={3} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Popconfirm
                                title={t("Setupanels.item.delmessage")}
                                placement="left"
                                onConfirm={() => remove(field.name)}
                              >
                                <Button
                                  icon={<DeleteOutlined />}
                                  block
                                  danger
                                  title={t("Setupanels.item.delete")}
                                  className="h-full"
                                />
                              </Popconfirm>
                            </Col>
                          </Row>
                        ))}
                        <Form.Item className="mt-4">
                          <Button
                            type="dashed"
                            block
                            icon={<PlusOutlined />}
                            onClick={() => add()}
                          >
                            {t("Setupanels.item.addprompt")}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Divider orientation="left" plain>
                    {t("Setupanels.item.testquestion")}
                  </Divider>
                  <Form.Item
                    name="qList"
                    // 开启校验后会有失焦问题
                    // rules={stepFormRules.qList}
                    // trigger=""
                    validateStatus="validating"
                    noStyle
                  >
                    <QuestionPicker.FormItem
                      rescored
                      onDelete={(qid) => qRef.current?.unpick(qid)}
                    />
                  </Form.Item>
                  <Button
                    type="dashed"
                    block
                    icon={<PlusOutlined />}
                    onClick={() => setQuestionPickerOpen(true)}
                  >
                    {t("Setupanels.item.addquestion")}
                  </Button>
                </>
              ) : null}
            </Form>
          </Spin>
        )}
      </Col>
    </Row>
  );
};

export default StepPanel;
