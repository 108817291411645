import { MenuProps, Modal, Row, Col, Menu } from "antd";
import { useState, useEffect } from "react";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";
import DemeritPanel from "./DemeritPanel";
import ManualScorePanel from "./ManualScorePanel";
import MultipleModulePanel from "./MultipleModulePanel";
import StepPanel from "./StepPanel";
import TeachInfoPanel from "./TeachInfoPanel";
import TeachPanel from "./TeachPanel";
import UniversalPanel from "./UniversalPanel";
import VideoPanel from "./VideoPanel";

interface SetupPanelProps {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
  labData?: any;
}

type PanelKeyType = "0" | "1" | "2" | "3" | "4" | "5" | "6";

export const getParentKey = (key: React.Key, tree: any[]): React.Key => {
  let parentKey: React.Key = "";
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

const SetupPanel = ({
  visible = false,
  setVisible,
  labData,
}: SetupPanelProps) => {
  const [panelKey, setPanelKey] = useState<PanelKeyType>("0");
  const { t } = useLocale(i18n);

  const onMenuChange: MenuProps["onClick"] = (info) => {
    setPanelKey(info.key as PanelKeyType);
  };

  const panels = {
    0: <UniversalPanel labId={labData?.id} />,
    1: <StepPanel labId={labData?.id} />,
    2: <TeachPanel labId={labData?.id} />,
    3: <DemeritPanel labId={labData?.id} />,
    4: <ManualScorePanel labId={labData?.id} />,
    5: <MultipleModulePanel labId={labData?.id} />,
    6: <VideoPanel labId={labData?.id} />,
    7: <TeachInfoPanel labId={labData?.id} />,
  };

  useEffect(() => {
    setPanelKey("0");
  }, [visible]);

  return (
    <Modal
      title={`${labData?.cName} - ${t("Setupanels.item.setting")}`}
      footer={null}
      destroyOnClose
      width={1000}
      open={visible}
      bodyStyle={{ padding: 0 }}
      onCancel={() => setVisible(false)}
    >
      <Row className="h-150">
        <Col span={4} style={{ borderRight: "1px solid #eee" }}>
          <Menu
            onClick={onMenuChange}
            selectedKeys={[panelKey]}
            className="border-none"
            items={
              [
                {
                  label: t("Setupanels.item.currency"),
                  key: "0",
                },
                labData?.nlabtype !== 9 && {
                  label: t("Setupanels.item.experimentsteps"),
                  key: "1",
                },
                labData?.nlabtype !== 9 && {
                  label: t("Setupanels.item.teachingtips"),
                  key: "2",
                },
                labData?.nlabtype !== 9 && {
                  label: t("Setupanels.item.automatic"),
                  key: "3",
                },
                labData?.nlabtype !== 9 && {
                  label: t("Setupanels.item.submitscore"),
                  key: "4",
                },
                {
                  label: t("Setupanels.item.exercisesetting"),
                  key: "6",
                },
                {
                  label: t("Setupanels.item.teachinginf"),
                  key: "7",
                },
                labData?.nlabtype === 4 && {
                  label: t("Setupanels.item.modulesetting"),
                  key: "5",
                },
              ].filter((item) => Boolean(item)) as any
            }
          />
        </Col>
        <Col span={20} className="p-6 h-full overflow-y-auto">
          {panels[panelKey]}
        </Col>
      </Row>
    </Modal>
  );
};

export default SetupPanel;
