import React, { useCallback, useEffect, useState } from "react";
import { doLogin, doLogout } from "../api/LoginController";
import { getCookie, getItem, rmItem, setItem } from "../utils/storage";
import {
  getOneUserByLogin,
  updateOneUserByLogin,
} from "../api/NewUserController";
import type { ReactNode } from "react";
import { listRightMenuByLogin } from "../api/NewRightRoleController";
import type { MenuItem } from "../router/menu";
import { keyEncode } from "../utils/format";

interface AuthForm {
  username: string;
  password: string;
  captchaCode: string;
}

interface User {
  id: number;
  loginID: string;
  mail: string;
  myac: string;
  name: string;
  sex: 1 | 2;
  tel: string;
  areaKey: any;
  logNumber: number;
  type: number;
}

type AuthContextValues = {
  user: User | null;
  login: (form: AuthForm) => Promise<any>;
  logout: () => Promise<void>;
  update: (form: any) => Promise<any>;
  menu: MenuItem[] | null;
  getMenu: () => Promise<void>;
  baseLoading: boolean;
  userLoading: boolean;
} | null;

const AuthContext = React.createContext<AuthContextValues>(null);
AuthContext.displayName = "Auth-Context";

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState(getItem("user"));
  const [menu, setMenu] = useState(null);
  const [baseLoading, setBaseLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);

  // 登录
  const login = async (form: AuthForm) => {
    setBaseLoading(true);
    form.password = keyEncode(form.password);
    const { data } = await doLogin(form);
    if (data.code) {
      setUser(data.data.userInfo);
      setItem("token", data.data.token);
      setItem("user", data.data.userInfo);
      return Promise.resolve(data);
    }
    return Promise.reject(data);
  };

  // 登出
  const logout = useCallback(async () => {
    const { data } = await doLogout();
    if (data.code) {
      setUser(null);
      rmItem("token");
      rmItem("user");
      return Promise.resolve(data);
    }
    return Promise.reject(data);
  }, []);

  // 修改个人信息
  const update = async (form: any) => {
    const { data } = await updateOneUserByLogin(form);
    if (data.code) {
      setUser(data.data);
      setItem("user", data.data);
      return Promise.resolve(data);
    }
    return Promise.reject(data);
  };

  // 个人菜单权限
  const getMenu = useCallback(async () => {
    const { data } = await listRightMenuByLogin();
    setBaseLoading(false);
    if (data?.code) {
      setMenu(data.data);
      return Promise.resolve(data);
    }
    setMenu(null);
    return Promise.reject(data);
  }, []);

  useEffect(() => {
    const vr_token = getCookie("vr_token");
    const token = getItem("token");
    const stale = getItem("user");

    if (!vr_token) return;

    setUserLoading(true);

    if (vr_token === token && stale?.id) {
      setUser(stale);
      setUserLoading(false);
    } else {
      getOneUserByLogin()
        .then(({ data }) => {
          if (data.code) {
            setItem("user", data.data);
            setItem("token", vr_token);
            setUser(data.data);
            return;
          }
        })
        .finally(() => setUserLoading(false));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        update,
        menu,
        getMenu,
        baseLoading,
        userLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
