import {
  Button,
  message,
  Typography,
  Image,
  Tag,
  Form,
  Radio,
  Checkbox,
  Space,
  Empty,
} from "antd";
import { shuffle } from "lodash-es";
import { useCallback, useEffect, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import {
  compareAnswers,
  getScoreVideoById,
} from "../../api/LabSettingController";
import { keyEncode } from "../../utils/format";
import { type Question } from "../exam/library";
import CustomHeader from "../home/CustomHeader";
import { type AnswerSheet } from "../play";
import AnswerCard from "../play/AnswerCard";
import { useLocale } from "../../hooks/useLocale";
import i18n from "../play/i18n";

const questionTypes: any = {
  1: "单选题",
  2: "多选题",
  3: "判断题",
};

interface Ans {
  answerResult: boolean;
  question: Question;
  userAnswer: string;
}

const formRules: FormRules = {
  _answer: [{ required: true, message: "请选择答案" }],
};

const PracticePage: FC = () => {
  const { t } = useLocale(i18n);
  const { recordId } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [lab, setLab] = useState<any>();
  const [questionList, setQuestionList] = useState<AnswerSheet[]>();
  const [askList, setAskList] = useState<Question[]>();
  const [ask, setAsk] = useState<Question>();
  //
  const [form] = Form.useForm();
  const [ans, setAns] = useState<Ans>();
  const [formLoading, setFormLoading] = useState(false);

  const getQuestions = useCallback(() => {
    if (!recordId) {
      message.error(t("play.item.invalid"));
      return;
    }

    getScoreVideoById({ id: recordId }).then(({ data }) => {
      if (data.code) {
        console.log(data.data);
        const { lab, questionList, scoreVideoQuestionList } = data.data;

        for (let i of scoreVideoQuestionList) {
          const item = (questionList as AnswerSheet[]).find(
            (x) => x.n_question_id === i.questionId
          );
          if (item) {
            const { answerResult, userAnswer, c_analysis, mtAnswer } = i;
            item.answerSheet = {
              answerResult,
              userAnswer,
              c_analysis,
              mtAnswer,
            };
          }
        }

        const askedQidList: string[] = scoreVideoQuestionList.map(
          (x: any) => x.questionId
        );

        let forAskList = (questionList as AnswerSheet[]).filter(
          (q) => !askedQidList.includes(q.n_question_id)
        );
        forAskList = shuffle(forAskList);

        setLab(lab);
        setQuestionList(questionList);
        setAskList(forAskList);
        setAsk(forAskList?.[0]);
      }
    });
  }, [recordId, t]);

  const nextQuestion = () => {
    setAskList((list) => {
      list?.shift();
      setAsk(list?.[0]);
      return list;
    });

    setAns(undefined);
  };

  const commitAnwser = () => {
    form.validateFields().then((d) => {
      if (Array.isArray(d._answer)) {
        d.userAnswer = d._answer.join("#&");
        delete d._answer;
      }
      d.userAnswer = keyEncode(d.userAnswer);

      setFormLoading(true);
      compareAnswers({
        ...d,
        n_question_id: ask?.n_question_id,
        scoreVideoId: recordId,
      })
        .then(({ data }) => {
          if (data.code) {
            const { answerResult, userAnswer, question } = data.data;
            setAns(data.data);
            setQuestionList((old) => {
              if (Array.isArray(old)) {
                const copy = [...old];
                const item = copy.find(
                  (x) =>
                    x.n_question_id ===
                    (data.data.question as Question).n_question_id
                );

                if (item) {
                  item.answerSheet = {
                    answerResult,
                    userAnswer,
                    c_analysis: question.c_analysis,
                    mtAnswer: question.mt_answer,
                  };
                }
                return copy;
              }
              return old;
            });
          }
        })
        .finally(() => setFormLoading(false));
    });
  };

  const exit = () => {
    window.close();
  };

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const getChooses = () => {
    if (!ask) return;
    const { n_question_type } = ask;

    if (n_question_type === 1) {
      return (
        <Form.Item name="userAnswer" rules={formRules._answer}>
          <Radio.Group>
            <Space direction="vertical">
              {ask?.questionContent?.choiceList &&
                Object.keys(ask.questionContent.choiceList).map((symbol) => (
                  <Radio
                    value={ask?.questionContent?.choiceList?.[symbol]}
                    key={symbol}
                  >
                    {symbol}. {ask?.questionContent?.choiceList?.[symbol]}
                    <div className="">
                      <Image
                        src={ask?.questionContent?.choiceImgList?.[symbol]}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      );
    }

    if (n_question_type === 2) {
      return (
        <Form.Item name="_answer" rules={formRules._answer}>
          <Checkbox.Group>
            <Space direction="vertical">
              {ask?.questionContent?.choiceList &&
                Object.keys(ask.questionContent.choiceList).map((symbol) => (
                  <Checkbox
                    value={ask?.questionContent?.choiceList?.[symbol]}
                    key={symbol}
                  >
                    {symbol}. {ask?.questionContent?.choiceList?.[symbol]}
                    <div>
                      <Image
                        src={ask?.questionContent?.choiceImgList?.[symbol]}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Checkbox>
                ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      );
    }

    if (n_question_type === 3) {
      return (
        <Form.Item name="userAnswer" rules={formRules._answer}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="T">{t("play.item.correct")}</Radio>
              <Radio value="F">{t("play.item.error")}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      );
    }

    return t("play.item.notsupport");
  };

  return (
    <div className="practice-page">
      <CustomHeader
        onCollapse={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
        showCollapse={false}
        logo
      />
      <div className="page-main-wrap">
        <div className="page-main">
          <Typography.Text className="block mb-4 text-lg">
            {lab?.cName}
          </Typography.Text>
          <div className="player-wraper">
            <div className="player-left-container">
              {ask ? (
                <div className="answer-area">
                  <Typography.Paragraph>
                    {ask && <Tag>{questionTypes?.[ask.n_question_type]}</Tag>}
                    {ask?.c_question_name}
                  </Typography.Paragraph>
                  {ask?.questionContent?.titleImg && (
                    <Image
                      src={ask?.questionContent?.titleImg}
                      className="max-w-full"
                    />
                  )}
                  <Form
                    form={form}
                    disabled={Boolean(ans)}
                    className="mt-2 max-h-100 overflow-y-auto"
                  >
                    {getChooses()}
                  </Form>
                  {ans && (
                    <div>
                      {ans.answerResult ? (
                        <div className="text-green-500">
                          {t("play.item.correctly")}
                        </div>
                      ) : (
                        <div className="text-red-500">
                          {t("play.item.wrong")}
                        </div>
                      )}
                      <div>
                        {t("play.item.answercorrect")}
                        {ans.question.mt_answer.split("#&").map((x) => (
                          <div key={x}>{x}</div>
                        ))}
                      </div>
                      {ans.question.c_analysis && (
                        <div>
                          {t("play.item.analysis")}
                          {ans.question.c_analysis}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="mt-4">
                    {ans ? (
                      <Button type="primary" onClick={nextQuestion}>
                        {t("play.item.continuelearn")}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        onClick={commitAnwser}
                        loading={formLoading}
                      >
                        {t("play.item.submitanswer")}
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="answer-area">
                  <Empty description={t("play.item.noquestion")}>
                    <Button type="primary" onClick={exit}>
                      {t("play.item.exit")}
                    </Button>
                  </Empty>
                </div>
              )}
            </div>
            {Array.isArray(questionList) && questionList.length > 0 && (
              <div className="player-right-container">
                <AnswerCard questions={questionList} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticePage;
