import {
  BankOutlined,
  CheckOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  FormOutlined,
  ReadOutlined,
  RedoOutlined,
  ReloadOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Card,
  Dropdown,
  Input,
  Menu,
  message,
  Popconfirm,
  Space,
  Spin,
  Switch,
  Tree,
  type TreeProps,
} from "antd";
import {
  type FC,
  type Key,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  addOneDeptOrDepartment,
  deleteOneDeptOrDepartment,
  queryDeptTree,
  updateOneDeptOrDepartment,
} from "../../../api/NewDeptController";
import type { MenuProps } from "antd";
import HighligntKeyNode from "../HighligntKeyNode";
import { getFlatten } from "../../../utils/format";
import Transition from "../Transition";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

type DepartmentTreeProps = {
  // 异步加载节点；默认开启，关闭后即全量加载节点（受控）
  async?: boolean;
  // 树层级深度，分别是院系/专业/班级/用户
  depth?: 1 | 2 | 3 | 4;
  // 是否允许编辑非用户节点
  allowEdit?: boolean;
  // 是否开启搜索功能
  allowSearch?: boolean;
  // 启用粘性布局
  sticky?: boolean;
  // 粘滞高度
  stickyTop?: number;
  // 节点选中事件（取消选中节点时回调参数为undefined）
  onNodeSelect?: (nodedata: NodeDataType | undefined) => void;
};

export type NodeDataType = {
  id: number | string;
  parentId: number;
  level: 1 | 2 | 3 | 4;
  name: string;
  children?: NodeDataType[];
};

export const nodeIcon: {
  [k in Required<DepartmentTreeProps>["depth"]]: ReactNode;
} = {
  1: <BankOutlined />,
  2: <ReadOutlined />,
  3: <TeamOutlined />,
  4: <UserOutlined />,
};

const getParentKey = (key: React.Key, tree: NodeDataType[]): React.Key => {
  let parentKey: React.Key = "";
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

const DepartmentTree: React.FC<DepartmentTreeProps> = ({
  async = false,
  depth = 3,
  allowEdit = false,
  allowSearch = false,
  sticky = false,
  stickyTop = 0,
  onNodeSelect,
}) => {
  const { t } = useLocale(i18n);
  const [treeData, setTreeData] = useState<NodeDataType[]>([]);
  const [flattenTree, setFlattenTree] = useState<NodeDataType[]>([]);
  const [contextNode, setContextNode] = useState<NodeDataType>();
  const [treeLoading, setTreeLoading] = useState(false);
  const [editingNodeKey, setEditingNodeKey] = useState<React.Key>();
  const [tagSearch, setTagSearch] = useState<string>();
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const getTree = useCallback(async () => {
    setTreeLoading(true);
    const { data } = await queryDeptTree({ level: depth });
    if (data?.code) {
      setTreeData(data.data);
      if (allowSearch) {
        setFlattenTree(getFlatten(data.data));
      }
    }
    setTreeLoading(false);
  }, [allowSearch, depth]);

  // 树搜索相关
  const onTagSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    let newExpandedKeys: (React.Key | null)[] = [];

    if (value) {
      newExpandedKeys = flattenTree
        .map((item) =>
          item.name.includes(value) ? getParentKey(item.id, treeData) : null
        )
        .filter((item, i, self) => item && self.indexOf(item) === i);
    }

    setExpandedKeys(newExpandedKeys);
    setTagSearch(value);
    setAutoExpandParent(true);
  };

  const onNodeExpand: TreeProps["onExpand"] = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  // context menu
  const handleAddNode = useCallback(
    (level: 1 | 2 | 3) => {
      const addNode = (tree: NodeDataType[], id: Key, node: NodeDataType) => {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].id === id) {
            if (Array.isArray(tree[i].children)) {
              tree[i].children?.push(node);
            } else {
              tree[i].children = [node];
            }
            break;
          }
          addNode(tree[i]?.children || [], id, node);
        }
      };

      const node: NodeDataType = {
        id: "_edit" as any,
        parentId: -1,
        level,
        name: "",
      };

      if (level === 1) {
        node.parentId = 0;
        setTreeData((tree) => {
          tree.push(node);
          return tree;
        });
      } else {
        node.parentId = contextNode!.id as number;
        setTreeData((tree) => {
          addNode(tree, contextNode!.id, node);
          return tree;
        });
        setExpandedKeys((keys) => [...keys, contextNode!.id]);
      }

      setEditingNodeKey("_edit");
    },
    [contextNode]
  );

  const deleteEditNode = () => {
    flattenTree.find((node) => node.id === "_edit");

    const rmTreeDataNode = (tree: NodeDataType[]) => {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id === "_edit") {
          tree.splice(i, 1);
          break;
        }
        rmTreeDataNode(tree[i]?.children || []);
      }
    };

    setTreeData((tree) => {
      rmTreeDataNode(tree);
      return tree;
    });
  };

  const onNodeDelete = (level: number, id: React.Key) => {
    deleteOneDeptOrDepartment({ level, id }).then(({ data }) => {
      if (data.code) {
        getTree();
        message.success(t("departmentTree.item.deletednode"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const onNodeUpdate = (node: NodeDataType) => {
    const { id, parentId, name, level } = node;
    if (id === "_edit") {
      return addOneDeptOrDepartment({ level, pid: parentId, name }).then(
        ({ data }) => {
          if (data.code) {
            message.success(t("departmentTree.item.addsuccess"));
            getTree();
            return;
          }
          data?.msg && message.error(data.msg);
        }
      );
    }
    return updateOneDeptOrDepartment({ level, id, name }).then(({ data }) => {
      if (data.code) {
        message.success(t("departmentTree.item.editsuccess"));
        getTree();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  useEffect(() => {
    getTree();
  }, [getTree]);

  const ctxMenu = useCallback(() => {
    if (!allowEdit) {
      return [
        {
          key: 2,
          label: (
            <Space onClick={getTree}>
              <ReloadOutlined /> {t("departmentTree.item.refresh")}
            </Space>
          ),
        },
      ];
    }

    return [
      {
        key: 1,
        label: (
          <Space onClick={() => handleAddNode(1)}>
            {nodeIcon[1]}
            {t("departmentTree.item.adddepartment")}
          </Space>
        ),
      },
      contextNode && { key: "title", type: "group", label: contextNode.name },
      contextNode?.level === 1 &&
        contextNode?.id !== "_edit" && {
          key: 3,
          label: (
            <Space onClick={() => handleAddNode(2)}>
              {nodeIcon[2]}
              {t("departmentTree.item.addmajor")}
            </Space>
          ),
        },
      contextNode?.level === 2 &&
        contextNode?.id !== "_edit" && {
          key: 3,
          label: (
            <Space onClick={() => handleAddNode(3)}>
              {nodeIcon[3]}
              {t("departmentTree.item.addclass")}
            </Space>
          ),
        },
      {
        key: 2,
        label: (
          <Space onClick={getTree}>
            <ReloadOutlined /> {t("departmentTree.item.refresh")}
          </Space>
        ),
      },
    ].filter((x) => Boolean(x)) as MenuProps["items"];
  }, [allowEdit, contextNode, getTree, handleAddNode, t]);

  const setTreeNodeData = (data: NodeDataType[]): any =>
    data?.map((item) => ({
      ...item,
      title: (
        <Node
          nodedata={item}
          editkey={editingNodeKey}
          setEditing={setEditingNodeKey}
          allowEdit={allowEdit}
          search={tagSearch}
          onNodeContextMenu={(node) => {
            setContextNode(node);
          }}
          onDelete={onNodeDelete}
          onUpdate={onNodeUpdate}
          onEmptyNodeUndo={deleteEditNode}
        />
      ),
      children: item.children?.length ? setTreeNodeData(item.children) : null,
    }));

  return (
    <Card
      className="w-70 min-h-50 bg-white rounded mr-4 h-min flex-shrink-0"
      style={
        sticky
          ? {
              position: "sticky",
              top: stickyTop || 0,
            }
          : undefined
      }
      size="small"
      title={t("departmentTree.item.organization")}
      extra={
        <Space>
          {allowSearch ? (
            <Input.Search
              className="w-40"
              size="small"
              allowClear
              placeholder={t("departmentTree.item.searchname")}
              onInput={onTagSearch}
            />
          ) : null}
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <div onClick={(e) => e.stopPropagation()}>
                      {t("departmentTree.item.fullload")}
                      <Switch defaultChecked />
                    </div>
                  ),
                },
              ],
            }}
          >
            <EllipsisOutlined />
          </Dropdown>
        </Space>
      }
    >
      <Spin spinning={treeLoading}>
        <Dropdown trigger={["contextMenu"]} menu={{ items: ctxMenu() }}>
          <div className="overflow-y-auto overflow-x-hidden">
            <Tree
              fieldNames={{ key: "id" }}
              defaultExpandAll
              className="h-full"
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onExpand={onNodeExpand}
              treeData={setTreeNodeData(treeData)}
              onSelect={(_keys, { selected, node }) =>
                onNodeSelect?.(selected ? (node as any) : undefined)
              }
            />
          </div>
        </Dropdown>
      </Spin>
    </Card>
  );
};

type NodeProps = {
  nodedata: NodeDataType;
  allowEdit: boolean;
  editkey?: React.Key;
  search?: string;
  setEditing: (editing?: React.Key) => void;
  onNodeContextMenu?: (node: NodeDataType) => void;
  onDelete?: (level: number, id: React.Key) => void;
  onEmptyNodeUndo?: () => void;
  onUpdate?: (node: NodeDataType) => Promise<any>;
};

const Node: FC<NodeProps> = ({
  nodedata,
  allowEdit,
  editkey,
  search,
  setEditing,
  onNodeContextMenu,
  onDelete,
  onEmptyNodeUndo,
  onUpdate,
}) => {
  const { t } = useLocale(i18n);
  const [nodeHover, setNodeHover] = useState(false);
  const [newName, setNewName] = useState<string>("");
  const onInputDone = () => {
    if (!newName) {
      message.warning(t("departmentTree.item.notempty"));
      return;
    }

    const { level, parentId, id } = nodedata;

    onUpdate?.({ level, parentId, id, name: newName }).finally(() => undo());
  };

  const onDeleteNode = () => {
    const { level, id } = nodedata;
    onDelete?.(level, id);
  };

  const undo = () => {
    setNewName(nodedata.name);
    setNodeHover(false);
    setEditing();
    if (nodedata.id === "_edit") {
      onEmptyNodeUndo?.();
    }
  };

  return (
    <Space
      onMouseOver={() => allowEdit && setNodeHover(true)}
      onMouseOut={() => allowEdit && setNodeHover(false)}
      onContextMenu={(e) => {
        // e.stopPropagation();
        onNodeContextMenu?.(nodedata);
      }}
      className="break-all"
    >
      <span>{nodeIcon?.[nodedata.level] || null}</span>
      {editkey === nodedata.id ? (
        <Space
          onBlur={(e) => {
            const nextDom = e.relatedTarget;
            if (!(nextDom && e.currentTarget.contains(nextDom))) {
              undo();
            }
          }}
        >
          <Input
            autoFocus
            size="small"
            value={newName}
            defaultValue={nodedata.name}
            onPressEnter={onInputDone}
            onInput={(e: any) => {
              setNewName(e.target.value);
            }}
          />
          <CheckOutlined
            title={t("departmentTree.item.save")}
            className="edit-icon "
            onClick={onInputDone}
          />
          <RedoOutlined
            title={t("departmentTree.item.cancel")}
            className="edit-icon"
            onClick={undo}
          />
        </Space>
      ) : (
        <Space>
          <HighligntKeyNode label={nodedata.name} keyword={search} />
          <Transition in={nodeHover} timeout={300} animation="slide-in">
            <Space>
              <FormOutlined
                title={t("departmentTree.item.edit")}
                className="edit-icon"
                onClick={(e) => {
                  setNewName(nodedata.name);
                  setEditing(nodedata.id);
                }}
              />
              <Popconfirm
                title={t("departmentTree.item.suredel")}
                placement="top"
                onConfirm={onDeleteNode}
              >
                <DeleteOutlined
                  title={t("departmentTree.item.delete")}
                  className="edit-icon"
                />
              </Popconfirm>
            </Space>
          </Transition>
        </Space>
      )}
    </Space>
  );
};

export default DepartmentTree;
