import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const MyPracticePage = () => {
  return (
    <div className="page-main">
      <CustomBreadcrumb breads={["考试", "我的练习"]} />
      我的练习
    </div>
  );
};

export default MyPracticePage;
