import {
  CopyOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import copy from "copy-to-clipboard";
import { useState } from "react";
import {
  getOneQaById,
  listQaAllByLogin,
  updateOneQaById,
} from "../../api/NewUserController";
import { useLocale } from "../../hooks/useLocale";
import { EscapeReverseHTML } from "../../utils/format";
import DeclareReplyList from "./declareReplyList";
import i18n from "./i18n";
import defaultAvatar from "../../assets/images/avatar-default.png";
import { RichDisplay } from "../components/RichEditor";
interface QuestionListItem {
  id: string;
  qaTitle: string;
  qaContent: string;
  createTime: string;
  closeTime: string;
  isUrgent: number;
  isValid: number;
}

interface MyQuestionProps {
  dataSource: QuestionListItem[];
  visible?: boolean;
  setVisible: (visible: boolean) => void;
}

const { confirm } = Modal;

const MyQuestion = (prop: MyQuestionProps) => {
  const { visible, setVisible } = prop;
  const { dataSource } = prop;
  const { t } = useLocale(i18n);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [quId, setQuId] = useState<string>();
  const [qaContent, setQaContent] = useState<any>();
  const [isValid, setIsValid] = useState<string>();
  const [closeTime, setCloseTime] = useState<string>();
  const [isUrgent, setIsUrgent] = useState<string>();
  const [userName, setuserName] = useState<string>();
  const [yName, setYName] = useState<string>();
  const [userAvatar, setUserAvatar] = useState<string>();
  const [createTime, setCreateTime] = useState<string>();
  const [questionVisible, setQuestionVisible] = useState(false);
  const [qutitle, setQuTitle] = useState<string>();

  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  const showQuestion = async (id: any) => {
    setQuId(id);
    setQuestionVisible(true);
    const { data } = await getOneQaById({ id: id });
    setuserName(data.data.userName);
    setYName(data.data.yName);
    setCreateTime(data.data.createTime);
    setUserAvatar(data.data.userAvatar);
    setQaContent(data.data.qaContent);
    setCloseTime(data.data.closeTime);
    setIsUrgent(data.data.isUrgent);
    if (data.data.isUrgent === 0) {
      data.data.isUrgent = (
        <Tag color="green">{t("declare.item.ordinary")}</Tag>
      );
    } else if (data.data.isUrgent === 1) {
      data.data.isUrgent = <Tag color="red">{t("declare.item.urgency")}</Tag>;
    }
    setIsUrgent(data.data.isUrgent);

    if (data.data.isValid === 1) {
      data.data.isValid = t("declare.item.resolved");
    } else if (data.data.isValid === 2) {
      data.data.isValid = t("declare.item.processing");
    } else if (data.data.isValid === 3) {
      data.data.isValid = t("declare.item.waitReply");
    }
    setIsValid(data.data.isValid);
  };

  const onClose = () => {
    setQuestionVisible(false);
  };

  //复制
  const copyCot = (id: any) => {
    copy(id);
    message.success(t("declare.item.copyMsg"));
  };

  return (
    <div>
      <Modal
        title={t("declare.item.question")}
        footer={null}
        destroyOnClose
        open={visible}
        forceRender
        width={1100}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Table
          size="middle"
          rowKey="id"
          bordered
          dataSource={dataSource}
          loading={loading}
          pagination={{
            size: "default",
            showQuickJumper: true,
            showSizeChanger: true,
            position: ["bottomCenter"],
            current: tablePagenation.page,
            pageSize: tablePagenation.rows,
            total,
            showTotal: (total) =>
              `${t("table.pagination.all")}${total}${t(
                "table.pagination.total"
              )}`,
            onChange: (page, rows) => {
              setTablePagenation({
                page,
                rows,
              });
            },
          }}
          columns={[
            {
              title: t("table.index"),
              width: 50,
              fixed: "left",
              align: "center",
              render: (val, row, index) => index + 1,
            },
            {
              title: t("declare.item.description"),
              dataIndex: "qaTitle",
              width: 150,
              render(text, row) {
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    onClick={() => {
                      showQuestion(row.id);
                      setQuTitle(text);
                    }}
                  >
                    {text}
                  </a>
                );
              },
            },
            {
              title: t("declare.item.qatime"),
              dataIndex: "createTime",
              width: 120,
            },
            {
              title: t("declare.results.endingTime"),
              dataIndex: "closeTime",
              width: 120,
            },
            {
              title: t("declare.item.state"),
              dataIndex: "isUrgent",
              width: 120,
              render(val, row) {
                if (val === 0) {
                  return <Tag color="green">{t("declare.item.ordinary")}</Tag>;
                } else if (val === 1) {
                  return <Tag color="red">{t("declare.item.urgency")}</Tag>;
                }
              },
            },
            {
              title: t("declare.item.schedule"),
              dataIndex: "isValid",
              width: 120,
              render(val, row) {
                if (val === 1) {
                  return t("declare.item.resolved");
                } else if (val === 2) {
                  return t("declare.item.processing");
                } else if (val === 3) {
                  return t("declare.item.waitReply");
                }
              },
            },
            {
              title: t("declare.item.Operation"),
              width: 200,
              align: "center",
              render(value, row: any) {
                return (
                  <Space>
                    {row.isValid !== 1 ? (
                      <Button
                        size="small"
                        danger
                        onClick={() => {
                          confirm({
                            title: t("declare.item.closeModelQuestion"),
                            icon: <ExclamationCircleOutlined />,
                            content: t("declare.item.closeModelTitle"),
                            centered: true,
                            async onOk() {
                              const { data } = await updateOneQaById({
                                id: row.id,
                              });
                              if (data.code) {
                                //   LoginQaList();
                                message.success(t("declare.item.closeMsg"));
                              } else {
                                message.error(t("declare.item.closeErrorMsg"));
                              }
                            },
                            onCancel() {},
                          });
                        }}
                      >
                        {t("declare.item.closeqa")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Space>
                );
              },
            },
          ]}
        ></Table>
      </Modal>
      {/* 提问详情 */}
      <Drawer
        title={
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <div className="flex">
              <div>{qutitle}</div>
              <div className="ml-3 text-sm">
                <Popover
                  className="relative"
                  placement="bottom"
                  title={t("declare.item.personalDetails")}
                  content={
                    <div className="max-h-60 max-w-md overflow-auto">
                      <p>
                        {t("declare.item.questionNumber")}：{quId}{" "}
                        <CopyOutlined
                          className="cursor-pointer hover:text-blue-500 "
                          onClick={() => copyCot(quId)}
                        />
                      </p>
                      <p>
                        {t("declare.item.questioner")}： {userName}
                        <br />
                        {yName === null ? (
                          ""
                        ) : (
                          <span className="ml-15">{yName}</span>
                        )}
                      </p>

                      <p>
                        {" "}
                        {t("declare.item.questionTime")}： {createTime}
                      </p>
                      <p>
                        {t("declare.item.schedule")}： {isValid}
                      </p>
                    </div>
                  }
                >
                  <InfoCircleOutlined className="text-blue-500 text-lg cursor-pointer" />
                </Popover>
              </div>
            </div>
            <div className="flex">
              <div className="text-sm mr-2 mt-1">
                {t("declare.item.state")}： {isUrgent}
              </div>
              <div className="mr-6 mt-1 text-sm">
                {t("declare.item.schedule")}：{isValid}
              </div>
            </div>
          </div>
        }
        width="50%"
        placement="right"
        onClose={onClose}
        open={questionVisible}
      >
        <div className="bg-white px-5 rounded">
          <div>
            <Row className="py-6 pl-3 mb-4 border-solid border-1 border-gray-200 relative">
              <Col span={1} className="text-center">
                <Avatar
                  src={userAvatar}
                  icon={<img src={defaultAvatar} alt="avatar" />}
                />
              </Col>
              <Col span={22} className="ml-4">
                <h4 className="text-sm text-gray-400">
                  {userName}
                  <span className="ml-1">{createTime}</span>
                </h4>
                <div className="flex">
                  <RichDisplay
                    html={qaContent}
                    className="text-base text-gray-800 break-all"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <DeclareReplyList
            showQuestion={showQuestion}
            quId={quId}
          ></DeclareReplyList>
        </div>
      </Drawer>
    </div>
  );
};
export default MyQuestion;
