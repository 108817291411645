const i18n = {
  "en-US": {
    "questionPicker.item.operation": "operation",
    "questionPicker.item.rank": "rank",
    "questionPicker.item.stem": "stem",
    "questionPicker.item.testtype": "Test question type",
    "questionPicker.item.seltest": "Select test questions",
    "questionPicker.item.searchtag": "Search tag",
    "questionPicker.item.seltype": "Select test question type",
    "questionPicker.item.searchstem": "Search stem",
    "questionPicker.item.abstract": "abstract",
    "questionPicker.item.tag": "tag",
    "questionPicker.item.score": "score",
    "questionPicker.item.accuracy": "accuracy rate",
    "questionPicker.item.error": "error rate",
    "questionPicker.item.difficulty": "difficulty factor",
    "questionPicker.item.confirmsel": "Confirm selection",
    "questionPicker.item.delete": "delete",
  },
  "zh-CN": {
    "questionPicker.item.operation": "操作",
    "questionPicker.item.rank": "排位",
    "questionPicker.item.stem": "题干",
    "questionPicker.item.testtype": "试题类型",
    "questionPicker.item.seltest": "选择试题",
    "questionPicker.item.searchtag": "搜索标签",
    "questionPicker.item.seltype": "选择试题类型",
    "questionPicker.item.searchstem": "搜索题干",
    "questionPicker.item.abstract": "摘选",
    "questionPicker.item.tag": "标签",
    "questionPicker.item.score": "分值",
    "questionPicker.item.accuracy": "正确率",
    "questionPicker.item.error": "错误率",
    "questionPicker.item.difficulty": "难度系数",
    "questionPicker.item.confirmsel": "确认选择",
    "questionPicker.item.delete": "删除",
  },
};

export default i18n;
