import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  message,
  Spin,
  InputNumber,
  Space,
  Popconfirm,
  Button,
} from "antd";
import { useState, useCallback, useEffect } from "react";
import {
  showAutoScore,
  addLabSetUpTimeByLabId,
  restoreBackUp,
} from "../../../../api/NewLabInfoController";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

const DemeritPanel = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState<number>();

  const getSetting = useCallback(() => {
    setLoading(true);
    showAutoScore({ pmid: labId })
      .then(({ data }) => {
        if (data.code) {
          setId(data.data?.autoScore?.id);
          form.setFieldsValue({
            setupLabTimeNum: data.data.setupLabTimeNum,
            scoreAuto: data.data?.autoScore?.scoreAuto,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [form, labId]);

  const save = () => {
    form.validateFields().then((raw) => {
      setSaving(true);
      addLabSetUpTimeByLabId({ ...raw, id, pmid: labId })
        .then(({ data }) => {
          if (data.code) {
            getSetting();
            message.success(t("Setupanels.item.savedsetting"));
            return;
          }
          data?.msg && message.error(data.msg);
        })
        .finally(() => setSaving(false));
    });
  };

  const initialSet = () => {
    restoreBackUp({ pmid: labId, backType: "backautoscore" }).then(
      ({ data }) => {
        if (data.code) {
          getSetting();
          message.success(t("Setupanels.item.resetsetting"));
          return;
        }
        data?.msg && message.error(data.msg);
      }
    );
  };

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Form.Item label={t("Setupanels.item.automatic")} name="scoreAuto">
          <InputNumber
            min={0}
            max={100}
            placeholder={t("Setupanels.item.score")}
          />
        </Form.Item>
        <Form.Item
          label={t("Setupanels.item.duration")}
          name="setupLabTimeNum"
          rules={[
            { required: true, message: t("Setupanels.item.enterduration") },
          ]}
        >
          <InputNumber min={0} addonAfter={t("Setupanels.item.minute")} />
        </Form.Item>
        <Space>
          <Popconfirm
            title={t("Setupanels.item.suredel")}
            placement="bottom"
            onConfirm={initialSet}
          >
            <Button
              icon={
                <FontAwesomeIcon
                  icon={["fas", "arrow-rotate-right"]}
                  className="mr-2"
                />
              }
            >
              {t("Setupanels.item.reset")}
            </Button>
          </Popconfirm>
          <Button type="primary" loading={saving} onClick={save}>
            {t("Setupanels.item.savechange")}
          </Button>
        </Space>
      </Form>
    </Spin>
  );
};

export default DemeritPanel;
