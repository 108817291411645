import { Button, Modal, Table, type TableProps, Tag } from "antd";
import moment, { duration } from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  queryMyScoreByLabIDAndUserID,
  queryScoreByLabIDAndUserID,
} from "../../../api/NewScoreController";
import { useLocale } from "../../../hooks/useLocale";
import { durationFormat } from "../../../utils/format";
import i18n from "./i18n";

interface ScoreListProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  onReportView?: (id: number) => void;
  param: {
    // 如果没有userid, 则查询登录者个人的成绩列表
    userid?: number;
    labid: number;
  } | null;
}

const ScoreList = (prop: ScoreListProps) => {
  const { visible, setVisible, param, onReportView } = prop;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const { t } = useLocale(i18n);
  const getScoreList = useCallback(() => {
    // 如果查询指定用户的成绩列表。没有userid, 则查询登录者个人的成绩列表
    const imap = () => {
      return param?.userid
        ? queryScoreByLabIDAndUserID
        : queryMyScoreByLabIDAndUserID;
    };

    setLoading(true);

    imap()({ ...param, ...tablePagenation })
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, [param, tablePagenation]);

  const viewReport = (id: number) => {
    onReportView?.(id);
  };

  useEffect(() => {
    if (visible && param) {
      setTableData([]);
      getScoreList();
    }
  }, [getScoreList, param, visible]);

  useEffect(() => {
    if (visible) {
      setTablePagenation({ page: 1, rows: 10 });
    }
  }, [visible]);

  return (
    <Modal
      title={t("table.head.scorelist")}
      width={1100}
      open={visible}
      footer={null}
      destroyOnClose
      onCancel={() => {
        setVisible?.(false);
      }}
    >
      <Table
        size="small"
        bordered
        rowKey="id"
        dataSource={tableData}
        loading={loading}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: ["bottomCenter"],
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          total,
          showTotal: (total) =>
            `${t("table.pagination.all")} ${total} ${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        columns={[
          {
            title: t("declare.results.experimentTimes"),
            dataIndex: "nLabnumber",
            align: "center",
            width: 50,
            sorter: (a, b) => a.nLabnumber - b.nLabnumber,
          },
          {
            title: t("declare.results.score"),
            dataIndex: "fScore",
            align: "center",
            width: 50,
            sorter: (a, b) => a.fScore - b.fScore,
          },
          {
            title: t("declare.results.startingTime"),
            dataIndex: "dStartime",
            align: "center",
            width: 120,
            sorter: (a, b) =>
              new Date(a.dStartime)?.getTime() -
              new Date(b.dStartime)?.getTime(),
          },
          {
            title: t("declare.results.endingTime"),
            dataIndex: "dEndtime",
            align: "center",
            width: 120,
          },
          {
            title: t("declare.results.experimentTime"),
            dataIndex: "dUsetime",
            align: "center",
            width: 120,
            render: (val) => durationFormat(val),
            sorter: (a, b) => a.dUsetime - b.dUsetime,
          },
          {
            title: t("declare.results.experimentreport"),
            align: "center",
            width: 70,
            render(_val, row) {
              return (
                <Button type="link" onClick={() => viewReport(row.id)}>
                  {t("declare.results.view")}
                  {!row?.labReportId && t("declare.results.unfinished")}
                </Button>
              );
            },
          },
          {
            title: t("declare.results.type"),
            dataIndex: "clabexamId",
            width: 50,
            render(val) {
              return val === null
                ? t("declare.results.exercise")
                : t("declare.results.exam");
            },
          },
          {
            title: t("declare.results.result"),
            dataIndex: "fScore",
            width: 50,
            render(val) {
              return val < 60 ? (
                <Tag color="#cd201f">{t("declare.results.failed")}</Tag>
              ) : (
                <Tag color="#87d068">{t("declare.results.passed")}</Tag>
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

export default ScoreList;
