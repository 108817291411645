import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Progress, Upload } from "antd";
import { useState, useEffect } from "react";
import {
  FileAcceptList,
  uploadManually,
} from "../../../api/NewUpLoadController";
import { useLocale } from "../../../hooks/useLocale";

interface UploaderProps {
  value?: string;
  onChange?: (data: string) => {};
  keyName?: string;
  placeholder?: string;
  size?: number;
}

const i18n = {
  "en-US": {
    error1: "JPG, PNG, GIF images Only",
    error2: "Please upload pictures up to 5MB",
    success: "Upload successfully",
    fail: "Upload failed",
    uploadimg: "Upload images here",
  },
  "zh-CN": {
    error1: "请上传png/jpg/gif格式的图片",
    error2: "请上传5MB以内的图片",
    success: "上传成功",
    fail: "上传失败",
    uploadimg: "在此处上传图片",
  },
};

type StatusType = "idle" | "loading" | "error" | "done";

const Uploader: React.FC<UploaderProps> = (prop) => {
  const { t } = useLocale(i18n);
  const { value, onChange, keyName, placeholder, size } = prop;
  const [status, setStatus] = useState<StatusType>("idle");
  const [progress, setProgress] = useState<number>(0);
  const [screenBackImg, setScreenBackImg] = useState<string>();

  const beforeImgUpload = (file: File) =>
    new Promise<boolean>((resolve, reject) => {
      if (!FileAcceptList.image.includes(file.type)) {
        message.error(t("error1"));
        return reject(false);
      }
      if (file.size / 1024 / 1024 > 5) {
        message.error(t("error2"));
        return reject(false);
      }
      return resolve(true);
    });

  const manuallyUpload = (options: any) => {
    setStatus("loading");

    const data = new FormData();
    data.append("file", options.file);
    data.append("keyName", keyName!);

    uploadManually(data, (e) =>
      setProgress(Math.floor((e.loaded / e.total) * 100))
    ).then(({ data }) => {
      if (data.code) {
        setScreenBackImg(data.data);
        setStatus("done");
        onChange?.(data.data);
        message.success(t("success"));
      } else {
        setStatus("error");
      }
    });
  };

  useEffect(() => {
    if (value) {
      setStatus("done");
      setScreenBackImg(value);
    }
  }, [value]);

  const dragStatus = {
    idle: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "cloud-arrow-up"]}
            size="3x"
            className="text-gray-300"
          />
        </p>
        <p className="text-gray-500">{placeholder}</p>
      </>
    ),

    loading: (
      <Progress
        strokeColor={{ "0%": "#f492f0", "100%": "#a18dce" }}
        percent={progress}
        width={size}
        status="active"
        type="circle"
      />
    ),

    error: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "circle-xmark"]}
            size="3x"
            className="text-red-500"
          />
        </p>
        <p className="ant-upload-text">{t("fail")}</p>
      </>
    ),

    done: (
      <img
        src={screenBackImg}
        alt=""
        className="w-full max-h-full object-contain"
      />
    ),
  } as const;

  return (
    <div
      className="bg-gray-100 overflow-hidden"
      style={{ width: size, height: size }}
    >
      <Upload.Dragger
        maxCount={1}
        showUploadList={false}
        accept={FileAcceptList.image.join()}
        beforeUpload={beforeImgUpload}
        customRequest={manuallyUpload}
        className="uploader"
      >
        {dragStatus[status]}
      </Upload.Dragger>
    </div>
  );
};

Uploader.defaultProps = {
  keyName: "schoolLogo",
  placeholder: "在此处上传图片",
  size: 100,
};

export default Uploader;
