const i18n = {
  "en-US": {
    "mine.item.course": "course",
    "mine.item.selecttype": "Select experiment type",
    "mine.item.all": "all",
    "mine.item.virtual": "Virtual simulation experiment",
    "mine.item.videocourse": "Video course",
    "mine.item.entername": "Enter the experiment name",
    "mine.item.detail": "Experiment details",
    "mine.item.teachinginf": "Teaching information",
    "mine.item.exercises": "Exercises assessment",
    "mine.item.watchvideo": "watch video",
    "mine.item.startexperiment": "start experiment",
    "mine.item.Unuploaded": "experiment not uploaded",
    "mine.item.mygrades": "my grades",
    "mine.item.since": "Has ended since:",
    "mine.item.deteaching": "teaching information",
    "mine.item.teaching": "teaching information",
  },
  "zh-CN": {
    "mine.item.course": "课程",
    "mine.item.selecttype": "选择实验类型",
    "mine.item.all": "全部",
    "mine.item.virtual": "虚拟仿真实验",
    "mine.item.videocourse": "视频课程",
    "mine.item.entername": "输入实验名称",
    "mine.item.detail": "实验详情",
    "mine.item.teachinginf": "教学信息",
    "mine.item.exercises": "习题考核",
    "mine.item.watchvideo": "观看视频",
    "mine.item.startexperiment": "开始实验",
    "mine.item.Unuploaded": "未上传实验",
    "mine.item.mygrades": "我的成绩",
    "mine.item.since": "已截止自：",
    "mine.item.deteaching": "的教学信息",
    "mine.item.teaching": "教学信息",
  },
};

export default i18n;
