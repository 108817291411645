import { Button, Divider, message, Modal, Space, Spin, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  addOrUpdateLabReport,
  addReportScore,
  outputlabreporthtml,
  queryLabLogAndLabreportByScoreId,
} from "../../../api/NewScoreController";
import { useLocale } from "../../../hooks/useLocale";
import { downloadByURL } from "../../../utils/download";
import { EscapeReverseHTML } from "../../../utils/format";
import { URLReg } from "../../../utils/regExp";
import RichEditor, { RichDisplay } from "../RichEditor";
import i18n from "./i18n";
import { findLabReportFormworkAll } from "../../../api/NewLabReportFormworkController";

interface LabReportProps {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
  scoreid?: string | number;
  allowEdit?: boolean;
  allowComment?: boolean;
}

const LabReport = (prop: LabReportProps) => {
  const {
    visible,
    setVisible,
    scoreid,
    allowEdit = false,
    allowComment = false,
  } = prop;
  const [loading, setLoading] = useState(false);
  const [commentEditorShow, setCommentEditorShow] = useState(false);
  const [reportEditorShow, setReportEditorShow] = useState(false);
  const [commentContent, setCommentContent] = useState<string>();
  const [reportContent, setReportContent] = useState<string>();

  const [d, setD] = useState<any>();
  const { t, lang } = useLocale(i18n);

  const getReport = useCallback(() => {
    setLoading(true);
    queryLabLogAndLabreportByScoreId({ scoreid: scoreid })
      .then(({ data }) => {
        if (data.code) {
          setD(data.data);
        }
      })
      .finally(() => setLoading(false));
  }, [scoreid]);

  const exportReport = () => {
    downloadByURL(`${outputlabreporthtml}?scoreId=${scoreid}&lang=${lang}`);
  };

  // 编辑实验报告
  const onEditReport = () => {
    setReportEditorShow(true);
    setReportContent(EscapeReverseHTML(d?.labreport?.lablr));
  };

  const saveReport = () => {
    addOrUpdateLabReport({ scoreid, lablr: reportContent }).then(({ data }) => {
      if (data.code) {
        message.success(t("system.msg.savesuccess"));
        setReportEditorShow(false);
        getReport();
      } else {
        data.msg && message.error(data.msg);
      }
    });
  };

  const resetReport = () => {
    Modal.confirm({
      title: t("report.msg.reset"),
      content: t("report.msg.resetconfirm"),
      async onOk() {
        findLabReportFormworkAll({ labId: d.labId }).then(({ data }) => {
          if (data.code) {
            setReportContent(EscapeReverseHTML(data?.data?.lablr));
            message.success(t("report.msg.resetdone"));
          } else {
            data?.msg && message.error(data.msg);
          }
        });
      },
    });
  };

  // 编辑评语
  const onEditComment = () => {
    if (!d.labReportId) {
      message.error(t("report.msg.not-filled"));
      return;
    }
    setCommentEditorShow(true);
    setCommentContent(EscapeReverseHTML(d?.labReportScore?.note));
  };

  const saveComment = () => {
    if (!d?.labreport?.id) {
      message.error(t("report.msg.cannot-save"));
      return;
    }
    addReportScore({ labReportId: d.labreport.id, note: commentContent }).then(
      ({ data }) => {
        if (data.code) {
          message.success(t("system.msg.savesuccess"));
          setCommentEditorShow(false);
          getReport();
        } else {
          data.msg && message.error(data.msg);
        }
      }
    );
  };

  useEffect(() => {
    if (visible && scoreid) {
      setD(undefined);
      setCommentEditorShow(false);
      setReportEditorShow(false);
      getReport();
    }
  }, [getReport, scoreid, visible]);

  const CommentButton = () => {
    if (commentEditorShow) {
      return (
        <Space>
          <Button onClick={() => setCommentEditorShow(false)}>
            {t("system.confirm.cancel")}
          </Button>
          <Button type="primary" onClick={saveComment}>
            {t("report.item.savecomment")}
          </Button>
        </Space>
      );
    }
    return (
      <Button onClick={onEditComment}>{t("report.item.editcomment")}</Button>
    );
  };

  const ReportButton = () => {
    if (reportEditorShow) {
      return (
        <Space className="mb-2">
          <Button onClick={() => setReportEditorShow(false)}>
            {t("system.confirm.cancel")}
          </Button>
          <Button danger onClick={resetReport}>
            {t("report.item.reset")}
          </Button>
          <Button type="primary" onClick={saveReport}>
            {t("report.item.savereport")}
          </Button>
        </Space>
      );
    }
    return (
      <Button onClick={onEditReport}>{t("report.item.editreport")}</Button>
    );
  };

  return (
    <Modal
      title={
        <>
          {t("modal.title")}
          {!d?.labReportId && (
            <Tag className="ml-2">{t("declare.results.unfinished")}</Tag>
          )}
        </>
      }
      width={800}
      centered
      open={visible}
      footer={null}
      destroyOnClose
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Spin size="large" spinning={loading} tip={t("system.tips.loadingtip")}>
        <Button type="primary" onClick={() => exportReport()}>
          {t("report.export")}
        </Button>
        <div className="block mt-4 w-full max-h-[650px] overflow-y-scroll">
          <table className="lab-report-table w-full">
            <tbody>
              <tr>
                <td className="w-1/2">
                  <span className="rt-row-name">{t("report.title")}</span>
                  <span>{d?.labName}</span>
                </td>
                <td className="w-1/2">
                  <span className="rt-row-name">{t("report.score")}</span>
                  <span>{d?.scoreNumber}</span>
                </td>
              </tr>
              <tr>
                <td className="w-1/2">
                  <span className="rt-row-name">{t("report.name")}</span>
                  <span>{d?.userName}</span>
                </td>
                <td className="w-1/2">
                  <span className="rt-row-name">{t("report.number")}</span>
                  <span>{d?.loginId}</span>
                </td>
              </tr>
              <tr>
                <td className="w-1/2">
                  <span className="rt-row-name">{t("report.starttime")}</span>
                  <span>{d?.staTime}</span>
                </td>
                <td className="w-1/2">
                  <span className="rt-row-name">{t("report.endtime")}</span>
                  <span>{d?.endTime}</span>
                </td>
              </tr>
              {d?.steperrorinfo && (
                <tr>
                  <td className="nonepad" colSpan={2}>
                    <div className="nonepad-inner all">
                      <div className="rt-row-name">{t("report.record")}</div>
                      <RichDisplay html={d?.steperrorinfo} />
                    </div>
                  </td>
                </tr>
              )}
              {d?.preferencesInfoList?.length > 0 && (
                <tr>
                  <td className="nonepad" colSpan={2}>
                    <div className="nonepad-inner all min-h-25">
                      <div className="rt-row-name">{t("report.param")}</div>
                      <div className="w-full">
                        {d?.preferencesInfoList?.map(
                          (el: any, index: number) => (
                            <div className="flex pb-4" key={index}>
                              <RichDisplay html={el?.parametersettig} />
                              <div>
                                {URLReg.test(el?.result) ? (
                                  <img
                                    className="max-w-full"
                                    src={el.result}
                                    alt=""
                                  />
                                ) : (
                                  <RichDisplay html={el?.result} />
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td className="nonepad" colSpan={2}>
                  <div className="nonepad-inner all min-h-25">
                    <div className="rt-row-name">{t("report.content")}</div>
                    <div className="rt-content">
                      {allowEdit ? <ReportButton /> : null}
                      {reportEditorShow ? (
                        <RichEditor
                          value={reportContent}
                          onChange={setReportContent}
                        />
                      ) : (
                        <RichDisplay html={d?.labreport?.lablr} />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="nonepad" colSpan={2}>
                  <div className="nonepad-inner">
                    <div className="rt-row-name">{t("report.comment")}</div>
                    <div className="w-full">
                      <div className="rtgrid-top">
                        <div className="rt-row-cell">
                          <span className="rt-row-name">
                            {t("report.reviewer")}
                          </span>
                          <span>{d?.labReportScore?.userName}</span>
                        </div>
                        <div className="rt-row-cell">
                          <span className="rt-row-name">
                            {t("report.reviewtime")}
                          </span>
                          <span>{d?.labReportScore?.createTime}</span>
                        </div>
                      </div>
                      <div className="min-h-25 p-1.5">
                        {commentEditorShow ? (
                          <RichEditor
                            value={commentContent}
                            onChange={setCommentContent}
                          />
                        ) : (
                          <RichDisplay html={d?.labReportScore?.note} />
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              {allowComment ? (
                <tr>
                  <td colSpan={2}>
                    <div className="text-right">
                      <CommentButton />
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </Spin>
    </Modal>
  );
};

export default LabReport;
