import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Progress, Upload } from "antd";
import { useState } from "react";
import {
  uploadManually,
  FileAcceptList,
} from "../../../api/NewUpLoadController";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

interface UploaderProps {
  src?: string;
  onChange?: (data: string) => void;
}

type StatusType = "idle" | "loading" | "error" | "done";

const VideoUploader = (prop: UploaderProps) => {
  const { t } = useLocale(i18n);
  const { src, onChange } = prop;
  const [status, setStatus] = useState<StatusType>("idle");
  const [progress, setProgress] = useState<number>(0);

  const manuallyUpload = (options: any) => {
    setStatus("loading");
    setProgress(0);
    const data = new FormData();
    data.append("file", options.file);
    data.append("keyName", "schoolLogo");

    uploadManually(data, (e) =>
      setProgress(Math.floor((e.loaded / e.total) * 100))
    ).then(({ data }) => {
      if (data.code) {
        setStatus("done");
        onChange?.(data.data);
      } else {
        setStatus("error");
      }
    });
  };

  const dragStatus = {
    idle: (
      <p>
        <FontAwesomeIcon
          icon={["fas", "cloud-arrow-up"]}
          size="2x"
          className="text-gray-300"
        />
        <span className="text-gray-500">{t("declare.item.uploadvideo")}</span>
      </p>
    ),

    loading: (
      <Progress
        strokeColor={{
          "0%": "#f492f0",
          "100%": "#a18dce",
        }}
        percent={progress}
        status="active"
      />
    ),

    error: (
      <div>
        <FontAwesomeIcon
          icon={["fas", "circle-xmark"]}
          size="2x"
          className="text-red-500"
        />
        <span className="text-gray-500">{t("declare.item.uploadfail")}</span>
      </div>
    ),

    done: (
      <div className="w-full max-h-full">
        {t("declare.item.uploadcomplete")}
      </div>
    ),
  } as const;

  return (
    <div className="h-15 bg-gray-100 overflow-hidden">
      <Upload.Dragger
        maxCount={1}
        showUploadList={false}
        accept={FileAcceptList.video.join()}
        customRequest={manuallyUpload}
        className="uploader"
      >
        {dragStatus[status]}
      </Upload.Dragger>
    </div>
  );
};

export default VideoUploader;
