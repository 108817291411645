import i18n from "./i18n";
import { useLocale } from "../../hooks/useLocale";
import {
  Col,
  List,
  Row,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  listAllByParams,
  getOneById,
} from "../../api/NewQuestionAnswerCommonController";
import { addOneQa, listQaAllByLogin } from "../../api/NewUserController";
import { cleanObject } from "../../utils/format";
import RichEditor, { RichDisplay } from "../components/RichEditor";
import MyQuestion from "./myQuestion";

const QuestionList = () => {
  const { t, lang } = useLocale(i18n);
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [quAnswerList, setQuAnswerList] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const [planvisible, setPlanvisible] = useState(false);
  const [rich, setRich] = useState<string>();

  const [formloading, setFormloading] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [dayiDetail, setDayiDetail] = useState<any>();
  const [qacTitle, setQacTitle] = useState<string>();
  const [createTime, setCreateTime] = useState<string>();

  const [loginQaList, setLoginQaList] = useState<any[]>([]);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  const { Option } = Select;
  const formRoles = {
    questionName: [{ required: true, message: t("declare.item.prodes") }],
  };

  const QuAnswerList = async () => {
    const { data } = await listAllByParams({ lang: lang });
    if (data.code) {
      setQuAnswerList(data.data.rows);
      setTotal(data.data.rows.length);
    }
  };

  useEffect(() => {
    QuAnswerList();
    LoginQaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setDetailVisible(false);
  };

  //登录人自己的提问列表
  const LoginQaList = async () => {
    const { data } = await listQaAllByLogin({ ...tablePagenation });
    if (data.code) {
      setLoginQaList(data.data.rows);
    }
  };

  //新增提问
  const onCommit = () => {
    form
      .validateFields()
      .then(async (d) => {
        const formdata = cleanObject({
          ...d,
        });
        console.log(formdata);
        setFormloading(true);
        const { data } = await addOneQa(formdata);
        setVisible(false);
        if (data.code) {
          QuAnswerList();
          message.success(t("declare.item.submitsuccess"));
          setFormloading(false);
          form.resetFields();
          LoginQaList();
        } else {
          setFormloading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(t("declare.item.check"));
      });
  };

  const showDetailDrawer = async (id: any) => {
    setDetailVisible(true);
    const { data } = await getOneById({ id: id });
    if (data.code) {
      setDayiDetail(data.data.qacContent);
      setQacTitle(data.data.qacTitle);
      setCreateTime(data.data.createTime);
    }
  };

  return (
    <div className="questionList">
      <div className="flex justify-between">
        <span className="text-base">{t("declare.item.answers")}</span>
        <div>
          <span
            className="text-blue-400 cursor-pointer text-base mr-5"
            onClick={() => {
              setPlanvisible(true);
            }}
          >
            {t("declare.item.question")}
          </span>
          <Button type="primary" onClick={showDrawer}>
            {t("declare.item.support")}
          </Button>
        </div>
      </div>
      <List
        itemLayout="vertical"
        rowKey="forumId"
        className="news-list border-solid border-1 border-gray-200 p-4 mt-5"
        dataSource={quAnswerList}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: "bottom",
          total,
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          showTotal: (total) =>
            `${t("table.pagination.all")} ${total} ${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        renderItem={(item) => (
          <List.Item>
            <Row
              gutter={10}
              align="middle"
              className="bbs-item cursor-pointer"
              onClick={() => {
                showDetailDrawer(item.id);
              }}
            >
              <Col
                span={19}
                className="bbsList-content"
                style={{ padding: "0px 20px 10px" }}
              >
                <div className="text-base text-blue-400">{item.qacTitle}</div>
              </Col>
              <Col
                span={5}
                className="bbsList-content"
                style={{ padding: "0px 20px 10px" }}
              >
                <div>{item.createTime}</div>
              </Col>
            </Row>
          </List.Item>
        )}
      />
      <Drawer
        title={t("declare.item.technical")}
        width="60%"
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <Form
            labelCol={{ span: 2 }}
            form={form}
            initialValues={{ isUrgent: "0" }}
          >
            <Form.Item
              label={t("declare.item.problem")}
              name="qaTitle"
              rules={formRoles.questionName}
            >
              <Input
                placeholder={t("declare.item.briefly")}
                maxLength={50}
                showCount
              />
            </Form.Item>
            <Form.Item label={t("declare.item.particulars")} name="qaContent">
              <RichEditor maxLength={5000} value={rich} onChange={setRich} />
            </Form.Item>
            <Form.Item label={t("declare.item.urgency")} name="isUrgent">
              <Select style={{ width: 120 }}>
                <Option
                  value="0"
                  style={{ background: "#f6ffed", color: "#389e0d" }}
                >
                  {t("declare.item.ordinary")}
                </Option>
                <Option
                  value="1"
                  style={{ background: "#fff1f0", color: "#cf1322" }}
                >
                  {t("declare.item.urgency")}
                </Option>
              </Select>
            </Form.Item>
          </Form>
          <Row>
            <Col span="6" offset="9">
              <Button
                size="large"
                block
                type="primary"
                className=""
                loading={formloading}
                onClick={onCommit}
              >
                {t("declare.item.submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
      <MyQuestion
        visible={planvisible}
        setVisible={setPlanvisible}
        dataSource={loginQaList}
      ></MyQuestion>
      {/* 答疑详情 */}
      <Drawer
        title={
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <div>{qacTitle}</div>
            <div className="text-sm">{createTime}</div>
          </div>
        }
        width="60%"
        placement="right"
        onClose={onClose}
        open={detailVisible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <RichDisplay html={dayiDetail} />
        </div>
      </Drawer>
    </div>
  );
};
export default QuestionList;
