const i18n = {
  "en-US": {
    "declare.item.loginNumber": "Number of logins",
    "declare.item.loginTime": "Last login time",
    "declare.item.experimentsnum": "Cumulative number of experiments",
    "declare.item.experimentstime": "Cumulative experiment duration",
    "declare.item.Personal": "Personal experiment profile statistics",
    "declare.item.Completion": "Completion of the experiment",
    "declare.item.pass": "Pass",
    "declare.item.fail": "Fail",
    "declare.item.notDone": "Not done",
    "declare.item.results": "Results of the last ten experiments",
  },
  "zh-CN": {
    "declare.item.loginNumber": "登陆次数",
    "declare.item.loginTime": "上次登陆时间",
    "declare.item.experimentsnum": "累计实验次数",
    "declare.item.experimentstime": "累计实验时长",
    "declare.item.Personal": "个人实验概况统计",
    "declare.item.Completion": "实验完成情况",
    "declare.item.pass": "及格",
    "declare.item.fail": "不及格",
    "declare.item.notDone": "未做",
    "declare.item.results": "最近十次实验成绩",
  },
};

export default i18n;
