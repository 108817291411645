import { Routes, Route, Navigate } from "react-router-dom";
import routes from "./config";
import * as AllComponents from "./entry";
import type { RouterInfo } from "./config";
import useAuth from "../hooks/useAuth";
import { flatMapDeep } from "lodash-es";
import { useMemo } from "react";
import { Spin } from "antd";
import { useLocale } from "../hooks/useLocale";
import { useSystemInfo } from "../hooks/useSystemInfo";

const RouteRender = (routes: RouterInfo[]) => {
  return routes.map((route) => {
    let Component = route?.component && (AllComponents as any)[route.component];

    if (route.redirect) {
      Component = () => <Navigate to={route.redirect!} replace />;
    }

    return (
      <Route
        path={route.path}
        key={route.path}
        element={
          Component ? (
            <RequireAuth route={route}>
              <Component />
            </RequireAuth>
          ) : undefined
        }
      >
        {route.children?.length ? RouteRender(route.children) : null}
      </Route>
    );
  });
};

const RequireAuth = ({
  route,
  children,
}: {
  route: RouterInfo;
  children: JSX.Element;
}) => {
  const { user, menu, baseLoading, userLoading } = useAuth();
  const menuArr = useMemo(
    () =>
      menu && menu.length > 0
        ? flatMapDeep(menu, (menuItem) => [menuItem, menuItem.child])
        : [],
    [menu]
  );

  if (route.login && !user?.id) {
    return userLoading ? <Loading /> : <Navigate to="/401" replace />;
  }

  if (
    route?.auth &&
    !menuArr.some((item: any) => item.rightKey === route.path)
  ) {
    return baseLoading ? <Loading /> : <Navigate to="/403" replace />;
  }

  return <DocumentMeta meta={route.meta}>{children}</DocumentMeta>;
};

const DocumentMeta = ({
  meta,
  children,
}: {
  meta: RouterInfo["meta"];
  children: JSX.Element;
}) => {
  const { t } = useLocale();
  const { baseInfo } = useSystemInfo();

  document.title = `${baseInfo?.title || t("system.title")}${
    meta?.title ? " - " + t(meta.title) : ""
  }`;
  return children;
};

const Loading = () => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-1000 bg-light-100 flex justify-center items-center">
      <Spin size="large" />
    </div>
  );
};

export const RouterMap = () => <Routes>{RouteRender(routes)}</Routes>;
