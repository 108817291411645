import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Radio,
  Rate,
  Row,
  Select,
  Tag,
  TreeSelect,
} from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { type QuestionTypes, questionTypes, useTags } from ".";
import { addQuestion, updateQuestion } from "../../../api/QuestionController";
import { keyEncode } from "../../../utils/format";
import Uploader from "../../components/Uploader";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

interface QuestionEditDrawerProps {
  onFinished?: () => void;
}

export interface EditRef {
  openToEdit: (edit?: any) => void;
}

const { TextArea } = Input;

const formRules: FormRules = {
  n_question_type: [{ required: true, message: "请选择试题类型" }],
  c_question_name: [{ required: true, message: "请输入题干" }],
  questionContent: [{ required: true, message: "请输入选项" }],
  _mt_answer: [{ required: true, message: "请选择正确答案" }],
  f_points: [{ required: true, message: "请设置默认分值" }],
  _tagList: [{ required: true, message: "请设置试题标签" }],
};

const LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const QuestionEditDrawer = forwardRef<EditRef, QuestionEditDrawerProps>(
  ({ onFinished }, ref) => {
    const { t } = useLocale(i18n);
    const [form] = Form.useForm();
    const { tagTree } = useTags();
    const [formloading, setFormLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [editId, setEditId] = useState<string>();
    const qtype = Form.useWatch("n_question_type", form);
    const selections = Form.useWatch("_selections", form);

    const save = () => {
      form.validateFields().then((payload) => {
        setFormLoading(true);
        const { _selections } = payload;
        const questionContent: any = {
          titleImg: payload._titleImg,
        };

        if ([1, 2].includes(payload.n_question_type)) {
          let rawChoice: { [key: string]: string } = {};
          const choiceList = _selections?.reduce((prev: any, crt: any) => {
            rawChoice[crt.symbol] = crt.label;
            return {
              ...prev,
              [crt.symbol]: keyEncode(crt.label),
            };
          }, {});
          questionContent.choiceImgList = _selections?.reduce(
            (prev: any, crt: any) => ({
              ...prev,
              [crt.symbol]: crt.img,
            }),
            {}
          );

          if (Array.isArray(payload._mt_answer)) {
            payload.mt_answer = payload._mt_answer
              .map((selection: any) => rawChoice[selection])
              .join("#&");
          } else {
            payload.mt_answer = rawChoice[payload._mt_answer];
          }
          questionContent.choiceList = choiceList;
        } else {
          payload.mt_answer = payload._mt_answer;
        }
        payload.questionContent = questionContent;
        payload.c_question_name = keyEncode(payload.c_question_name);
        payload.mt_answer = keyEncode(payload.mt_answer);

        if (Array.isArray(payload._tagList)) {
          payload.tagList = payload._tagList.map((x: any) => x.value);
        }

        if (editId) {
          updateQuestion({ ...payload, n_question_id: editId })
            .then(({ data }) => {
              if (data.code) {
                message.success(t("library.item.modifysuccess"));
                setOpen(false);
                onFinished?.();
                return;
              }
            })
            .finally(() => setFormLoading(false));
        } else {
          addQuestion(payload)
            .then(({ data }) => {
              if (data.code) {
                message.success(t("library.item.addsuccess"));
                setOpen(false);
                onFinished?.();
                return;
              }
              data?.msg && message.error(data.msg);
            })
            .finally(() => setFormLoading(false));
        }
      });
    };

    const getCorrectSet = (type: QuestionTypes) => {
      const _selections = selections?.filter((s: any) => Boolean(s));

      if (type === 1) {
        return (
          <Form.Item
            label={t("library.item.correctanswer")}
            name="_mt_answer"
            rules={formRules._mt_answer}
          >
            <Radio.Group
              options={_selections?.map((opt: any) => ({
                value: opt?.symbol,
                label: opt?.symbol,
              }))}
            />
          </Form.Item>
        );
      } else if (type === 2) {
        return (
          <Form.Item
            label={t("library.item.correctanswer")}
            name="_mt_answer"
            rules={formRules._mt_answer}
          >
            <Checkbox.Group
              options={_selections?.map((opt: any) => ({
                value: opt?.symbol,
                label: opt?.symbol,
              }))}
            />
          </Form.Item>
        );
      } else if (type === 3) {
        return (
          <Form.Item
            label={t("library.item.correctanswer")}
            name="_mt_answer"
            rules={formRules._mt_answer}
          >
            <Select
              className="!w-30"
              options={[
                { label: t("library.item.correct"), value: "T" },
                { label: t("library.item.error"), value: "F" },
              ]}
            />
          </Form.Item>
        );
      } else {
        return (
          <Form.Item
            label={t("library.item.reference")}
            name="_mt_answer"
            rules={formRules._mt_answer}
          >
            <TextArea
              rows={3}
              placeholder={t("library.item.enterreference")}
              maxLength={200}
            />
          </Form.Item>
        );
      }
    };

    const openToEdit: EditRef["openToEdit"] = (edit) => {
      if (edit) {
        const {
          n_question_id,
          questionContent,
          tagnameList,
          n_question_type,
          mt_answer,
        } = edit;
        setEditId(n_question_id);

        edit._tagList = tagnameList?.map(({ n_tag_id, c_tag_name }: any) => ({
          value: n_tag_id,
          label: c_tag_name,
        }));

        if (questionContent) {
          const { titleImg, choiceImgList, choiceList } = questionContent;
          edit._titleImg = titleImg;
          const _selections = Object.keys(choiceList || {})?.map((symbol) => ({
            symbol,
            label: choiceList[symbol],
            img: choiceImgList[symbol],
          }));

          edit._selections = _selections;

          if ([1, 2].includes(n_question_type)) {
            const answerlist = mt_answer
              ?.split("#&")
              ?.map(
                (answer: string) =>
                  _selections.find((opt) => opt.label === answer)?.symbol
              );

            if (n_question_type === 1) {
              edit._mt_answer = answerlist[0];
            } else {
              edit._mt_answer = answerlist;
            }
          } else {
            edit._mt_answer = edit.mt_answer;
          }
        }

        form.setFieldsValue(edit);
      } else {
        setEditId(undefined);
        form.resetFields();
      }
      setOpen(true);
    };

    useImperativeHandle(ref, () => ({ openToEdit }));

    return (
      <Drawer
        title={editId ? t("library.item.editqu") : t("library.item.addqu")}
        width="70%"
        placement="right"
        open={open}
        onClose={() => setOpen(false)}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 3 }}
          form={form}
          scrollToFirstError
          preserve={false}
        >
          <Form.Item
            label={t("library.item.texttype")}
            name="n_question_type"
            initialValue={1}
            rules={formRules.n_question_type}
          >
            <Select
              placeholder={t("library.item.selecttype")}
              className="!w-50"
              onChange={() => form.setFieldValue("_mt_answer", "")}
              options={Array.from(questionTypes, ([value, label]) => ({
                value,
                label,
              }))}
            />
          </Form.Item>
          <Form.Item label={t("library.item.stem")} required>
            <Form.Item
              className="inline-block w-2/3"
              name="c_question_name"
              rules={formRules.c_question_name}
            >
              <TextArea
                rows={4}
                placeholder={t("library.item.inputstem")}
                maxLength={500}
                showCount
              />
            </Form.Item>
            <Form.Item className="inline-block ml-4" name="_titleImg">
              <Uploader
                placeholder={t("library.item.uploadimg")}
                size={100}
                keyName="questionFile"
              />
            </Form.Item>
          </Form.Item>
          {(qtype === 1 || qtype === 2) && (
            <Form.Item label={t("library.item.option")} required>
              <Form.List initialValue={[]} name="_selections">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Row key={field.key} gutter={[16, 16]}>
                        <Col span={20}>
                          <Form.Item
                            hidden
                            name={[field.name, "symbol"]}
                            initialValue={LETTERS[field.name]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            className="inline-block w-2/3"
                            name={[field.name, "label"]}
                            label={
                              <Tag color="#986">{LETTERS[field.name]}</Tag>
                            }
                            rules={[
                              {
                                required: true,
                                message: t("library.item.enteroption"),
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              placeholder={t("library.item.inputoption")}
                              maxLength={100}
                              showCount
                            />
                          </Form.Item>
                          <Form.Item
                            className="inline-block ml-4"
                            name={[field.name, "img"]}
                          >
                            <Uploader
                              size={100}
                              placeholder={t("library.item.uploadoption")}
                              keyName="questionFile"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Popconfirm
                            title={t("library.item.suredeloption")}
                            placement="left"
                            onConfirm={() => remove(field.name)}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              danger
                              title={t("library.item.delete")}
                            >
                              {t("library.item.delete")}
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item className="mt-4">
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          if (fields.length <= 25) add();
                        }}
                      >
                        {t("library.item.addoption")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          )}
          {getCorrectSet(qtype)}
          <Form.Item
            label={t("library.item.score")}
            name="f_points"
            rules={formRules.f_points}
          >
            <InputNumber placeholder={t("library.item.score")} min={0} />
          </Form.Item>
          <Form.Item
            label={t("library.item.tag")}
            name="_tagList"
            rules={formRules._tagList}
          >
            <TreeSelect
              showSearch
              showArrow
              allowClear
              treeCheckable
              treeCheckStrictly
              placeholder={t("library.item.selecttag")}
              treeData={tagTree}
              treeDefaultExpandAll
              fieldNames={{ label: "c_tag_name", value: "n_tag_id" }}
            />
          </Form.Item>
          <Form.Item
            label={t("library.item.difficulty")}
            name="n_difficulty"
            rules={formRules.n_difficulty}
          >
            <Rate count={3} />
          </Form.Item>
          <Form.Item label={t("library.item.tip")} name="c_reference">
            <Input placeholder={t("library.item.inputtip")} />
          </Form.Item>
          <Form.Item label={t("library.item.describe")} name="c_note">
            <Input placeholder={t("library.item.enterdescribe")} />
          </Form.Item>
          <Form.Item label={t("library.item.analysis")} name="c_analysis">
            <TextArea
              rows={4}
              placeholder={t("library.item.inputanalysis")}
              maxLength={200}
            />
          </Form.Item>
        </Form>
        <Row>
          <Col span="6" offset="9">
            <Button
              size="large"
              block
              type="primary"
              loading={formloading}
              onClick={save}
            >
              {t("library.item.save")}
            </Button>
          </Col>
        </Row>
      </Drawer>
    );
  }
);

export default QuestionEditDrawer;
