import { Modal, Form, Input, Button, Progress, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useState } from "react";
import { changeMyPwd } from "../../api/NewUserController";
import { mediumPuzzle, strongPuzzle } from "../../utils/regExp";
import type { Rule } from "antd/lib/form";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import i18n from "../login/i18n";
import { keyEncode } from "../../utils/format";

interface ForgetPanelProps {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
  force?: boolean;
}

const { useForm } = Form;

const ForgetPanel = (prop: ForgetPanelProps) => {
  const { visible, setVisible, force } = prop;
  const [progress, setProgress] = useState({ percent: 0, strokeColor: "" });
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [pwdForm] = useForm();
  const { t } = useLocale(i18n);

  const pwdFormRoles: { [key: string]: Rule[] } = {
    oldPwd: [{ required: true, message: t("login.roles.password6") }],
    password: [
      {
        required: true,
        validator(_, value) {
          if (!value)
            return Promise.reject(new Error(t("login.roles.password7")));
          if (value.length < 8) {
            return Promise.reject(new Error(t("login.roles.password3")));
          }
          if (mediumPuzzle.test(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject(new Error(t("login.roles.password4")));
          }
        },
      },
    ],
    password2: [
      { required: true, message: t("login.roles.password2") },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(t("login.roles.password5")));
        },
      }),
    ],
  };

  const onInputForm = (crt: any) => {
    const { password } = crt;
    if (password === "") setProgress({ percent: 0, strokeColor: "" });
    if (password) {
      if (strongPuzzle.test(password)) {
        setProgress({ percent: 100, strokeColor: "#52c41a" });
      } else if (mediumPuzzle.test(password)) {
        setProgress({ percent: 66, strokeColor: "#ffc903" });
      } else {
        setProgress({ percent: 33, strokeColor: "#ff0303" });
      }
    }
  };

  const submit = async () => {
    pwdForm.validateFields().then(async (res) => {
      res.oldPwd = keyEncode(res.oldPwd);
      res.password = keyEncode(res.password);
      res.password2 = keyEncode(res.password2);

      const { data } = await changeMyPwd(res);
      if (data.code) {
        setVisible(false);
        logout();
        navigate("/");
        message.success(t("login.tips.changesuccess"));
      } else {
        data?.msg && message.error(data.msg);
      }
    });
  };

  return (
    <Modal
      title={t("header.item.password")}
      width={370}
      centered
      open={visible}
      footer={null}
      destroyOnClose
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Form
        form={pwdForm}
        size="large"
        preserve={false}
        onValuesChange={onInputForm}
      >
        <Form.Item name="oldPwd" rules={pwdFormRoles.oldPwd}>
          <Input.Password
            visibilityToggle
            prefix={<LockOutlined />}
            placeholder={t("login.roles.password6")}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item noStyle name="password" rules={pwdFormRoles.password}>
            <Input.Password
              visibilityToggle
              prefix={<LockOutlined />}
              maxLength={16}
              showCount
              placeholder={t("login.roles.password7")}
            />
          </Form.Item>
          <Form.Item noStyle>
            <Progress
              steps={3}
              percent={progress.percent}
              strokeColor={progress.strokeColor}
              format={(persent = 0) => {
                if (persent < 17) return "";
                if (persent < 50) return t("login.roles.weak");
                if (persent < 84) return t("login.roles.medium");
                return t("login.roles.strong");
              }}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="password2"
          rules={pwdFormRoles.password2}
          dependencies={["password"]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            visibilityToggle
            maxLength={16}
            showCount
            placeholder={t("login.roles.password2")}
          />
        </Form.Item>
        <Button block type="primary" htmlType="submit" onClick={submit}>
          {t("login.item.changepwd")}
        </Button>
      </Form>
    </Modal>
  );
};

export default ForgetPanel;
