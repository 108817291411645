import { Button } from "antd";
import classNames from "classnames";
import { type FC, type ReactNode, useState } from "react";
import { isNullish } from "../../../utils/format";
import { useLocale } from "../../../hooks/useLocale";

interface SorterProps {
  title?: string;
  value?: string | number;
  onChange?: (val: string | number) => void;
  options?: {
    label: ReactNode;
    value: string | number;
  }[];
  all?:
    | boolean
    | {
        label: ReactNode;
        value: string | number;
      };
}

const i18n = {
  "en-US": {
    all: "All",
  },
  "zh-CN": {
    all: "全部",
  },
};

const defaultAll = {
  label: "全部",
  value: "",
};

const Sorter: FC<SorterProps> = ({
  title = "",
  value,
  options,
  onChange,
  all = true,
}) => {
  const { t } = useLocale(i18n);
  const handleChange = (val: string | number) => {
    onChange?.(val);
  };

  const getBtnClasses = (val: string | number) => {
    const active = value === val;
    return classNames("px-2", active && "font-bold bg-gray-100");
  };

  return (
    <div className="inline-block max-w-220">
      <div className="font-bold ml-2">{title}</div>
      <div>
        {all && (
          <Button
            type="text"
            className={getBtnClasses("")}
            onClick={() => handleChange("")}
          >
            {t("all")}
          </Button>
        )}
        {options?.map((opt) => (
          <Button
            type="text"
            className={getBtnClasses(opt.value)}
            key={opt.value}
            onClick={() => handleChange(opt.value)}
          >
            {opt.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Sorter;
