const i18n = {
  "en-US": {
    "declare.item.title": "title",
    "declare.item.entertitle": "Enter the article title",
    "declare.item.type": "type",
    "declare.item.selectType": "Select Article Type",
    "declare.item.display": "Do not display",
    "declare.item.news": "Current affairs news",
    "declare.item.notice": "Notice announcement",
    "declare.item.remove": "Remove",
    "declare.item.query": "Query",
    "declare.item.addArtical": "New Article",
    "declare.item.delete": "delete",
    "declare.item.cancel": "cancel",
    "declare.item.batchoperation": "Batch operation",
    "table.pagination.all": "",
    "table.pagination.total": " total",
    "table.index": "Index",
    "declare.item.author": "author",
    "declare.item.release": "Release time",
    "declare.item.operation": "operation",
    "declare.item.modify": "modify",
    "declare.item.check": "check",
    "declare.item.top": "Top",
    "declare.item.notop": "No topping",
    "declare.item.introduction": "Introduction",
    "declare.item.enterintroduction": "Enter the introduction of the article",
    "declare.item.text": "Text",
    "declare.item.submit": "Submit",
    "declare.item.edit": "Edit Article",
    "declare.item.details": "Article Details",
    "declare.item.failed": "Article modification failed",
    "declare.item.success": "Modify successfully",
    "declare.item.checkinvalid": "Please check for invalid verification",
    "declare.item.postsuccess": "Article published successfully!",
    "declare.item.selectlist": "You need to select the list item first",
    "declare.item.deletion": "Confirm the deletion",
    "declare.item.delarticles":
      "Are you sure you want to delete these articles?",
  },
  "zh-CN": {
    "declare.item.title": "标题",
    "declare.item.entertitle": "输入文章标题",
    "declare.item.type": "类型",
    "declare.item.selectType": "选择文章类型",
    "declare.item.display": "不显示",
    "declare.item.news": "时事新闻",
    "declare.item.notice": "通知公告",
    "declare.item.remove": "清除",
    "declare.item.query": "查询",
    "declare.item.addArtical": "新增文章",
    "declare.item.delete": "删除",
    "declare.item.cancel": "取消",
    "declare.item.batchoperation": "批量操作",
    "table.pagination.all": "共",
    "table.pagination.total": "条",
    "table.index": "序号",
    "declare.item.author": "作者",
    "declare.item.release": "发布时间",
    "declare.item.operation": "操作",
    "declare.item.modify": "修改",
    "declare.item.check": "查看",
    "declare.item.top": "置顶",
    "declare.item.notop": "不置顶",
    "declare.item.introduction": "简介",
    "declare.item.enterintroduction": "输入文章简介",
    "declare.item.text": "正文",
    "declare.item.submit": "提交",
    "declare.item.edit": "修改文章",
    "declare.item.details": "文章详情",
    "declare.item.failed": "文章修改失败",
    "declare.item.success": "修改成功",
    "declare.item.checkinvalid": "请检查不合法的校验项",
    "declare.item.postsuccess": "文章发布成功！",
    "declare.item.selectlist": "需要先选择列表项",
    "declare.item.deletion": "请确认删除操作",
    "declare.item.delarticles": "确定要删除这些文章吗？",
  },
};

export default i18n;
