import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  message,
  Spin,
  Space,
  Popconfirm,
  Button,
  Radio,
  InputNumber,
  Tag,
  Input,
  Col,
  Row,
  Image,
} from "antd";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  findLabAssociatedAllByPid,
  addLabSetupModeByLabId,
  deleteLabSetupModeAndLabDeblockingByLabId,
} from "../../../../api/NewLabInfoController";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

const MultipleModulePanel = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const type = Form.useWatch("type", form);
  const list = Form.useWatch("_labidlist", form);
  const [lablist, setLablist] = useState<any[]>([]);
  const [lablistMap, setLablistMap] = useState<Map<number, number>>();

  const formRules: FormRules = {
    type: [{ required: true, message: t("Setupanels.item.selectmode") }],
    _labidlist: [
      { required: true, message: t("Setupanels.item.selectexperiment") },
    ],
  };

  const getSetting = useCallback(() => {
    setFormLoading(true);
    findLabAssociatedAllByPid({ labId })
      .then(({ data }) => {
        if (data.code) {
          setLablist(data.data.labList);
          const map = new Map();
          (data.data.labList as any[]).forEach((el) => {
            map.set(el.id, el.cName);
          });
          setLablistMap(map);
          if (data.data.labSetUpMode) {
            data.data.labSetUpMode._labidlist =
              data.data.labSetUpMode?.labDeblockingList.map(
                (el: any) => el.labId
              );
            form.setFieldsValue(data.data.labSetUpMode);
          }
        }
      })
      .finally(() => setFormLoading(false));
  }, [form, labId]);

  const save = () => {
    // const raw = form.getFieldsValue();

    form.validateFields().then((raw) => {
      setLoading(true);
      addLabSetupModeByLabId({ ...raw, labid: labId })
        .then(({ data }) => {
          if (data.code) {
            message.success(t("Setupanels.item.setmodule"));
            return;
          }
          data?.msg && message.error(data.msg);
        })
        .finally(() => setLoading(false));
    });
  };

  const initialSet = () => {
    deleteLabSetupModeAndLabDeblockingByLabId({ labid: labId }).then(
      ({ data }) => {
        if (data.code) {
          form.resetFields();
          message.success(t("Setupanels.item.setupmodule"));
          getSetting();
          return;
        }
        data?.msg && message.error(data.msg);
      }
    );
  };

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  return (
    <Spin spinning={formLoading}>
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 3 }}
        preserve={false}
      >
        <div className="mb-4 text-right">
          <Space>
            <Popconfirm
              title={t("Setupanels.item.suredelmodule")}
              placement="left"
              onConfirm={initialSet}
            >
              <Button
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "arrow-rotate-right"]}
                    className="mr-2"
                  />
                }
              >
                {t("Setupanels.item.Initialization")}
              </Button>
            </Popconfirm>
            <Button type="primary" loading={loading} onClick={save}>
              {t("Setupanels.item.savechange")}
            </Button>
          </Space>
        </div>
        <Form.Item
          label={t("Setupanels.item.mode")}
          name="type"
          rules={formRules.type}
        >
          <Radio.Group>
            <Radio value={2}>{t("Setupanels.item.taskmode")}</Radio>
            <Radio value={1}>{t("Setupanels.item.assessmentmode")}</Radio>
          </Radio.Group>
        </Form.Item>
        {type === 1 ? (
          <Form.Item label={t("Setupanels.item.cycles")}>
            <Space>
              <Form.Item name="num">
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                label={t("Setupanels.item.assessable")}
                name="beiyong1"
              >
                <InputNumber min={0} />
              </Form.Item>
            </Space>
          </Form.Item>
        ) : null}
        <Form.Item
          label={t("Setupanels.item.selectex")}
          valuePropName="checked"
          name="_labidlist"
          rules={formRules._labidlist}
        >
          <CardSelector lablist={lablist} />
        </Form.Item>
        <Form.Item label={t("Setupanels.item.sequence")}>
          {Array.isArray(list)
            ? list.map((val, idx) =>
                idx === 0 ? (
                  <React.Fragment key={val}>
                    <Form.Item
                      className="mb-2 text-gray-300"
                      label={
                        <>
                          <Tag color="red">{idx + 1}</Tag>
                          {lablistMap?.get(val) || (
                            <span className="text-gray-300">
                              {t("Setupanels.item.deleted")}({val})
                            </span>
                          )}
                        </>
                      }
                    >
                      {t("Setupanels.item.unlock")}
                    </Form.Item>
                    <Form.Item
                      hidden
                      name={["labDeblockingList", idx, "labId"]}
                      initialValue={val}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={val}>
                    <Form.Item
                      className="mb-2"
                      key={val}
                      name={["labDeblockingList", idx, "deblockingCondition"]}
                      label={
                        <>
                          <Tag color="red">{idx + 1}</Tag>
                          {lablistMap?.get(val) || (
                            <span className="text-gray-300">
                              {t("Setupanels.item.deleted")}({val})
                            </span>
                          )}
                        </>
                      }
                    >
                      <InputNumber
                        min={0}
                        placeholder={t("Setupanels.item.unlockscore")}
                      />
                    </Form.Item>
                    <Form.Item
                      hidden
                      name={["labDeblockingList", idx, "labId"]}
                      initialValue={val}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </React.Fragment>
                )
              )
            : null}
        </Form.Item>
        <Form.Item label={t("Setupanels.item.prompt")} name="cankaonote">
          <Input.TextArea
            rows={5}
            placeholder={t("Setupanels.item.inpprompt")}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};

interface CardSelectorProps<T = any> {
  lablist: T[];
  checked?: number[];
  onChange?: (value: number[]) => void;
}

type SelectedMap = Map<number, number>;

const CardSelector = ({
  lablist,
  checked = [],
  onChange,
}: CardSelectorProps) => {
  const selected = useMemo<SelectedMap>(() => {
    const checkedMap = new Map();
    checked.forEach((id, idx) => checkedMap.set(id, idx + 1));
    return checkedMap;
  }, [checked]);

  const onSelect = (id: number) => {
    const idx = checked.findIndex((itm) => itm === id);
    const nchecked = [...checked];

    if (idx >= 0) {
      nchecked.splice(idx, 1);
      onChange?.(nchecked);
      return;
    }
    nchecked.push(id);
    onChange?.(nchecked);
  };

  return (
    <Row gutter={[16, 16]}>
      {lablist?.map((item) => (
        <Col span={6} key={item.id}>
          <CardItem
            item={item}
            onSelect={onSelect}
            order={selected.get(item.id)}
          />
        </Col>
      ))}
    </Row>
  );
};

const CardItem = ({
  item,
  order,
  onSelect,
}: {
  item: any;
  order?: number;
  onSelect?: Function;
}) => {
  return (
    <div
      className={`w-30 rounded overflow-hidden cursor-pointer relative ${
        order && order > 0 ? "bg-blue-200" : "bg-gray-200"
      }`}
      onClick={() => onSelect?.(item.id)}
    >
      {order && order > 0 ? (
        <div className="absolute top-0 right-0 w-6 h-6 leading-6 text-center bg-blue-300 z-1 text-gray-100 font-bold rounded-bl-lg">
          {order}
        </div>
      ) : null}
      <Image
        preview={false}
        width={120}
        height={100}
        src={item.cImgid}
        placeholder
        className="object-contain"
      />
      <div className="text-center p-2">{item.cName}</div>
    </div>
  );
};

export default MultipleModulePanel;
