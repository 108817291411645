import { useContext } from "react";
import { NoticeContext } from "../context/NoticeContext";

const useNotice = () => {
  const context = useContext(NoticeContext);
  if (!context) {
    throw new Error(
      "NoticeContext不在父状态树中.你是否将Provider挂载在了错误的位置?"
    );
  }
  return context;
};

export default useNotice;
