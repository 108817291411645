import Driver from "driver.js";
import { useLocale } from "./useLocale";

const i18n = {
  "en-US": {
    done: "Done",
    close: "Close",
    next: "next",
    previous: "Previous",
  },
  "zh-CN": {
    done: "完成",
    close: "关闭",
    next: "下一步",
    previous: "上一步",
  },
};

const useGuide = (steps: Driver.Step[]) => {
  const { t } = useLocale(i18n);

  const driver = new Driver({
    className: "driver-scoped-class",
    animate: true,
    opacity: 0.75,
    padding: 0,
    allowClose: true,
    overlayClickNext: true,
    // onHighlightStarted: (el) => {
    //   console.log(el);
    // },
    doneBtnText: t("done"),
    closeBtnText: t("close"),
    nextBtnText: t("next"),
    prevBtnText: t("previous"),
  });

  const startGuide = () => {
    driver.defineSteps(steps);
    driver.start();
  };

  return { startGuide };
};

export default useGuide;
