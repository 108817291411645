import {
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Segmented,
  Select,
  Space,
  Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  FormOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  addSubject,
  deleteSubject,
  listSubject,
  updateSubject,
} from "../../../api/SubjectController";
import VideoUploader from "./VideoUploader";
import RichEditor from "../../components/RichEditor";
import { EscapeReverseHTML } from "../../../utils/format";
import { updAllPath } from "../../../api/ImagePtController";
import StepsModal from "./stepsModal";
import { showNews } from "../../../api/NewNewsController";
import i18n from "./i18n";

export type SubjectType = {
  id: number;
  pid: number;
  subjectLevel: 1 | 2;
  subjectName: string;
  subjectType: typeof subjectTypes[number]["value"];
  contentText: any;
  tipTitle?: string;
  tipContent?: string;
  articalTitle?: string;
  child: Omit<SubjectType, "child">[];
};

export const subjectTypes = [
  { value: 0, label: "二级菜单", firstOnly: true },
  { value: 1, label: "富文本", firstOnly: false },
  { value: 2, label: "提示框", firstOnly: false },
  { value: 3, label: "实验列表", firstOnly: false },
  { value: 4, label: "在线提问", firstOnly: false },
  // { value: 5, label: "资源下载" },
  { value: 6, label: "文章列表", firstOnly: false },
] as const;

const formRules: FormRules = {
  subjectName: [{ required: true, message: "请输入标题名" }],
  subjectType: [{ required: true, message: "请选择内容类型" }],
};

const DeclarePageSetting = () => {
  const { t } = useLocale(i18n);
  const [langVersion, setLangVersion] = useState<SupportedLangType>("zh-CN");
  const [projectList, setProjectList] = useState<SubjectType[]>();
  const [editingProject, setEditingProject] = useState<SubjectType>();
  const [addPopShow, setAddPopShow] = useState(false);
  const [addform] = Form.useForm();
  const [stepsModalOpen, setStepsModalOpen] = useState(false);

  const getNavMenu = useCallback(
    () =>
      listSubject({ lang: langVersion }).then(({ data }) => {
        if (data.code) {
          setProjectList(data.data);
        } else {
          setProjectList([]);
          data?.msg && message.error(data.msg);
        }
        return data;
      }),
    [langVersion]
  );

  const addProject = () => {
    addform.validateFields().then((d) => {
      addSubject({ ...d, lang: langVersion }).then(({ data }) => {
        if (data.code) {
          setAddPopShow(false);
          message.success(t("declare.item.firstlevel"));
          getNavMenu();
          return;
        }

        data?.msg && message.error(data.msg);
      });
    });
  };

  const delProject = (project: SubjectType) => {
    Modal.confirm({
      content: `${t("declare.item.delmenu")} ${project.subjectName} ${t(
        "declare.item.delcontent"
      )}`,
      onOk() {
        deleteSubject({ id: project.id }).then(({ data }) => {
          if (data.code) {
            message.success(t("declare.item.delsuccess"));
            getNavMenu();
            setEditingProject(undefined);
            return;
          }
          data?.msg && message.error(data.msg);
        });
      },
    });
  };

  const updateProject = () => {
    getNavMenu().then(({ data }) => {
      if (Array.isArray(data)) {
        setEditingProject(data.find((item) => item.id === editingProject?.id));
      } else {
        setEditingProject(undefined);
      }
    });
  };

  const onVideoUpload = (imagePtPath: string, imagePtType: 6 | 7) => {
    updAllPath({ imagePtPath, imagePtType }).then(({ data }) => {
      if (data.code) {
        message.success(t("declare.item.savevideo"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  useEffect(() => {
    getNavMenu();
  }, [getNavMenu]);

  return (
    <div className="page-main page-sys-declare">
      <CustomBreadcrumb breads={[t("menu.system"), t("menu.system.declare")]} />
      <Row gutter={[16, 0]} className="mb-4">
        <Col span={8}>
          <div className="table-wrapper h-full">
            <Typography.Text className="text-lg font-bold">
              {t("declare.item.language")}
            </Typography.Text>
            <Segmented
              block
              className="mt-6 font-bold"
              onChange={(o: any) => {
                setEditingProject(undefined);
                setLangVersion(o);
              }}
              options={[
                {
                  value: "zh-CN",
                  label: "简体中文",
                },
                {
                  value: "en-US",
                  label: "English",
                },
              ]}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="table-wrapper h-full">
            <Typography.Text className="text-lg font-bold">
              {t("declare.item.guidevideo")}
            </Typography.Text>
            <VideoUploader onChange={(url) => onVideoUpload(url, 6)} />
          </div>
        </Col>
        <Col span={8}>
          <div className="table-wrapper h-full">
            <Typography.Text className="text-lg font-bold">
              {t("declare.item.introductionvideo")}
            </Typography.Text>
            <VideoUploader onChange={(url) => onVideoUpload(url, 7)} />
          </div>
        </Col>
      </Row>
      <div className="table-wrapper">
        <div className="flex items-center justify-between">
          <Typography.Title className="text-lg font-bold">
            {t("declare.item.setting")}
          </Typography.Title>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: t("declare.item.stepset"),
                  onClick() {
                    setStepsModalOpen(true);
                  },
                },
              ],
            }}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </div>
        <div className="group">
          {projectList?.map((project) => (
            <ProjectItem
              key={project.id}
              project={project}
              editingProject={editingProject}
              setEditingProject={setEditingProject}
              onDelete={delProject}
              onUpdated={updateProject}
            />
          ))}
          <Popover
            trigger="click"
            destroyTooltipOnHide
            open={addPopShow}
            onOpenChange={setAddPopShow}
            content={
              <Form labelAlign="right" form={addform} preserve={false}>
                <Form.Item
                  label={t("declare.item.title")}
                  name="subjectName"
                  rules={formRules.subjectName}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("declare.item.contenttype")}
                  name="subjectType"
                  rules={formRules.subjectType}
                >
                  <Select>
                    {subjectTypes.map((k) => (
                      <Select.Option key={k.value} value={k.value}>
                        {k.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className="text-right">
                  <Button type="primary" onClick={() => addProject()}>
                    {t("declare.item.creatmenu")}
                  </Button>
                </div>
              </Form>
            }
          >
            <div className="project" onClick={() => setAddPopShow(true)}>
              <PlusOutlined />
            </div>
          </Popover>
        </div>
        <ContentPanel project={editingProject} update={updateProject} />
      </div>
      <StepsModal
        open={stepsModalOpen}
        onCancel={() => setStepsModalOpen(false)}
      />
    </div>
  );
};

// 一级菜单项
const ProjectItem = ({
  project,
  editingProject,
  setEditingProject,
  onDelete,
  onUpdated,
}: {
  project: SubjectType;
  editingProject?: SubjectType;
  setEditingProject?: any;
  onDelete: (p: SubjectType) => void;
  onUpdated: (p: SubjectType) => void;
}) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateFormData = () => {
    form.setFieldsValue(project);
  };

  const updateProject = () => {
    setLoading(true);
    form
      .validateFields()
      .then((d) => updateSubject({ ...d, id: project.id }))
      .then(({ data }) => {
        if (data.code) {
          setOpen(false);
          message.success(t("declare.item.modifymenu"));
          onUpdated(project);
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className="project"
      onClick={() => setEditingProject(project)}
      style={
        editingProject?.id === project.id
          ? {
              backgroundColor: "#bae7ff",
              boxShadow: "2px 2px 4px inset rgba(0, 0, 0, 0.24)",
            }
          : undefined
      }
    >
      {project.subjectName}
      <Popover
        trigger="click"
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
          open && updateFormData();
        }}
        content={
          <Form labelAlign="right" form={form}>
            <Form.Item
              label={t("declare.item.title")}
              name="subjectName"
              rules={formRules.subjectName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("declare.item.contenttype")}
              name="subjectType"
              rules={formRules.subjectType}
            >
              <Select>
                {subjectTypes.map((k) => (
                  <Select.Option key={k.value} value={k.value}>
                    {k.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="text-right">
              <Button
                icon={<DeleteOutlined />}
                danger
                className="mr-2"
                onClick={() => {
                  setOpen(false);
                  onDelete(project);
                }}
              >
                {t("declare.item.delete")}
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={() => updateProject()}
              >
                {t("declare.item.save")}
              </Button>
            </div>
          </Form>
        }
      >
        <FormOutlined className="mx-1" onClick={() => setOpen(true)} />
      </Popover>
    </div>
  );
};

// 内容控制面板
const ContentPanel = ({
  project,
  update,
}: {
  project?: SubjectType;
  update: () => void;
}) => {
  const { t } = useLocale(i18n);
  const contents = {
    0: <SubMenuContent project={project} update={update} />,
    1: <RichContent project={project!} update={update} />,
    2: <MessageBoxContent project={project!} update={update} />,
    3: <Empty description={t("declare.item.delete")} className="mx-auto" />,
    4: <Empty description={t("declare.item.online")} className="mx-auto" />,
    6: <ArticalContent project={project!} update={update} />,
  };

  return (
    <div className="content-wrap">
      {project?.id ? (
        contents[project.subjectType]
      ) : (
        <Empty description={false} className="mx-auto" />
      )}
    </div>
  );
};

// 二级菜单
const SubMenuContent = ({
  project,
  update,
}: {
  project?: SubjectType;
  update: () => void;
}) => {
  const [editingContent, setEditingContent] = useState<
    Omit<SubjectType, "child"> | "addon"
  >();
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const subjectType = Form.useWatch("subjectType", form);
  const [loading, setLoading] = useState(false);

  const onSubChange = (child: Omit<SubjectType, "child"> | "addon") => {
    setEditingContent(child);
    if (child === "addon") return form.resetFields();

    if (child.subjectType === 1) {
      child.contentText = EscapeReverseHTML(child.contentText);
    } else if (child.subjectType === 2) {
      try {
        const { tipContent, tipTitle } = JSON.parse(child.contentText);
        child.tipContent = tipContent;
        child.tipTitle = tipTitle;
      } catch (err) {
        console.warn(t("declare.item.unableparse"));
      }
    } else if (child.subjectType === 6) {
      try {
        const { articalTitle } = JSON.parse(child.contentText);
        child.articalTitle = articalTitle;
      } catch (err) {
        console.warn(t("declare.item.unableparse"));
      }
    }
    form.setFieldsValue(child);
  };

  const save = () => {
    setLoading(true);
    form
      .validateFields()
      .then((d) => {
        console.log(d);
        if (d.subjectType === 2) {
          const { tipContent, tipTitle } = d;
          d.contentText = JSON.stringify({ tipContent, tipTitle });
        }

        if (d.subjectType === 6) {
          const { articalTitle } = d;
          d.contentText = JSON.stringify({ articalTitle });
        }

        if (editingContent === "addon") {
          // 创建二级导航
          addSubject({ ...d, pid: project?.id }).then(({ data }) => {
            if (data.code) {
              message.success(t("declare.item.secondlevel"));
              update();
              return;
            }

            data?.msg && message.error(data.msg);
          });
        } else {
          updateSubject({ ...d, id: editingContent?.id }).then(({ data }) => {
            if (data.code) {
              message.success(t("declare.item.seclevelmodify"));
              update();
              return;
            }
            data?.msg && message.error(data.msg);
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const onSubDelete = () => {
    if (editingContent === "addon" || !editingContent) return;

    Modal.confirm({
      content: `${t("declare.item.delsecondmenu")} ${
        editingContent.subjectName
      } ${t("declare.item.delcontent")} `,
      onOk() {
        deleteSubject({ id: editingContent.id }).then(({ data }) => {
          if (data.code) {
            message.success(t("declare.item.delsuccess"));
            update();
            setEditingContent(undefined);
            return;
          }
          data?.msg && message.error(data.msg);
        });
      },
    });
  };

  //文章列表
  const [newsList, setNewsList] = useState<any[]>([]);
  const getNewsList = async () => {
    const { data } = await showNews({});
    if (data.code) {
      setNewsList(data.data.rows);
    }
  };

  useEffect(() => {
    getNewsList();
  }, []);

  useEffect(() => {
    setEditingContent(undefined);
  }, [project]);

  return (
    <>
      <div className="sub-menu">
        <div className="menu-head">
          <Space>{project?.subjectName}</Space>
        </div>
        <div
          className="menu-item"
          style={
            editingContent === "addon"
              ? { color: "#3671c9", backgroundColor: "#ccc" }
              : undefined
          }
          onClick={() => onSubChange("addon")}
        >
          <PlusCircleOutlined />
          {editingContent === "addon" && (
            <CaretRightOutlined className="menu-item-indicator" />
          )}
        </div>
        {project?.child.map((item) => (
          <div
            className="menu-item"
            key={item.id}
            onClick={() => onSubChange(item)}
            style={
              editingContent !== "addon" && editingContent?.id === item.id
                ? { color: "#3671c9", backgroundColor: "#ccc" }
                : undefined
            }
          >
            {item.subjectName}
            {editingContent !== "addon" && editingContent?.id === item.id && (
              <CaretRightOutlined className="menu-item-indicator" />
            )}
          </div>
        ))}
      </div>
      {editingContent ? (
        <Form className="ml-4 w-full" form={form}>
          <Form.Item>
            {editingContent === "addon" ? (
              <Button type="primary" onClick={save} loading={loading}>
                {t("declare.item.create")}
              </Button>
            ) : (
              <Space>
                <Button danger type="primary" onClick={onSubDelete}>
                  {t("declare.item.delete")}
                </Button>
                <Button type="primary" onClick={save} loading={loading}>
                  {t("declare.item.save")}
                </Button>
              </Space>
            )}
          </Form.Item>
          <Form.Item
            label={t("declare.item.title")}
            name="subjectName"
            rules={formRules.subjectName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("declare.item.contenttype")}
            name="subjectType"
            rules={formRules.subjectType}
          >
            <Select>
              {subjectTypes
                .filter((f) => f.firstOnly === false)
                .map((k) => (
                  <Select.Option key={k.value} value={k.value}>
                    {k.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {subjectType === 1 && (
            <Form.Item label={t("declare.item.richcontent")} name="contentText">
              <RichEditor />
            </Form.Item>
          )}
          {subjectType === 2 && (
            <>
              <Form.Item
                label={t("declare.item.tiptitle")}
                name="tipTitle"
                rules={[
                  { message: t("declare.item.entertiptitle"), required: true },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("declare.item.tipcontent")}
                name="tipContent"
                rules={[
                  {
                    message: t("declare.item.entertipcontent"),
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={3} maxLength={500} />
              </Form.Item>
            </>
          )}
          {subjectType === 6 && (
            <>
              <Form.Item
                label={t("declare.item.articletitle")}
                name="articalTitle"
                rules={[
                  {
                    message: t("declare.item.enterarticletitle"),
                    required: true,
                  },
                ]}
              >
                <Select
                  showArrow
                  allowClear
                  mode="multiple"
                  placeholder={t("declare.item.enterarticletitle")}
                >
                  {newsList?.map((el: any) => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      ) : (
        <Empty
          description={false}
          className="w-full flex justify-center items-center"
        />
      )}
    </>
  );
};

// 富文本
const RichContent = ({
  project,
  update,
}: {
  project: SubjectType;
  update: () => void;
}) => {
  const { t } = useLocale(i18n);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const save = () => {
    setLoading(true);
    form
      .validateFields()
      .then((d) => {
        project.contentText = d.contentText;

        updateSubject(project).then(({ data }) => {
          if (data.code) {
            message.success(t("declare.item.modifycontent"));
            update();
            return;
          }
          data?.msg && message.error(data.msg);
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    project.contentText = EscapeReverseHTML(project.contentText);
    form.setFieldsValue(project);
  }, [form, project]);

  return (
    <Form form={form}>
      <Form.Item>
        <Space>
          <Button danger type="primary" onClick={() => form.resetFields()}>
            {t("declare.item.clearcontent")}
          </Button>
          <Button type="primary" loading={loading} onClick={save}>
            {t("declare.item.save")}
          </Button>
        </Space>
      </Form.Item>
      <Form.Item label={t("declare.item.richcontent")} name="contentText">
        <RichEditor />
      </Form.Item>
    </Form>
  );
};

// 提示框
const MessageBoxContent = ({
  project,
  update,
}: {
  project: SubjectType;
  update: () => void;
}) => {
  const { t } = useLocale(i18n);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const save = () => {
    setLoading(true);
    form
      .validateFields()
      .then((d) => {
        project.contentText = JSON.stringify(d);

        updateSubject(project).then(({ data }) => {
          if (data.code) {
            message.success(t("declare.item.modifycontent"));
            update();
            return;
          }
          data?.msg && message.error(data.msg);
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    try {
      const { tipContent, tipTitle } = JSON.parse(project.contentText);
      project.tipContent = tipContent;
      project.tipTitle = tipTitle;
      form.setFieldsValue(project);
    } catch (err) {
      form.resetFields();
      console.warn(t("declare.item.resetvalue"));
    }
  }, [form, project, t]);

  return (
    <Form className="w-full" form={form}>
      <Form.Item>
        <Space>
          <Button danger type="primary" onClick={() => form.resetFields()}>
            {t("declare.item.clearcontent")}
          </Button>
          <Button type="primary" onClick={save} loading={loading}>
            {t("declare.item.save")}
          </Button>
        </Space>
      </Form.Item>
      <Form.Item
        label={t("declare.item.tiptitle")}
        name="tipTitle"
        rules={[{ message: t("declare.item.entertiptitle"), required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("declare.item.tipcontent")}
        name="tipContent"
        rules={[{ message: t("declare.item.entertipcontent"), required: true }]}
      >
        <Input.TextArea rows={3} maxLength={500} />
      </Form.Item>
    </Form>
  );
};

// 文章列表
const ArticalContent = ({
  project,
  update,
}: {
  project: SubjectType;
  update: () => void;
}) => {
  const { t } = useLocale(i18n);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const save = () => {
    setLoading(true);
    form
      .validateFields()
      .then((d) => {
        project.contentText = JSON.stringify(d);

        updateSubject(project).then(({ data }) => {
          if (data.code) {
            message.success(t("declare.item.modifycontent"));
            update();
            return;
          }
          data?.msg && message.error(data.msg);
        });
      })
      .finally(() => setLoading(false));
  };

  //文章列表
  const [newsList, setNewsList] = useState<any[]>([]);
  const getNewsList = async () => {
    const { data } = await showNews({});
    if (data.code) {
      setNewsList(data.data.rows);
    }
  };

  useEffect(() => {
    getNewsList();
  }, []);

  useEffect(() => {
    try {
      const { articalTitle } = JSON.parse(project.contentText);
      project.articalTitle = articalTitle;
      form.setFieldsValue(project);
    } catch (err) {
      form.resetFields();
      console.warn(t("declare.item.resetvalue"));
    }
  }, [form, project, t]);

  return (
    <Form className="w-full" form={form}>
      <Form.Item>
        <Space>
          <Button danger type="primary" onClick={() => form.resetFields()}>
            {t("declare.item.clear")}
          </Button>
          <Button type="primary" onClick={save} loading={loading}>
            {t("declare.item.save")}
          </Button>
        </Space>
      </Form.Item>
      <Form.Item
        label={t("declare.item.articletitle")}
        name="articalTitle"
        rules={[
          { message: t("declare.item.enterarticletitle"), required: true },
        ]}
      >
        <Select
          showArrow
          allowClear
          mode="multiple"
          placeholder={t("declare.item.enterarticletitle")}
          options={newsList?.map(({ id, name }: any) => ({
            value: id,
            label: name,
          }))}
        />
      </Form.Item>
    </Form>
  );
};

export default DeclarePageSetting;
