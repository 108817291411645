import req from "../configs/axios";

// 申报颜色
const findClassColorAll = (data?: any) => {
  return req.post("classColorController/findClassColorAll", data);
};

// 更新申报颜色设置
const updateClassColorAll = (data?: any) => {
  return req.post("auth/classColorController/updateClassColorAll", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export { findClassColorAll, updateClassColorAll };
