import { useEffect, type FC } from "react";
import { type AnswerSheet } from ".";
import i18n from "./i18n";
import { useLocale } from "../../hooks/useLocale";

interface AnswerCardProps {
  questions?: AnswerSheet[];
}

const AnswerCard: FC<AnswerCardProps> = ({ questions = [] }) => {
  const { t } = useLocale(i18n);
  const getItem = (q: AnswerSheet) => {
    if (q.answerSheet) {
      if (q.answerSheet.answerResult) {
        return (
          <div
            className="item bg-green-200 p-2 mt-2 rounded"
            key={q.n_question_id}
          >
            {q.c_question_name}
            <div className="text-right">{t("play.item.correctly")}</div>
          </div>
        );
      }

      return (
        <div className="item bg-red-200 p-2 mt-2 rounded" key={q.n_question_id}>
          {q.c_question_name}
          <div className="text-right">{t("play.item.wrong")}</div>
        </div>
      );
    }

    return (
      <div className="item bg-gray-50 p-2 mt-2 rounded" key={q.n_question_id}>
        {t("play.item.noanswer")}
      </div>
    );
  };

  return (
    <div className="answer-card">
      <div className="title text-lg">
        {t("play.item.statistics")}（
        {questions.filter((q) => Boolean(q.answerSheet)).length}/
        {questions.length}）
      </div>
      {questions.sort((q) => (q.answerSheet ? -1 : 1)).map(getItem)}
    </div>
  );
};

export default AnswerCard;
