import {
  DownloadOutlined,
  PieChartOutlined,
  LayoutOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import {
  Form,
  Space,
  Input,
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Tabs,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  queryScoreByLabIDWithMaxScore,
  queryScoreByLabIDWithMaxScoreExcel,
} from "../../../api/NewScoreController";
import { useLocale } from "../../../hooks/useLocale";
import { downloadByURL } from "../../../utils/download";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import LabReport from "../../components/LabReport";
import i18n from "./i18n";
import ScoreList from "./ScoreList";
import VideoScore from "./VideoScore";

const { confirm } = Modal;
const { useForm } = Form;

const ExperimentScorePage = () => {
  const { t } = useLocale(i18n);
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const [queryForm] = useForm();

  const getTableData = useCallback(async () => {
    setLoading(true);

    queryScoreByLabIDWithMaxScore({
      ...queryForm.getFieldsValue(),
      ...tablePagenation,
    })
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [queryForm, tablePagenation]);

  // 成绩列表
  const [scoreListShow, setScoreListShow] = useState(false);
  const [scoreListParam, setScoreListParam] = useState<any>(null);
  const reviewScoreList = (userid: number, labid: number) => {
    setScoreListParam({ userid, labid });
    setScoreListShow(true);
  };

  // 实验报告
  const [labRptShow, setLabRptShow] = useState(false);
  const [scoreId, setScoreId] = useState<number>();

  const reviewLabReport = (id: number) => {
    setScoreId(id);
    setLabRptShow(true);
  };

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div className="page-main">
      <CustomBreadcrumb
        breads={[t("menu.experiment"), t("menu.experiment.marks")]}
      />
      <div className="flex">
        <div className="overflow-hidden flex-grow">
          <div className="rounded px-4 bg-white">
            <Tabs
              items={[
                {
                  label: t("declare.results.simulation"),
                  key: "1",
                  children: (
                    <>
                      <div className="mb-4">
                        <Form layout="inline" form={queryForm}>
                          <Space wrap>
                            <Form.Item label={t("query.search")} name="search">
                              <Input
                                className="w-60"
                                allowClear
                                placeholder={t("query.search.placeholder")}
                              />
                            </Form.Item>
                            <Button onClick={() => queryForm.resetFields()}>
                              {t("query.search.clear")}
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() =>
                                setTablePagenation({ page: 1, rows: 10 })
                              }
                            >
                              {t("query.query")}
                            </Button>
                          </Space>
                        </Form>
                      </div>
                      <Space className="table-tool-bar">
                        <Space>
                          <Button
                            icon={<DownloadOutlined />}
                            onClick={() =>
                              downloadByURL(queryScoreByLabIDWithMaxScoreExcel)
                            }
                          >
                            {t("table.tools.export")}
                          </Button>
                        </Space>
                      </Space>
                      <Table
                        size="small"
                        rowKey="id"
                        bordered
                        scroll={{ x: 1700 }}
                        loading={loading}
                        dataSource={tableData}
                        pagination={{
                          size: "default",
                          showQuickJumper: true,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                          current: tablePagenation.page,
                          pageSize: tablePagenation.rows,
                          total,
                          showTotal: (total) =>
                            `${t("table.pagination.all")}${total}${t(
                              "table.pagination.total"
                            )}`,
                          onChange: (page, rows) => {
                            setTablePagenation({ page, rows });
                          },
                        }}
                        columns={[
                          {
                            title: t("table.index"),
                            width: 70,
                            fixed: "left",
                            align: "center",
                            render: (_val, _row, index) => index + 1,
                          },
                          {
                            title: t("user.attr.loginId"),
                            dataIndex: "loginID",
                            fixed: "left",
                            width: 120,
                          },
                          {
                            title: t("user.attr.name"),
                            dataIndex: "cScorename",
                            width: 120,
                          },
                          {
                            title: t("table.head.fscore"),
                            dataIndex: "fScore",
                            width: 120,
                          },
                          {
                            title: t("user.attr.yid"),
                            dataIndex: "yname",
                          },
                          {
                            title: t("user.attr.zid"),
                            dataIndex: "zname",
                          },
                          {
                            title: t("user.attr.bid"),
                            dataIndex: "bname",
                          },
                          {
                            title: t("table.head.kcname"),
                            dataIndex: "kcname",
                          },
                          {
                            title: t("table.head.labname"),
                            dataIndex: "labname",
                          },
                          {
                            title: t("table.head.labreport"),
                            align: "center",
                            render: (_val, row) => (
                              <Button
                                icon={<LayoutOutlined />}
                                onClick={() => reviewLabReport(row.id)}
                              >
                                {t("table.head.labreport")}
                              </Button>
                            ),
                          },
                          {
                            title: t("table.head.scorelist"),
                            align: "center",
                            render: (_val, row) => (
                              <Button
                                icon={<OrderedListOutlined />}
                                onClick={() =>
                                  reviewScoreList(row.nUseridbys, row.nLabid)
                                }
                              >
                                {t("table.head.scorelist")}
                              </Button>
                            ),
                          },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  label: t("declare.results.exerciseresult"),
                  key: "2",
                  children: <VideoScore />,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <ScoreList
        visible={scoreListShow}
        setVisible={setScoreListShow}
        param={scoreListParam}
        onReportView={(id) => reviewLabReport(id)}
      />
      <LabReport
        visible={labRptShow}
        setVisible={setLabRptShow}
        scoreid={scoreId}
        allowComment
      />
    </div>
  );
};

export default ExperimentScorePage;
