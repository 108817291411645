import req from "../configs/axios";

// 邮箱-列表
const listAllMailByParams = (data?: any) => {
  return req.post("auth/MailController/listAllByParams", data);
};

//邮箱-新增
const addMail = (data?: any) => {
  return req.post("auth/MailController/addMail", data);
};

//邮箱-新增
const deleteMail = (data?: any) => {
  return req.post("auth/MailController/deleteMail", data);
};

//邮箱-根据提问id查详情
const getOneMailById = (data?: any) => {
  return req.post("MailController/getOneQaById", data);
};

//邮箱-新增一级评论
const addOneCommentFirst = (data?: any) => {
  return req.post("MailController/addOneCommentFirst", data);
};

export {
  listAllMailByParams,
  addMail,
  deleteMail,
  getOneMailById,
  addOneCommentFirst,
};
