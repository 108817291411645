import req from "../configs/axios";

//常见答疑-列表
const listAllByParams = (data: any) => {
  return req.post("NewQuestionAnswerCommonController/listAllByParams", data);
};

//常见答疑-新增
const addOne = (data: any) => {
  return req.post("auth/NewQuestionAnswerCommonController/addOne", data);
};

//常见答疑-修改
const updateOne = (data: any) => {
  return req.post("auth/NewQuestionAnswerCommonController/updateOne", data);
};

//常见答疑-删除
const deleteOneById = (data: any) => {
  return req.post("NewQuestionAnswerCommonController/deleteOneById", data);
};

//常见答疑-单条数据
const getOneById = (data: any) => {
  return req.post("NewQuestionAnswerCommonController/getOneById", data);
};

export { listAllByParams, addOne, updateOne, deleteOneById, getOneById };
