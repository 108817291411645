import { listAllByStudent } from "@/api/ExamController";
import { cleanObject } from "@/utils/format";
import { Form, Space, Input, Button, Table, Tabs } from "antd";
import { useState, useEffect, type FC } from "react";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import ExamRecord from "./examRecord";

const initPagination = {
  page: 1,
  rows: 10,
};

const MyExamPage: FC = () => {
  const { t } = useLocale();
  const [loading, setLoading] = useState(false);
  const [queryForm] = Form.useForm();
  const [tableData, setTableData] = useState();
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);

  const getTableData = (params?: object) => {
    setLoading(true);
    if (!params) {
      setTablePagenation(initPagination);
      params = initPagination;
    }
    listAllByStudent(cleanObject({ ...params }))
      .then(({ data }) => {
        setTableData(data.data.rows);
        setTotal(data.data.records);
      })
      .finally(() => setLoading(false));
  };

  const onSearch = () => {
    const d = queryForm.getFieldsValue();
    if (Array.isArray(d?.tagIds)) {
      d.tagIds = d.tagIds.map(({ value }: any) => value).join();
    }
    getTableData({ ...d, ...initPagination });
    setTablePagenation(initPagination);
  };

  const onClear = () => {
    setTablePagenation(initPagination);
    queryForm.resetFields();
  };

  const goExam = (examId: string) => {
    window.open(`/#/exam-sheet/${examId}`);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.exam"), t("menu.exam.exam")]} />
      <div className="flex">
        <div className="overflow-hidden flex-grow">
          <div className="rounded px-4 pb-4 bg-white">
            <Tabs
              destroyInactiveTabPane
              items={[
                {
                  label: "我的考试",
                  key: "1",
                  children: (
                    <>
                      <div className="mb-4">
                        <Form layout="inline" form={queryForm}>
                          <Space wrap>
                            <Form.Item label="搜索" name="search">
                              <Input allowClear placeholder="搜索考试" />
                            </Form.Item>
                            <Button onClick={onClear}>清除</Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={onSearch}
                            >
                              查询
                            </Button>
                          </Space>
                        </Form>
                      </div>
                      <Table
                        size="small"
                        rowKey="id"
                        bordered
                        scroll={{ x: 1200 }}
                        loading={loading}
                        dataSource={tableData}
                        pagination={{
                          size: "default",
                          showQuickJumper: true,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                          current: tablePagenation.page,
                          pageSize: tablePagenation.rows,
                          total,
                          showTotal: (total) => `共${total}条`,
                          onChange: (page, rows) => {
                            setTablePagenation({ page, rows });
                            getTableData({
                              ...queryForm.getFieldsValue(),
                              page,
                              rows,
                            });
                          },
                        }}
                        columns={[
                          {
                            title: "序号",
                            width: 50,
                            align: "center",
                            render: (_val, _row, index) => index + 1,
                          },
                          {
                            title: "考试名称",
                            dataIndex: "examName",
                            width: 160,
                          },
                          {
                            title: "描述",
                            dataIndex: "examDescription",
                          },
                          {
                            title: "开放时间",
                            dataIndex: "startTime",
                            width: 200,
                            align: "center",
                          },
                          {
                            title: "时长(分钟)",
                            dataIndex: "examDuration",
                            width: 90,
                            align: "center",
                          },
                          {
                            title: "及格分值",
                            dataIndex: "passScore",
                            width: 80,
                            align: "center",
                          },
                          {
                            title: "关闭时间",
                            dataIndex: "endTime",
                            width: 200,
                            align: "center",
                          },
                          {
                            title: "操作",
                            width: 140,
                            align: "center",
                            fixed: "right",
                            render: (value, row: any) => (
                              <Button
                                type="primary"
                                onClick={() => goExam(row.id)}
                              >
                                开始考试
                              </Button>
                            ),
                          },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  label: "我的考试记录",
                  key: "2",
                  children: <ExamRecord />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyExamPage;
