import { Form, Spin, DatePicker, Button, Switch, message } from "antd";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState, type FC } from "react";
import {
  addOneLabSetting,
  getOneLabSettingByLabId,
  updateOneLabSetting,
} from "../../../../api/LabSettingController";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

interface UniversalPanelProps {
  labId: number;
}

const UniversalPanel: FC<UniversalPanelProps> = ({ labId }) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [formId, setFormId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const alertEnable = Form.useWatch("alertEnable", form);

  const getSetting = useCallback(() => {
    setLoading(true);
    getOneLabSettingByLabId({ labId })
      .then(({ data }) => {
        if (data.code) {
          if (data.data?.id) {
            const d = data.data;
            setFormId(d.id);
            form.setFieldsValue({
              _time: [moment(d.startTime), moment(d.endTime)],
              alertEnable: data.data.alertEnable === 1,
              beiYong1: data.data.beiYong1 === 1,
            });
          } else {
            form.resetFields();
          }
        }
      })
      .finally(() => setLoading(false));
  }, [form, labId]);

  const save = () => {
    form
      .validateFields()
      .then(
        (d: {
          _time?: [Moment, Moment];
          alertEnable: boolean;
          beiYong1: boolean;
        }) => {
          const { _time } = d;
          const payload: any = {
            labId,
          };
          if (Array.isArray(_time)) {
            payload.startTime = _time[0].format("YYYY-MM-DD HH:mm:ss");
            payload.endTime = _time[1].format("YYYY-MM-DD HH:mm:ss");
          }
          payload.alertEnable = d.alertEnable ? 1 : 0;
          payload.beiYong1 = d.beiYong1 ? 1 : 0;

          setSaving(true);
          if (formId) {
            updateOneLabSetting({
              id: formId,
              ...payload,
            })
              .then(({ data }) => {
                if (data.code) {
                  message.success(t("Setupanels.item.updategeneral"));
                  return;
                }
                data?.msg && message.error(data.msg);
              })
              .finally(() => setSaving(false));
          } else {
            addOneLabSetting(payload)
              .then(({ data }) => {
                if (data.code) {
                  getSetting();
                  message.success(t("Setupanels.item.setgeneral"));
                  return;
                }
                data?.msg && message.error(data.msg);
              })
              .finally(() => setSaving(false));
          }
        }
      );
  };

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Form.Item
          label={t("Setupanels.item.opennotification")}
          name="alertEnable"
          valuePropName="checked"
          tooltip={
            <>
              {t("Setupanels.item.afteropen")}
              <div>{t("Setupanels.item.changetime")}</div>
              <div>{t("Setupanels.item.changeauthority")}</div>
              <div>{t("Setupanels.item.deadline")}</div>
            </>
          }
        >
          <Switch />
        </Form.Item>
        {alertEnable && (
          <>
            <Form.Item
              label={t("Setupanels.item.learntime")}
              name="_time"
              tooltip={t("Setupanels.item.specify")}
            >
              <DatePicker.RangePicker showTime />
            </Form.Item>
            <Form.Item
              label={t("Setupanels.item.continue")}
              name="beiYong1"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </>
        )}
      </Form>
      <Button type="primary" loading={saving} onClick={save}>
        {t("Setupanels.item.savechange")}
      </Button>
    </Spin>
  );
};

export default UniversalPanel;
