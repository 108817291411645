import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { findAllPath } from "../api/ImagePtController";
import { findAllSystemMessage } from "../api/SystemMessageController";
import { useLocale } from "../hooks/useLocale";

type SystemInfoContextValues = {
  sysInfo?: {
    logo: string;
    logoDeclare: string; // 申报页logo(纯色)
    bg: string;
    banner: string;
    browser: string; // 推荐浏览器
    introVideo: string; // 简介视频
    guideVideo: string; // 引导视频
  };
  baseInfo?: {
    title: string;
    greeting: string;
    projectName: string;
    footer: string;
  };
};

const SystemInfoContext = createContext<SystemInfoContextValues | undefined>(
  undefined
);
SystemInfoContext.displayName = "SystemInfo-Context";

// 系统基础信息(logo\banner\bg\footer\title等)
const SystemInfoProvider = ({ children }: { children: ReactNode }) => {
  const { t, lang } = useLocale();
  const [sysInfo, setSysInfo] = useState<SystemInfoContextValues["sysInfo"]>();
  const [baseInfo, setBaseInfo] =
    useState<SystemInfoContextValues["baseInfo"]>();
  // const [title, setTitle] = useState(t("system.title"));

  const getSystemInfo = useCallback(() => {
    findAllSystemMessage().then(({ data }) => {
      if (data.code) {
        const d = data.data;
        setBaseInfo({
          title: d?.[lang]?.tSystemMessageName || t("system.title"),
          greeting: d?.[lang]?.tSystemMessageWelcomes,
          projectName: d?.[lang]?.tSystemMessageProject,
          footer: d?.[lang]?.tSystemMessageBeiyong3,
        });
      }
    });
  }, [lang, t]);

  const getSystemResource = () => {
    findAllPath().then(({ data }) => {
      if (data.code) {
        const d = data.data;
        setSysInfo({
          logo: d?.["1"]?.tImagePtPath,
          bg: d?.["2"]?.tImagePtPath,
          logoDeclare: d?.["3"]?.tImagePtPath,
          banner: d?.["4"]?.tImagePtPath,
          browser: d?.["5"]?.tImagePtPath,
          guideVideo: d?.["6"]?.tImagePtPath,
          introVideo: d?.["7"]?.tImagePtPath,
        });
      }
    });
  };

  useEffect(() => {
    getSystemInfo();
  }, [getSystemInfo]);

  useEffect(() => {
    getSystemResource();
  }, []);

  return (
    <SystemInfoContext.Provider value={{ sysInfo, baseInfo }}>
      {children}
    </SystemInfoContext.Provider>
  );
};

export { SystemInfoContext, SystemInfoProvider };
