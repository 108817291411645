import {
  DeleteOutlined,
  FileTextOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Input,
  InputNumber,
  Modal,
  Popover,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tree,
  type TreeProps,
} from "antd";
import { flatMapDeep } from "lodash-es";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { queryTagTree } from "../../../api/queryTagTree";
import { showQuestion } from "../../../api/QuestionController";
import { isVoid } from "../../../utils/format";
import {
  questionTypes,
  type QuestionTagTree,
  type Question,
} from "../../exam/library";
import HighligntKeyNode from "../HighligntKeyNode";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";
import { RichDisplay } from "../RichEditor";

interface QuestionPickerProps {
  open?: boolean;
  onClose?: () => void;
  picked?: Question[]; // 已选择的试题列表（受控属性）
  onChange?: (pickedList: Question[]) => void;
  onConfirm?: (pickedList: Question[]) => void;
  objectiveOnly?: boolean; // 仅列出所有客观题
}

export interface QuestionPickerRef {
  unpick: (questionId: string) => void;
}

interface QuestionPicked
  extends Pick<
    Question,
    "c_question_name" | "n_question_id" | "n_question_type" | "f_points"
  > {
  order: number;
}

const initPagination = {
  page: 1,
  rows: 10,
};

const getParentKey = (key: React.Key, tree: QuestionTagTree[]): React.Key => {
  let parentKey: React.Key = "";
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.n_tag_id === key)) {
        parentKey = node.n_tag_id;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

const QuestionPicker = forwardRef<QuestionPickerRef, QuestionPickerProps>(
  (
    { open, picked, onClose, onChange, onConfirm, objectiveOnly = true },
    ref
  ) => {
    const { t } = useLocale(i18n);
    const [tagTree, setTagTree] = useState<QuestionTagTree[]>([]);
    const [flattenTagTree, setFlattenTagTree] = useState<QuestionTagTree[]>([]);
    const [tagLoading, setTagLoading] = useState(false);
    const [questionList, setQuestionList] = useState<any[]>([]);
    const [questionMap, setQuestionMap] = useState<Map<string, any>>();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [tablePagenation, setTablePagenation] = useState(initPagination);
    const [pickedList, setPickedList] = useState<Question[]>([]);
    const [typeIds, setTypeIds] = useState<number>();
    const [tagIds, setTagIds] = useState<string>();
    const [tagSearch, setTagSearch] = useState<string>();
    const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const getQuestionList = useCallback(
      (search?: string) => {
        setLoading(true);
        showQuestion({ ...tablePagenation, typeIds, tagIds, search })
          .then(({ data }) => {
            setQuestionList(data.data.rows);
            // const map = new Map();
            // for (let row of data.data.rows) {
            //   map.set(row.n_question_id, row);
            // }
            // setQuestionMap(map);
            setTotal(data.data.records);
          })
          .finally(() => setLoading(false));
      },
      [tablePagenation, tagIds, typeIds]
    );

    const getTagTree = async () => {
      setTagLoading(true);
      const { data } = await queryTagTree();
      setTagLoading(false);
      setTagTree(data.data);
      setFlattenTagTree(
        flatMapDeep(data.data, (item) => [item, item.children])
      );
    };

    const pick = (question: Question) => {
      if (picked) {
        onChange?.([...picked, question]);
      } else {
        setPickedList((o) => [...o, question]);
      }
    };

    const unpick = (questionId: string) => {
      if (picked) {
        const n = [...picked];
        n.splice(
          n.findIndex((value) => value.n_question_id === questionId),
          1
        );
        onChange?.(n);
      } else {
        setPickedList((o) => {
          const n = [...o];
          n.splice(
            n.findIndex((value) => value.n_question_id === questionId),
            1
          );

          return n;
        });
      }
    };

    const confirm = () => {
      onConfirm?.(picked || pickedList);
      onClose?.();
    };

    useEffect(() => {
      if (open) getQuestionList();
    }, [getQuestionList, open]);

    useEffect(() => {
      if (open) {
        getTagTree();
      } else {
        setPickedList([]);
      }
    }, [open]);

    useImperativeHandle(ref, () => ({ unpick }));

    const popContent = (
      <div className="w-100">
        <Table
          bordered
          size="small"
          rowKey="n_question_id"
          pagination={false}
          dataSource={picked || pickedList}
          scroll={{ y: 400 }}
          columns={[
            {
              title: t("questionPicker.item.operation"),
              width: 50,
              align: "center",
              render: (_val, row) => (
                <Button
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => unpick(row.n_question_id)}
                />
              ),
            },
            {
              title: t("questionPicker.item.rank"),
              width: 50,
              align: "center",
              render: (_val, _row, index) => index + 1,
            },
            {
              title: t("questionPicker.item.stem"),
              dataIndex: "c_question_name",
              render: (val) => <RichDisplay html={val} />,
            },
            {
              title: t("questionPicker.item.testtype"),
              dataIndex: "n_question_type",
              width: 80,
              align: "center",
              render: (val) => questionTypes.get(val),
            },
          ]}
        />
      </div>
    );

    // 树搜索相关
    const onTagSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value } = e.target;
      let newExpandedKeys: (React.Key | null)[] = [];

      if (value) {
        newExpandedKeys = flattenTagTree
          .map((item) =>
            item.c_tag_name.includes(value)
              ? getParentKey(item.n_tag_id, tagTree)
              : null
          )
          .filter((item, i, self) => item && self.indexOf(item) === i);
      }

      setExpandedKeys(newExpandedKeys);
      setTagSearch(value);
      setAutoExpandParent(true);
    };

    const setTreeNode = (data: QuestionTagTree[]): any[] =>
      data?.map((item) => ({
        ...item,
        title: (
          <HighligntKeyNode
            key={item.n_tag_id}
            label={item.c_tag_name}
            keyword={tagSearch}
          />
        ),
        children: item.children?.length ? setTreeNode(item.children) : null,
      }));

    const onNodeExpand: TreeProps["onExpand"] = (newExpandedKeys) => {
      setExpandedKeys(newExpandedKeys);
      setAutoExpandParent(false);
    };

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title={t("questionPicker.item.seltest")}
        footer={null}
        destroyOnClose
        width={1200}
        bodyStyle={{ padding: 0 }}
      >
        <Row className="min-h-180">
          <Col
            span={6}
            className="p-4 overflow-y-auto relative"
            style={{ borderRight: "1px solid #eee" }}
          >
            <Input.Search
              className="mb-2"
              placeholder={t("questionPicker.item.searchtag")}
              allowClear
              onChange={onTagSearch}
            />
            <Spin spinning={tagLoading}>
              <Tree
                checkable
                checkStrictly
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onExpand={onNodeExpand}
                onCheck={(_, { checkedNodes }: any) => {
                  setTablePagenation(initPagination);
                  setTagIds(
                    checkedNodes
                      ?.map((tag: QuestionTagTree) => tag.n_tag_id)
                      ?.join()
                  );
                }}
                fieldNames={{ key: "n_tag_id" }}
                className="h-140 overflow-y-auto"
                treeData={setTreeNode(tagTree)}
              />
            </Spin>
            <Popover trigger="hover" placement="topLeft" content={popContent}>
              <Badge
                count={(picked || pickedList).length}
                className="absolute bottom-10 left-10"
              >
                <Button
                  icon={<FileTextOutlined />}
                  size="large"
                  shape="circle"
                  type="primary"
                  ghost
                />
              </Badge>
            </Popover>
          </Col>
          <Col
            span={18}
            className="p-4 h-full overflow-y-auto flex-grow text-right"
          >
            <Select
              placeholder={t("questionPicker.item.seltype")}
              className="w-40 text-left"
              allowClear
              value={typeIds}
              onChange={(value) => {
                setTablePagenation(initPagination);
                setTypeIds(value);
              }}
              options={Array.from(questionTypes, ([value, label]) => ({
                value,
                label,
              }))}
            />
            <Input.Search
              className="mb-2 w-60 ml-4"
              placeholder={t("questionPicker.item.searchstem")}
              allowClear
              onSearch={(text) => {
                setTablePagenation(initPagination);
                getQuestionList(text);
              }}
            />
            <Table
              size="small"
              rowKey="n_question_id"
              bordered
              dataSource={questionList}
              loading={loading}
              scroll={{ y: 500 }}
              pagination={{
                size: "default",
                showQuickJumper: true,
                showSizeChanger: true,
                position: ["bottomCenter"],
                current: tablePagenation.page,
                pageSize: tablePagenation.rows,
                total,
                showTotal: (total) =>
                  `${t("table.pagination.all")}${total}${t(
                    "table.pagination.total"
                  )}`,
                onChange: (page, rows) => {
                  setTablePagenation({
                    page,
                    rows,
                  });
                },
              }}
              columns={[
                {
                  title: t("questionPicker.item.abstract"),
                  width: 50,
                  fixed: "left",
                  align: "center",
                  render: (_val, row) =>
                    (picked || pickedList).some(
                      (x) => x.n_question_id === row.n_question_id
                    ) ? (
                      <Button
                        size="small"
                        danger
                        shape="circle"
                        icon={<MinusOutlined />}
                        onClick={() => unpick(row.n_question_id)}
                      />
                    ) : (
                      <Button
                        size="small"
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => pick(row)}
                      />
                    ),
                },
                {
                  title: t("questionPicker.item.stem"),
                  dataIndex: "c_question_name",
                  render: (val) => <RichDisplay html={val} />,
                },
                {
                  title: t("questionPicker.item.testtype"),
                  dataIndex: "n_question_type",
                  width: 80,
                  align: "center",
                  render: (val) => questionTypes.get(val),
                },
                {
                  title: t("questionPicker.item.tag"),
                  dataIndex: "tagnameList",
                  render: (tagnameList) => (
                    <Space wrap>
                      {tagnameList.map((tag: any) => (
                        <Tag color="blue" key={tag.n_tag_id}>
                          {tag.c_tag_name}
                        </Tag>
                      ))}
                    </Space>
                  ),
                },
                {
                  title: t("questionPicker.item.score"),
                  dataIndex: "f_points",
                  width: 50,
                  align: "center",
                },
                {
                  title: t("questionPicker.item.accuracy"),
                  dataIndex: "n_right_times",
                  width: 60,
                  align: "center",
                  render: (val) => val * 100 + "%",
                },
                {
                  title: t("questionPicker.item.error"),
                  dataIndex: "n_wrong_times",
                  width: 60,
                  align: "center",
                  render: (val) => val * 100 + "%",
                },
                {
                  title: t("questionPicker.item.difficulty"),
                  dataIndex: "n_difficulty",
                  width: 120,
                  align: "center",
                  render: (val) => <Rate disabled value={val} count={3} />,
                },
              ]}
            />
            <Button type="primary" onClick={confirm} className="mt-2">
              {t("questionPicker.item.confirmsel")}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
);

interface QuestionFormItemProps {
  value?: QuestionPicked[];
  onChange?: (value: QuestionPicked[]) => void;
  onDelete?: (qid: string) => void;
  rescored?: boolean;
}

const QuestionFormItem: React.FC<QuestionFormItemProps> = ({
  value,
  onChange,
  onDelete,
  rescored,
}) => {
  if (!value) return null;

  const itemChange = (id: string, point: number) => {
    // 此处仅通知父组件；已在子组件修改了数据源
    onChange?.(value);
  };

  const Item: React.FC<{
    q: QuestionPicked;
    i: number;
    onChange: (id: string, point: number) => void;
    onDelete?: (id: string) => void;
  }> = ({ q, i, onChange, onDelete }) => {
    const { t } = useLocale(i18n);
    const [point, setPoint] = useState<number>(q.f_points);

    const handleChange = (val: number | null) => {
      if (!val) return;
      setPoint(val);
      q.f_points = val;
      onChange(q.n_question_id, val);
    };

    return (
      <Row key={q.n_question_id} gutter={[10, 10]} className="mb-2">
        <Col span={2}>{i + 1}</Col>
        <Col span={15}>{q.c_question_name}</Col>
        {rescored && (
          <Col span={5}>
            <span>{t("questionPicker.item.score")}：</span>
            <InputNumber
              className="w-17"
              min={0}
              status={isVoid(point) ? "error" : undefined}
              onChange={handleChange}
              value={point}
            />
          </Col>
        )}
        <Col span={1}>
          <Button
            icon={<DeleteOutlined />}
            danger
            title={t("questionPicker.item.delete")}
            onClick={() => onDelete?.(q.n_question_id)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {value.map((q, i) => (
        <Item
          q={q}
          i={i}
          key={q.n_question_id}
          onChange={itemChange}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

const CompoundedQuestionPicker = QuestionPicker as typeof QuestionPicker & {
  FormItem: typeof QuestionFormItem;
};

CompoundedQuestionPicker.FormItem = QuestionFormItem;

export default CompoundedQuestionPicker;
