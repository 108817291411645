import { useEffect, useState } from "react";

export const useStorage = (key: string, defaultValue?: any) => {
  const [storedValue, setStoredValue] = useState(
    localStorage.getItem(key) || defaultValue
  );

  const setItem = (value: any) => {
    if (value instanceof Object) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }

    if (value !== storedValue) setStoredValue(value);
  };

  const rmItem = () => {
    localStorage.removeItem(key);
  };

  useEffect(() => {
    const item = localStorage.getItem(key);
    if (item) setStoredValue(item);
  }, [key]);

  return [storedValue, setItem, rmItem];
};
