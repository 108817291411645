import { createContext, useContext, useEffect, useState } from "react";
import CustomBreadcrumb from "@/views/components/CustomBreadcrumb";
import { type QuestionTagTree, type QuestionTag } from "../library";
import { cleanObject, getFlattenMap } from "@/utils/format";
import { Button, Form, Input, message, Modal, Space, Table, Image } from "antd";
import { deleteExam, listAllByParams } from "@/api/ExamController";
import { queryTagTree } from "@/api/queryTagTree";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExamEditor from "./examEditor";

export interface Exam {
  id: string;
  examName: string;
  examBanner: string;
  examDescription: string;
  questionOption: 0 | 1; // 选择题选项 0正序 1乱序
  startTime: string; // 考试开放时间
  endTime: string; // 考试关闭时间
  examDuration: number; // 考试时长(分钟)
  tagList: string[];
  groupList: string[]; // 范围
  examNumber: number; // 允许考试次数
  passScore: number; // 及格分值
  examRight?: 0 | 1; // 0不设权限，1设置班级权限
  questionList?: {
    questionId: string;
    questionPoints: number;
  }[];
  questionTypeList?: {
    points: number;
    questionNumber: number;
    questionType: number;
  }[];
}

interface ExamValues {
  exams?: Exam[];
  tagLoading?: boolean;
  tagTree?: QuestionTagTree[];
  tagMap?: Map<string, QuestionTag>;
}

const ExamContext = createContext<ExamValues | null>(null);

export const useExam = () => {
  const ctx = useContext(ExamContext);
  if (!ctx) {
    throw new Error("ExamContext 不在父状态树中");
  }
  return ctx;
};

const initPagination = {
  page: 1,
  rows: 10,
};

const ExamManagePage = () => {
  const [loading, setLoading] = useState(false);
  const [queryForm] = Form.useForm();
  const [multipleEdit, setMultipleEdit] = useState(false);
  const [multipleSelectedRows, setMultipleSelectedRows] = useState<React.Key[]>(
    []
  );
  const [tableData, setTableData] = useState();
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const [editOpen, setEditOpen] = useState(false);
  const [editExam, setEditExam] = useState<Exam>();
  const [tagLoading, setTagLoading] = useState(false);
  const [tagTree, setTagTree] = useState<QuestionTagTree[]>([]);
  const [tagMap, setTagMap] = useState<Map<string, QuestionTag>>();

  const getTableData = (params?: object) => {
    setLoading(true);
    if (!params) {
      setTablePagenation(initPagination);
      params = initPagination;
    }
    listAllByParams(cleanObject({ ...params }))
      .then(({ data }) => {
        setTableData(data.data.rows);
        setTotal(data.data.records);
      })
      .finally(() => setLoading(false));
  };

  const onSearch = () => {
    const d = queryForm.getFieldsValue();
    if (Array.isArray(d?.tagIds)) {
      d.tagIds = d.tagIds.map(({ value }: any) => value).join();
    }
    getTableData({ ...d, ...initPagination });
    setTablePagenation(initPagination);
  };

  const onClear = () => {
    setTablePagenation(initPagination);
    queryForm.resetFields();
  };

  const cancelTabRowSelection = () => {
    setMultipleEdit(false);
    setMultipleSelectedRows([]);
  };

  const deleteTableRows = () => {
    if (multipleSelectedRows.length === 0) {
      message.warning("需要先选择列表项");
      return;
    }

    Modal.confirm({
      title: "确定要删除选择的考试吗？",
      content: `将删除${multipleSelectedRows.length}个考试，此操作无法还原，确定吗？`,
      async onOk() {
        const { data } = await deleteExam({
          idList: multipleSelectedRows.join(),
        });
        if (data.code) {
          message.success("操作成功，已删除考试");
          onSearch();
        }
      },
    });
  };

  const openToEdit = (exam: any) => {
    // console.log(exam);
    setEditExam(exam);
    setEditOpen(true);
  };

  const getTagTree = () => {
    setTagLoading(true);
    queryTagTree()
      .then(({ data }) => {
        if (data.code) {
          setTagTree(data.data);
          setTagMap(getFlattenMap(data.data, "n_tag_id"));
        }
      })
      .finally(() => setTagLoading(false));
  };

  useEffect(() => {
    getTableData();
    getTagTree();
  }, []);

  return (
    <ExamContext.Provider value={{ tagLoading, tagTree, tagMap }}>
      <div className="page-main">
        <CustomBreadcrumb breads={["考试", "考试管理"]} />
        <div className="main-content-inner">
          <div className="query-form bg-white p-4 rounded mb-4">
            <Form layout="inline" form={queryForm}>
              <Space wrap>
                <Form.Item label="考试" name="search">
                  <Input allowClear placeholder="搜索考试名称" />
                </Form.Item>
                <Button onClick={onClear}>清除</Button>
                <Button type="primary" htmlType="submit" onClick={onSearch}>
                  查询
                </Button>
              </Space>
            </Form>
          </div>
          <div className="bg-white p-4 rounded">
            <Space className="mb-4 flex items-center justify-between">
              <Space>
                <Button type="primary" onClick={() => setEditOpen(true)}>
                  创建考试
                </Button>
              </Space>
              <Space>
                {multipleEdit ? (
                  <Space>
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={deleteTableRows}
                    >
                      删除
                    </Button>
                    <Button
                      icon={
                        <FontAwesomeIcon
                          icon={["fas", "arrow-rotate-right"]}
                          className="mr-2"
                        />
                      }
                      onClick={cancelTabRowSelection}
                    >
                      取消
                    </Button>
                  </Space>
                ) : (
                  <Button
                    icon={<FormOutlined />}
                    onClick={() => setMultipleEdit(true)}
                  >
                    批量操作
                  </Button>
                )}
              </Space>
            </Space>
            <Table
              size="small"
              rowKey="id"
              bordered
              scroll={{ x: 1200 }}
              loading={loading}
              dataSource={tableData}
              rowSelection={
                multipleEdit
                  ? {
                      type: "checkbox",
                      onChange: (
                        selectedRowKeys: React.Key[],
                        _selectedRows: any
                      ) => {
                        setMultipleSelectedRows(selectedRowKeys);
                      },
                    }
                  : undefined
              }
              pagination={{
                size: "default",
                showQuickJumper: true,
                showSizeChanger: true,
                position: ["bottomCenter"],
                current: tablePagenation.page,
                pageSize: tablePagenation.rows,
                total,
                showTotal: (total) => `共${total}条`,
                onChange: (page, rows) => {
                  setTablePagenation({ page, rows });
                  getTableData({ ...queryForm.getFieldsValue(), page, rows });
                },
              }}
              columns={[
                {
                  title: "序号",
                  width: 50,
                  align: "center",
                  render: (_val, _row, index) => index + 1,
                },
                {
                  title: "考试名称",
                  dataIndex: "examName",
                  width: 160,
                },
                {
                  title: "描述",
                  dataIndex: "examDescription",
                },
                {
                  title: "开放时间",
                  dataIndex: "startTime",
                  width: 200,
                  align: "center",
                },
                {
                  title: "时长(分钟)",
                  dataIndex: "examDuration",
                  width: 90,
                  align: "center",
                },
                {
                  title: "及格分值",
                  dataIndex: "passScore",
                  width: 80,
                  align: "center",
                },
                {
                  title: "关闭时间",
                  dataIndex: "endTime",
                  width: 200,
                  align: "center",
                },
                {
                  title: "操作",
                  width: 140,
                  align: "center",
                  fixed: "right",
                  render(value, row: any) {
                    return (
                      <Space>
                        <Button size="small" onClick={() => openToEdit(row)}>
                          修改
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          danger
                          onClick={() => {
                            Modal.confirm({
                              title: "确定要删除此竞赛吗？",
                              icon: <ExclamationCircleOutlined />,
                              content: "删除的内容无法恢复",
                              centered: true,
                              async onOk() {
                                // const { data } = await deleteContest({
                                //   id: row.id,
                                // });
                                // if (data.code) {
                                //   onSearch();
                                //   message.success("删除成功");
                                //   return;
                                // }
                              },
                            });
                          }}
                        >
                          删除
                        </Button>
                      </Space>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
        <ExamEditor
          open={editOpen}
          onClose={() => {
            setEditExam(undefined);
            setEditOpen(false);
          }}
          examInfo={editExam}
          refresh={onSearch}
        />
      </div>
    </ExamContext.Provider>
  );
};

export default ExamManagePage;
