import { Button, Space } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";

const NotFoundPage = () => {
  const [animated, setAnimated] = useState(false);
  const navigate = useNavigate();
  const { t } = useLocale();

  const handleEnter = () => {
    setAnimated(true);
  };

  return (
    <div className="page-not-found">
      <div className="title">
        <div
          className={`title-main ${animated ? "drift" : null}`}
          onMouseEnter={handleEnter}
        >
          404
        </div>
        <div className="title-shadow">404</div>
      </div>
      <div className="subtitle">{t("exception.page.404")}</div>
      <Space>
        <Button type="link" className="mt-6" onClick={() => navigate("/")}>
          {t("global.backtohome")}
        </Button>
        <Button type="link" className="mt-6" onClick={() => navigate(-1)}>
          {t("global.back")}
        </Button>
      </Space>
    </div>
  );
};

export default NotFoundPage;
