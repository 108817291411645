import req from "../configs/axios";

// 首页-仪表盘
const homeData = () => {
  return req.post("NewHomeController/homeData");
};

// 数据统计
const statisticsData = (data?: {
  labId?: number;
  startTime?: string;
  endTime?: string;
}) => {
  return req.post("NewHomeController/statisticsData", data);
};

export { homeData, statisticsData };
