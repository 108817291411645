import { Breadcrumb, Space } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useLocale } from "../../../hooks/useLocale";

interface CustomBreadcrumbProps {
  breads?: React.ReactNode[];
}

const CustomBreadcrumb = (props: CustomBreadcrumbProps) => {
  const { breads = [] } = props;
  const { t } = useLocale();

  return (
    <Breadcrumb className="py-2">
      <Breadcrumb.Item>
        <Link to="/home/dashboard">
          <Space>
            <HomeOutlined />
            {t("menu.home")}
          </Space>
        </Link>
      </Breadcrumb.Item>
      {breads?.map((bread, i) => (
        <Breadcrumb.Item key={i}>{bread}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
