const i18n = {
  "en-US": {
    "declare.item.FAQs": "Frequently Asked Questions and Answers",
    "declare.item.language": "Language",
    "declare.item.chooselanguage": "choose language",
    "declare.item.Chinese": "Chinese",
    "declare.item.description": "problem description",
    "declare.item.inpdescription": "Please enter a description of the problem",
    "declare.item.query": "Query",
    "declare.item.add": "newly added",
    "table.pagination.all": "",
    "table.pagination.total": " total",
    "table.index": "Index",
    "declare.item.Qtime": "Question time",
    "declare.item.operation": "Operation",
    "declare.item.modify": "modify",
    "declare.item.suredel": "Are you sure you want to delete it?",
    "declare.item.restored": "Delete cannot be restored",
    "declare.item.successdel": "deleted successfully",
    "declare.item.latertry": "Operation failed please try later!",
    "declare.item.delete": "delete",
    "declare.item.Qlist": "List of Questions",
    "declare.item.Receiver": "Receiver Email Settings",
    "declare.item.addQA": "Added questions and answers",
    "declare.item.question": "Question",
    "declare.item.particulars": "Particulars",
    "declare.item.submit": "Submit",
    "declare.item.modifyQA": "Modify questions and answers",
    "declare.item.checkinvalid": "Please check for invalid verification",
    "declare.item.addsuccess": "Adding Success!",
    "declare.item.infupdate": "Information has been updated",
    "declare.item.urgency": "Urgency",
    "declare.item.ordinary": "Ordinary",
    "declare.item.resolved": "Resolved",
    "declare.item.processing": "Processing",
    "declare.item.waitReply": "Forward to your reply",
    "declare.item.copyMsg": "Copy successful",
    "declare.item.state": "State",
    "declare.item.selectstate": "select state",
    "declare.item.schedule": "Schedule",
    "declare.item.selschedule": "Select schedule",
    "declare.item.endTime": "End time",
    "declare.item.Questioner": "Questioner",
    "declare.item.number": "number",
    "declare.item.personalDetails": "Personal details",
    "declare.item.close": "close",
    "declare.item.closesuccess": "Closed successfully",
    "declare.item.notreply": "Questions will not be answered after closing",
    "declare.item.sureclose": "Are you sure you want to close this question?",
    "declare.item.footer": "Technical Support",
    "declare.item.replysuccess": "Reply Success!",
    "declare.item.name": "Name",
    "declare.item.mail": "Mailbox",
    "declare.item.addmail": "Adding a Mailbox",
    "declare.item.entername": "Please enter your name",
    "declare.item.entermail": "Please enter email address",
  },
  "zh-CN": {
    "declare.item.FAQs": "常见问题与解答",
    "declare.item.language": "语言",
    "declare.item.chooselanguage": "选择语言",
    "declare.item.Chinese": "简体中文",
    "declare.item.description": "问题描述",
    "declare.item.inpdescription": "请输入问题描述",
    "declare.item.query": "查询",
    "declare.item.add": "新增",
    "table.pagination.all": "共",
    "table.pagination.total": "条",
    "table.index": "序号",
    "declare.item.Qtime": "提问时间",
    "declare.item.operation": "操作",
    "declare.item.modify": "修改",
    "declare.item.suredel": "确定要删除吗？",
    "declare.item.restored": "删除无法恢复",
    "declare.item.successdel": "删除成功",
    "declare.item.latertry": "操作失败请稍后尝试！",
    "declare.item.delete": "删除",
    "declare.item.Qlist": "提问列表",
    "declare.item.Receiver": "接收者邮箱设置",
    "declare.item.addQA": "新增问题与解答",
    "declare.item.question": "问题",
    "declare.item.particulars": "详情",
    "declare.item.submit": "提交",
    "declare.item.modifyQA": "修改问题与解答",
    "declare.item.checkinvalid": "请检查不合法的校验项",
    "declare.item.addsuccess": "新增成功！",
    "declare.item.infupdate": "信息已更新",
    "declare.item.urgency": "紧急",
    "declare.item.ordinary": "普通",
    "declare.item.resolved": "已解决",
    "declare.item.processing": "处理中",
    "declare.item.waitReply": "待您回复",
    "declare.item.copyMsg": "复制成功",
    "declare.item.state": "状态",
    "declare.item.selectstate": "选择状态",
    "declare.item.schedule": "进度",
    "declare.item.selschedule": "选择进度",
    "declare.item.endTime": "结束时间",
    "declare.item.Questioner": "提问者",
    "declare.item.number": "编号",
    "declare.item.personalDetails": "个人详情",
    "declare.item.close": "关闭",
    "declare.item.closesuccess": "关闭成功",
    "declare.item.notreply": "关闭提问后将不能再获得回复",
    "declare.item.sureclose": "确定要关闭此提问吗？",
    "declare.item.footer": "技术支持",
    "declare.item.replysuccess": "回复成功！",
    "declare.item.name": "姓名",
    "declare.item.mail": "邮箱",
    "declare.item.addmail": "新增邮箱",
    "declare.item.entername": "请输入姓名",
    "declare.item.entermail": "请输入邮箱",
  },
};

export default i18n;
