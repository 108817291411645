import req from "../configs/axios";

// 查询实验通用设置
const getOneLabSettingByLabId = (data?: any) => {
  return req.post("LabSettingController/getOneLabSettingByLabId", data);
};

// 新增实验通用设置
const addOneLabSetting = (data?: any) => {
  return req.post("LabSettingController/addOneLabSetting", data);
};

// 更新实验通用设置
const updateOneLabSetting = (data?: any) => {
  return req.post("LabSettingController/updateOneLabSetting", data);
};

// 查询视频实验绑定的试题集
const listLabQuestionByLabId = (data?: any) => {
  return req.post("LabSettingController/listLabQuestionByLabId", data);
};

// 设置视频实验的试题集
const addBatchLabQuestion = (data?: any) => {
  return req.post("LabSettingController/addBatchLabQuestion", data);
};

// 创建视频实验观看记录
const addScoreVideo = (data?: any) => {
  return req.post("LabSettingController/addScoreVideo", data);
};

// 根据视频实验记录id获取基本信息
const getScoreVideoById = (data?: any) => {
  return req.post("LabSettingController/getScoreVideoById", data);
};

// 更新观看时长
const updateWatchDurationById = (data: any) => {
  return req.post("LabSettingController/updateWatchDurationById", data);
};

// 提交答题结果
const compareAnswers = (data: any) => {
  return req.post("LabSettingController/compareAnswers", data);
};

// 查询学生视频实验记录列表
const listMaxByParams = (data?: any) => {
  return req.post("LabSettingController/listMaxByParams", data);
};

// 学生个人视频答题记录
const listAllByParams = (data?: any) => {
  return req.post("LabSettingController/listAllByParams", data);
};

// 查询个人视频实验记录列表
const listAllByParamsByLogin = (data?: any) => {
  return req.post("LabSettingController/listAllByParamsByLogin", data);
};

export {
  getOneLabSettingByLabId,
  addOneLabSetting,
  updateOneLabSetting,
  listLabQuestionByLabId,
  addBatchLabQuestion,
  addScoreVideo,
  getScoreVideoById,
  updateWatchDurationById,
  compareAnswers,
  listMaxByParams,
  listAllByParams,
  listAllByParamsByLogin,
};
