import { useRef, type ReactNode, type FC } from "react";
import CSSTransition, {
  CSSTransitionProps,
} from "react-transition-group/CSSTransition";

type TransitionProps = CSSTransitionProps & {
  // 需要在css中定义in/out过渡动画
  animation?: string;
  children?: ReactNode;
};

const Transition: FC<TransitionProps> = (props) => {
  const { children, classNames, animation, ...rest } = props;
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      classNames={classNames ? classNames : animation}
      {...rest}
    >
      <div ref={nodeRef}>{children}</div>
    </CSSTransition>
  );
};

Transition.defaultProps = {
  unmountOnExit: true,
  appear: true,
};

export default Transition;
