import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Progress, Upload } from "antd";
import { useState, useEffect } from "react";
import {
  FileAcceptList,
  uploadManually,
} from "../../../api/NewUpLoadController";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";

interface UploaderProps {
  vid?: string;
  onChange?: (data: string) => {};
}

type StatusType = "idle" | "loading" | "error" | "done";

const VideoUploader = (prop: UploaderProps) => {
  const { t } = useLocale(i18n);
  const { vid, onChange } = prop;
  const [status, setStatus] = useState<StatusType>("idle");
  const [progress, setProgress] = useState<number>(0);
  const [videoId, setVideoId] = useState<string>();

  const manuallyUpload = (options: any) => {
    setStatus("loading");

    const data = new FormData();
    data.append("file", options.file);
    data.append("keyName", "labFile");

    uploadManually(data, (e) =>
      setProgress(Math.floor((e.loaded / e.total) * 100))
    ).then(({ data }) => {
      if (data.code) {
        setVideoId(data.data);
        setStatus("done");
        onChange?.(data.data);
        message.success(t("experiment.item.effective"));
      } else {
        setStatus("error");
      }
    });
  };

  useEffect(() => {
    // 实验地址字段不包含"/"即认为是视频id
    if (vid && !vid.includes("/")) {
      setStatus("done");
      setVideoId(vid);
    }
  }, [vid]);

  const dragStatus = {
    idle: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "cloud-arrow-up"]}
            size="3x"
            className="text-gray-300"
          />
        </p>
        <p className="text-gray-500">{t("experiment.item.uploadvideo")}</p>
      </>
    ),

    loading: (
      <Progress
        strokeColor={{
          "0%": "#f492f0",
          "100%": "#a18dce",
        }}
        percent={progress}
        status="active"
        type="circle"
      />
    ),

    error: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "circle-xmark"]}
            size="3x"
            className="text-red-500"
          />
        </p>
        <p className="ant-upload-text">{t("experiment.item.uploadfail")}</p>
      </>
    ),

    done: (
      <div className="w-full max-h-full">
        {t("experiment.item.uploadcompleted")}
      </div>
    ),
  } as const;

  return (
    <>
      <div className="w-35 h-35 bg-gray-100 overflow-hidden">
        <Upload.Dragger
          maxCount={1}
          showUploadList={false}
          accept={FileAcceptList.video.join()}
          // beforeUpload={beforeImgUpload}
          customRequest={manuallyUpload}
          className="uploader"
        >
          {dragStatus[status]}
        </Upload.Dragger>
      </div>
      {status === "done" ? <span>Remote: {videoId}</span> : null}
    </>
  );
};

export default VideoUploader;
