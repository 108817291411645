import req from "../configs/axios";

// 根据scoreId导出实验报告
const outputlabreporthtml = "auth/NewScoreController/outputlabreporthtml";

// 导出汇总成绩
const queryScoreByLabIDWithMaxScoreExcel =
  "auth/NewScoreController/queryScoreByLabIDWithMaxScoreExcel";

const listMaxByParamsExcel = "LabSettingController/listMaxByParamsExcel";

//根据实验id和登陆人id查询我的成绩
const queryMyScoreByLabIDAndUserID = (data?: any) => {
  return req.post("auth/NewScoreController/queryMyScoreByLabIDAndUserID", data);
};

// 查询实验key
const setUserAndLabkey = (data?: any) => {
  return req.post("auth/NewScoreController/setUserAndLabkey", data);
};

// 查询每个实验，每个学生的最优成绩
const queryScoreByLabIDWithMaxScore = (data?: any) => {
  return req.post(
    "auth/NewScoreController/queryScoreByLabIDWithMaxScore",
    data
  );
};

// 查询实验报告
const queryLabLogAndLabreportByScoreId = (data?: any) => {
  return req.post(
    "auth/NewScoreController/queryLabLogAndLabreportByScoreId",
    data
  );
};

// 评价实验报告
const addReportScore = (data?: any) => {
  return req.post("auth/NewScoreController/addReportScore", data);
};

// 根据实验id，查询最近一次成绩
const queryLabScoreByLabId = (data?: any) => {
  return req.post("auth/NewScoreController/queryLabScoreByLabId", data);
};

// 编辑实验报告
const addOrUpdateLabReport = (data?: any) => {
  return req.post("auth/NewScoreController/addOrUpdateLabReport", data);
};

// 根据用户id和实验id查询成绩列表
const queryScoreByLabIDAndUserID = (data?: any) => {
  return req.post("auth/NewScoreController/queryScoreByLabIDAndUserID", data);
};

// 查询学生实验完成情况
const showTLabStatusCount = (data?: any) => {
  return req.post("auth/NewScoreController/showTLabStatusCount", data);
};

export {
  outputlabreporthtml,
  queryScoreByLabIDWithMaxScoreExcel,
  listMaxByParamsExcel,
  queryMyScoreByLabIDAndUserID,
  setUserAndLabkey,
  queryScoreByLabIDWithMaxScore,
  queryLabLogAndLabreportByScoreId,
  addReportScore,
  queryLabScoreByLabId,
  addOrUpdateLabReport,
  queryScoreByLabIDAndUserID,
  showTLabStatusCount,
};
