const i18n = {
  "en-US": {
    "library.item.stem": "question stem",
    "library.item.searchstem": "Search question stem",
    "library.item.type": "type",
    "library.item.searchtype": "Search question type",
    "library.item.tag": "tag",
    "library.item.selecttag": "Select item tag",
    "library.item.clear": "clear",
    "library.item.query": "query",
    "library.item.addquestions": "Add questions",
    "library.item.import": "Import test questions",
    "library.item.tagmanagement": "Tag management",
    "library.item.index": "index",
    "library.item.texttype": "test question type",
    "library.item.score": "score",
    "library.item.accuracy": "accuracy rate",
    "library.item.errorrate": "error rate",
    "library.item.difficulty": "degree of Difficulty",
    "library.item.operation": "operation",
    "library.item.modify": "modify",
    "library.item.suredel":
      "Are you sure you want to delete this test question?",
    "library.item.notrestored": "Deleted test questions cannot be restored",
    "library.item.delsuccess": "Delete succeeded",
    "library.item.delete": "delete",
    "library.item.batchimport": "Batch import test questions",
    "library.item.firststep": "first step",
    "library.item.download": "download",
    "library.item.secondstep": "second step",
    "library.item.thirdstep": "third step",
    "library.item.templatefile": "test question list Excel template file",
    "library.item.fallin":
      "Fill in the test question information according to the Excel template format.",
    "library.item.worksheet": "Upload the Excel worksheet below.",
    "library.item.ignored":
      "The system will only read the data of the default sheet. If multiple sheets are added, they will be ignored.",
    "library.item.drag": "Click or drag the file to this area",
    "library.item.wait": "Uploading, please wait",
    "library.item.submitsheet": "Submit data sheet",
    "library.item.modifysuccess": "Successfully modified the test question",
    "library.item.addsuccess": "Added test question successfully",
    "library.item.correctanswer": "correct answer",
    "library.item.correct": "correct",
    "library.item.error": "error",
    "library.item.reference": "reference Answer",
    "library.item.enterreference": "Enter the reference answer",
    "library.item.editqu": "Edit the questions",
    "library.item.addqu": "Add questions",
    "library.item.selecttype": "Select item type",
    "library.item.inputstem": "Input stem",
    "library.item.uploadimg": "Upload image",
    "library.item.option": "option",
    "library.item.enteroption": "Please enter the options",
    "library.item.inputoption": "Input options",
    "library.item.uploadoption": "Upload options chart",
    "library.item.suredeloption":
      "Are you sure you want to delete this option?",
    "library.item.addoption": "Add Options",
    "library.item.tip": "Tip reference",
    "library.item.inputtip": "Input tip reference",
    "library.item.describe": "describe",
    "library.item.enterdescribe": "Input description",
    "library.item.analysis": "analysis",
    "library.item.inputanalysis": "Input parsing content",
    "library.item.save": "save",
    "library.item.delnode": "Deleted node",
    "library.item.addnode": "Add Node Succeeded",
    "library.item.editnode": "Edit Node Succeeded",
    "library.item.refresh": "refresh",
    "library.item.creattag": "Creating tags",
    "library.item.creatchildtag": "Creating child tags",
    "library.item.searchtag": "Search tag",
    "library.item.notempty": "The tag name cannot be empty",
    "library.item.revoke": "revoke",
    "library.item.edit": "edit",
    "library.item.suredelnode":
      "This will delete the current node and all child nodes. Are you sure?",
  },
  "zh-CN": {
    "library.item.stem": "题干",
    "library.item.searchstem": "搜索题干",
    "library.item.type": "类型",
    "library.item.searchtype": "搜索试题类型",
    "library.item.tag": "标签",
    "library.item.selecttag": "选择试题标签",
    "library.item.clear": "清除",
    "library.item.query": "查询",
    "library.item.addquestions": "添加试题",
    "library.item.import": "导入试题",
    "library.item.tagmanagement": "标签管理",
    "library.item.index": "序号",
    "library.item.texttype": "试题类型",
    "library.item.score": "分值",
    "library.item.accuracy": "正确率",
    "library.item.errorrate": "错误率",
    "library.item.difficulty": "难度系数",
    "library.item.operation": "操作",
    "library.item.modify": "修改",
    "library.item.suredel": "确定要删除此试题吗？",
    "library.item.notrestored": "删除的试题无法恢复",
    "library.item.delsuccess": "删除成功",
    "library.item.delete": "删除",
    "library.item.batchimport": "批量导入试题",
    "library.item.firststep": "第一步",
    "library.item.download": "下载",
    "library.item.secondstep": "第二步",
    "library.item.thirdstep": "第三步",
    "library.item.templatefile": "试题列表Excel模板文件",
    "library.item.fallin": "按照Excel模板格式填写试题信息 。",
    "library.item.worksheet": "在下方上传Excel工作表。",
    "library.item.ignored":
      "系统只会读取默认sheet的数据，若添加多个sheet将会忽略。",
    "library.item.drag": "点击或拖拽文件到此区域",
    "library.item.wait": "上传中，请稍候",
    "library.item.submitsheet": "提交数据表",
    "library.item.modifysuccess": "修改试题成功",
    "library.item.addsuccess": "添加试题成功",
    "library.item.correctanswer": "正确答案",
    "library.item.correct": "正确",
    "library.item.error": "错误",
    "library.item.reference": "参考答案",
    "library.item.enterreference": "输入参考答案",
    "library.item.editqu": "编辑试题",
    "library.item.addqu": "添加试题",
    "library.item.selecttype": "选择试题类型",
    "library.item.inputstem": "输入题干",
    "library.item.uploadimg": "上传题图",
    "library.item.option": "选项",
    "library.item.enteroption": "请输入选项内容",
    "library.item.inputoption": "输入选项",
    "library.item.uploadoption": "上传选项图",
    "library.item.suredeloption": "确定要删除此选项吗?",
    "library.item.addoption": "添加选项",
    "library.item.tip": "提示参考",
    "library.item.inputtip": "输入提示参考",
    "library.item.describe": "描述",
    "library.item.enterdescribe": "输入描述",
    "library.item.analysis": "解析",
    "library.item.inputanalysis": "输入解析内容",
    "library.item.save": "保存",
    "library.item.delnode": "已删除节点",
    "library.item.addnode": "添加节点成功",
    "library.item.editnode": "编辑节点成功",
    "library.item.refresh": "刷新",
    "library.item.creattag": "创建标签",
    "library.item.creatchildtag": "创建子标签",
    "library.item.searchtag": "搜索标签",
    "library.item.notempty": "标签名称不能为空",
    "library.item.revoke": "撤销",
    "library.item.edit": "编辑",
    "library.item.suredelnode": "这将删除当前节点以及所有子节点，确定吗?",
  },
};

export default i18n;
