import req from "../configs/axios";

// 查询系统资源，tImagePtType:
// 3 - 学校logo
// 4 - banner图
// 5 - 推荐浏览器
// 6 - 引导视频
// 7 - 简介视频
const findAllPath = (data?: any) => {
  return req.post("ImagePtController/findAllPath", data);
};

// 更新系统资源
const updAllPath = (data?: any) => {
  return req.post("auth/ImagePtController/updAllPath", data);
};

export { findAllPath, updAllPath };
