const i18n = {
  "en-US": {
    "declare.item.details": "Details",
    "declare.item.communication": "Communication Platform",
    "declare.item.questions": "Q&A",
    "declare.item.browser":
      "Google chrome browser is recommended. Click here to download.",
    "declare.actions.introvideo": "Intro Video",
    "declare.actions.guidevideo": "Guide Video",
    "declare.actions.start": "Start the Experiment",
    "declare.actions.play": "Play the Video",
    "declare.actions.report": "Write an Experimental Report",
    "declare.actions.results": "Experiment Results",
    "declare.actions.practice": "Exercise",
    "declare.actions.instruction": "Experiment Instruction",
    "declare.communication.query": "Query",
    "declare.communication.title": "Title",
    "declare.communication.title.placeholder": "Please enter the forum title",
    "declare.communication.newPost": "Release new post",
    "declare.communication.text": "Text",
    "declare.communication.release": "Release",
    "declare.communication.posttitle": "Post title:",
    "declare.communication.reply": "Reply",
    "declare.communication.entertitle": "Enter the title",
    "declare.communication.enterTiezititle": "Please enter a post title",
    "declare.item.footer": "Technical Support",
    "declare.item.postsuccess": "Post published successfully!",
    "declare.item.check": "Please check for invalid verification",
    "declare.item.submitsuccess": "Submit Success!",
    "declare.item.replysuccess": "Reply Success!",
    "declare.item.landlord": "Landlord",
    "declare.item.floor": "Floor",
    "declare.item.answers": "Frequently asked questions and answers",
    "intro.title": "Use Guide",
    "intro.des":
      "Welcome to use virtual simulation experiment platform for the first time, to accept use the guide?",
    "intro.btn.ok": "Ok",
    "intro.btn.cancel": "Forget it",
    "intro.1.title": "Language",
    "intro.1.des": "Switch interface language from here",
    "intro.2.title": "Profile",
    "intro.2.des":
      "You can change your personal information, password or log out of the system by calling up a menu from your avatar. ",
    "intro.3.title": "Details of classification",
    "intro.3.des":
      "This tab shows the experiments that are currently available to you",
    "intro.4.title": "Online Question",
    "intro.4.des": "This page shows the common Q&A",
    "declare.item.question": "My questions",
    "declare.item.support": "Seek online technical support",
    "declare.item.description": "Problem Description",
    "declare.item.qatime": "Question Time",
    "declare.item.state": "State",
    "declare.item.schedule": "Schedule",
    "declare.item.Operation": "Operation",
    "declare.item.urgency": "Urgency",
    "declare.item.ordinary": "Ordinary",
    "declare.item.resolved": "Resolved",
    "declare.item.processing": "Processing",
    "declare.item.waitReply": "Forward to your reply",
    "declare.item.degree": "Urgency",
    "declare.item.closeqa": "Closed questions",
    "declare.item.submit": "Submit",
    "declare.item.remind": "Question reply reminder",
    "declare.item.unread": "Unread",
    "declare.item.technical": "Technical Support",
    "declare.item.prodes": "Please enter a problem description",
    "declare.item.particulars": "particulars",
    "declare.item.briefly": "Please summarize the problem briefly",
    "declare.item.problem": "Problem",
    "declare.item.copyMsg": "Copy successful",
    "declare.item.closeModelQuestion":
      "Are you sure you want to close this question?",
    "declare.item.closeModelTitle":
      "After closing the question, you will no longer be able to get a reply",
    "declare.item.closeMsg": "Closed successfully",
    "declare.item.closeErrorMsg": "Operation failed, please try later!",
    "declare.item.personalDetails": "Personal details",
    "declare.item.questionNumber": "Number",
    "declare.item.questioner": "Questioner",
    "declare.item.questionDetails": "Details",
    "declare.item.questionTime": "Question time",
    "declare.results.endingTime": "Ending Time",
  },
  "zh-CN": {
    "declare.item.details": "分类详情",
    "declare.item.communication": "论坛交流",
    "declare.item.questions": "在线提问",
    "declare.item.browser": "推荐使用谷歌浏览器，点击此处下载",
    "declare.actions.introvideo": "申报视频",
    "declare.actions.guidevideo": "引导视频",
    "declare.actions.start": "开始实验",
    "declare.actions.play": "观看视频",
    "declare.actions.report": "填写报告",
    "declare.actions.results": "实验结果",
    "declare.actions.practice": "习题考核",
    "declare.actions.instruction": "实验须知",
    "declare.communication.query": "查询",
    "declare.communication.title": "标题",
    "declare.communication.title.placeholder": "输入论坛标题",
    "declare.communication.newPost": "发布新贴",
    "declare.communication.text": "正文",
    "declare.communication.release": "发布",
    "declare.communication.posttitle": "帖子标题：",
    "declare.communication.reply": "回复",
    "declare.communication.entertitle": "输入标题",
    "declare.communication.enterTiezititle": "请输入帖子标题",
    "declare.item.footer": "技术支持",
    "declare.item.postsuccess": "帖子发布成功！",
    "declare.item.check": "请检查不合法的校验项",
    "declare.item.submitsuccess": "提交成功！",
    "declare.item.replysuccess": "回复成功！",
    "declare.item.landlord": "楼主",
    "declare.item.floor": "楼",
    "declare.item.answers": "常见问题与解答",
    "intro.title": "新手使用引导",
    "intro.des": "欢迎首次使用虚拟仿真实验平台，要接受使用引导吗？",
    "intro.btn.ok": "好的",
    "intro.btn.cancel": "算了",
    "intro.1.title": "UI语言",
    "intro.1.des": "从这里切换界面语言",
    "intro.2.title": "个人面板",
    "intro.2.des": "从您的头像唤出菜单，可以修改个人信息、密码或登出系统。",
    "intro.3.title": "分类详情",
    "intro.3.des": "此页签展示您当前可用的实验",
    "intro.4.title": "在线提问",
    "intro.4.des": "此页签展示常见问题答疑",
    "declare.item.question": "我的提问",
    "declare.item.support": "寻求在线技术支持",
    "declare.item.description": "问题描述",
    "declare.item.qatime": "提问时间",
    "declare.item.state": "状态",
    "declare.item.schedule": "进度",
    "declare.item.Operation": "操作",
    "declare.item.urgency": "紧急",
    "declare.item.ordinary": "普通",
    "declare.item.resolved": "已解决",
    "declare.item.processing": "处理中",
    "declare.item.waitReply": "待您回复",
    "declare.item.degree": "紧急程度",
    "declare.item.closeqa": "关闭提问",
    "declare.item.submit": "提交",
    "declare.item.remind": "问题回复提醒",
    "declare.item.unread": "未读",
    "declare.item.technical": "技术支持",
    "declare.item.prodes": "请输入问题描述!",
    "declare.item.particulars": "详情",
    "declare.item.briefly": "请简要概括问题",
    "declare.item.problem": "问题",
    "declare.item.copyMsg": "复制成功",
    "declare.item.closeModelQuestion": "确定要关闭此提问吗？",
    "declare.item.closeModelTitle": "关闭提问后将不能再获得回复",
    "declare.item.closeMsg": "关闭成功",
    "declare.item.closeErrorMsg": "操作失败请稍后尝试！",
    "declare.item.personalDetails": "个人详情",
    "declare.item.questionNumber": "编号",
    "declare.item.questioner": "提问者",
    "declare.item.questionDetails": "详情",
    "declare.item.questionTime": "提问时间",
    "declare.results.endingTime": "结束时间",
  },
};

export default i18n;
