import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tabs,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import { useLocale } from "../../../hooks/useLocale";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import RichEditor, { RichDisplay } from "../../components/RichEditor";
import QuestionList from "./questionList";
import MailList from "./mailList";
import {
  listAllByParams,
  addOne,
  updateOne,
  deleteOneById,
  getOneById,
} from "../../../api/NewQuestionAnswerCommonController";
import { cleanObject, EscapeReverseHTML } from "../../../utils/format";
import { IDomEditor } from "@wangeditor/editor";
import i18n from "./i18n";

const { confirm } = Modal;

const formRoles = {
  forumName: [{ required: true, message: "请输入问题描述！" }],
};

const QAManagePage = () => {
  const { t } = useLocale(i18n);
  const [queryForm] = useForm();
  const [editform] = Form.useForm();
  const [form] = Form.useForm();
  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [formloading, setFormloading] = useState(false);
  const [editor, setEditor] = useState<IDomEditor | null>(null); // 存储 editor 实例
  const [rich, setRich] = useState<string>();
  const [rowId, setRowId] = useState<string>();
  const [visible, setVisible] = useState(false);
  const [changeVisible, setChangeVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [quAnswerList, setQuAnswerList] = useState<any[]>([]);
  const [dayiDetail, setDayiDetail] = useState<any>();
  const [qacTitle, setQacTitle] = useState<string>();
  const [chlang, setChlang] = useState<string>();
  const [selectValue, setSelectValue] = useState<any>();
  const [createTime, setCreateTime] = useState<string>();
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  const QuAnswerList = useCallback(async () => {
    const { data } = await listAllByParams({
      qacTitle: search,
      lang: selectValue,
    });
    if (data.code) {
      setQuAnswerList(data.data.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation, search]);

  useEffect(() => {
    QuAnswerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation]);

  const onSearch = () => {
    setTablePagenation({ page: 1, rows: 10 });
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setChangeVisible(false);
    setDetailVisible(false);
    form.resetFields();
  };

  const showDetail = async (id: any) => {
    setDetailVisible(true);
    const { data } = await getOneById({ id: id });
    if (data.code) {
      setDayiDetail(data.data.qacContent);
      setQacTitle(data.data.qacTitle);
      setCreateTime(data.data.createTime);
    }
  };

  //修改答疑
  const commitEditform = () => {
    editform
      .validateFields()
      .then(async (d) => {
        const { data } = await updateOne({
          ...d,
          id: rowId,
          qacTitle: d.qacTitle,
          qacContent: d.qacContent,
        });
        setChangeVisible(false);
        console.log(d);
        if (data.code) {
          message.success(t("declare.item.infupdate"));
          QuAnswerList();
        } else {
          message.error(t("declare.item.latertry"));
        }
      })
      .catch((err) => {});
  };
  //新增答疑
  const onCommit = () => {
    form
      .validateFields()
      .then(async (d) => {
        const formdata = cleanObject({
          ...d,
        });
        console.log(formdata);
        setFormloading(true);
        const { data } = await addOne(formdata);
        setVisible(false);
        if (data.code) {
          QuAnswerList();
          message.success(t("declare.item.addsuccess"));
          setFormloading(false);
          form.resetFields();
        } else {
          setFormloading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(t("declare.item.checkinvalid"));
      });
  };

  const [value, setValue] = useState<string>();

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="QA-main">
      <CustomBreadcrumb breads={[t("menu.system"), t("menu.system.qa")]} />
      <div className="QA-main-content bg-white p-4 rounded mb-4">
        <Tabs defaultActiveKey="1" size="large">
          <Tabs.TabPane key="1" tab={t("declare.item.FAQs")}>
            <div className="query-form bg-white  rounded mb-4 float-right">
              <Form
                layout="inline"
                form={queryForm}
                // initialValues={{ lang: "请选择..." }}
              >
                <Form.Item label={t("declare.item.language")} name="lang">
                  <Select
                    placeholder={t("declare.item.chooselanguage")}
                    allowClear
                    style={{ width: "100px" }}
                    onChange={(value: { value: string }) => {
                      setSelectValue(value);
                      if (!value) {
                        setTablePagenation({ page: 1, rows: 10 });
                      }
                    }}
                  >
                    <Select.Option value="zh-CN">
                      {t("declare.item.Chinese")}
                    </Select.Option>
                    <Select.Option value="en-US">English</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={t("declare.item.description")} name="search">
                  <Input
                    placeholder={t("declare.item.inpdescription")}
                    allowClear
                    style={{ width: "230px" }}
                    onPressEnter={onSearch}
                    onChange={({ target: { value } }) => {
                      setSearch(value);
                      if (!value) {
                        setTablePagenation({ page: 1, rows: 10 });
                      }
                    }}
                  />
                </Form.Item>
                <Space>
                  <Button onClick={onSearch} htmlType="submit" type="primary">
                    {t("declare.item.query")}
                  </Button>
                </Space>
              </Form>
            </div>
            <div className="bg-white  rounded">
              <Space className="mb-4 flex items-center justify-between">
                <Space>
                  <Button type="primary" onClick={showDrawer}>
                    {t("declare.item.add")}
                  </Button>
                </Space>
              </Space>
              <Table
                size="middle"
                rowKey="id"
                bordered
                dataSource={quAnswerList}
                loading={loading}
                pagination={{
                  size: "default",
                  showQuickJumper: true,
                  showSizeChanger: true,
                  position: ["bottomCenter"],
                  current: tablePagenation.page,
                  pageSize: tablePagenation.rows,
                  total,
                  showTotal: (total) =>
                    `${t("table.pagination.all")}${total}${t(
                      "table.pagination.total"
                    )}`,
                  onChange: (page, rows) => {
                    setTablePagenation({
                      page,
                      rows,
                    });
                  },
                }}
                columns={[
                  {
                    title: t("table.index"),
                    width: 50,
                    fixed: "left",
                    align: "center",
                    render: (val, row, index) => index + 1,
                  },
                  {
                    title: t("declare.item.description"),
                    dataIndex: "qacTitle",
                    width: 150,
                    render(text, row) {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          onClick={() => {
                            showDetail(row.id);
                          }}
                        >
                          {text}
                        </a>
                      );
                    },
                  },
                  {
                    title: t("declare.item.language"),
                    dataIndex: "lang",
                    width: 150,
                    render(val) {
                      if (val === "zh-CN") {
                        return t("declare.item.Chinese");
                      } else if (val === "en-US") {
                        return "English";
                      }
                    },
                  },
                  {
                    title: t("declare.item.Qtime"),
                    dataIndex: "createTime",
                    width: 200,
                  },
                  {
                    title: t("declare.item.operation"),
                    width: 200,
                    align: "center",
                    render(value, row: any) {
                      return (
                        <Space>
                          <Button
                            size="small"
                            onClick={() => {
                              editform.setFieldsValue({
                                qacTitle: row.qacTitle,
                                qacContent: row.qacContent,
                                lang: row.lang,
                              });
                              setRowId(row.id);
                              setChangeVisible(true);
                              setChlang(row.lang);
                            }}
                          >
                            {t("declare.item.modify")}
                          </Button>
                          <Button
                            size="small"
                            danger
                            onClick={() => {
                              confirm({
                                title: t("declare.item.suredel"),
                                icon: <ExclamationCircleOutlined />,
                                content: t("declare.item.restored"),
                                centered: true,
                                async onOk() {
                                  const { data } = await deleteOneById({
                                    id: row.id,
                                  });
                                  if (data.code) {
                                    QuAnswerList();

                                    message.success(
                                      t("declare.item.successdel")
                                    );
                                  } else {
                                    message.error(t("declare.item.latertry"));
                                  }
                                },
                                onCancel() {},
                              });
                            }}
                          >
                            {t("declare.item.delete")}
                          </Button>
                        </Space>
                      );
                    },
                  },
                ]}
              ></Table>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={t("declare.item.Qlist")}>
            <QuestionList></QuestionList>
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={t("declare.item.Receiver")}>
            <MailList></MailList>
          </Tabs.TabPane>
        </Tabs>
      </div>
      <Drawer
        title={t("declare.item.addQA")}
        width="60%"
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <Form
            labelCol={{ span: 2 }}
            form={form}
            initialValues={{ lang: "zh-CN" }}
          >
            <Form.Item label={t("declare.item.language")} name="lang">
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={"zh-CN"}>{t("declare.item.Chinese")}</Radio>
                <Radio value={"en-US"}>English</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("declare.item.question")}
              name="qacTitle"
              rules={formRoles.forumName}
            >
              <Input
                placeholder={t("declare.item.inpdescription")}
                maxLength={50}
                showCount
              />
            </Form.Item>
            <Form.Item label={t("declare.item.particulars")} name="qacContent">
              <RichEditor maxLength={500} value={rich} onChange={setRich} />
            </Form.Item>
          </Form>
          <Row>
            <Col span="6" offset="9">
              <Button
                size="large"
                block
                type="primary"
                className=""
                loading={formloading}
                onClick={onCommit}
              >
                {t("declare.item.submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
      <Drawer
        title={t("declare.item.modifyQA")}
        width="60%"
        placement="right"
        onClose={onClose}
        open={changeVisible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <Form
            labelCol={{ span: 2 }}
            form={editform}
            initialValues={{ type: "1" }}
          >
            <Form.Item label={t("declare.item.language")} name="lang">
              <span className="ant-form-text">
                {chlang === "en-US" ? "English" : t("declare.item.Chinese")}
              </span>
            </Form.Item>
            <Form.Item
              label={t("declare.item.question")}
              name="qacTitle"
              rules={formRoles.forumName}
            >
              <Input
                placeholder={t("declare.item.inpdescription")}
                maxLength={50}
                showCount
              />
            </Form.Item>
            <Form.Item label={t("declare.item.particulars")} name="qacContent">
              <RichEditor maxLength={500} value={rich} onChange={setRich} />
            </Form.Item>
          </Form>
          <Row>
            <Col span="6" offset="9">
              <Button
                size="large"
                block
                type="primary"
                className=""
                loading={formloading}
                onClick={commitEditform}
              >
                {t("declare.item.submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
      {/* 答疑详情 */}
      <Drawer
        title={
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <div>
              {t("declare.item.description")}： {qacTitle}{" "}
            </div>
            <div className="text-sm">
              {t("declare.item.Qtime")}：{createTime}
            </div>
          </div>
        }
        width="60%"
        placement="right"
        onClose={onClose}
        open={detailVisible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <RichDisplay html={dayiDetail} style={{ textIndent: "2em" }} />
        </div>
      </Drawer>
    </div>
  );
};

export default QAManagePage;
