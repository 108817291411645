import { ClearOutlined } from "@ant-design/icons";
import { Space, Button, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  addLabReportFormworkAll,
  findLabReportFormworkAll,
} from "../../../api/NewLabReportFormworkController";
import RichEditor from "../../components/RichEditor";
import { EscapeReverseHTML } from "../../../utils/format";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";

const ReportTemplate = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [content, setContent] = useState<string>();

  const getTemp = useCallback(() => {
    findLabReportFormworkAll({ labId }).then(({ data }) => {
      if (data.code) {
        setContent(EscapeReverseHTML(data?.data?.lablr));
      }
    });
  }, [labId]);

  const commit = () => {
    addLabReportFormworkAll({ labId, lablr: content }).then(({ data }) => {
      if (data.code) {
        message.success(t("experiment.item.savesuccess"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  useEffect(() => {
    getTemp();
  }, [getTemp]);

  return (
    <div>
      <div className="text-right mb-6">
        <Space>
          <Button
            icon={<ClearOutlined />}
            onClick={() => setContent("<p></p>")}
          >
            {t("experiment.item.clear")}
          </Button>
          <Button type="primary" onClick={commit}>
            {t("experiment.item.savetem")}
          </Button>
        </Space>
      </div>
      <RichEditor value={content} onChange={setContent} />
    </div>
  );
};

export default ReportTemplate;
