import ReactDOM from "react-dom/client";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

console.log(
  `${"\n"} %c ${BRANCH} v${VERSION} ${GIT_SHA} ${"\n"}`,
  "color: #eaff8f; background: #000; padding:5px 0;"
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
