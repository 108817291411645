import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const MistakeRecordsPage = () => {
  return (
    <div className="page-main">
      <CustomBreadcrumb breads={["考试", "错题本"]} />
      错题本
    </div>
  );
};

export default MistakeRecordsPage;
