const i18n = {
  "en-US": {
    "play.item.invalid": "Invalid parameter",
    "play.item.endvideo": "End of video play",
    "play.item.correct": "correct",
    "play.item.error": "error",
    "play.item.notsupport": "Type of question not supported",
    "play.item.answer": "Please answer",
    "play.item.correctly": "answer correctly",
    "play.item.wrong": "answer error",
    "play.item.answercorrect": "correct answer:",
    "play.item.analysis": "analysis:",
    "play.item.continuelearn": "Continue learning",
    "play.item.submitanswer": "Submit answers",
    "play.item.noanswer": "No answer",
    "play.item.statistics": "Answer statistics",
    "play.item.noquestion": "There are no questions to answer",
    "play.item.exit": "Exit assessment",
  },
  "zh-CN": {
    "play.item.invalid": "参数无效",
    "play.item.endvideo": "视频播放结束",
    "play.item.correct": "正确",
    "play.item.error": "错误",
    "play.item.notsupport": "不支持作答的试题类型",
    "play.item.answer": "请作答",
    "play.item.correctly": "回答正确",
    "play.item.wrong": "回答错误",
    "play.item.answercorrect": "正确答案：",
    "play.item.analysis": "解析：",
    "play.item.continuelearn": "继续学习",
    "play.item.submitanswer": "提交答案",
    "play.item.noanswer": "未作答",
    "play.item.statistics": "答题情况统计",
    "play.item.noquestion": "无待作答试题",
    "play.item.exit": "退出考核",
  },
};

export default i18n;
