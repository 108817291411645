import "@wangeditor/editor/dist/css/style.css";
import { message } from "antd";
import { useState, useEffect } from "react";
import { Boot, i18nChangeLanguage, SlateTransforms } from "@wangeditor/editor";
import attachmentModule from "@wangeditor/plugin-upload-attachment";
import { Editor as WEditor, Toolbar } from "@wangeditor/editor-for-react";
import { uploadManually } from "../../../api/NewUpLoadController";
import { useLocale } from "../../../hooks/useLocale";
import type {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
} from "@wangeditor/editor";
import type { AttachmentElement } from "@wangeditor/plugin-upload-attachment";
import RichDisplay from "./RichDisplay";

interface RichEditorProps {
  value: string; // html string
  placeholder: string;
  maxLength: number;
  keyName: string;
  onChange: (value: string) => void;
}

const i18n = {
  "en-US": {
    success: "Upload successfully",
  },
  "zh-CN": {
    success: "上传成功",
  },
};

Boot.registerModule(attachmentModule);

const RichEditor = (props: Partial<RichEditorProps>) => {
  const { value, placeholder, keyName = "newsFile" } = props;
  const [editor, setEditor] = useState<IDomEditor | null>(null);
  // 这里如果是非html串会报错，需要进一步排查和优化
  const [html, setHtml] = useState<string | undefined>();
  const { lang, t } = useLocale(i18n);

  const customUpload = (
    file: File,
    insert: (url: string, alt?: string, href?: string) => void
  ) => {
    const data = new FormData();
    data.append("file", file);
    data.append("keyName", keyName);

    uploadManually(data, (event) => {
      // 上传进度
    }).then(({ data }) => {
      if (data.code) {
        insert(data.data);
        message.success(t("success"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
    // insert(URL.createObjectURL(file));
  };

  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = {
    // 附件工具
    insertKeys: {
      index: 0,
      keys: ["uploadAttachment"],
    },
  };

  // 编辑器配置
  const editorConfig: Partial<IEditorConfig> = {
    placeholder: placeholder ?? t("global.editor.placeholder"),
    maxLength: props?.maxLength,
    hoverbarKeys: {
      attachment: {
        menuKeys: ["downloadAttachment"], // “下载附件”菜单
      },
    },
    MENU_CONF: {
      // 图片
      uploadImage: { customUpload },

      // 视频
      uploadVideo: { customUpload },

      // 其他文件类型需要以附件形式上传
      uploadAttachment: {
        customUpload: (
          file: File,
          insert: (displayName: string, link: string) => void
        ) => {
          const data = new FormData();
          data.append("file", file);
          data.append("keyName", keyName);

          uploadManually(data).then(({ data }) => {
            if (data.code) {
              insert(file.name, data.data);
              message.success(t("success"));
              return;
            }
            data?.msg && message.error(data.msg);
          });
        },

        onInsertedAttachment(elem: AttachmentElement) {
          console.log("inserted attachment", elem);
          // SlateTransforms.insertNodes(
          //   editor!,
          //   {
          //     text: "A new string of text.",
          //   },
          //   {
          //     at: [0, 1],
          //   }
          // );
        },
      },
    },
  };

  // 组件卸载时销毁editor实例
  useEffect(() => {
    i18nChangeLanguage(lang);

    return () => {
      if (editor === null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor, lang]);

  useEffect(() => {
    setHtml(value);
  }, [value]);

  return (
    <div className="rounded-sm" style={{ border: "1px solid #d9d9d9" }}>
      <Toolbar
        editor={editor}
        defaultConfig={toolbarConfig}
        mode="default"
        style={{ borderBottom: "1px solid #d9d9d9" }}
      />
      <WEditor
        mode="default"
        style={{ height: 400 }}
        value={html}
        defaultConfig={editorConfig}
        onCreated={setEditor}
        onChange={(editor) => props.onChange?.(editor.getHtml())}
      />
    </div>
  );
};

export { RichDisplay };
export default RichEditor;
