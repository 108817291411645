import { Button, Form, List, Avatar, message, Row, Col, Empty } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  getOneQaById,
  addOneCommentFirst,
} from "../../../api/NewUserController";
import RichEditor, { RichDisplay } from "../../components/RichEditor";
import defaultAvatar from "../../../assets/images/avatar-default.png";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";

interface xDetailIdProps {
  quId: any;
  showQuestion?: (id: any) => void;
}

const ReplyList = (prop: xDetailIdProps) => {
  const { t } = useLocale(i18n);
  const { quId } = prop;
  const [form] = Form.useForm();
  const [rich, setRich] = useState<string>();
  const [isValid, setIsValid] = useState<number>();
  const [commitloading, setCommitLoading] = useState(false);
  const [oneQaList, setOneQaList] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const replylist = useCallback(async () => {
    const { data } = await getOneQaById({ id: quId });
    if (data.code) {
      setOneQaList(data.data.commentList);
      setTotal(data.data.commentList.length);
      setIsValid(data.data.isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation, quId]);

  useEffect(() => {
    replylist();
  }, [replylist]);

  //回复
  const commitReply = async () => {
    setCommitLoading(true);
    const { data } = await addOneCommentFirst({
      articleId: quId,
      content: rich,
      beiYong1: 1,
    });
    if (data.code) {
      message.success(t("declare.item.replysuccess"));
      replylist();
      form.resetFields();
    }
    setCommitLoading(false);
  };

  return (
    <div>
      <List
        itemLayout="vertical"
        rowKey="forumId"
        className="news-list border-solid border-1 border-gray-200 p-4"
        dataSource={oneQaList}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: "bottom",
          total,
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          showTotal: (total) =>
            `${t("table.pagination.all")}${total}${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        renderItem={(item) => (
          <List.Item className="relative">
            <List.Item.Meta
              title={
                <div className="text-sm text-gray-400">
                  {item.beiYong1 === 1
                    ? t("declare.item.footer")
                    : item.userName}
                  <span className="ml-2">{item.createTime}</span>
                </div>
              }
              avatar={
                <Avatar
                  src={item.userAvatar}
                  icon={<img src={defaultAvatar} alt="avatar" />}
                />
              }
              description={
                <RichDisplay
                  className="text-base text-gray-800 break-all"
                  html={item.content}
                />
              }
            />
          </List.Item>
        )}
      />
      <div className="mt-8 reply">
        {isValid !== 1 ? (
          <>
            <Form
              labelCol={{ span: 2 }}
              form={form}
              initialValues={{ type: "1" }}
            >
              <Form.Item label="" name="qacContent">
                <RichEditor maxLength={5000} value={rich} onChange={setRich} />
              </Form.Item>
            </Form>
            <Row>
              <Col span="6" offset="9">
                <Button
                  size="large"
                  block
                  type="primary"
                  className=""
                  loading={commitloading}
                  onClick={commitReply}
                >
                  {t("declare.item.submit")}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default ReplyList;
