import { TreeSelect } from "antd";
import { type FC, useState, useEffect } from "react";
import { queryDeptTreeByDeclare } from "../../../api/NewDeptController";
import { NodeDataType } from "../DepartmentTree";
import { useLocale } from "../../../hooks/useLocale";

interface DepartmentSelectorProps {
  value?: any;
  onChange?: any;
}

const i18n = {
  "en-US": {
    organization: "Select organization",
  },
  "zh-CN": {
    organization: "选择组织",
  },
};

const DepartmentSelector: FC<DepartmentSelectorProps> = ({
  value,
  onChange,
}) => {
  const { t } = useLocale(i18n);
  const [treeData, setTreeData] = useState<NodeDataType[]>([]);

  const getDepartmentTree = () => {
    queryDeptTreeByDeclare({ level: 3 }).then(({ data }) => {
      if (data.code) {
        setTreeData(data.data);
      }
    });
  };

  useEffect(() => {
    getDepartmentTree();
  }, []);

  return (
    <TreeSelect
      allowClear
      placeholder={t("organization")}
      fieldNames={{ label: "name", value: "id" }}
      style={{ width: "260px" }}
      treeData={treeData}
      treeDefaultExpandAll
      value={value?.id}
      onSelect={(_value: any, node: NodeDataType) => {
        onChange?.(node);
      }}
    />
  );
};

export default DepartmentSelector;
