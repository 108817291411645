import { DownOutlined, PictureOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Progress,
  Radio,
  Row,
  Select,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";
import { type FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCaptcha } from "../../api/LoginController";
import { useLocale } from "../../hooks/useLocale";
import { mediumPuzzle, strongPuzzle } from "../../utils/regExp";
import type { Rule } from "antd/lib/form";
import { adduser, showYZBWTree } from "../../api/RegisterController";
import i18n from "./i18n";
import defaultLocale from "../../i18n/default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSystemInfo } from "../../hooks/useSystemInfo";
import { keyEncode } from "@/utils/format";
import { type NodeDataType } from "../components/DepartmentTree";

const { useForm } = Form;

const getDepartmentId = (node?: NodeDataType) => {
  const key = {
    1: "yid",
    2: "zid",
    3: "bid",
    4: "uid",
  };

  return node?.level ? { [key[node.level]]: node.id } : {};
};

const RegisterPage = () => {
  const { t, lang, setLang } = useLocale(i18n);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState<string>();
  const [captchaUuid, setCaptchaUuid] = useState<string>();
  const [capsLockTipShow, setCapsLockTipShow] = useState(false);
  const [capsLockTipShow2, setCapsLockTipShow2] = useState(false);
  const [form] = useForm();
  const [progress, setProgress] = useState({ percent: 0, strokeColor: "" });
  const [deptTree, setDetpTree] = useState<any[]>();
  const [zwidList, setZwidList] = useState<any[]>([]);
  const { sysInfo } = useSystemInfo();

  const formRules: { [key: string]: Rule[] } = {
    loginId: [{ required: true, message: t("login.roles.username") }],
    password: [
      {
        required: true,
        validator(_, value) {
          if (!value)
            return Promise.reject(new Error(t("login.roles.password")));
          if (value.length < 8) {
            return Promise.reject(new Error(t("login.roles.password3")));
          }
          if (mediumPuzzle.test(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject(new Error(t("login.roles.password4")));
          }
        },
      },
    ],
    password2: [
      { required: true, message: t("login.roles.password2") },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(t("login.roles.password5")));
        },
      }),
    ],
    name: [{ required: true, message: t("login.roles.name") }],
    _org: [{ required: true, message: t("login.roles.yid") }],
    captchaCode: [{ required: true, message: t("login.roles.code") }],
  };

  const getCode = async () => {
    const {
      headers: { captchauuid },
      data,
    } = await getCaptcha();
    setCaptchaUuid(captchauuid);
    setCaptcha(URL.createObjectURL(data));
  };

  const getOrganize = async () => {
    const { data } = await showYZBWTree({ level: 3 });
    if (data.code) {
      setDetpTree(data.data.tree);
      setZwidList(data.data.zwList);
    }
  };

  const onInputForm = (crt: any) => {
    const { password } = crt;
    if (password === "") setProgress({ percent: 0, strokeColor: "" });
    if (password) {
      if (strongPuzzle.test(password)) {
        setProgress({ percent: 100, strokeColor: "#52c41a" });
      } else if (mediumPuzzle.test(password)) {
        setProgress({ percent: 66, strokeColor: "#ffc903" });
      } else {
        setProgress({ percent: 33, strokeColor: "#ff0303" });
      }
    }
  };

  const register = () => {
    setLoading(true);
    form
      .validateFields()
      .then((data) => {
        const dept = getDepartmentId(data._org);
        data.password = keyEncode(data.password);
        return adduser({ captchaUuid, ...data, ...dept });
      })
      .then(({ data }) => {
        if (data.code) {
          message.success(data.msg);
          navigate("/");
        } else {
          getCode();
          data?.msg && message.error(data.msg);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const changeLanguage = (language: SupportedLangType) => {
    if (language !== lang) {
      setLang(language);
      const nextLang = defaultLocale[language];
      message.info(`${nextLang["system.msg.switchlang"]} ${language}`);
    }
  };

  useEffect(() => {
    getCode();
    getOrganize();
  }, []);

  return (
    <div
      className="bg-cover bg-no-repeat flex justify-center items-center min-h-screen"
      style={{
        backgroundImage: `url(${sysInfo?.bg})`,
      }}
    >
      <div className="w-[600px]">
        <Card
          style={{ backgroundColor: "rgba(255,255,255,0.9)" }}
          title={t("login.item.register")}
        >
          <div className="p-4">
            <Form
              size="large"
              labelCol={{ span: 4, offset: 2 }}
              wrapperCol={{ span: 15 }}
              form={form}
              onValuesChange={onInputForm}
            >
              <Form.Item
                label={t("user.attr.loginId")}
                name="loginId"
                rules={formRules.loginId}
              >
                <Input placeholder={t("login.roles.username")} />
              </Form.Item>
              <Tooltip
                open={capsLockTipShow}
                title={t("system.tips.capslock")}
                color="rgba(0,0,0, 0.7)"
              >
                <Form.Item label={t("user.attr.password")} required>
                  <Form.Item noStyle name="password" rules={formRules.password}>
                    <Input.Password
                      visibilityToggle
                      autoComplete="password"
                      maxLength={16}
                      showCount
                      placeholder={t("login.roles.password")}
                      onBlur={() => setCapsLockTipShow(false)}
                      onKeyUp={(e) =>
                        setCapsLockTipShow(e.getModifierState("CapsLock"))
                      }
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <Progress
                      percent={progress.percent}
                      steps={3}
                      strokeColor={progress.strokeColor}
                      format={(persent = 0) => {
                        if (persent < 17) return "";
                        if (persent < 50) return t("login.roles.weak");
                        if (persent < 84) return t("login.roles.medium");
                        return t("login.roles.strong");
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Tooltip>
              <Tooltip
                open={capsLockTipShow2}
                title={t("system.tips.capslock")}
                color="rgba(0,0,0, 0.7)"
              >
                <Form.Item
                  label={t("user.attr.password2")}
                  name="password2"
                  rules={formRules.password2}
                >
                  <Input.Password
                    visibilityToggle
                    maxLength={16}
                    showCount
                    placeholder={t("login.roles.password")}
                    onBlur={() => setCapsLockTipShow2(false)}
                    onKeyUp={(e) =>
                      setCapsLockTipShow2(e.getModifierState("CapsLock"))
                    }
                  />
                </Form.Item>
              </Tooltip>
              <Form.Item
                label={t("user.attr.name")}
                name="name"
                rules={formRules.name}
              >
                <Input placeholder={t("login.roles.name")} />
              </Form.Item>
              <Form.Item
                label={t("login.item.usertype")}
                name="type"
                initialValue={2}
              >
                <Radio.Group>
                  <Radio value={2}>{t("login.item.student")}</Radio>
                  <Radio value={1}>{t("login.item.teacher")}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label={t("user.attr.sex")} name="sex">
                <Select placeholder={t("login.roles.sex")}>
                  <Select.Option value={1}>
                    {t("user.attr.sex.male")} <Tag color="blue">♂</Tag>
                  </Select.Option>
                  <Select.Option value={2}>
                    {t("user.attr.sex.female")} <Tag color="magenta">♀</Tag>
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={t("login.item.organization")}
                name="_org"
                rules={formRules._org}
              >
                <DepartmentSelector treeData={deptTree} />
              </Form.Item>
              <Form.Item label={t("user.attr.zwid")} name="zwid">
                <Select placeholder={t("login.roles.zwid")}>
                  {zwidList.map((el) => {
                    return (
                      <Select.Option value={el.id} key={el.id}>
                        {el.cName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("user.attr.captchaCode")}
                name="captchaCode"
                rules={formRules.captchaCode}
              >
                <Row>
                  <Col span={15}>
                    <Input
                      prefix={<PictureOutlined />}
                      maxLength={5}
                      placeholder={t("login.roles.code")}
                    />
                  </Col>
                  <Col span={7} offset={2}>
                    <Button className="p-0" block onClick={getCode}>
                      <img src={captcha} className="h-full w-full" alt="" />
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              <Row>
                <Col span={4} offset={2}>
                  <Button block onClick={() => navigate("/")}>
                    {t("login.item.back")}
                  </Button>
                </Col>
                <Col span={14} offset={1}>
                  <Button
                    block
                    loading={loading}
                    onClick={register}
                    type="primary"
                    htmlType="submit"
                  >
                    {t("login.item.register")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

interface DepartmentSelectorProps {
  value?: any;
  onChange?: any;
  treeData?: any[];
}

const DepartmentSelector: FC<DepartmentSelectorProps> = ({
  value,
  onChange,
  treeData,
}) => {
  const { t } = useLocale(i18n);
  return (
    <TreeSelect
      allowClear
      placeholder={t("login.item.selorganization")}
      fieldNames={{ label: "name", value: "id" }}
      treeData={treeData}
      treeDefaultExpandAll
      value={value?.id}
      onSelect={(_value: any, node: NodeDataType) => {
        onChange?.(node);
      }}
    />
  );
};

export default RegisterPage;
