import { ConfigProvider } from "antd";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useStorage } from "../hooks/useStorage";
import zhCN from "antd/es/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import axios from "../configs/axios";
import moment from "moment";

type LocaleContextValues = {
  lang: SupportedLangType;
  setLang: (value: SupportedLangType) => void;
  theme: string;
  setTheme: (value: string) => void;
} | null;

const LocaleContext = createContext<LocaleContextValues>(null);
LocaleContext.displayName = "Locale-Context";

const getPriorLocale = () => {
  return navigator?.languages?.[0]?.includes("zh") ? "zh-CN" : "en-US";
};

// 本地化语言、主题
const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const [lang, setLang] = useStorage("lang", getPriorLocale());
  const [theme, setTheme] = useStorage("theme", "light");
  const [antdLang, setAntdLang] = useState(zhCN);

  useEffect(() => {
    switch (lang) {
      case "zh-CN":
        setAntdLang(zhCN);
        moment.locale("zh-cn");
        break;

      case "en-US":
        setAntdLang(enUS);
        moment.locale("en");
        break;

      default:
        break;
    }
    axios.lang = lang;
  }, [lang]);

  return (
    <LocaleContext.Provider value={{ lang, setLang, theme, setTheme }}>
      <ConfigProvider locale={antdLang}>{children}</ConfigProvider>
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleProvider };
