import { FormProps, useForm } from "antd/lib/form/Form";
import { useState, useEffect, useCallback } from "react";
import { Modal, Form, DatePicker, Table } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import { queryLogByUserId } from "../../../api/NewLogController";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

interface LogTableProps {
  logId: any;
  visible?: boolean;
  setVisible: (visible: boolean) => void;
}

const { RangePicker } = DatePicker;

const MyLogTable = (prop: LogTableProps) => {
  const { t } = useLocale(i18n);
  const { logId, visible, setVisible } = prop;
  const [logStartTime, setLogStartTime] = useState<undefined | string>();
  const [logEndTime, setLogEndTime] = useState<undefined | string>();
  const [logTablePagenation, setLogTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  const onValuesChange: FormProps["onValuesChange"] = (_changed, values) => {
    const { time } = values;
    const startTime = time?.[0]?.format("YYYY-MM-DD");
    const endTime = time?.[1]?.format("YYYY-MM-DD");
    setLogStartTime(startTime);
    setLogEndTime(endTime);
  };

  //登录日志
  const [logform] = useForm();
  const [logList, setLogList] = useState<any[]>([]);
  const [logRecords, setLogRecords] = useState(0);
  const [logLoading, setLogLoading] = useState(false);

  const showLogList = useCallback(
    async () => {
      setLogLoading(true);
      queryLogByUserId({
        userId: logId,
        startTime: logStartTime,
        endTime: logEndTime,
        ...logTablePagenation,
      })
        .then(({ data }) => {
          if (data.code) {
            setLogList(data.data.rows);
            setLogRecords(data.data.records);
          }
        })
        .finally(() => {
          setLogLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logTablePagenation, logId, logStartTime, logEndTime]
  );

  useEffect(() => {
    showLogList();
  }, [showLogList]);

  return (
    <Modal
      title={t("manage.item.loginlog")}
      open={visible}
      footer={null}
      width={1200}
      centered
      destroyOnClose
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div>
        <Form layout="inline" form={logform} onValuesChange={onValuesChange}>
          <Form.Item name="time">
            <RangePicker
              placeholder={[t("manage.item.eversince"), t("manage.item.sofar")]}
              allowEmpty={[true, true]}
            />
          </Form.Item>
          {/* <Button type="primary" htmlType="submit" onClick={onSearchLog}>
              查询
            </Button> */}
        </Form>
        <Table
          className="mt-5"
          size="small"
          rowKey="id"
          bordered
          loading={logLoading}
          dataSource={logList}
          pagination={{
            size: "default",
            showQuickJumper: true,
            showSizeChanger: true,
            position: ["bottomCenter"],
            total: logRecords,
            showTotal: (total) =>
              `${t("table.pagination.all")}${total}${t(
                "table.pagination.total"
              )}`,
            current: logTablePagenation.page,
            pageSize: logTablePagenation.rows,
            onChange: (page, rows) => {
              setLogTablePagenation({ page, rows });
            },
          }}
          columns={[
            {
              title: t("user.attr.name"),
              dataIndex: "userName",
              width: 120,
              fixed: "left",
            },
            {
              title: t("manage.item.logintime"),
              dataIndex: "dLogtime",
              align: "center",
              width: 180,
            },
            {
              title: t("manage.item.exittime"),
              dataIndex: "dLogouttime",
              width: 180,
              align: "center",
            },

            {
              title: t("manage.item.loginip"),
              dataIndex: "cIp",
              width: 150,
            },
            {
              title: t("manage.item.loginMAC"),
              dataIndex: "cMac",
              width: 150,
            },
            {
              title: t("manage.item.loginnum"),
              dataIndex: "nNumber",
              width: 100,
            },
            {
              title: t("manage.item.thistime"),
              dataIndex: "dUsetime",
              render: (val) => moment.duration(val).humanize(),
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default MyLogTable;
