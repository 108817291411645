import type { defaultI18nKeys } from "../i18n/default";

interface RouterInfo {
  path: string; // 路径
  depend?: string; // 依赖路径（鉴权使用。若该路由是非菜单路由，会依据此项判定权限）
  component?: string; // 组件标识符
  redirect?: string; // 重定向路径。仅在component为空时有效
  login?: boolean; // 是否需要登陆
  auth?: boolean; // 是否需要鉴权
  children?: RouterInfo[]; // 子路由
  meta?: {
    title: defaultI18nKeys; // 页面title
  };
}

const routes: RouterInfo[] = [
  {
    path: "/",
    component: "LoginPage",
    meta: {
      title: "router.title.login",
    },
  },
  {
    path: "/login",
    redirect: "/",
  },
  {
    path: "/register",
    component: "RegisterPage",
    meta: {
      title: "router.title.register",
    },
  },
  {
    path: "/home",
    login: true,
    component: "HomePage",
    children: [
      {
        path: "/home/dashboard",
        login: true,
        auth: true,
        component: "DashBoard",
        meta: {
          title: "menu.dashboard",
        },
      },
      {
        path: "/home/system",
        login: true,
        children: [
          {
            path: "/home/system/statistic",
            login: true,
            auth: true,
            component: "StatisticPage",
            meta: {
              title: "menu.system.statistic",
            },
          },
          {
            path: "/home/system/authority",
            login: true,
            auth: true,
            component: "AuthorityManagePage",
            meta: {
              title: "menu.system.authority",
            },
          },
          {
            path: "/home/system/setting",
            login: true,
            auth: true,
            component: "SystemSettingPage",
            meta: {
              title: "menu.system.setting",
            },
          },
          {
            path: "/home/system/notice",
            login: true,
            auth: true,
            component: "NoticeManagePage",
            meta: {
              title: "menu.system.notice",
            },
          },
          {
            path: "/home/system/article",
            login: true,
            auth: true,
            component: "ArticleManagePage",
            meta: {
              title: "menu.system.article",
            },
          },
          {
            path: "/home/system/qa",
            login: true,
            auth: true,
            component: "QAManagePage",
            meta: {
              title: "menu.system.qa",
            },
          },
          {
            path: "/home/system/site",
            login: true,
            auth: true,
            component: "SitePresetPage",
            meta: {
              title: "menu.system.site",
            },
          },
          {
            path: "/home/system/declare",
            login: true,
            auth: true,
            component: "DeclarePageSetting",
            meta: {
              title: "menu.system.declare",
            },
          },
        ],
      },
      {
        path: "/home/users",
        login: true,
        auth: true,
        children: [
          {
            path: "/home/users/manage",
            login: true,
            auth: true,
            component: "UserManagePage",
            meta: {
              title: "menu.users.manage",
            },
          },
          {
            path: "/home/users/registers",
            login: true,
            auth: true,
            component: "RegisterCenterPage",
            meta: {
              title: "menu.users.registers",
            },
          },
        ],
      },
      {
        path: "/home/experiment",
        login: true,
        auth: true,
        children: [
          {
            path: "/home/experiment/manage",
            login: true,
            auth: true,
            component: "ExperimentManagePage",
            meta: {
              title: "menu.experiment.manage",
            },
          },
          {
            path: "/home/experiment/mine",
            login: true,
            auth: true,
            component: "MineExperimentPage",
            meta: {
              title: "menu.experiment.mine",
            },
          },
          {
            path: "/home/experiment/marks",
            login: true,
            auth: true,
            component: "ExperimentMarksPage",
            meta: {
              title: "menu.experiment.marks",
            },
          },
        ],
      },
      {
        path: "/home/exam",
        login: true,
        auth: true,
        children: [
          {
            path: "/home/exam/score",
            login: true,
            auth: true,
            component: "ExamScorePage",
            meta: {
              title: "menu.exam.score",
            },
          },
          {
            path: "/home/exam/lib",
            login: true,
            auth: true,
            component: "ExamLibraryPage",
            meta: {
              title: "menu.exam.lib",
            },
          },
          // {
          //   path: "/home/exam/papers",
          //   login: true,
          //   auth: true,
          //   component: "ExamPapersPage",
          //   meta: {
          //     title: "menu.exam.papers",
          //   },
          // },
          {
            path: "/home/exam/manage",
            login: true,
            auth: true,
            component: "ExamManagePage",
            meta: {
              title: "menu.exam.manage",
            },
          },
          {
            path: "/home/exam/exam",
            login: true,
            auth: true,
            component: "MyExamPage",
            meta: {
              title: "menu.exam.exam",
            },
          },
          {
            path: "/home/exam/practice",
            login: true,
            auth: true,
            component: "MyPracticePage",
            meta: {
              title: "menu.exam.practice",
            },
          },
          {
            path: "/home/exam/mistakes",
            login: true,
            auth: true,
            component: "MistakeRecordsPage",
            meta: {
              title: "menu.exam.mistakes",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/declare",
    login: true,
    component: "DeclarePage",
    meta: {
      title: "router.title.declare",
    },
  },
  {
    path: "/play/:recordId",
    login: true,
    component: "PlayPage",
  },
  {
    path: "/practice/:recordId",
    login: true,
    component: "PracticePage",
  },
  {
    path: "/exam-sheet/:examId",
    login: true,
    component: "ExamSheetPage",
  },
  {
    path: "/mobile",
    component: "MobileQaReply",
    meta: {
      title: "router.title.mobile",
    },
  },
  {
    path: "/401",
    component: "NotAuthPage",
    meta: {
      title: "router.title.401",
    },
  },
  {
    path: "/403",
    component: "PermissionDeniedPage",
    meta: {
      title: "router.title.403",
    },
  },
  {
    path: "/404",
    component: "NotFoundPage",
    meta: {
      title: "router.title.404",
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

export type { RouterInfo };

export default routes;
