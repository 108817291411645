import req from "../configs/axios";

// 查询院系、专业、班级、职务列表
const showYZBWTree = (data?: any) => {
  return req.post("RegisterController/showYZBWTree", data);
};

// 用户注册
const adduser = (data?: any) => {
  return req.post("RegisterController/adduser", data);
};

// 注册列表
const showuserfull = (data?: any) => {
  return req.post("auth/RegisterController/showuserfull", data);
};

// 通过标记拒绝
const saveuser = (data?: any) => {
  return req.post("auth/RegisterController/saveuser", data);
};

export { showYZBWTree, adduser, showuserfull, saveuser };
