import axios from "axios";
import qs from "qs";
import { message } from "antd";
import { merge } from "lodash-es";
import i18n from "../i18n/default";
import type { AxiosRequestConfig, AxiosInstance, AxiosPromise } from "axios";

interface RequestParam {
  baseURL: string;
}

const baseURL = "/";

class Request {
  baseURL: string;
  lang: SupportedLangType;

  constructor(params: RequestParam) {
    this.baseURL = params.baseURL;
    this.lang = "zh-CN";
  }

  getInsideConfig(): Partial<AxiosRequestConfig> {
    const conf = {
      baseURL: this.baseURL,
      timeout: 30000,
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return conf;
  }

  interceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(
      (config) => {
        // before request is sent
        return config;
      },
      (error) => {
        // request error
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (res) => {
        // HTTP 2xx
        switch (res.data.httpStatus) {
          case 401:
            message.error(i18n[this.lang]["system.msg.401"]);
            break;

          case 403:
            message.error(i18n[this.lang]["system.msg.403"]);
            break;

          case 500:
            res?.data?.msg && message.error(res.data.msg);
            break;

          default:
            break;
        }

        return res;
      },
      (error) => {
        // except HTTP 2xx
        if (typeof error.response === "undefined") {
          if (error.message.includes("timeout")) {
            message.error(i18n[this.lang]["system.msg.timeout"]);
          } else {
            message.error(i18n[this.lang]["system.msg.302"]);
            window.sessionStorage.clear();
          }
        } else {
          message.error(error.message);
        }
        return Promise.reject(error);
      }
    );
  }

  request<U>(options: AxiosRequestConfig): AxiosPromise<U> {
    const instance = axios.create();
    const newOptions = merge(this.getInsideConfig(), options);
    this.interceptors(instance);

    if (
      (newOptions?.headers?.["Content-Type"] as string) ===
      "application/x-www-form-urlencoded"
    ) {
      if (newOptions.data) {
        newOptions.data = qs.stringify(newOptions.data);
      }
      if (newOptions.params) {
        newOptions.params = qs.stringify(newOptions.params);
      }
    }

    return instance(newOptions);
  }

  get<T = any, U = any>(url: string, params?: T, config?: AxiosRequestConfig) {
    const options = Object.assign(
      {
        method: "get",
        url: url,
        params,
      },
      config
    );
    return this.request<U>(options);
  }

  post<T = any, U = any>(url: string, data?: T, config?: AxiosRequestConfig) {
    const options = Object.assign(
      {
        method: "post",
        url: url,
        data,
      },
      config
    );
    return this.request<U>(options);
  }
}

export default new Request({ baseURL });
