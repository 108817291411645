import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomHeader from "./CustomHeader";
import * as AllIcons from "../../router/menu";
import type { MenuItem } from "../../router/menu";
import type { ItemType } from "antd/lib/menu/hooks/useItems";
import { useLocale } from "../../hooks/useLocale";
import useAuth from "../../hooks/useAuth";
import { useSystemInfo } from "../../hooks/useSystemInfo";
import { RichDisplay } from "../components/RichEditor";

const { Content, Footer, Sider } = Layout;

const HomePage = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useLocale();
  const { menu, getMenu } = useAuth();
  const { baseInfo, sysInfo } = useSystemInfo();

  const renderMenu = (menu: MenuItem): ItemType => {
    const Icon = menu?.rightIcon && (AllIcons as any)[menu.rightIcon];
    if (menu.child?.length) {
      return {
        key: menu.rightKey,
        icon: menu.rightIcon ? <Icon /> : null,
        label: t(menu.title),
        children: menu.child.map(renderMenu),
      };
    }

    return {
      key: menu.rightKey,
      icon: menu.rightIcon ? <Icon /> : null,
      label: <Link to={menu.rightKey}>{t(menu.title)}</Link>,
    };
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setOpenKeys([pathname.substring(0, pathname.lastIndexOf("/"))]);
    setSelectedKeys([pathname]);
  }, [pathname]);

  useEffect(() => {
    getMenu();
  }, [getMenu]);

  return (
    <Layout className="h-screen">
      <Sider
        className="bg-white overflow-y-auto select-none"
        collapsed={collapsed}
      >
        <div
          id="header-home"
          className="w-full cursor-pointer p-2 h-16 flex justify-center items-center"
          onClick={() => navigate("/home/dashboard")}
        >
          {collapsed ? null : (
            <img
              src={sysInfo?.logo}
              className="h-full object-contain w-full"
              alt=""
            />
          )}
        </div>
        <Menu
          mode="inline"
          className="shadow"
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onSelect={({ key, keyPath: _keyPath }) => {
            setSelectedKeys([key]);
          }}
          onOpenChange={(keys) => setOpenKeys([keys[keys.length - 1]])}
          items={menu?.map(renderMenu)}
        />
      </Sider>
      <Layout>
        <CustomHeader onCollapse={toggleCollapse} collapsed={collapsed} />
        <Content className="px-4 overflow-y-auto">
          <Outlet />
          <Footer className="text-center py-4">
            <RichDisplay html={baseInfo?.footer} />
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default HomePage;
