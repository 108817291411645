import * as echarts from "echarts/core";
import {
  GridComponent,
  VisualMapComponent,
  MarkLineComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  type TooltipComponentOption,
  type LegendComponentOption,
} from "echarts/components";
import { LineChart, PieChart, type PieSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";
import { debounce } from "lodash-es";

echarts.use([
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
  MarkLineComponent,
  LineChart,
  PieChart,
  CanvasRenderer,
  UniversalTransition,
  LegendComponent,
  TitleComponent,
]);

export type EChartsOption = echarts.ComposeOption<
  TooltipComponentOption | LegendComponentOption | PieSeriesOption
>;

interface ChartProps {
  option: EChartsOption;
  loading?: boolean;
}

const Chart: React.FC<ChartProps> = ({ option, loading }) => {
  const chart = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const instance = echarts.init(chart.current!);
    instance.setOption(option);

    const resize = debounce(() => {
      instance.resize();
    }, 20);

    window.addEventListener("resize", resize);

    if (loading) {
      instance.showLoading();
    } else {
      instance.hideLoading();
    }

    return () => {
      window.removeEventListener("resize", resize);
      instance.dispose();
    };
  }, [loading, option]);

  return <div ref={chart} className="w-full h-full"></div>;
};

export default Chart;
