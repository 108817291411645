import {
  Space,
  Input,
  Button,
  Table,
  Form,
  Tooltip,
  Image,
  message,
  Tag,
  Modal,
  Steps,
  Alert,
  Upload,
  notification,
  Select,
  TreeSelect,
  Progress,
  DatePicker,
  Radio,
} from "antd";
import {
  FormOutlined,
  LinkOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  addOneUser,
  deleteBatchUser,
  deleteOneUser,
  listAllUserByParams,
  updateBatchUser,
  updateOneUserByUserId,
  showMyLog,
} from "../../../api/NewUserController";
import { downloadByURL } from "../../../utils/download";
import { uploadUserExcel } from "../../../api/NewUpLoadController";
import { mediumPuzzle, mobile, strongPuzzle } from "../../../utils/regExp";
import {
  queryYuanListToSelect,
  queryZhuanListToSelect,
  queryBanListToSelect,
  queryZWListToSelect,
} from "../../../api/NewDeptController";
import type { UploadProps } from "antd";
import type { Rule } from "antd/lib/form";
import DepartmentTree, {
  type NodeDataType,
} from "../../components/DepartmentTree";
import useToggle from "../../../hooks/useToggle";
import { useLocale } from "../../../hooks/useLocale";
import moment from "moment";
import "moment/locale/zh-cn";
import UserImportTemplate from "../../../assets/template/UserImportTemplate.xls";
import { array2Tree, keyEncode } from "../../../utils/format";
import PageMain from "../../components/PageMain";
import MyLogTable from "./MyLogTable";
import {
  listAllRightByUserId,
  listRoleByParams,
} from "../../../api/NewRightRoleController";
import { type RoleInstance } from "../../system/authority/RoleManageModal";
import i18n from "./i18n";

const { Step } = Steps;
const { Dragger } = Upload;
const { confirm } = Modal;
const { useForm } = Form;
const { RangePicker } = DatePicker;

interface ImportReport {
  key: "上传失败" | "上传成功";
  ExcelNum: number; // 数据条数
  eorrRowNum: number; // 错误条数
  importNumber: number; // 成功导入条数
  cfNum: number; // 重复的数据条数
  cfList: any[]; //
  eorrList: {
    excelRow: number; // 重复行
    excelCFdata: string; // 已存在内容
    exceldata?: string; // 不合法或重复内容
    data?: string; // 自动修正后的内容
  }[];
}

const formRoles: { [key: string]: Rule[] } = {
  loginID: [{ required: true, message: "请输入工(学)号" }],
  name: [{ required: true, message: "请输入姓名" }],
  password: [
    { required: false },
    {
      validator(_, value) {
        if (!value) return Promise.resolve();
        if (value.length < 8) {
          return Promise.reject(new Error("口令过短。请设置至少8位的密码"));
        }
        if (mediumPuzzle.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            new Error("密码必须包含数字和大、小写字母、符号中的一种")
          );
        }
      },
    },
  ],
  tel: [
    { required: false },
    {
      message: "手机号码格式不正确",
      validator: (_rule, val) =>
        mobile.test(val) || !val ? Promise.resolve(val) : Promise.reject(),
    },
  ],
  mail: [{ required: false }, { type: "email", message: "邮箱格式不正确" }],
};

const UserManagePage = () => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [treeMap, setTreeMap] = useState<Map<number, any>>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [yuanMap, setYuanMap] = useState<Map<number, any>>();
  const [zhuanMap, setZhuanMap] = useState<Map<number, any>>();
  const [banMap, setBanMap] = useState<Map<number, any>>();
  const [zwMap, setZwMap] = useState<Map<number, any>>();
  const [roles, setRoles] = useState<RoleInstance[]>();
  const [multipleEdit, setMultipleEdit] = useToggle(false);
  const [multipleSelectedRows, setMultipleSelectedRows] = useState<React.Key[]>(
    []
  );
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const [queryForm] = useForm();
  const { t } = useLocale(i18n);

  const onSearch = () => {
    setTablePagenation({ page: 1, rows: 10 });
  };

  const tableRowSelection = {
    onChange: (selectedRowKeys: React.Key[], _selectedRows: any) => {
      setMultipleSelectedRows(selectedRowKeys);
    },
  };

  const cancelTabRowSelection = () => {
    setMultipleEdit();
    setMultipleSelectedRows([]);
  };

  const deleteTableRows = () => {
    if (multipleSelectedRows.length === 0) {
      message.error(t("manage.item.selectlist"));
      return;
    }
    console.log(multipleSelectedRows);

    Modal.confirm({
      title: t("manage.item.confirmdel"),
      content: `将注销${multipleSelectedRows.length}个用户，并且删除这些用户的所有数据，确定吗？`,
      async onOk() {
        const { data } = await deleteBatchUser(multipleSelectedRows);
        console.log(data);
        if (data.code) {
          message.success(t("manage.item.operationsuccess"));
          setTablePagenation({ page: 1, rows: 10 });
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  const openRowEdit = (rowData: any) => {
    // console.log(rowData);
    editform.setFieldsValue(rowData);
    setEditingUserId(rowData.id);
    setEditModalShow(true);
  };

  const onEntitySelect = (node?: NodeDataType) => {
    const key = {
      1: "yid",
      2: "zid",
      3: "bid",
      4: "uid",
    };

    getTableData(node?.level ? { [key[node.level]]: node.id } : {});
  };

  const getTableData = useCallback(
    async (depart?: any) => {
      setLoading(true);

      listAllUserByParams({
        ...queryForm.getFieldsValue(),
        ...tablePagenation,
        ...depart,
      })
        .then(({ data }) => {
          if (data.code) {
            setTableData(data.data.rows);
            setTotal(data.data.records);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [queryForm, tablePagenation]
  );

  // 获取所有角色
  const getRoles = async () => {
    const { data } = await listRoleByParams();
    if (data?.code) {
      setRoles(data.data.rows);
      return Promise.resolve(data.data.rows);
    }
    setRoles(undefined);
    return Promise.reject(data);
  };

  useEffect(() => {
    getTableData();
    getRoles();
  }, [getTableData]);

  useEffect(() => {
    let queryYuan = queryYuanListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setYuanMap(map);
        return map;
      }
    });

    let queryZhuan = queryZhuanListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setZhuanMap(map);
        return map;
      }
    });

    let queryBan = queryBanListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setBanMap(map);
        return map;
      }
    });

    queryZWListToSelect().then(({ data }) => {
      if (data?.code) {
        const map = new Map();
        for (let i of data.data.rows) map.set(i.id, i);
        setZwMap(map);
      }
    });

    Promise.allSettled([queryYuan, queryZhuan, queryBan]).then((reslist) => {
      const settled = (
        reslist.filter(
          (promise) => promise.status === "fulfilled"
        ) as PromiseFulfilledResult<any>[]
      ).map((item) => item.value);

      const merged = new Map();
      for (let i of settled) {
        for (let part of i) {
          merged.set(part[0], part[1]);
        }
      }

      setTreeMap(merged);
      const tree = array2Tree(Array.from(merged.values()));
      setTreeData(tree);
    });
  }, []);

  // 用户组织树
  const [tableSiderShow, setTableSiderShow] = useToggle(true);

  // 导入用户
  const [importModalShow, setImportModalShow] = useState(false);
  const [fileList, setFileList] = useState<any[]>();
  const [importUploading, setImportUploading] = useState(false);

  const closeImportModal = () => {
    setFileList([]);
    setImportModalShow(false);
  };

  const beforeUpload: UploadProps["beforeUpload"] = (file) => {
    const suffix = file.name.substring(file.name.lastIndexOf("."));
    if (!suffix.includes("xls")) {
      message.error(t("manage.item.onlyexcel"));
      return Upload.LIST_IGNORE;
    }
    setFileList([file]);
    return false;
  };

  const onFileRemove: UploadProps["onRemove"] = () => {
    setFileList([]);
  };

  const manuallyUpload = () => {
    if (!fileList || fileList?.length === 0) {
      message.error(t("manage.item.selectfirst"));
      return;
    }
    const formData = new FormData();
    formData.append("file", fileList[0]);

    setImportUploading(true);
    uploadUserExcel(formData)
      .then(({ data }) => {
        if (!data.code) {
          data?.msg && message.error(data.msg);
          return;
        }
        closeImportModal();
        if (data.data.eorrRowNum > 0) {
          let okText =
            data.data.cfNum > 0
              ? `${t("manage.item.coverage")}${data.data.cfNum}${t(
                  "table.pagination.total"
                )}${t("manage.item.duplicate")}`
              : false;
          const modal = Modal.confirm({
            maskClosable: false,
            width: 800,
            title: `${t("manage.item.checkerror")}`,
            content: (
              <>
                <Alert
                  className="mb-2"
                  type="warning"
                  showIcon
                  message={`${t("manage.item.uploaded")}${
                    data.data.ExcelNum
                  }${t("table.pagination.total")}${t(
                    "manage.item.uploaded"
                  )}其中${data.data.cfNum}条数据已存在，${
                    data.data.eorrRowNum - data.data.cfNum
                  }条数据错误，系统已自动修正。`}
                />
                <Table
                  size="small"
                  bordered
                  rowKey="excelRow"
                  scroll={{ y: 400 }}
                  dataSource={data.data.eorrList}
                  pagination={false}
                  rowClassName={(records) =>
                    records.exceldata ? "bg-red-200 hover:text-red-500" : ""
                  }
                  columns={[
                    {
                      title: t("manage.item.errorline"),
                      dataIndex: "excelRow",
                      align: "center",
                      width: 60,
                    },
                    {
                      title: t("manage.item.type"),
                      align: "center",
                      width: 60,
                      render: (_val, row) =>
                        row?.excelCFdata ? (
                          <Tag color="#ffc53d">
                            {t("manage.item.repetition")}
                          </Tag>
                        ) : (
                          <Tag color="#f5222d">{t("manage.item.error")}</Tag>
                        ),
                    },
                    {
                      title: t("manage.item.errorcontent"),
                      dataIndex: "excelCFdata",
                      width: 360,
                      render: (_val, row) => row?.excelCFdata || row?.exceldata,
                    },
                    {
                      title: t("manage.item.automatically"),
                      dataIndex: "data",
                    },
                  ]}
                />
              </>
            ),
            okText,
            okButtonProps: { loading: false, danger: true },
            cancelText: t("manage.item.close"),
            onOk() {
              if (!okText) return Promise.resolve();
              modal.update({ okButtonProps: { loading: true, danger: true } });
              updateBatchUser(data.data.cfList)
                .then(({ data: d }) => {
                  modal.destroy();
                  if (d.code) {
                    notification.success({
                      message: t("manage.item.importsuccess"),
                      description: `${t("manage.item.changed")}${
                        d.data.importNumber
                      }${t("table.pagination.total")}${t(
                        "manage.item.systemdata"
                      )}`,
                    });
                  } else {
                    message.error(t("manage.item.unknownerror"));
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
              return Promise.reject();
            },
          });
        } else {
          notification.success({
            message: t("manage.item.importsuccess"),
            description: `${t("manage.item.uploaded")}${
              data.data.importNumber
            }${t("table.pagination.total")}${t("manage.item.uploaded")}${t(
              "manage.item.allenter"
            )}`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(t("manage.item.wrong"));
      })
      .finally(() => {
        setImportUploading(false);
      });
  };

  // 编辑用户
  const [editform] = useForm();
  const [progress, setProgress] = useState({ percent: 0, strokeColor: "" });
  const [editingUserId, setEditingUserId] = useState<number>();
  const [editModalShow, setEditModalShow] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const openToEdit = async (rowData?: any) => {
    if (rowData) {
      rowData._org = rowData.bid || rowData.zid || rowData.yid;
      rowData._roles = await getUserRoles(rowData.id);
      editform.setFieldsValue(rowData);
      formRoles.password[0] = { required: false };
      setEditingUserId(rowData.id);
    } else {
      formRoles.password[0] = {
        required: true,
        message: t("manage.item.enterpassword"),
      };
      setEditingUserId(undefined);
      editform.resetFields();
    }
    setProgress({ percent: 0, strokeColor: "" });
    setEditModalShow(true);
  };

  const getUserRoles = async (userId: number) => {
    const { data } = await listAllRightByUserId({ userId });
    if (data.code) {
      const { roleCollect = [] } = data.data;
      return roleCollect.map((role: RoleInstance) => role.id);
    }
    return [];
  };

  const onEditFormValuesChange = (crt: any) => {
    const { password } = crt;
    if (password === "") setProgress({ percent: 0, strokeColor: "" });
    if (password) {
      if (strongPuzzle.test(password)) {
        setProgress({ percent: 100, strokeColor: "#52c41a" });
      } else if (mediumPuzzle.test(password)) {
        setProgress({ percent: 66, strokeColor: "#ffc903" });
      } else {
        setProgress({ percent: 33, strokeColor: "#ff0303" });
      }
    }
  };

  const onUpdateUser = () => {
    editform.validateFields().then(async (d) => {
      const { _org, _roles } = d;

      let yid, zid, bid;
      let current = _org;

      while (current) {
        let tar = treeMap?.get(current);
        if (tar.nLevel === 2) {
          bid = tar.id;
        } else if (tar.nLevel === 1) {
          zid = tar.id;
        } else {
          yid = tar.id;
        }

        current = tar.pid;
      }

      d.roleIdList = _roles.join();
      d = { ...d, yid, zid, bid };
      if (d.password) {
        d.password = keyEncode(d.password);
      } else {
        delete d.password;
      }

      setEditLoading(true);
      if (editingUserId) {
        // 更新用户信息
        d.id = editingUserId;

        const { data } = await updateOneUserByUserId(d).finally(() => {
          setEditLoading(false);
        });
        if (data.code) {
          message.success(t("manage.item.modifysuccess"));
          setTablePagenation({ page: 1, rows: 10 });
          setEditModalShow(false);
          return;
        }
        data?.msg && message.error(data.msg);
        return;
      }
      // 新增用户
      const { data } = await addOneUser(d).finally(() => {
        setEditLoading(false);
      });
      if (data.code) {
        message.success(t("manage.item.addsuccess"));
        setTablePagenation({ page: 1, rows: 10 });
        setEditModalShow(false);
        return;
      }
      data?.msg && message.error(data.msg);
      return;
    });
  };

  //登录日志
  const [logTableShow, setLogTableShow] = useState(false);
  const [logId, setLogId] = useState<string>();

  return (
    <PageMain>
      <CustomBreadcrumb breads={[t("menu.users"), t("menu.users.manage")]} />
      <div className="flex flex-grow">
        {tableSiderShow ? (
          <DepartmentTree
            depth={3}
            onNodeSelect={onEntitySelect}
            allowEdit
            allowSearch
          />
        ) : null}
        <div className="overflow-hidden flex-grow">
          <div className="table-query-bar">
            <Form layout="inline" form={queryForm}>
              <Space wrap>
                <Form.Item label={t("manage.item.nameorwork")} name="search">
                  <Input
                    className="w-50"
                    allowClear
                    placeholder={t("manage.item.enternameornum")}
                  />
                </Form.Item>
                <Button onClick={() => queryForm.resetFields()}>
                  {t("manage.item.clear")}
                </Button>
                <Button type="primary" htmlType="submit" onClick={onSearch}>
                  {t("manage.item.query")}
                </Button>
              </Space>
            </Form>
          </div>
          <div className="table-wrapper h-full">
            <Space className="table-tool-bar">
              <Space>
                <Tooltip
                  title={`${tableSiderShow ? "收起" : "展开"}用户组织树`}
                  placement="top"
                >
                  <Button
                    icon={<ClusterOutlined />}
                    onClick={setTableSiderShow}
                  ></Button>
                </Tooltip>
                <Button type="primary" onClick={() => openToEdit()}>
                  {t("manage.item.account")}
                </Button>
                <Button
                  onClick={() => {
                    setImportModalShow(true);
                  }}
                >
                  {t("manage.item.batchimport")}
                </Button>
              </Space>
              <Space>
                {multipleEdit ? (
                  <>
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={deleteTableRows}
                    >
                      {t("manage.item.delete")}
                    </Button>
                    <Button
                      icon={
                        <FontAwesomeIcon
                          icon={["fas", "arrow-rotate-right"]}
                          className="mr-2"
                        />
                      }
                      onClick={cancelTabRowSelection}
                    >
                      {t("manage.item.cancel")}
                    </Button>
                  </>
                ) : (
                  <Button icon={<FormOutlined />} onClick={setMultipleEdit}>
                    {t("manage.item.batchoperation")}
                  </Button>
                )}
              </Space>
            </Space>
            <Table
              size="small"
              rowKey="id"
              bordered
              scroll={{ x: 1700 }}
              loading={loading}
              dataSource={tableData}
              rowSelection={
                multipleEdit
                  ? { type: "checkbox", ...tableRowSelection }
                  : undefined
              }
              pagination={{
                size: "default",
                showQuickJumper: true,
                showSizeChanger: true,
                position: ["bottomCenter"],
                current: tablePagenation.page,
                pageSize: tablePagenation.rows,
                total,
                showTotal: (total) =>
                  `${t("table.pagination.all")}${total}${t(
                    "table.pagination.total"
                  )}`,
                onChange: (page, rows) => {
                  setTablePagenation({ page, rows });
                },
              }}
              columns={[
                {
                  title: t("table.index"),
                  width: 60,
                  fixed: "left",
                  align: "center",
                  render: (_val, _row, index) => index + 1,
                },
                {
                  title: t("manage.item.loginid"),
                  dataIndex: "loginID",
                  width: 120,
                  fixed: "left",
                },
                {
                  title: t("user.attr.name"),
                  dataIndex: "name",
                  width: 120,
                  fixed: "left",
                },
                {
                  title: t("manage.item.avatar"),
                  dataIndex: "myac",
                  align: "center",
                  width: 80,
                  render: (val) =>
                    val ? <Image width={60} height={60} src={val} /> : null,
                },
                {
                  title: t("user.attr.sex"),
                  dataIndex: "sex",
                  width: 50,
                  align: "center",
                  render: (val) =>
                    val === 1 ? (
                      <Tag color="blue">♂</Tag>
                    ) : (
                      <Tag color="magenta">♀</Tag>
                    ),
                },
                {
                  title: t("user.attr.tel"),
                  dataIndex: "tel",
                  width: 120,
                },
                {
                  title: t("user.attr.mail"),
                  dataIndex: "mail",
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: t("user.attr.yid"),
                  dataIndex: "yid",
                  width: 120,
                  render: (val) => yuanMap?.get(val)?.cName,
                },
                {
                  title: t("user.attr.zid"),
                  dataIndex: "zid",
                  width: 120,
                  render: (val) => zhuanMap?.get(val)?.cName,
                },
                {
                  title: t("user.attr.bid"),
                  dataIndex: "bid",
                  width: 120,
                  render: (val) => banMap?.get(val)?.cName,
                },
                {
                  title: t("user.attr.zwid"),
                  dataIndex: "zwid",
                  width: 120,
                  render: (val) => zwMap?.get(val)?.cName,
                },
                {
                  title: t("manage.item.currency"),
                  dataIndex: "accountBalance",
                  width: 120,
                },
                {
                  title: t("manage.item.logintimes"),
                  dataIndex: "logNumber",
                  width: 150,
                },
                {
                  title: t("manage.item.usetime"),
                  dataIndex: "allLogTime",
                  render: (val) => moment.duration(val).humanize(),
                },
                {
                  title: t("manage.item.operation"),
                  dataIndex: "opt",
                  width: 200,
                  fixed: "right",
                  align: "center",
                  render(_text, row) {
                    return (
                      <Space>
                        <Button
                          size="small"
                          onClick={() => {
                            setLogId(row.id);
                            setLogTableShow(true);
                          }}
                        >
                          {t("header.item.rizhi")}
                        </Button>
                        <Button size="small" onClick={() => openToEdit(row)}>
                          {t("manage.item.edit")}
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          danger
                          onClick={() => {
                            confirm({
                              title: t("manage.item.suredeluser"),
                              icon: <ExclamationCircleOutlined />,
                              content: t("manage.item.loggedoff"),
                              okText: t("manage.item.confirm"),
                              cancelText: t("manage.item.cancel"),
                              centered: true,
                              async onOk() {
                                const { data } = await deleteOneUser({
                                  id: row.id,
                                });
                                if (data.code) {
                                  message.success(t("manage.item.deluser"));
                                  getTableData();
                                } else {
                                  data?.msg && message.error(data.msg);
                                }
                              },
                            });
                          }}
                        >
                          {t("manage.item.delete")}
                        </Button>
                      </Space>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      {/* import user */}
      <Modal
        title={t("manage.item.batchcreat")}
        destroyOnClose
        open={importModalShow}
        footer={null}
        maskClosable={false}
        width={430}
        onCancel={closeImportModal}
      >
        <Steps
          direction="vertical"
          size="small"
          items={[
            {
              title: t("manage.item.firststep"),
              icon: <CloudDownloadOutlined />,
              status: "process",
              description: (
                <>
                  {t("manage.item.download")}
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => downloadByURL(UserImportTemplate)}
                  >
                    <>
                      <LinkOutlined />
                      {t("manage.item.templatefile")}
                    </>
                  </Button>
                  。
                </>
              ),
            },
            {
              title: t("manage.item.secondstep"),
              icon: <EditOutlined />,
              status: "process",
              description: (
                <>
                  {t("manage.item.fallin")}
                  <Alert
                    className="mt-2"
                    type="warning"
                    showIcon
                    message={t("manage.item.ignored")}
                  />
                </>
              ),
            },
            {
              title: t("manage.item.thirdstep"),
              icon: <CloudUploadOutlined />,
              status: "process",
              description: (
                <>
                  {t("manage.item.worksheet")}
                  <Dragger
                    className="mt-2"
                    maxCount={1}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    onRemove={onFileRemove}
                  >
                    <p className="ant-upload-drag-icon">
                      <FontAwesomeIcon
                        icon={["fas", "cloud-arrow-up"]}
                        size="3x"
                        className="text-gray-300"
                      />
                    </p>
                    <p className="ant-upload-text">{t("manage.item.drag")}</p>
                  </Dragger>
                </>
              ),
            },
          ]}
        />
        <Button
          block
          type="primary"
          onClick={manuallyUpload}
          loading={importUploading}
        >
          {importUploading
            ? t("manage.item.wait")
            : t("manage.item.submitsheet")}
        </Button>
      </Modal>
      {/* 新增、编辑用户 */}
      <Modal
        title={
          editingUserId
            ? t("manage.item.modifyinf")
            : t("manage.item.creataccount")
        }
        open={editModalShow}
        footer={null}
        destroyOnClose
        forceRender
        onCancel={() => {
          setEditModalShow(false);
        }}
      >
        <Form
          name="edit"
          form={editform}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          preserve={false}
          onValuesChange={onEditFormValuesChange}
        >
          <Form.Item
            label={t("manage.item.worknum")}
            name="loginID"
            rules={formRoles.loginID}
          >
            <Input
              placeholder={t("manage.item.modifyworknum")}
              maxLength={20}
              showCount
            />
          </Form.Item>
          <Form.Item
            label={t("user.attr.name")}
            name="name"
            rules={formRoles.name}
          >
            <Input
              placeholder={t("manage.item.modifyname")}
              maxLength={15}
              showCount
            />
          </Form.Item>
          <Form.Item
            label={t("manage.item.usertype")}
            name="type"
            initialValue={2}
          >
            <Radio.Group>
              <Radio value={2}>{t("manage.item.student")}</Radio>
              <Radio value={1}>{t("manage.item.teacher")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t("user.attr.sex")} name="sex">
            <Select placeholder={t("manage.item.choosesex")}>
              <Select.Option value={1}>
                {t("user.attr.sex.male")} <Tag color="blue">♂</Tag>
              </Select.Option>
              <Select.Option value={2}>
                {t("user.attr.sex.female")} <Tag color="magenta">♀</Tag>
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("user.attr.password")}>
            <Form.Item noStyle name="password" rules={formRoles.password}>
              <Input.Password
                visibilityToggle
                placeholder={t("manage.item.changepassword")}
                maxLength={16}
                showCount
              />
            </Form.Item>
            <Form.Item noStyle>
              <Progress
                percent={progress.percent}
                steps={3}
                strokeColor={progress.strokeColor}
                format={(persent = 0) => {
                  if (persent < 17) return "";
                  if (persent < 50) return t("manage.item.weak");
                  if (persent < 84) return t("manage.item.centre");
                  return t("manage.item.strong");
                }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item label={t("manage.item.role")} name="_roles">
            <Select
              mode="multiple"
              allowClear
              showArrow
              placeholder={t("manage.item.selrole")}
              fieldNames={{ label: "roleTitle", value: "id" }}
              options={roles}
            />
          </Form.Item>
          <Form.Item label={t("manage.item.organization")} name="_org">
            <TreeSelect
              placeholder={t("manage.item.selorganization")}
              showSearch
              fieldNames={{ label: "cName", value: "id" }}
              treeDefaultExpandAll
              treeData={treeData}
            />
          </Form.Item>
          <Form.Item label={t("user.attr.zwid")} name="zwid">
            <Select
              placeholder={t("manage.item.seljob")}
              options={Array.from(zwMap?.values() || [], (zw) => ({
                value: zw.id,
                label: zw.cName,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={t("user.attr.tel")}
            name="tel"
            rules={formRoles.tel}
          >
            <Input placeholder={t("manage.item.modifyphone")} />
          </Form.Item>
          <Form.Item
            label={t("user.attr.mail")}
            name="mail"
            rules={formRoles.mail}
          >
            <Input placeholder={t("manage.item.mailbox")} />
          </Form.Item>
        </Form>
        <div style={{ textAlign: "right", marginTop: "30px" }}>
          <Space>
            <Button type="primary" loading={editLoading} onClick={onUpdateUser}>
              {t("manage.item.submit")}
            </Button>
            <Button onClick={() => setEditModalShow(false)}>
              {" "}
              {t("system.confirm.cancel")}
            </Button>
          </Space>
        </div>
      </Modal>
      <MyLogTable
        logId={logId}
        visible={logTableShow}
        setVisible={setLogTableShow}
      ></MyLogTable>
    </PageMain>
  );
};

export default UserManagePage;
