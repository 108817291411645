import "driver.js/dist/driver.min.css";
import "./style/index.less";
import "./App.css";
import "./virtual:windi.css";
import "./style/CompositeClasses.css";
import "./configs/icons.ts";
import { HashRouter } from "react-router-dom";
import { RouterMap } from "./router/router";
import { message } from "antd";
import { AuthProvider } from "./context/AuthContext";
import { LocaleProvider } from "./context/LocaleContext";
import { SystemInfoProvider } from "./context/SystemInfoContext";
import { NoticeProvider } from "./context/NoticeContext";

function App() {
  message.config({ maxCount: 3 });

  return (
    <HashRouter>
      <LocaleProvider>
        <SystemInfoProvider>
          <AuthProvider>
            <NoticeProvider>
              <div className="App">
                <RouterMap />
              </div>
            </NoticeProvider>
          </AuthProvider>
        </SystemInfoProvider>
      </LocaleProvider>
    </HashRouter>
  );
}

export default App;
