import req from "../configs/axios";

// 获取某个实验的实验报告模板
const findLabReportFormworkAll = (data?: any) => {
  return req.post(
    "auth/NewLabReportFormworkController/findLabReportFormworkAll",
    data
  );
};

// 新增（编辑）实验报告
const addLabReportFormworkAll = (data?: any) => {
  return req.post(
    "auth/NewLabReportFormworkController/addLabReportFormworkAll",
    data
  );
};

export { findLabReportFormworkAll, addLabReportFormworkAll };
