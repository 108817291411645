const requestSystemNotificationPermission = () => {
  if (window.Notification.permission !== "granted") {
    return Notification.requestPermission();
  }
  return Promise.resolve(window.Notification.permission);
};

const systemNotification = (
  title: string,
  body: string,
  icon: string = "/favicon.ico",
  tag = "tag",
  duration = 3000
) => {
  if (window.Notification && window.Notification.permission === "granted") {
    const n = new window.Notification(title, {
      icon,
      body,
      tag,
    });
    n.onclick = function handleClick() {
      window.focus();
      this.close();
    };
    setTimeout(n.close.bind(n), duration);
  }
};

export { requestSystemNotificationPermission, systemNotification };
