import { Button, Form, message, Spin } from "antd";
import { useCallback, useEffect, useState, type FC } from "react";
import {
  getLearnContentById,
  updateLearnContentById,
} from "../../../../api/NewLabInfoController";
import { EscapeReverseHTML } from "../../../../utils/format";
import RichEditor from "../../../components/RichEditor";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

interface TeachInfoPanelProps {
  labId?: number;
}

const TeachInfoPanel: FC<TeachInfoPanelProps> = ({ labId }) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const getInfo = useCallback(() => {
    setLoading(true);
    getLearnContentById({ labId })
      .then(({ data }) => {
        if (data.code) {
          form.setFieldValue("learnContent", EscapeReverseHTML(data.data));
          return;
        }
        form.resetFields();
      })
      .finally(() => setLoading(false));
  }, [labId, form]);

  const save = () => {
    const d = form.getFieldsValue();
    setSaving(true);
    updateLearnContentById({ ...d, labId })
      .then(({ data }) => {
        if (data.code) {
          message.success(t("Setupanels.item.savesuccess"));
        } else {
          data?.msg && message.error(data.msg);
        }
        getInfo();
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Form.Item label={t("Setupanels.item.teachinginf")} name="learnContent">
          <RichEditor />
        </Form.Item>
      </Form>
      <Button type="primary" loading={saving} onClick={save}>
        {t("Setupanels.item.savechange")}
      </Button>
    </Spin>
  );
};

export default TeachInfoPanel;
