import req from "../configs/axios";

// 通用文件上传地址
const UPLOAD = "auth/NewUpLoadController/upload";

// 批量导入用户Excel上传
const IMPORTUSEREXCEL = "auth/NewPoiExcelController/ImportUserExcel";

// 批量导入实验Excel上传
const IMPORTLABEXCEL = "auth/NewPoiExcelController/ImportLabExcel";

// 可接受文件mime类型表
const FileAcceptList = {
  image: ["image/png", "image/gif", "image/jpeg", "image/jpg"],
  video: ["video/mp4"],
  audio: ["audio/mp3"],
};

// 手动上传文件
const uploadManually = (
  data: FormData,
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  return req.post(UPLOAD, data, {
    headers: { "Content-Type": "multipart/form-data;" },
    timeout: 0,
    onUploadProgress,
  });
};

// 手动上传Excel录入用户
const uploadUserExcel = (
  data: FormData,
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  return req.post(IMPORTUSEREXCEL, data, {
    headers: { "Content-Type": "multipart/form-data;" },
    timeout: 0,
    onUploadProgress,
  });
};

// 上传excel录入实验
const uploadLabExcel = (
  data: FormData,
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  return req.post(IMPORTLABEXCEL, data, {
    headers: { "Content-Type": "multipart/form-data;" },
    timeout: 0,
    onUploadProgress,
  });
};

export {
  UPLOAD,
  FileAcceptList,
  uploadManually,
  uploadUserExcel,
  uploadLabExcel,
};
