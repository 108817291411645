import req from "../configs/axios";

//文章列表
const showNews = (data?: any) => {
  return req.post("auth/NewNewsController/shownews", data);
};

//新增文章
const addNews = (data?: any) => {
  return req.post("auth/NewNewsController/addnews", data);
};

//批量删除文章
const deletenewsbatch = (data?: any) => {
  return req.post("auth/NewNewsController/deletenewsbatch", data);
};

//编辑新闻
const updatenews = (data?: any) => {
  return req.post("auth/NewNewsController/updatenews", data);
};

//根据id查单条新闻
const findnews = (data?: any) => {
  return req.post("auth/NewNewsController/findnews", data);
};

export { showNews, addNews, deletenewsbatch, updatenews, findnews };
