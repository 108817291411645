import {
  Button,
  message,
  Typography,
  Image,
  Tag,
  Form,
  Radio,
  Checkbox,
  Space,
  Empty,
  Modal,
  Card,
  Statistic,
  Alert,
} from "antd";
import { shuffle } from "lodash-es";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { type Question } from "@/views/exam/library";
import CustomHeader from "@/views/home/CustomHeader";
import {
  addExamScoreByLogin,
  listQuestionByExamId,
} from "@/api/ExamController";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import QuestionMap from "./question-map";
import moment from "moment";
import { encryptAES128 } from "@/utils/format";

export interface Answer extends Question {
  _userAnswer?: string; // 用户答案
  _createTime?: string; // 作答时间
}

const questionTypes: any = {
  1: "单选题",
  2: "多选题",
  3: "判断题",
};

const ExamSheetPage: FC = () => {
  const { examId } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [examInfo, setExamInfo] = useState<any>();
  const [fullScore, setFullScore] = useState<number>();
  const [askList, setAskList] = useState<Answer[]>();
  const [askIndex, setAskIndex] = useState<number>(0);
  const [ask, setAsk] = useState<Answer>();
  const [form] = Form.useForm();
  const [finished, setFinished] = useState(false);

  const startTime = useMemo(() => Date.now(), []);

  const getQuestions = useCallback(() => {
    if (!examId) {
      message.error("参数无效");
      return;
    }

    listQuestionByExamId({ examId }).then(({ data }) => {
      if (data.code) {
        console.log(data.data);
        const { questionOption, questionList, exam, sumQuestionScore } =
          data.data;

        setExamInfo(exam);
        setFullScore(sumQuestionScore);

        if (questionOption === 1) {
          // 选项乱序
          for (const q of questionList as Question[]) {
            if ([1, 2].includes(q.n_question_type)) {
              const symbols = Object.keys(q.questionContent?.choiceList || {});
              const anonymousGroup = Object.entries(
                q.questionContent?.choiceList || {}
              ).map(([k, v]) => ({
                choice: v,
                choiceImg: q.questionContent?.choiceImgList?.[k],
              }));

              const mixed = shuffle(anonymousGroup);
              let choiceList: Record<string, string> = {},
                choiceImgList: Record<string, string> = {};

              for (let i = 0; i < symbols.length; i++) {
                const symbol = symbols[i];
                choiceList[symbol] = mixed[i].choice || "";
                choiceImgList[symbol] = mixed[i].choiceImg || "";
              }

              q.questionContent!.choiceList = choiceList;
              q.questionContent!.choiceImgList = choiceImgList;

              console.log(mixed);
            }
          }
        }

        setAskList(questionList);
      }
    });
  }, [examId]);

  const prev = () => {
    setAskIndex((idx) => idx - 1);
  };

  const next = () => {
    setAskIndex((idx) => idx + 1);
  };

  const onSelect = (id: string) => {
    const idx = askList?.findIndex((q) => q.n_question_id === id);
    if (typeof idx === "number") setAskIndex(idx);
  };

  const getSheet = () => {
    return {
      examId,
      createTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      contentList: askList?.map((ans) => ({
        questionId: ans.n_question_id,
        questionName: ans.c_question_name,
        content: ans.c_content,
        fPoints: ans.f_points,
        mtAnswer: ans.mt_answer,
        userAnswer: ans._userAnswer || "",
        createTime: ans._createTime,
      })),
    };
  };

  const submitSheet = (payload: string) => {
    addExamScoreByLogin({
      score: encryptAES128(payload, "BJWeiRuiLabScore"),
    }).then(({ data }) => {
      if (data.code) {
        setFinished(true);
        Modal.info({
          title: "提交成功。稍后可查看成绩",
          keyboard: false,
          maskClosable: false,
          closable: false,
          icon: <ExclamationCircleOutlined />,
          okText: "确定并退出考试",
          onOk: exit,
        });
      }
    });
  };

  /**
   * @param force 强制交卷
   */
  const commitSheet = (force?: boolean) => {
    const payload = getSheet();

    if (force) {
      submitSheet(JSON.stringify(payload));
    } else {
      Modal.confirm({
        title: "确定要提交试卷吗？",
        icon: <ExclamationCircleOutlined />,
        // content: "",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          submitSheet(JSON.stringify(payload));
        },
      });
    }
  };

  const exit = () => {
    window.close();
  };

  const beforeunload = (e: Event) => {
    // 存储答题卡和计时器
  };

  const onResponse = (id: string, userAnswer: string | string[]) => {
    const timeStr = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");

    setAskList((list) => {
      const asklist = [...list!];
      const idx = asklist.findIndex((q) => q.n_question_id === id);
      if (Array.isArray(userAnswer)) {
        asklist[idx]._userAnswer = userAnswer.join("#&");
      } else {
        asklist[idx]._userAnswer = userAnswer;
      }
      asklist[idx]._createTime = timeStr;

      return askList;
    });
  };

  const cancel = () => {
    Modal.confirm({
      title: "确定放弃考试吗？",
      icon: <ExclamationCircleOutlined />,
      content: "答题结果不会保存",
      okText: "确认",
      cancelText: "取消",
      onOk: exit,
    });
  };

  useEffect(() => {
    if (typeof askIndex === "number") {
      const ask = askList?.[askIndex];
      setAsk(ask);
      if (ask) {
        const { n_question_type, _userAnswer } = ask;

        if ([1, 3].includes(n_question_type)) {
          form.setFieldValue("userAnswer", _userAnswer);
        } else if (n_question_type === 2) {
          form.setFieldValue("_answer", _userAnswer?.split("#&"));
        }
      }
    }
  }, [askIndex, askList, form]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);
    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, []);

  const getChooses = () => {
    if (!ask) return;
    const { n_question_type, n_question_id } = ask;

    if (n_question_type === 1) {
      return (
        <Form.Item name="userAnswer">
          <Radio.Group
            onChange={(e) => onResponse(n_question_id, e.target.value)}
          >
            <Space direction="vertical">
              {ask?.questionContent?.choiceList &&
                Object.keys(ask.questionContent.choiceList).map((symbol) => (
                  <Radio
                    value={ask?.questionContent?.choiceList?.[symbol]}
                    key={symbol}
                  >
                    {symbol}. {ask?.questionContent?.choiceList?.[symbol]}
                    <div className="">
                      <Image
                        src={ask?.questionContent?.choiceImgList?.[symbol]}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      );
    }

    if (n_question_type === 2) {
      return (
        <Form.Item name="_answer">
          <Checkbox.Group
            onChange={(value) => onResponse(n_question_id, value as string[])}
          >
            <Space direction="vertical">
              {ask?.questionContent?.choiceList &&
                Object.keys(ask.questionContent.choiceList).map((symbol) => (
                  <Checkbox
                    value={ask?.questionContent?.choiceList?.[symbol]}
                    key={symbol}
                  >
                    {symbol}. {ask?.questionContent?.choiceList?.[symbol]}
                    <div>
                      <Image
                        src={ask?.questionContent?.choiceImgList?.[symbol]}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </Checkbox>
                ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      );
    }

    if (n_question_type === 3) {
      return (
        <Form.Item name="userAnswer">
          <Radio.Group
            onChange={(e) => onResponse(n_question_id, e.target.value)}
          >
            <Space direction="vertical">
              <Radio value="T">正确</Radio>
              <Radio value="F">错误</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      );
    }

    return <Alert type="warning" showIcon message="此试题类型暂不支持作答" />;
  };

  return (
    <div className="practice-page">
      <CustomHeader
        onCollapse={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
        showCollapse={false}
        logo
      />
      <div className="page-main-wrap">
        <div className="page-main">
          <Typography.Text className="block mb-4 text-lg">
            {examInfo?.examName}
          </Typography.Text>
          <div className="player-wraper">
            <div className="player-left-container">
              {ask ? (
                <div className="answer-area">
                  <Typography.Paragraph>
                    <Space>
                      {ask && <Tag>{questionTypes?.[ask.n_question_type]}</Tag>}
                    </Space>
                    {askIndex + 1}. {ask?.c_question_name}
                  </Typography.Paragraph>
                  {ask?.questionContent?.titleImg && (
                    <Image
                      src={ask?.questionContent?.titleImg}
                      className="max-w-full"
                    />
                  )}
                  <Form form={form} className="mt-2">
                    {getChooses()}
                  </Form>
                  <Space className="mt-4">
                    <Button
                      type="primary"
                      onClick={prev}
                      disabled={!askList || askIndex === 0}
                    >
                      上一题
                    </Button>
                    <Button
                      type="primary"
                      onClick={next}
                      disabled={!askList || askIndex === askList.length - 1}
                    >
                      下一题
                    </Button>
                  </Space>
                </div>
              ) : (
                <div className="answer-area">
                  <Empty description="无待作答试题">
                    <Button type="primary" onClick={exit}>
                      退出考试
                    </Button>
                  </Empty>
                </div>
              )}
            </div>
            {Array.isArray(askList) && askList.length > 0 && (
              <div className="player-right-container">
                <Card className="mb-4">
                  <Statistic.Countdown
                    format="HH:mm:ss"
                    value={startTime + 1000 * 60 * examInfo.examDuration}
                    onFinish={() => {
                      if (!finished) commitSheet(true);
                    }}
                  />
                  <Space>
                    <Button type="primary" onClick={() => commitSheet()}>
                      交卷
                    </Button>
                    <Button onClick={cancel}>取消考试</Button>
                  </Space>
                </Card>
                <QuestionMap
                  questions={askList}
                  crt={ask}
                  onSelect={onSelect}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamSheetPage;
