import {
  addExam,
  updateExam,
  countQuestionByTagId,
  listQuestionByIdList,
} from "@/api/ExamController";
import QuestionPicker, {
  type QuestionPickerRef,
} from "@/views/components/QuestionPicker";
import Uploader from "@/views/components/Uploader";
import { isVoid } from "@/utils/format";
import { PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Switch,
  TreeSelect,
} from "antd";
import moment, { type Moment } from "moment";
import { useCallback, useEffect, useRef, useState, type FC } from "react";
import DepartmentSelector from "@/views/components/DepartmentTreeSelector";
import { useExam, type Exam } from ".";
import { QuestionTypes, questionTypes, type Question } from "../library";
import DepartmentMultipleSelector from "@/views/components/DepartmentMultipleSelector";

interface ExamInfo extends Exam {
  _time?: [Moment, Moment];
  _tagList?: any[];
  _questionList?: any[];
  _questionOption?: boolean;
  _questionTypeList?: any[];
}

interface ExamEditorProps {
  open?: boolean;
  onClose?: () => void;
  examInfo?: ExamInfo;
  refresh?: () => void;
}

const initQuestionTypeList = [
  { questionType: 1, questionNumber: 0, points: 0 },
  { questionType: 2, questionNumber: 0, points: 0 },
  { questionType: 3, questionNumber: 0, points: 0 },
];

const formRules: FormRules = {
  contestName: [{ required: true, message: "请输入考试名称" }],
  contestDescription: [{ required: true, message: "请输入考试描述" }],
  examDuration: [{ required: true, message: "请输入考试规定时长" }],
  passScore: [{ required: true, message: "请设置及格分值" }],
  _time: [{ required: true, message: "请设置考试开放时期" }],
  _tagList: [{ required: true, message: "请指定试题标签" }],
};

const ExamEditor: FC<ExamEditorProps> = ({
  open,
  onClose,
  examInfo,
  refresh,
}) => {
  const [form] = Form.useForm();
  const [formloading, setFormloading] = useState(false);
  const { tagMap, tagTree } = useExam();
  const examType = Form.useWatch("examType", form);
  const tags = Form.useWatch("_tagList", form);
  const _questionTypeList = Form.useWatch("_questionTypeList", form);
  const [counter, setCounter] = useState<string>();
  const [counting, setCounting] = useState(false);
  const [amount, setAmount] = useState<string>();
  // 习题集
  const [questionPickerOpen, setQuestionPickerOpen] = useState<boolean>(false);
  const [questionPicked, setQuestionPicked] = useState<Question[]>([]);
  const qRef = useRef<QuestionPickerRef>(null);

  const questionCounter = useCallback(() => {
    if (Array.isArray(tags) && tags.length > 0) {
      const tagIds = tags.map((t: any) => t.value);
      setCounting(true);
      countQuestionByTagId(tagIds)
        .then(({ data }) => {
          if (data.code) {
            const keys = Object.keys(data.data || {});

            if (Array.isArray(keys) && keys.length > 0) {
              const info = Object.keys(data.data)
                ?.map(
                  (k) =>
                    `${questionTypes.get(Number(k) as QuestionTypes)} ${
                      data.data[k]
                    }`
                )
                ?.join("， ");

              setCounter(info);
            } else {
              setCounter("没有符合要求的试题");
            }

            const mapper = new Map<number, any>();

            if (examInfo?.questionTypeList) {
              const { questionTypeList } = examInfo;
              for (const i of questionTypeList) {
                mapper.set(i.questionType, i);
              }
            }

            form.setFieldValue(
              "_questionTypeList",
              Object.keys(data.data)?.map((k) => ({
                questionType: Number(k),
                limit: data.data[k],
                questionNumber: mapper.get(Number(k))?.questionNumber || 0,
                points: mapper.get(Number(k))?.points || 0,
              }))
            );
          }
        })
        .finally(() => setCounting(false));
    } else {
      setCounter(undefined);
    }
  }, [examInfo, form, tags]);

  const queryQuestions = (
    rawList: Exclude<Exam["questionList"], undefined>
  ) => {
    const mapper = new Map<string, any>();
    const idList: string[] = [];

    for (const i of rawList) {
      idList.push(i.questionId);
      mapper.set(i.questionId, i);
    }

    listQuestionByIdList(idList).then(({ data }) => {
      if (data.code) {
        let picked;
        if (Array.isArray(data.data) && data.data.length > 0) {
          picked = data.data.map((q: Question) => ({
            ...q,
            f_points: mapper.get(q.n_question_id).questionPoints,
          }));
        } else {
          picked = undefined;
        }
        // console.log(picked);
        setQuestionPicked(picked);
      }
    });
  };

  const save = () => {
    form.validateFields().then((d) => {
      if (d._time) {
        const [startTime, endTime] = d._time.map((day: Moment) =>
          day.format("YYYY-MM-DD HH:mm:ss")
        );
        d.startTime = startTime;
        d.endTime = endTime;
        delete d._time;
      }

      if (d._tagList) {
        d.tagList = d._tagList.map((t: any) => t.value);
      }

      d.questionOption = d._questionOption ? 1 : 0;

      if (
        Array.isArray(d._questionTypeList) &&
        d._questionTypeList.length > 0
      ) {
        d.questionTypeList = d._questionTypeList.map((x: any) => ({
          questionType: x.questionType,
          questionNumber: x.questionNumber,
          points: x.points,
        }));
      }

      if (Array.isArray(d._questionList) && d._questionList.length > 0) {
        d.questionList = d._questionList.map((x: any) => ({
          questionId: x.n_question_id,
          questionPoints: x.f_points,
        }));
      }

      console.log(d);

      setFormloading(true);
      if (examInfo?.id) {
        d.id = examInfo.id;
        updateExam(d)
          .then(({ data }) => {
            if (data.code) {
              message.success("保存成功");
              onClose?.();
              refresh?.();
            }
          })
          .finally(() => setFormloading(false));
      } else {
        addExam(d)
          .then(({ data }) => {
            if (data.code) {
              message.success("添加成功");
              onClose?.();
              refresh?.();
            }
          })
          .finally(() => setFormloading(false));
      }
    });
  };

  useEffect(() => {
    questionCounter();
  }, [questionCounter]);

  useEffect(() => {
    if (open && examInfo) {
      examInfo._questionOption = examInfo.questionOption !== 0;
      examInfo._time = [moment(examInfo.startTime), moment(examInfo.endTime)];

      if (Array.isArray(examInfo.questionList)) {
        queryQuestions(examInfo.questionList);
      }

      if (tagMap) {
        examInfo._tagList = examInfo.tagList?.map((id) => ({
          value: id,
          label: tagMap.get(id)?.c_tag_name,
        }));
      }

      form.setFieldsValue(examInfo);
    } else {
      setCounter(undefined);
    }
  }, [examInfo, form, open, tagMap]);

  useEffect(() => {
    // console.log(questionPicked);
    if (open) {
      const _questionList = questionPicked.map((x, i) => ({
        c_question_name: x.c_question_name,
        n_question_id: x.n_question_id,
        n_question_type: x.n_question_type,
        f_points: x.f_points,
        order: i,
      }));

      form.setFieldValue("_questionList", _questionList);
    }
  }, [form, open, questionPicked]);

  useEffect(() => {
    if (Array.isArray(_questionTypeList) && _questionTypeList.length > 0) {
      let count = 0,
        total = 0;
      for (const i of _questionTypeList) {
        count += i.questionNumber;
        total += i.questionNumber * (i.points || 0);
      }

      setAmount(`选择 ${count} 题，总计 ${total} 分`);
    } else {
      setAmount(undefined);
    }
  }, [_questionTypeList]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={examInfo ? "编辑考试" : "创建考试"}
      footer={null}
      destroyOnClose
      width={800}
      afterClose={() => {
        form.resetFields();
        setQuestionPicked([]);
      }}
      maskClosable={false}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        scrollToFirstError={{ behavior: "smooth" }}
        onFinish={save}
        data-cy="form"
      >
        <Form.Item label="考试名称" name="examName" rules={formRules.examName}>
          <Input
            placeholder="输入名称"
            maxLength={15}
            showCount
            data-cy="examName"
          />
        </Form.Item>
        <Form.Item
          label="考试描述"
          name="examDescription"
          rules={formRules.examDescription}
        >
          <Input
            placeholder="输入描述"
            maxLength={20}
            showCount
            data-cy="examDescription"
          />
        </Form.Item>
        <Form.Item label="考试开放时期" name="_time" rules={formRules._time}>
          <DatePicker.RangePicker showTime />
        </Form.Item>
        <Form.Item
          label="考试时长"
          name="examDuration"
          rules={formRules.examDuration}
        >
          <InputNumber
            min={0}
            max={9999}
            placeholder="输入考试时长"
            data-cy="examDuration"
            addonAfter="分钟"
          />
        </Form.Item>
        <Form.Item
          label="及格分值"
          name="passScore"
          rules={formRules.passScore}
        >
          <InputNumber min={0} placeholder="分值" data-cy="passScore" />
        </Form.Item>
        {/* <Row gutter={4}>
          <Col span={12}>
            <Form.Item label="封面图" name="examBanner" labelCol={{ span: 12 }}>
              <Uploader keyName="courseFile" data-cy="examBanner" />
            </Form.Item>
          </Col>
        </Row> */}
        <Form.Item
          label="考试次数上限"
          name="examNumber"
          tooltip="单人参与考试的次数上限"
        >
          <InputNumber
            min={0}
            max={9999}
            placeholder="设置次数上限（0-9999）"
            data-cy="examNumber"
            addonAfter="次/人"
          />
        </Form.Item>
        <Form.Item
          label="考试范围"
          name="groupList"
          tooltip="指定组织内的用户对此考试的可见性。不指定时，考试对所有用户可见"
        >
          <DepartmentMultipleSelector />
        </Form.Item>
        <Form.Item
          label="选项乱序"
          name="_questionOption"
          valuePropName="checked"
          tooltip="开启后，将打乱题集中所有选择题的选项标号"
        >
          <Switch />
        </Form.Item>
        <Form.Item label="选题模式" name="examType" initialValue={1}>
          <Radio.Group
            options={[
              { value: 1, label: "随机试题" },
              { value: 2, label: "固定试题" },
            ]}
          />
        </Form.Item>
        {examType === 1 ? (
          <>
            <Form.Item
              label="指定标签"
              name="_tagList"
              rules={formRules._tagList}
            >
              <TreeSelect
                showSearch
                showArrow
                allowClear
                treeCheckable
                treeCheckStrictly
                placeholder="选择试题标签"
                maxTagCount="responsive"
                treeData={tagTree}
                treeDefaultExpandAll
                fieldNames={{ label: "c_tag_name", value: "n_tag_id" }}
                showCheckedStrategy={TreeSelect.SHOW_ALL}
              />
            </Form.Item>
            {counter ? (
              <Alert
                type="info"
                showIcon
                message={
                  <Space>
                    题库计数结果:
                    {counter}
                  </Space>
                }
                description={amount}
                className="mb-4"
              />
            ) : null}
            <Form.List
              name="_questionTypeList"
              initialValue={initQuestionTypeList}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row
                      key={field.key}
                      className="px-4 pt-8 pb-4 rounded mb-4 border-dashed border-gray-200 border-2"
                    >
                      <Col span={4}>
                        {questionTypes.get(
                          form.getFieldValue("_questionTypeList")[field.key][
                            "questionType"
                          ]
                        )}
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label="题目数量"
                          labelCol={{ span: 10 }}
                          name={[field.name, "questionNumber"]}
                          rules={[
                            {
                              required: true,
                              validator(_rule, value) {
                                if (isVoid(value)) {
                                  return Promise.reject(
                                    new Error("请输入题目数量")
                                  );
                                }

                                if (
                                  value >
                                  form.getFieldValue("_questionTypeList")[
                                    field.key
                                  ]["limit"]
                                ) {
                                  return Promise.reject(
                                    new Error(`
                                  数量超限(${
                                    form.getFieldValue("_questionTypeList")[
                                      field.key
                                    ]["limit"]
                                  })
                                  `)
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber placeholder="数量" min={0} />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label="每题分值"
                          labelCol={{ span: 10 }}
                          name={[field.name, "points"]}
                          rules={[{ required: true, message: "请输入分值" }]}
                        >
                          <InputNumber placeholder="分值" min={0} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </>
        ) : (
          <>
            <Form.Item name="_questionList" label="习题集" tooltip="考试习题集">
              <QuestionPicker.FormItem
                rescored
                onDelete={(qid) => qRef.current?.unpick(qid)}
              />
              {/* <QuestionPicker.TableForm
                rescored
                onDelete={(qid) => qRef.current?.unpick(qid)}
              /> */}
            </Form.Item>
            <Button
              type="dashed"
              className="mb-4"
              block
              icon={<PlusOutlined />}
              onClick={() => setQuestionPickerOpen(true)}
            >
              选择试题
            </Button>
          </>
        )}
        <Row>
          <Col span="16" offset="4">
            <Button
              block
              size="large"
              type="primary"
              loading={formloading}
              // onClick={save}
              htmlType="submit"
            >
              保存
            </Button>
          </Col>
        </Row>
      </Form>
      <QuestionPicker
        open={questionPickerOpen}
        onClose={() => setQuestionPickerOpen(false)}
        onChange={setQuestionPicked}
        picked={questionPicked}
        ref={qRef}
      />
    </Modal>
  );
};

export default ExamEditor;
