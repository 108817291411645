import { Button, Modal, Table, Tag } from "antd";
import { useCallback, useEffect, useState, type FC } from "react";
import {
  getScoreVideoById,
  listAllByParams,
  listAllByParamsByLogin,
} from "../../../api/LabSettingController";
import { useLocale } from "../../../hooks/useLocale";
import { durationFormat } from "../../../utils/format";
import i18n from "./i18n";

export interface VideoScoreRecordModalProps {
  open?: boolean;
  setOpen?: (visible: boolean) => void;
  params?: {
    userId?: number;
    labId: number;
    userName?: string;
  };
}

const initPagination = {
  page: 1,
  rows: 10,
};

const VideoScoreRecordModal: FC<VideoScoreRecordModalProps> = ({
  open,
  setOpen,
  params,
}) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const [detailOpen, setDetailOpen] = useState(false);
  const [recordId, setRecordId] = useState<string>();
  const { t } = useLocale(i18n);

  const getTableData = useCallback(async () => {
    const imap = () => {
      return params?.userId ? listAllByParams : listAllByParamsByLogin;
    };

    setLoading(true);
    imap()({ ...tablePagenation, ...params })
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, tablePagenation]);

  const openDetail = (id: string) => {
    setRecordId(id);
    setDetailOpen(true);
  };

  useEffect(() => {
    if (open) getTableData();
  }, [getTableData, open]);

  const modalTitle = params?.userName
    ? `${params.userName} ${t("declare.results.delearnrecord")}`
    : t("declare.results.learnrecord");

  return (
    <Modal
      title={modalTitle}
      width={800}
      open={open}
      footer={null}
      destroyOnClose
      onCancel={() => setOpen?.(false)}
    >
      <Table
        size="small"
        bordered
        rowKey="id"
        dataSource={tableData}
        loading={loading}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: ["bottomCenter"],
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          total,
          showTotal: (total) =>
            `${t("table.pagination.all")} ${total} ${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        columns={[
          {
            title: t("declare.results.starttime"),
            dataIndex: "createTime",
            align: "center",
            width: 120,
          },
          {
            title: t("declare.results.answer"),
            dataIndex: "questionDetail",
            align: "center",
            width: 110,
          },
          {
            title: t("declare.results.duration"),
            dataIndex: "watchDuration",
            align: "center",
            width: 120,
            render: (val) => durationFormat(val * 1000),
          },

          {
            title: t("declare.results.effectiveness"),
            dataIndex: "isValid",
            align: "center",
            width: 120,
            render(val) {
              return val === 1 ? (
                <Tag color="#87d068">{t("declare.results.effective")}</Tag>
              ) : (
                <Tag color="#cd201f">{t("declare.results.invalid")}</Tag>
              );
            },
          },
          {
            title: t("declare.results.answerdetails"),
            width: 50,
            align: "center",
            render: (_val, row) => (
              <Button onClick={() => openDetail(row.id)}>
                {t("declare.results.answerdetails")}
              </Button>
            ),
          },
        ]}
      />
      <AnswerDetailModal
        open={detailOpen}
        setOpen={setDetailOpen}
        recordId={recordId}
      />
    </Modal>
  );
};

interface AnswerDetailModalProps {
  open?: boolean;
  setOpen?: (visible: boolean) => void;
  recordId?: string;
}

const AnswerDetailModal: FC<AnswerDetailModalProps> = ({
  open,
  setOpen,
  recordId,
}) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const { t } = useLocale(i18n);

  const getRecord = useCallback(() => {
    setLoading(true);
    getScoreVideoById({ id: recordId })
      .then(({ data }) => {
        if (data.code) {
          const { questionList, scoreVideoQuestionList } = data.data;

          for (let i of scoreVideoQuestionList) {
            const item = (questionList as any[]).find(
              (x) => x.n_question_id === i.questionId
            );
            if (item) item.answerSheet = i;
          }

          setTableData(questionList);
        }
      })
      .finally(() => setLoading(false));
  }, [recordId]);

  useEffect(() => {
    if (open) getRecord();
  }, [getRecord, open]);

  return (
    <Modal
      title={t("declare.results.answerdetails")}
      width={1000}
      open={open}
      footer={null}
      destroyOnClose
      onCancel={() => setOpen?.(false)}
    >
      <Table
        size="small"
        bordered
        rowKey="n_question_id"
        dataSource={tableData}
        loading={loading}
        scroll={{ y: 800 }}
        pagination={false}
        columns={[
          {
            title: t("declare.results.stem"),
            dataIndex: "c_question_name",
            align: "center",
            width: 120,
          },
          {
            title: t("declare.results.correct"),
            align: "center",
            width: 110,
            render: (_val, row) =>
              row.answerSheet?.mtAnswer
                ?.split("#&")
                .map((x: string) => <div key={x}>{x}</div>),
          },
          {
            title: t("declare.results.written"),
            align: "center",
            width: 120,
            render: (_val, row) =>
              row.answerSheet?.userAnswer
                ?.split("#&")
                .map((x: string) => <div key={x}>{x}</div>),
          },
          {
            title: t("declare.results.decision"),
            dataIndex: "watchDuration",
            align: "center",
            width: 120,
            render(_val, row) {
              if (row.answerSheet) {
                return row.answerSheet.answerResult ? (
                  <Tag color="#87d068">{t("declare.results.correctly")}</Tag>
                ) : (
                  <Tag color="#cd201f">{t("declare.results.wrong")}</Tag>
                );
              }
              return <Tag>{t("declare.results.noanswer")}</Tag>;
            },
          },

          {
            title: t("declare.results.answertime"),
            dataIndex: "isValid",
            align: "center",
            width: 120,
            render: (_val, row) =>
              row.answerSheet ? row.answerSheet.createTime : "-",
          },
        ]}
      />
    </Modal>
  );
};

export default VideoScoreRecordModal;

export { AnswerDetailModal };
