const i18n = {
  "en-US": {
    "declare.item.firstlevel":
      "The first level navigation menu has been created",
    "declare.item.delmenu": "Delete navigation menu",
    "declare.item.delcontent":
      "after, the content will also be deleted. Are you sure?",
    "declare.item.delsuccess": "Delete succeeded",
    "declare.item.savevideo": "Video saved successfully",
    "declare.item.language": "language version",
    "declare.item.guidevideo": "Guide video",
    "declare.item.introductionvideo": "Introduction video",
    "declare.item.setting": "Navigation menu and content setting",
    "declare.item.stepset": "Experimental Step Settings",
    "declare.item.title": "Title",
    "declare.item.contenttype": "Content type",
    "declare.item.creatmenu": "Create navigation menu",
    "declare.item.modifymenu": "Navigation menu modified",
    "declare.item.delete": "Delete",
    "declare.item.save": "Save",
    "declare.item.experiment": "Experiments list not previewed yet",
    "declare.item.online": "Online Q&A is not previewed yet",
    "declare.item.unableparse": "Unable to parse tips JSON",
    "declare.item.secondlevel":
      "The second level navigation menu has been created",
    "declare.item.seclevelmodify":
      "The second level navigation menu has been modified",
    "declare.item.delsecondmenu": "Delete the secondary navigation menu",
    "declare.item.create": "Create",
    "declare.item.richcontent": "Rich text content",
    "declare.item.tiptitle": "Tip Title",
    "declare.item.entertiptitle": "Please enter the Tip Title",
    "declare.item.tipcontent": "Tip Content",
    "declare.item.entertipcontent": "Please enter the Tip Content",
    "declare.item.articletitle": "Article Title",
    "declare.item.enterarticletitle": "Please enter the Article Title",
    "declare.item.modifycontent":
      "The navigation menu content has been modified",
    "declare.item.clearcontent": "Clear content",
    "declare.item.resetvalue": "Unable to parse tips JSON, reset field value",
    "declare.item.clear": "Clear",
    "declare.item.uploadvideo": "Upload video file",
    "declare.item.uploadfail": "Upload failed",
    "declare.item.uploadcomplete": "Upload completed",
    "declare.item.selectlist": "You need to select the list item first",
    "declare.item.deletion": "Confirm the deletion",
    "declare.item.willdelete": "Will delete",
    "declare.item.delstepset": "step setting, are you sure?",
    "declare.item.operationsuccess":
      "The operation succeeded. The step Settings have been deleted",
    "declare.item.modifysuccess": "Modified successfully",
    "declare.item.addsuccess": "Add successfully",
    "declare.item.addstep": "Add step",
    "declare.item.batchoperation": "Batch operation",
    "declare.item.bindexperiment": "Binding experiment",
    "declare.item.unknown": "Unknown experiment",
    "declare.item.startend": "Start and end steps",
    "declare.item.stepobjective": "Step objective requirement",
    "declare.item.appropriate": "Appropriate time for steps (minutes)",
    "declare.item.scoringmodel": "Goal achievement degree scoring model",
    "declare.item.stepmark": "Step full mark",
    "declare.item.operation": "Operation",
    "declare.item.edit": "Edit",
    "declare.item.suredel":
      "Are you sure you want to delete this experimental step?",
    "declare.item.deleted":
      "The operation succeeded. The step Settings have been deleted",
    "declare.item.modifystep": "Modify the experiment steps",
    "declare.item.createstep": "Create experiment steps",
    "declare.item.selexperiment": "Select the step bound experiment",
    "declare.item.startstep": "Starting step",
    "declare.item.endstep": "Termination step",
    "declare.item.enterstepobjective": "Please enter step goal requirements",
    "declare.item.minute": "minute",
    "declare.item.enterappropriate":
      "Please enter the appropriate time for the step",
    "declare.item.model": "Scoring model",
    "declare.item.entermodel":
      "Please enter the goal achievement scoring model",
    "declare.item.enterpoint":
      "Please enter the maximum number of points available for the step",
  },
  "zh-CN": {
    "declare.item.firstlevel": "已创建一级导航菜单",
    "declare.item.delmenu": "删除导航菜单",
    "declare.item.delcontent": "后，内容也将一并删除，确定吗？",
    "declare.item.delsuccess": "删除成功",
    "declare.item.savevideo": "保存视频成功",
    "declare.item.language": "语言版本",
    "declare.item.guidevideo": "引导视频",
    "declare.item.introductionvideo": "简介视频",
    "declare.item.setting": "导航菜单与内容设置",
    "declare.item.stepset": "实验步骤设置",
    "declare.item.title": "标题",
    "declare.item.contenttype": "内容类型",
    "declare.item.creatmenu": "创建导航菜单",
    "declare.item.modifymenu": "导航菜单已修改",
    "declare.item.delete": "删除",
    "declare.item.save": "保存",
    "declare.item.experiment": "实验列表暂无预览",
    "declare.item.online": "在线问答暂无预览",
    "declare.item.unableparse": "无法解析tips JSON",
    "declare.item.secondlevel": "已创建二级导航菜单",
    "declare.item.seclevelmodify": "已修改二级导航菜单",
    "declare.item.delsecondmenu": "删除二级导航菜单",
    "declare.item.create": "创建",
    "declare.item.richcontent": "富文本内容",
    "declare.item.tiptitle": "提示标题",
    "declare.item.entertiptitle": "请输入提示标题",
    "declare.item.tipcontent": "提示内容",
    "declare.item.entertipcontent": "请输入提示内容",
    "declare.item.articletitle": "文章标题",
    "declare.item.enterarticletitle": "请选择文章标题",
    "declare.item.modifycontent": "已修改导航菜单内容",
    "declare.item.clearcontent": "清除内容",
    "declare.item.resetvalue": "无法解析tips JSON，已重置字段值",
    "declare.item.clear": "清除",
    "declare.item.uploadvideo": "上传视频文件",
    "declare.item.uploadfail": "上传失败",
    "declare.item.uploadcomplete": "已完成上传",
    "declare.item.selectlist": "需要先选择列表项",
    "declare.item.deletion": "请确认删除操作",
    "declare.item.willdelete": "将删除",
    "declare.item.delstepset": "步骤设置，确定吗？",
    "declare.item.operationsuccess": "操作成功，已删除步骤设置",
    "declare.item.modifysuccess": "修改成功",
    "declare.item.addsuccess": "添加成功",
    "declare.item.addstep": "添加步骤",
    "declare.item.batchoperation": "批量操作",
    "declare.item.bindexperiment": "绑定实验",
    "declare.item.unknown": "未知实验",
    "declare.item.startend": "起止步骤",
    "declare.item.stepobjective": "步骤目标要求",
    "declare.item.appropriate": "步骤合理用时（分）",
    "declare.item.scoringmodel": "目标达成度赋分模型",
    "declare.item.stepmark": "步骤满分",
    "declare.item.operation": "操作",
    "declare.item.edit": "编辑",
    "declare.item.suredel": "确定要删除此实验步骤吗？",
    "declare.item.deleted": "操作成功，已删除步骤设置",
    "declare.item.modifystep": "修改实验步骤",
    "declare.item.createstep": "创建实验步骤",
    "declare.item.selexperiment": "选择步骤绑定的实验",
    "declare.item.startstep": "起始步骤",
    "declare.item.endstep": "终止步骤",
    "declare.item.enterstepobjective": "请输入步骤目标要求",
    "declare.item.minute": "分钟",
    "declare.item.enterappropriate": "请输入步骤合理用时",
    "declare.item.model": "赋分模型",
    "declare.item.entermodel": "请输入目标达成度赋分模型",
    "declare.item.enterpoint": "请输入步骤最大可得分数",
  },
};

export default i18n;
