// webstorage存/取/删
const storage = [window.localStorage, window.sessionStorage];

const setItem = (name: string, item: object | string, isSession: 0 | 1 = 0) => {
  if (typeof item === "object") {
    storage[isSession].setItem(name, JSON.stringify(item));
  } else {
    storage[isSession].setItem(name, item);
  }
};

const getItem = (name: string, isSession: 0 | 1 = 0) => {
  const item = storage[isSession].getItem(name);

  if (item) {
    try {
      return JSON.parse(item);
    } catch (err) {}
  }
  return item;
};

const rmItem = (name: string, isSession: 0 | 1 = 0) => {
  storage[isSession].removeItem(name);
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};

export { setItem, getItem, rmItem, getCookie };
