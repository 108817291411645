import { queryDeptTreeByDeclare } from "@/api/NewDeptController";
import { type TreeSelectProps, TreeSelect } from "antd";
import { useEffect, useState, type FC } from "react";

interface DepartmentSelectorProps {
  value?: TreeSelectProps["value"];
  onChange?: TreeSelectProps["onChange"];
}

const DepartmentSelector: FC<DepartmentSelectorProps> = ({
  value,
  onChange,
}) => {
  const [treeData, setTreeData] = useState();

  const getTree = () => {
    queryDeptTreeByDeclare({ level: 3 }).then(({ data }) => {
      if (data?.code) {
        setTreeData(data.data);
      }
    });
  };

  useEffect(() => {
    getTree();
  }, []);

  return (
    <TreeSelect
      showSearch
      showArrow
      allowClear
      treeCheckable
      placeholder="选择组织"
      maxTagCount="responsive"
      treeData={treeData}
      treeDefaultExpandAll
      fieldNames={{ label: "name", value: "id" }}
      showCheckedStrategy={TreeSelect.SHOW_ALL}
      value={treeData && value ? value : undefined}
      onChange={onChange}
    />
  );
};

export default DepartmentSelector;
