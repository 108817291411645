import { DownloadOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Form, Space, Input, Button, Dropdown, Menu, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FC, useCallback, useEffect, useState } from "react";
import { listMaxByParams } from "../../../api/LabSettingController";
import { listMaxByParamsExcel } from "../../../api/NewScoreController";
import { useLocale } from "../../../hooks/useLocale";
import { downloadByURL } from "../../../utils/download";
import { durationFormat } from "../../../utils/format";
import i18n from "./i18n";
import VideoScoreRecordModal, {
  type VideoScoreRecordModalProps,
} from "./VideoScoreRecordModal";

const initPagination = {
  page: 1,
  rows: 10,
};

const VideoScore: FC = () => {
  const { t } = useLocale(i18n);
  const [tableData, setTableData] = useState<any[]>([]);
  const [recordOpen, setRecordOpen] = useState(false);
  const [recordParams, setRecordParams] =
    useState<VideoScoreRecordModalProps["params"]>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const [queryForm] = useForm();

  const getTableData = useCallback(async () => {
    const params = queryForm.getFieldsValue();

    listMaxByParams({
      ...tablePagenation,
      ...params,
    })
      .then(({ data }) => {
        // console.log(data);
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [queryForm, tablePagenation]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div>
      <div className="mb-4">
        <Form layout="inline" form={queryForm}>
          <Space wrap>
            <Form.Item label={t("query.search")} name="search">
              <Input
                className="w-60"
                allowClear
                placeholder={t("query.search.placeholder")}
              />
            </Form.Item>
            <Button onClick={() => queryForm.resetFields()}>
              {t("query.search.clear")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setTablePagenation({ page: 1, rows: 10 })}
            >
              {t("query.query")}
            </Button>
          </Space>
        </Form>
      </div>
      <Space className="table-tool-bar">
        <Space>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => downloadByURL(listMaxByParamsExcel)}
          >
            {t("declare.results.exportrecord")}
          </Button>
        </Space>
      </Space>
      <Table
        size="small"
        rowKey="id"
        bordered
        scroll={{ x: 1700 }}
        loading={loading}
        dataSource={tableData}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: ["bottomCenter"],
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          total,
          showTotal: (total) =>
            `${t("table.pagination.all")}${total}${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        columns={[
          {
            title: t("table.index"),
            width: 70,
            fixed: "left",
            align: "center",
            render: (_val, _row, index) => index + 1,
          },
          {
            title: t("user.attr.loginId"),
            dataIndex: "loginID",
            fixed: "left",
            width: 120,
          },
          {
            title: t("user.attr.name"),
            dataIndex: "userName",
            width: 120,
          },
          {
            title: t("declare.results.learnnum"),
            dataIndex: "labCount",
          },
          {
            title: t("declare.results.maximumduration"),
            dataIndex: "watchDuration",
            render: (val) => durationFormat(val * 1000),
          },
          {
            title: t("declare.results.maximumschedule"),
            dataIndex: "percentage",
          },
          {
            title: t("user.attr.yid"),
            dataIndex: "yName",
          },
          {
            title: t("user.attr.zid"),
            dataIndex: "zName",
          },
          {
            title: t("user.attr.bid"),
            dataIndex: "bName",
          },
          {
            title: t("table.head.kcname"),
            dataIndex: "kcName",
          },
          {
            title: t("table.head.labname"),
            dataIndex: "labName",
          },
          {
            title: t("declare.results.learnrecord"),
            align: "center",
            render: (_val, row) => (
              <Button
                icon={<OrderedListOutlined />}
                onClick={() => {
                  setRecordParams({
                    userId: row.userId,
                    labId: row.labId,
                    userName: row.userName,
                  });
                  setRecordOpen(true);
                }}
              >
                {t("declare.results.learnrecord")}
              </Button>
            ),
          },
        ]}
      />
      <VideoScoreRecordModal
        open={recordOpen}
        setOpen={setRecordOpen}
        params={recordParams}
      />
    </div>
  );
};

export default VideoScore;
