import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  showNews,
  addNews,
  deletenewsbatch,
  updatenews,
  findnews,
} from "../../../api/NewNewsController";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useToggle from "../../../hooks/useToggle";
import RichEditor from "../../components/RichEditor";
import { cleanObject } from "../../../utils/format";
import type { Rule } from "antd/lib/form";
import { useLocale } from "../../../hooks/useLocale";
import moment from "moment";
import i18n from "./i18n";

const { Option } = Select;
const { useForm } = Form;

const formRoles: { [key in string]: Rule[] } = {
  name: [{ required: true, message: "请输入文章标题" }],
  info: [
    {
      required: true,
      validateTrigger: "blur",
      async validator(rule, value) {
        if (value === "<p><br></p>") {
          return Promise.reject("请输入内容");
        }
        console.log(rule, value);
      },
    },
  ],
};

const ArticleManagePage = () => {
  const { t } = useLocale(i18n);
  const [queryForm] = useForm();
  // const [search, setSearch] = useState<undefined | string>();
  const [loading, setLoading] = useState(false);
  const [checkShow, setCheckShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [editformId, setEditformId] = useState<undefined | number>();
  const [formloading, setFormloading] = useState(false);
  const [newsList, setNewsList] = useState<any[]>([]);
  const [oneList, setOneList] = useState<any>({});
  const [oneType, setOneType] = useState<any>();
  const [searchType, setSearchType] = useState<undefined | string>();
  const [multipleEdit, setMultipleEdit] = useToggle(false);
  const [xUpdateTime, setXUpdateTime] = useState<Date>();
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const [multipleSelectedRows, setMultipleSelectedRows] = useState<React.Key[]>(
    []
  );
  const [rich, setRich] = useState<string>();

  const tableRowSelection = {
    onChange: (selectedRowKeys: React.Key[], _selectedRows: any) => {
      setMultipleSelectedRows(selectedRowKeys);
    },
  };
  const cancelTabRowSelection = () => {
    setMultipleEdit();
    setMultipleSelectedRows([]);
  };
  const deleteTableRows = () => {
    if (multipleSelectedRows.length === 0) {
      message.error(t("declare.item.selectlist"));
      return;
    }
    console.log(multipleSelectedRows);

    Modal.confirm({
      title: t("declare.item.deletion"),
      content: t("declare.item.delarticles"),
      async onOk() {
        const { data } = await deletenewsbatch({
          idList: multipleSelectedRows.toString(),
        });
        console.log(data);
        if (data.code) {
          message.success(data.msg);
          setTablePagenation({ page: 1, rows: 10 });
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  const getNewsList = useCallback(async () => {
    setLoading(true);
    const { data } = await showNews({
      ...queryForm.getFieldsValue(),
      ...tablePagenation,
    }).finally(() => setLoading(false));
    if (data.code) {
      setNewsList(data.data.rows);
      setTotal(data.data.records);
    }
  }, [queryForm, tablePagenation]);

  useEffect(() => {
    getNewsList();
  }, [getNewsList, tablePagenation]);

  const onSearch = () => {
    setTablePagenation({ page: 1, rows: 10 });
  };

  const [visible, setVisible] = useState(false);
  const [changevisible, setChangevisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setChangevisible(false);
  };

  //新增文章
  const onCommit = () => {
    form
      .validateFields()
      .then(async (d) => {
        const formdata = cleanObject({
          ...d,
          updateDate: d.updateDate?.format("YYYY-MM-DD HH:mm:ss"),
          top: d.top !== 0 ? true : false,
        });
        console.log(d, formdata);
        setFormloading(true);
        const { data } = await addNews(formdata);
        setVisible(false);
        if (data.code) {
          getNewsList();
          message.success(t("declare.item.postsuccess"));
          form.resetFields();
          setFormloading(false);
        } else {
          setFormloading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(t("declare.item.checkinvalid"));
      });
  };

  //修改文章
  const commitEditform = () => {
    editform
      .validateFields()
      .then(async (d) => {
        const { data } = await updatenews({
          ...d,
          id: editformId,
          updateDate: d.updateDate?.format("YYYY-MM-DD HH:mm:ss"),
          top: d.top !== 0 ? true : false,
        });
        console.log(d);

        setChangevisible(false);
        if (!data.code) {
          message.error(t("declare.item.failed"));
          return;
        }
        getNewsList();
        message.success(t("declare.item.success"));
      })
      .catch((err) => {
        // setTableLoading(false);
      });
  };

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.system"), t("menu.system.article")]} />
      <div className="main-content-inner">
        <div className="query-form bg-white p-4 rounded mb-4">
          <Form layout="inline" form={queryForm}>
            <Form.Item label={t("declare.item.title")} name="search">
              <Input
                placeholder={t("declare.item.entertitle")}
                allowClear
                style={{ width: "190px" }}
                onPressEnter={onSearch}
                // onChange={({ target: { value } }) => {
                //   setSearch(value);
                //   if (!value) {
                //     setTablePagenation({ page: 1, rows: 10 });
                //   }
                // }}
              />
            </Form.Item>
            <Form.Item label={t("declare.item.type")} name="type">
              <Select
                placeholder={t("declare.item.selectType")}
                style={{ width: "190px" }}
                allowClear
                onClear={() => {
                  setTablePagenation({ page: 1, rows: 10 });
                }}
                onChange={(val) => {
                  setSearchType(val);
                }}
              >
                <Option value="1">{t("declare.item.display")}</Option>
                <Option value="2">{t("declare.item.news")}</Option>
                <Option value="3">{t("declare.item.notice")}</Option>
              </Select>
            </Form.Item>
            <Space>
              <Button onClick={() => queryForm.resetFields()}>
                {t("declare.item.remove")}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onSearch}>
                {t("declare.item.query")}
              </Button>
            </Space>
          </Form>
        </div>
        <div className="bg-white p-4 rounded">
          <Space className="mb-4 flex items-center justify-between">
            <Space>
              <Button type="primary" onClick={showDrawer}>
                {t("declare.item.addArtical")}
              </Button>
            </Space>
            <Space>
              {multipleEdit ? (
                <>
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={deleteTableRows}
                  >
                    {t("declare.item.delete")}
                  </Button>
                  <Button
                    icon={
                      <FontAwesomeIcon
                        icon={["fas", "arrow-rotate-right"]}
                        className="mr-2"
                      />
                    }
                    onClick={cancelTabRowSelection}
                  >
                    {t("declare.item.cancel")}
                  </Button>
                </>
              ) : (
                <Button icon={<FormOutlined />} onClick={setMultipleEdit}>
                  {t("declare.item.operation")}
                </Button>
              )}
            </Space>
          </Space>
          <Table
            size="middle"
            rowKey="id"
            bordered
            dataSource={newsList}
            loading={loading}
            rowSelection={
              multipleEdit
                ? { type: "checkbox", ...tableRowSelection }
                : undefined
            }
            pagination={{
              size: "default",
              showQuickJumper: true,
              showSizeChanger: true,
              position: ["bottomCenter"],
              current: tablePagenation.page,
              pageSize: tablePagenation.rows,
              total,
              showTotal: (total) =>
                `${t("table.pagination.all")}${total}${t(
                  "table.pagination.total"
                )}`,
              onChange: (page, rows) => {
                setTablePagenation({
                  page,
                  rows,
                });
              },
            }}
            columns={[
              {
                title: t("table.index"),
                width: 50,
                fixed: "left",
                align: "center",
                render: (val, row, index) => index + 1,
              },
              {
                title: t("declare.item.title"),
                dataIndex: "name",
                width: 400,
              },
              {
                title: t("declare.item.author"),
                dataIndex: "author",
                width: 150,
              },
              {
                title: t("declare.item.type"),
                dataIndex: "type",
                width: 150,
                render(val, row) {
                  if (val === "1") {
                    return t("declare.item.display");
                  } else if (val === "2") {
                    return t("declare.item.news");
                  } else if (val === "3") {
                    return t("declare.item.notice");
                  }
                },
              },
              {
                title: t("declare.item.release"),
                dataIndex: "updateDate",
                width: 200,
              },
              {
                title: t("declare.item.top"),
                dataIndex: "show",
                width: 150,
                render(val, row) {
                  if (val !== 0) {
                    return t("declare.item.top");
                  } else if (val === 0) {
                    return t("declare.item.notop");
                  }
                },
              },
              {
                title: t("declare.item.operation"),
                width: 200,
                align: "center",
                render(value, row: any) {
                  return (
                    <Space>
                      <Button
                        size="small"
                        onClick={() => {
                          editform.setFieldsValue({
                            id: row.id,
                            name: row.name,
                            introduce: row.introduce,
                            info: row.info,
                            type: row.type,
                            top: row.show,
                            updateDate: moment(row.updateDate),
                          });
                          setXUpdateTime(row.updateDate);
                          setEditformId(row.id);
                          setChangevisible(true);
                        }}
                      >
                        {t("declare.item.modify")}
                      </Button>
                      <Button
                        size="small"
                        onClick={async () => {
                          setCheckShow(true);
                          setEditformId(row.id);
                          const { data } = await findnews({
                            id: row.id,
                          });
                          setOneList(data.data);
                          if (data.data.type === "1") {
                            data.data.type = t("declare.item.display");
                          } else if (data.data.type === "2") {
                            data.data.type = t("declare.item.news");
                          } else {
                            data.data.type = t("declare.item.notice");
                          }
                          setOneType(data.data.type);
                        }}
                      >
                        {t("declare.item.check")}
                      </Button>
                    </Space>
                  );
                },
              },
            ]}
          ></Table>
        </div>
      </div>
      {/* 添加文章抽屉 */}
      <Drawer
        title={t("declare.item.addArtical")}
        width="60%"
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <Form
            labelCol={{ span: 2 }}
            form={form}
            initialValues={{ type: "1" }}
          >
            <Form.Item
              label={t("declare.item.title")}
              name="name"
              rules={formRoles.name}
            >
              <Input placeholder={t("declare.item.entertitle")} />
            </Form.Item>
            <Form.Item
              label={t("declare.item.top")}
              name="top"
              valuePropName="checked"
              initialValue={0}
              normalize={(val) => Number(val)}
            >
              <Switch />
            </Form.Item>
            <Form.Item label={t("declare.item.type")} name="type">
              <Select
                style={{ width: "190px" }}
                allowClear
                onClear={() => {
                  setTablePagenation({ page: 1, rows: 10 });
                }}
                onChange={(val) => {
                  // setXRightId(val);
                }}
              >
                <Option value="1">{t("declare.item.display")}</Option>
                <Option value="2">{t("declare.item.news")}</Option>
                <Option value="3">{t("declare.item.notice")}</Option>
              </Select>
            </Form.Item>
            <Form.Item name="updateDate" label={t("declare.item.release")}>
              <DatePicker showTime />
            </Form.Item>
            <Form.Item label={t("declare.item.introduction")} name="introduce">
              <TextArea
                rows={5}
                placeholder={t("declare.item.enterintroduction")}
                maxLength={200}
              />
            </Form.Item>
            <Form.Item
              label={t("declare.item.text")}
              name="info"
              rules={formRoles.info}
            >
              <RichEditor maxLength={500} value={rich} onChange={setRich} />
            </Form.Item>
          </Form>
          <Row>
            <Col span="6" offset="9">
              <Button
                size="large"
                block
                type="primary"
                style={{ cursor: "pointer" }}
                loading={formloading}
                onClick={onCommit}
              >
                {t("declare.item.submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>

      {/* 修改文章抽屉 */}
      <Drawer
        title={t("declare.item.edit")}
        width="60%"
        placement="right"
        onClose={onClose}
        open={changevisible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <Form
            labelCol={{ span: 2 }}
            form={editform}
            initialValues={{
              type: "1",
              updateDate: moment(xUpdateTime),
            }}
          >
            <Form.Item label={t("declare.item.title")} name="name">
              <Input placeholder={t("declare.item.entertitle")} />
            </Form.Item>
            <Form.Item label={t("declare.item.introduction")} name="introduce">
              <TextArea
                rows={5}
                placeholder={t("declare.item.enterintroduction")}
                maxLength={200}
              ></TextArea>
            </Form.Item>
            <Form.Item label={t("declare.item.text")} name="info">
              <RichEditor maxLength={500} value={rich} onChange={setRich} />
            </Form.Item>
            <Form.Item
              label={t("declare.item.top")}
              name="top"
              valuePropName="checked"
              initialValue={0}
              normalize={(val) => Number(val)}
            >
              <Switch />
            </Form.Item>
            <Form.Item name="updateDate" label={t("declare.item.release")}>
              <DatePicker showTime />
            </Form.Item>
            <Form.Item label={t("declare.item.type")} name="type">
              <Select
                style={{ width: "190px" }}
                allowClear
                onClear={() => {
                  setTablePagenation({ page: 1, rows: 10 });
                }}
                onChange={(val) => {
                  // setXRightId(val);
                }}
              >
                <Option value="1">{t("declare.item.display")}</Option>
                <Option value="2">{t("declare.item.news")}</Option>
                <Option value="3">{t("declare.item.notice")}</Option>
              </Select>
            </Form.Item>
          </Form>
          <Row>
            <Col span="6" offset="9">
              <Button
                size="large"
                block
                type="primary"
                style={{ cursor: "pointer" }}
                loading={formloading}
                onClick={commitEditform}
              >
                {t("declare.item.submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>

      {/* 查看文章 modal */}
      <Modal
        title={t("declare.item.details")}
        open={checkShow}
        footer={null}
        width={820}
        centered
        destroyOnClose
        onCancel={() => {
          setCheckShow(false);
        }}
      >
        <div className="">
          <h1 className="text-center">{oneList.name}</h1>
          <div className="p-4 rounded">
            <Space
              className="mb-4 flex items-center justify-between"
              style={{ borderBottom: "1px solid #DDD" }}
            >
              <Space>{oneList.createDate}</Space>
              <Space>{oneType}</Space>
            </Space>
          </div>
          <p className="px-4 pb-5 rounded">{oneList.info}</p>
        </div>
      </Modal>
    </div>
  );
};

export default ArticleManagePage;
