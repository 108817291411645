// 常用的正则规则
const IDcard =
  /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/; // 身份证
const mobile = /^1([3|4|5|7|8|])\d{9}$/; // 手机号码
const telephone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/; // 固定电话
const num = /^[0-9]*$/; // 数字
const phoneNo =
  /(^1([3|4|5|7|8|])\d{9}$)|(^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$)/; // 电话或者手机
const isNumAlpha = /^[0-9A-Za-z]*$/; // 字母或数字
const isAlpha = /^[a-zA-Z]*$/; // 是否字母
const isNumAlphaCn = /^[0-9a-zA-Z\u4E00-\uFA29]*$/; // 是否数字或字母或汉字
const isPostCode = /^[\d-]*$/i; // 是否邮编
const isNumAlphaUline = /^[0-9a-zA-Z_]*$/; // 是否数字、字母或下划线
const isNumAndThanZero = /^([1-9]\d*(\.\d+)?|0)$/; // 是否为整数且大于0/^[1-9]\d*(\.\d+)?$/
const isNormalEncode = /^(\w||[\u4e00-\u9fa5]){0,}$/; // 是否为非特殊字符（包括数字字母下划线中文）
const isInt = /^-?\d+$/; // 整数
const isChinese = /^[\u4e00-\u9fa5]{2,8}$/;
const isNozeroNumber = /^\+?[1-9]\d*$/; // 大于零的正整数
const float = /^\d+(\.?|(\.\d+)?)$/; // 匹配正整数或者小数 或者0.这个特殊值
const URLReg =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
// 高强密码：8位以上，且同时包含大、小字母、数字、字符
const strongPuzzle =
  /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[`~·!@#$%^&*()\-+=/.]{1,}).*$/;
// 中强密码：8位以上 且包含数字和大、小字母、字符中的1种
const mediumPuzzle =
  /^.*(?=.{8,})(?=.*\d)(?=.*[a-zA-Z`~·!@#$%^&*()\-+=/.]{1,}).*$/;

export {
  IDcard,
  mobile,
  telephone,
  num,
  phoneNo,
  isNumAlpha,
  isAlpha,
  isNumAlphaCn,
  isPostCode,
  isNumAlphaUline,
  isNumAndThanZero,
  isNormalEncode,
  isInt,
  isChinese,
  isNozeroNumber,
  float,
  URLReg,
  strongPuzzle,
  mediumPuzzle,
};
