import req from "../configs/axios";

// 查询个人信息
const getOneUserByLogin = () => {
  return req.post("auth/NewUserController/getOneUserByLogin");
};

// 修改个人信息
const updateOneUserByLogin = (data: any) => {
  return req.post("auth/NewUserController/updateOneUserByLogin", data);
};

// 修改个人密码
const changeMyPwd = (data?: any) => {
  return req.post("auth/NewUserController/changeMyPwd", data);
};

// 查询用户列表
const listAllUserByParams = (data?: any) => {
  return req.post("auth/NewUserController/listAllUserByParams", data);
};

// 修改用户信息
const updateOneUserByUserId = (data: any) => {
  return req.post("auth/NewUserController/updateOneUserByUserId", data);
};

// 删除单个用户
const deleteOneUser = (data: any) => {
  return req.post("auth/NewUserController/deleteOneUser", data);
};

// 创建单个用户
const addOneUser = (data: any) => {
  return req.post("auth/NewUserController/addOneUser", data);
};

// 批量删除用户
const deleteBatchUser = (data: any[]) => {
  return req.post("auth/NewUserController/deleteBatchUser", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 批量修改用户信息
const updateBatchUser = (data: any) => {
  return req.post("auth/NewUserController/updateBatchUser", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//后台管理员查看提问列表
const listQaAllByParams = (data: any) => {
  return req.post("auth/NewUserController/listQaAllByParams", data);
};

//单条体温数据和回复列表
const getOneQaById = (data: any) => {
  return req.post("auth/NewUserController/getOneQaById", data);
};

//新增一级评论
const addOneCommentFirst = (data: any) => {
  return req.post("auth/NewUserController/addOneCommentFirst", data);
};

//提问列表-删除
const deleteQaById = (data: any) => {
  return req.post("auth/NewUserController/deleteQaById", data);
};

//新增提问
const addOneQa = (data: any) => {
  return req.post("auth/NewUserController/addOneQa", data);
};

//登录人自己的提问列表
const listQaAllByLogin = (data: any) => {
  return req.post("auth/NewUserController/listQaAllByLogin", data);
};

//关闭提问
const updateOneQaById = (data: any) => {
  return req.post("auth/NewUserController/updateOneQaById", data);
};

//登录人查未读提醒
const listCommentByHaveReadAndUserId = (data: any) => {
  return req.post(
    "auth/NewUserController/listCommentByHaveReadAndUserId",
    data
  );
};

//查询登录人日志
const showMyLog = (data?: any) => {
  return req.post("auth/NewUserController/showMyLog", data);
};

export {
  changeMyPwd,
  getOneUserByLogin,
  updateOneUserByLogin,
  listAllUserByParams,
  updateOneUserByUserId,
  deleteOneUser,
  addOneUser,
  deleteBatchUser,
  updateBatchUser,
  listQaAllByParams,
  getOneQaById,
  addOneCommentFirst,
  deleteQaById,
  addOneQa,
  listQaAllByLogin,
  updateOneQaById,
  listCommentByHaveReadAndUserId,
  showMyLog,
};
