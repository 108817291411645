const i18n = {
  "en-US": {
    "experiment.item.name": "Name of Experiment",
    "experiment.item.entername": "Enter the experiment name",
    "experiment.item.course": "Course",
    "experiment.item.selectcourse": "select courses",
    "experiment.item.remove": "Remove",
    "experiment.item.query": "Query",
    "experiment.item.tree": "User organization tree",
    "experiment.item.add": "Add Experiments",
    "experiment.item.import": "Batch import",
    "experiment.item.management": "course management",
    "experiment.item.delete": "delete",
    "experiment.item.cancel": "cancel",
    "experiment.item.batchoperation": "batch operation",
    "table.index": "Index",
    "experiment.item.introduction": "Introduction",
    "experiment.item.unknown": "Unknown course",
    "experiment.item.startexperiment": "Start the experiment",
    "experiment.item.start": "start",
    "experiment.item.watchvideo": "watch video",
    "experiment.item.notupload": "not uploaded",
    "experiment.item.material": "material",
    "experiment.item.handouts": "View Handouts",
    "experiment.item.document": "View Document",
    "experiment.item.view": "View",
    "experiment.item.feedback": "Feedback",
    "experiment.item.viewfeedback": "view feedback",
    "experiment.item.operation": "operation",
    "experiment.item.Setting": "Setting",
    "experiment.item.edit": "Edit",
    "experiment.item.suredel":
      "Are you sure you want to delete the experiment?",
    "experiment.item.success":
      "Operation succeeded. The experiment has been deleted",
    "experiment.item.batch": "Batch input experimental information",
    "experiment.item.firststep": "first step",
    "experiment.item.download": "download",
    "experiment.item.secondstep": "second step",
    "experiment.item.thirdstep": "third step",
    "experiment.item.templatefile": "Experiment list Excel template file",
    "experiment.item.fallin":
      "Fill in the basic experimental information according to the Excel template format.",
    "experiment.item.confirm": "confirm",
    "experiment.item.delcourse": "Are you sure you want to delete the course?",
    "experiment.item.save": "save",
    "experiment.item.remarks": "remarks",
    "experiment.item.coursename": "course name",
    "experiment.item.addcourse": "Add Course",
    "experiment.item.cover": "Cover",
    "experiment.item.stucourse": "Course",
    "experiment.item.worksheet": "Upload the Excel worksheet below.",
    "experiment.item.drag": "Click or drag the file to this area",
    "experiment.item.ignored":
      "The system will only read the data of the default sheet. If multiple sheets are added, they will be ignored.",
    "experiment.item.wait": "Uploading, please wait",
    "experiment.item.submitsheet": "Submit data sheet",
    "experiment.item.modifyinf": "Modify experimental information",
    "experiment.item.basicinf": "basic information",
    "experiment.item.template": "experimental report template",
    "experiment.item.advanced": "advanced setup",
    "experiment.item.savebasic": "Save basic information",
    "experiment.item.selcourse": "Please select the course of the experiment",
    "experiment.item.icon": "Icon",
    "experiment.item.enterintroduction":
      "Please enter the experiment introduction",
    "experiment.item.addsuccess": "successfully added",
    "experiment.item.savesuccess": "save successfully",
    "experiment.item.delsuccess": "Deleted course successfully",
    "experiment.item.impsuccess": "Import succeeded",
    "experiment.item.sorry": "Sorry, something went wrong",
    "experiment.item.close": "close",
    "experiment.item.errorcon": "Error content",
    "experiment.item.repetition": "repetition",
    "experiment.item.error": "error",
    "experiment.item.type": "type",
    "experiment.item.errorline": "Error line",
    "experiment.item.incorrect":
      "There are error data in the file, please check them",
    "experiment.item.selectfile": "Please select the file first",
    "experiment.item.modifysuccess": "Modify successfully",
    "experiment.item.only": "Only Excel files can be uploaded",
    "experiment.item.selectlist": "You need to select the list item first",
    "experiment.item.savetype": "Successfully saved the experiment type",
    "experiment.item.savenumber": "The number Settings are saved successfully",
    "experiment.item.savepath":
      "The experiment path and steps are saved successfully",
    "experiment.item.extype": "type of experiment",
    "experiment.item.selecttype": "Please select an experiment type",
    "experiment.item.submodule": "submodule",
    "experiment.item.selectsubmodule":
      "Please select the experimental submodule",
    "experiment.item.savesetting": "Save type Settings",
    "experiment.item.numberset": "Number setting",
    "experiment.item.exnumber": "Experiment number",
    "experiment.item.number": "number",
    "experiment.item.smallnumber": "small number",
    "experiment.item.savenumberset": "Save number settings",
    "experiment.item.set": "Set the resource path and step",
    "experiment.item.video": "video",
    "experiment.item.path": "Experimental path",
    "experiment.item.enterpath": "Enter experiment path",
    "experiment.item.clear": "Clear template",
    "experiment.item.savetem": "Save the lab report template",
    "experiment.item.decompression":
      "Decompression directory of resource compressed file",
    "experiment.item.filepackage": "Resource file compression package",
    "experiment.item.rootdirectory":
      "The root directory of the package should not contain only one directory. Please avoid directly compressing the directory",
    "experiment.item.Uploadzip": "Upload zip file",
    "experiment.item.stepinf": "Experimental step information",
    "experiment.item.enterstepinf": "Enter experimental step information",
    "experiment.item.upasave": "Upload resources and save steps",
    "experiment.item.entersec": "Please enter the second password",
    "experiment.item.updatesuccess":
      "Update experiments and steps successfully",
    "experiment.item.containone":
      "The path must contain at least one '/' accessor",
    "experiment.item.containtwo":
      "Invalid path: Contains more than two consecutive '/' accessors",
    "experiment.item.onlyzip": "Only zip files can be uploaded",
    "experiment.item.resource": "The resource will be decompressed in",
    "experiment.item.uploadsuccess": "upload successful",
    "experiment.item.multiple": "Click Upload (multiple choices are allowed)",
    "experiment.item.uploadpic": "Please upload pictures less than 5MB",
    "experiment.item.uploadfail": "Upload failed",
    "experiment.item.uploadhere": "Upload the file here",
    "experiment.item.uploadimage":
      "Please upload images in png/jpg/gif format ",
    "experiment.item.effective":
      "Uploaded successfully, effective after saving",
    "experiment.item.uploadvideo": "Upload video file",
    "experiment.item.uploadcompleted": "Upload completed",
  },
  "zh-CN": {
    "experiment.item.name": "实验名称",
    "experiment.item.entername": "输入实验名称",
    "experiment.item.course": "课程",
    "experiment.item.selectcourse": "选择课程",
    "experiment.item.remove": "清除",
    "experiment.item.query": "查询",
    "experiment.item.tree": "用户组织树",
    "experiment.item.add": "添加实验",
    "experiment.item.import": "批量导入",
    "experiment.item.management": "课程管理",
    "experiment.item.delete": "删除",
    "experiment.item.cancel": "取消",
    "experiment.item.batchoperation": "批量操作",
    "table.index": "序号",
    "experiment.item.introduction": "简介",
    "experiment.item.unknown": "未知课程",
    "experiment.item.startexperiment": "开始实验",
    "experiment.item.start": "开始实验",
    "experiment.item.watchvideo": "观看视频",
    "experiment.item.notupload": "未上传实验",
    "experiment.item.material": "资料",
    "experiment.item.handouts": "查看讲义",
    "experiment.item.document": "查看文档",
    "experiment.item.view": "查看",
    "experiment.item.feedback": "反馈",
    "experiment.item.viewfeedback": "查看反馈",
    "experiment.item.operation": "操作",
    "experiment.item.Setting": "设置",
    "experiment.item.edit": "编辑",
    "experiment.item.suredel": "确定要删除实验吗？",
    "experiment.item.success": "操作成功，已删除实验",
    "experiment.item.batch": "批量录入实验信息",
    "experiment.item.firststep": "第一步",
    "experiment.item.download": "下载",
    "experiment.item.secondstep": "第二步",
    "experiment.item.thirdstep": "第三步",
    "experiment.item.templatefile": "实验列表Excel模板文件",
    "experiment.item.fallin": "按照Excel模板格式填写实验基本信息 。",
    "experiment.item.confirm": "确定",
    "experiment.item.delcourse": "确定要删除课程吗?",
    "experiment.item.save": "保存",
    "experiment.item.remarks": "备注",
    "experiment.item.coursename": "课程名称",
    "experiment.item.addcourse": "添加课程",
    "experiment.item.cover": "封面图",
    "experiment.item.stucourse": "所属课程",
    "experiment.item.worksheet": "在下方上传Excel工作表。",
    "experiment.item.drag": "点击或拖拽文件到此区域",
    "experiment.item.ignored":
      "系统只会读取默认sheet的数据，若添加多个sheet将会忽略。",
    "experiment.item.wait": "上传中，请稍候",
    "experiment.item.submitsheet": "提交数据表",
    "experiment.item.modifyinf": "修改实验信息",
    "experiment.item.basicinf": "基本信息",
    "experiment.item.template": "实验报告模板",
    "experiment.item.advanced": "高级设置",
    "experiment.item.savebasic": "保存基本信息",
    "experiment.item.selcourse": "请选择实验所属课程",
    "experiment.item.icon": "图标",
    "experiment.item.enterintroduction": "请输入实验简介",
    "experiment.item.addsuccess": "添加成功",
    "experiment.item.savesuccess": "保存成功",
    "experiment.item.delsuccess": "删除课程成功",
    "experiment.item.impsuccess": "导入成功",
    "experiment.item.sorry": "抱歉，发生了一些错误",
    "experiment.item.close": "关闭",
    "experiment.item.errorcon": "错误内容",
    "experiment.item.repetition": "重复",
    "experiment.item.error": "错误",
    "experiment.item.type": "类型",
    "experiment.item.errorline": "错误行",
    "experiment.item.incorrect": "文件中存在错误数据，请检查它们",
    "experiment.item.selectfile": "请先选择文件",
    "experiment.item.modifysuccess": "修改成功",
    "experiment.item.only": "只能上传Excel文件",
    "experiment.item.selectlist": "需要先选择列表项",
    "experiment.item.savetype": "保存实验类型成功",
    "experiment.item.savenumber": "保存编号设置成功",
    "experiment.item.savepath": "保存实验路径和步骤成功",
    "experiment.item.extype": "实验类型",
    "experiment.item.selecttype": "请选择实验类型",
    "experiment.item.submodule": "子模块",
    "experiment.item.selectsubmodule": "请选择实验子模块",
    "experiment.item.savesetting": "保存类型设置",
    "experiment.item.numberset": "编号设置",
    "experiment.item.exnumber": "实验编号",
    "experiment.item.number": "编号",
    "experiment.item.smallnumber": "小编号",
    "experiment.item.savenumberset": "保存编号设置",
    "experiment.item.set": "资源路径和步骤设置",
    "experiment.item.video": "视频",
    "experiment.item.path": "实验路径",
    "experiment.item.enterpath": "输入实验路径",
    "experiment.item.clear": "清空模板",
    "experiment.item.savetem": "保存实验报告模板",
    "experiment.item.decompression": "资源压缩文件的解压目录",
    "experiment.item.filepackage": "资源文件压缩包",
    "experiment.item.rootdirectory":
      "压缩包根目录不应只包含一个目录，请避免直接对目录进行压缩",
    "experiment.item.Uploadzip": "上传zip压缩文件",
    "experiment.item.stepinf": "实验步骤信息",
    "experiment.item.enterstepinf": "输入实验步骤信息",
    "experiment.item.upasave": "上传资源并保存步骤",
    "experiment.item.entersec": "请输入二级密码",
    "experiment.item.updatesuccess": "更新实验和步骤成功",
    "experiment.item.containone": "路径必须至少包含一个'/'访问符",
    "experiment.item.containtwo": "路径不合法：包含两个以上连续的'/'访问符",
    "experiment.item.onlyzip": "只能上传zip压缩文件",
    "experiment.item.resource": "资源将解压在",
    "experiment.item.uploadsuccess": "上传成功",
    "experiment.item.multiple": "点击上传（可多选）",
    "experiment.item.uploadpic": "请上传5MB以内的图片",
    "experiment.item.uploadfail": "上传失败",
    "experiment.item.uploadhere": "在此处上传文件",
    "experiment.item.uploadimage": "请上传png/jpg/gif格式的图片",
    "experiment.item.effective": "上传成功，保存后生效",
    "experiment.item.uploadvideo": "上传视频文件",
    "experiment.item.uploadcompleted": "已完成上传",
  },
};

export default i18n;
