import req from "../configs/axios";

// 登录
const doLogin = (data: any) => {
  return req.post("LoginController/doLogin", data);
};

// 登出
const doLogout = () => {
  return req.post("LoginController/doLogout");
};

// 验证码
const getCaptcha = () => {
  return req.get("LoginController/getCaptcha", null, { responseType: "blob" });
};

export { doLogin, doLogout, getCaptcha };
