import {
  Button,
  Col,
  Form,
  Input,
  message,
  Progress,
  Row,
  Segmented,
  Switch,
  Typography,
  Upload,
} from "antd";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import RichEditor from "../../components/RichEditor";
import { useCallback, useEffect, useState } from "react";
import type { SegmentedValue } from "antd/lib/segmented";
import {
  findAllSystemMessage,
  updAllSystemMessage,
} from "../../../api/SystemMessageController";
import { FileAcceptList } from "../../../api/NewUpLoadController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findAllPath, updAllPath } from "../../../api/ImagePtController";
import { uploadManually } from "../../../api/NewUpLoadController";
import {
  findClassColorAll,
  updateClassColorAll,
} from "../../../api/classColorController";
import ColorPicker from "../../components/ColorPicker";
import i18n from "./i18n";

const { useForm } = Form;

const SitePresetPage = () => {
  const { t } = useLocale(i18n);
  const [basePanel, setBasePanel] = useState<SegmentedValue>("zh-CN");
  const [baseLoading, setBaseLoading] = useState(false);
  const [imgs, setImgs] = useState<any>({});
  const [colors, setColors] = useState<any>({});
  const [zhform] = useForm();
  const [enform] = useForm();
  const [colorsForm] = useForm();

  const getBaseInfo = useCallback(() => {
    findAllSystemMessage().then(({ data }) => {
      zhform.setFieldsValue(data.data["zh-CN"]);
      enform.setFieldsValue(data.data["en-US"]);
    });

    findAllPath().then(({ data }) => {
      if (data.code) {
        setImgs(data.data);
      }
    });

    findClassColorAll().then(({ data }) => {
      if (data.code) {
        setColors(data.data);
        let values: any = {};
        for (let k in data.data) values[k] = data.data[k].colorName;
        colorsForm.setFieldsValue(values);
      }
    });
  }, [colorsForm, enform, zhform]);

  const postBaseInfo = () => {
    const zh = zhform.getFieldsValue();
    const en = enform.getFieldsValue();

    zh.tSystemMessageId = 1;
    en.tSystemMessageId = 2;

    setBaseLoading(true);
    updAllSystemMessage([zh, en])
      .then(({ data }) => {
        if (data.code) {
          message.success(t("system.msg.savesuccess"));
        } else {
          data?.msg && message.error(data.msg);
        }
      })
      .finally(() => setBaseLoading(false));
  };

  const postColors = () => {
    const d = colorsForm.getFieldsValue();
    d.zhuanjiadenglu = d?.zhuanjiadenglu ? 1 : 0;

    const payload = Object.keys(d).map((className) => ({
      className,
      id: colors?.[className]?.colorId,
      colorName: d[className],
    }));

    updateClassColorAll(payload).then(({ data }) => {
      if (data.code) {
        message.success(t("system.msg.savesuccess"));
      } else {
        data?.msg && message.error(data.msg);
      }
    });
  };

  useEffect(() => {
    getBaseInfo();
  }, [getBaseInfo]);

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.system"), t("menu.system.site")]} />
      <Row gutter={15} className="flex-grow">
        <Col span={8}>
          <div className="bg-white p-4 rounded h-full">
            <Typography.Title level={4}>
              {t("site.item.basicinf")}
            </Typography.Title>
            <Segmented
              block
              className="mb-4"
              value={basePanel}
              onChange={setBasePanel}
              options={[
                {
                  value: "zh-CN",
                  label: "简体中文",
                },
                {
                  value: "en-US",
                  label: "English",
                },
              ]}
            />
            <Form
              layout="vertical"
              form={zhform}
              className={basePanel === "zh-CN" ? "block" : "hidden"}
            >
              <Form.Item
                label={t("site.item.systentitle")}
                name="tSystemMessageName"
              >
                <Input placeholder={t("site.item.entersystentitle")} />
              </Form.Item>
              <Form.Item
                label={t("site.item.welcomemessage")}
                name="tSystemMessageWelcomes"
              >
                <Input placeholder={t("site.item.enterwelcomemessage")} />
              </Form.Item>
              <Form.Item
                label={t("site.item.projectname")}
                name="tSystemMessageProject"
              >
                <Input placeholder={t("site.item.enterprojectname")} />
              </Form.Item>
              <Form.Item
                label={t("site.item.footerinf")}
                name="tSystemMessageBeiyong3"
              >
                <RichEditor />
              </Form.Item>
            </Form>
            <Form
              layout="vertical"
              form={enform}
              className={basePanel === "en-US" ? "block" : "hidden"}
            >
              <Form.Item
                label={t("site.item.systentitleen")}
                name="tSystemMessageName"
              >
                <Input placeholder={t("site.item.entersystentitleen")} />
              </Form.Item>
              <Form.Item
                label={t("site.item.welcomemessageen")}
                name="tSystemMessageWelcomes"
              >
                <Input placeholder={t("site.item.enterwelcomemessageen")} />
              </Form.Item>
              <Form.Item
                label={t("site.item.projectnameen")}
                name="tSystemMessageProject"
              >
                <Input placeholder={t("site.item.enterprojectnameen")} />
              </Form.Item>

              <Form.Item
                label={t("site.item.footerinfen")}
                name="tSystemMessageBeiyong3"
              >
                <RichEditor />
              </Form.Item>
            </Form>
            <Button
              block
              type="primary"
              onClick={postBaseInfo}
              loading={baseLoading}
            >
              {t("site.item.save")}
            </Button>
          </div>
        </Col>
        <Col span={16}>
          <div className="bg-white p-4 rounded h-full">
            <Typography.Title level={4}>
              {t("site.item.sitesetting")}
            </Typography.Title>
            <Row gutter={[15, 30]} justify="center">
              <Col span={12}>
                <span>{t("site.item.loginlogo")}</span>
                <Uploader
                  tImagePtId={imgs?.["1"]?.tImagePtId}
                  src={imgs?.["1"]?.tImagePtPath}
                />
              </Col>
              <Col span={12}>
                <span>{t("site.item.declarelogo")}</span>
                <Uploader
                  tImagePtId={imgs?.["3"]?.tImagePtId}
                  src={imgs?.["3"]?.tImagePtPath}
                />
              </Col>
              <Col span={12}>
                <span>{t("site.item.loginbg")}</span>
                <Uploader
                  tImagePtId={imgs?.["2"]?.tImagePtId}
                  src={imgs?.["2"]?.tImagePtPath}
                />
              </Col>
              <Col span={12}>
                <span>{t("site.item.bannerimg")}</span>
                <Uploader
                  tImagePtId={imgs?.["4"]?.tImagePtId}
                  src={imgs?.["4"]?.tImagePtPath}
                />
              </Col>
            </Row>

            <Row gutter={[15, 30]} justify="center">
              <Col span={12}>
                <Typography.Title className="block text-lg font-bold my-4">
                  {t("site.item.miscellaneous")}
                </Typography.Title>
                <Form form={colorsForm}>
                  <Form.Item
                    label={t("site.item.free")}
                    name="zhuanjiadenglu"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    label={t("site.item.btncolor")}
                    name="yemiananniuyanse"
                  >
                    <ColorPicker />
                  </Form.Item>
                  <Form.Item
                    label={t("site.item.btnhovercolor")}
                    name="yemiananniuyanse_hover"
                  >
                    <ColorPicker />
                  </Form.Item>
                  <Form.Item
                    label={t("site.item.freebtncolor")}
                    name="mianzhuceanniu"
                  >
                    <ColorPicker />
                  </Form.Item>
                  <Form.Item
                    label={t("site.item.freebtnhovercolor")}
                    name="mianzhuceanniu_hover"
                  >
                    <ColorPicker />
                  </Form.Item>
                  <Button type="primary" onClick={postColors}>
                    {t("site.item.saveset")}
                  </Button>
                </Form>
              </Col>
              <Col span={12}></Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

interface UploaderProps {
  src?: string;
  tImagePtId: number;
}

type StatusType = "idle" | "loading" | "error" | "done";

const Uploader = (prop: UploaderProps) => {
  const { t } = useLocale(i18n);
  const { src, tImagePtId } = prop;
  const [status, setStatus] = useState<StatusType>("idle");
  const [progress, setProgress] = useState<number>(0);
  const [screenBackImg, setScreenBackImg] = useState<string>();

  const beforeImgUpload = (file: File) =>
    new Promise<boolean>((resolve, reject) => {
      if (!FileAcceptList.image.includes(file.type)) {
        message.error(t("site.item.uploadimage"));
        return reject(false);
      }
      if (file.size / 1024 / 1024 > 5) {
        message.error(t("site.item.uploadpic"));
        return reject(false);
      }
      return resolve(true);
    });

  const manuallyUpload = (options: any) => {
    setStatus("loading");

    const data = new FormData();
    data.append("file", options.file);
    data.append("keyName", "schoolLogo");

    uploadManually(data, (e) =>
      setProgress(Math.floor((e.loaded / e.total) * 100))
    )
      .then(({ data }) => {
        setScreenBackImg(data.data);
        return updAllPath({ imagePtId: tImagePtId, imagePtPath: data.data });
      })
      .then(({ data }) => {
        if (data.code) {
          setStatus("done");
          message.success(t("site.item.setup"));
        } else {
          setStatus("error");
        }
      });
  };

  useEffect(() => {
    if (src) {
      setStatus("done");
      setScreenBackImg(src);
    }
  }, [src]);

  const dragStatus = {
    idle: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "cloud-arrow-up"]}
            size="3x"
            className="text-gray-300"
          />
        </p>
        <p className="ant-upload-text">{t("site.item.uploadhere")}</p>
      </>
    ),

    loading: (
      <Progress
        strokeColor={{
          "0%": "#f492f0",
          "100%": "#a18dce",
        }}
        percent={progress}
        status="active"
        type="circle"
      />
    ),

    error: (
      <>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon
            icon={["fas", "circle-xmark"]}
            size="3x"
            className="text-red-500"
          />
        </p>
        <p className="ant-upload-text">{t("site.item.uploadfail")}</p>
      </>
    ),

    done: <img src={screenBackImg} alt="" className="w-full max-h-full" />,
  } as const;

  return (
    <div
      className="w-60 h-45 object-contain"
      style={{
        backgroundImage:
          "linear-gradient(-45deg, #dddddd 25%, #ffffff 25%, #ffffff 50%, #dddddd 50%, #dddddd 75%, #ffffff 75%, #ffffff 100%)",
        backgroundSize: "7px 7px",
      }}
    >
      <Upload.Dragger
        maxCount={1}
        showUploadList={false}
        accept={FileAcceptList.image.join()}
        beforeUpload={beforeImgUpload}
        customRequest={manuallyUpload}
        className="!bg-transparent"
      >
        {dragStatus[status]}
      </Upload.Dragger>
    </div>
  );
};

export default SitePresetPage;
