import req from "../configs/axios";

// 添加试题
const addQuestion = (data?: any) => {
  return req.post("auth/QuestionController/addQuestion", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 试题列表
const showQuestion = (data?: any) => {
  return req.post("auth/QuestionController/showQuestion", data);
};

// 删除试题
const removeQuestion = (data?: any) => {
  return req.post("auth/QuestionController/removeQuestion", data);
};

// 更新试题
const updateQuestion = (data?: any) => {
  return req.post("auth/QuestionController/updateQuestion", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * 更新试题列表
 * @deprecated 仅兼容旧版存在的接口，可能在未来版本中删除
 */
const updateQuestionList = (data?: any) => {
  return req.post("auth/QuestionController/updateQuestionList", data);
};

// 创建标签
const addTag = (data?: any) => {
  return req.post("auth/QuestionController/addTag", data);
};

// 修改标签
const updateTag = (data?: any) => {
  return req.post("auth/QuestionController/updateTag", data);
};

// 删除标签
const deleteTag = (data?: any) => {
  return req.post("auth/QuestionController/deleteTag", data);
};

export {
  addQuestion,
  showQuestion,
  removeQuestion,
  updateQuestion,
  updateQuestionList,
  addTag,
  updateTag,
  deleteTag,
};
