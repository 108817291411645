import { Row, Col, Card, Space, message } from "antd";
import { useLocale } from "../../hooks/useLocale";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import icon1 from "../../assets/images/icons/16b.png";
import icon2 from "../../assets/images/icons/17b.png";
import icon3 from "../../assets/images/icons/19b.png";
import icon4 from "../../assets/images/icons/18b.png";
import { useEffect, useState } from "react";
import { homeData } from "../../api/NewHomeController";
import Chart, { type EChartsOption } from "../components/Chart";
import { graphic } from "echarts/core";
import { durationFormat } from "../../utils/format";
import i18n from "./i18n";

export interface AnalysisData {
  logCount: number; // 登陆次数
  lastLoginTime: string; // 上次登陆时间
  logUserCount: number; // 浏览人数
  scoreCount: number; // 	实验次数
  scoreUserCount: number; // 实验人数
  scoreUseTimeSum: number; // 实验累计时长 (秒)
  avgUseTime: number; // 平均实验时长 (秒)
  labTestPass: number; // 及格实验数
  labTestFail: number; // 不及格实验数
  labTestNotDone: number; // 未做实验数
  scoreList: { score: number; time: string; labName: string }[]; // 最近十次成绩
}

const HomeDashBoard = () => {
  const { t } = useLocale(i18n);
  const [analysis, setAnalysis] = useState<AnalysisData>();
  const [loading, setLoading] = useState(false);

  const getAnalysis = () => {
    setLoading(true);
    homeData()
      .then(({ data }) => {
        // console.log(data);
        if (data.code) {
          setAnalysis(data.data);
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAnalysis();
  }, []);

  const getCompletedChartOptions = () =>
    ({
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
      },
      title: {
        text: t("declare.item.Completion"),
      },
      series: [
        {
          name: t("declare.item.Completion"),
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "20",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          color: ["#91cc75", "#ee6666", "#fac858"],
          data: [
            { value: analysis?.labTestPass || 0, name: t("declare.item.pass") },
            { value: analysis?.labTestFail || 0, name: t("declare.item.fail") },
            {
              value: analysis?.labTestNotDone || 0,
              name: t("declare.item.notDone"),
            },
          ],
        },
      ],
    } as EChartsOption);

  const getScoreLast10Options = () =>
    ({
      title: {
        text: t("declare.item.results"),
      },
      tooltip: {
        trigger: "item",
        // formatter(params, ticket) {
        //   console.log(params);
        //   return "";
        // },
      },
      xAxis: {
        type: "category",
        data: analysis?.scoreList?.map((x) => x.time) || [],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: analysis?.scoreList?.map((x) => x.score) || [],
          type: "line",
          color: "#986db2",
          areaStyle: {
            //填充区域的渐变色
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(141, 135, 252, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(198, 248, 255, 0)",
                },
              ],
              global: false,
            },
          },
          lineStyle: {
            width: 5,
            type: "solid",
            //线条渐变色
            color: new graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 0,
                color: "#6999ff",
              },
              {
                offset: 1,
                color: "#f8acff",
              },
            ]),
          },

          smooth: true,
        },
      ],
    } as EChartsOption);

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.dashboard")]} />
      <div>
        <Row gutter={[16, 16]}>
          <Col xl={6} md={12} sm={24}>
            <Card title={<Space>{t("declare.item.loginNumber")}</Space>}>
              <div className="h-10 flex items-center justify-center">
                <img src={icon1} alt="" className="mr-8" />
                <div className="text-3xl font-bold">
                  {analysis?.logCount || 0}
                </div>
              </div>
            </Card>
          </Col>
          <Col xl={6} md={12} sm={24}>
            <Card title={<Space>{t("declare.item.loginTime")}</Space>}>
              <div className="h-10 flex items-center justify-center">
                <img src={icon2} alt="" className="mr-8" />
                <div className="text-xl font-bold">
                  {analysis?.lastLoginTime || ""}
                </div>
              </div>
            </Card>
          </Col>
          <Col xl={6} md={12} sm={24}>
            <Card title={<Space>{t("declare.item.experimentsnum")}</Space>}>
              <div className="h-10 flex items-center justify-center">
                <img src={icon3} alt="" className="mr-8" />
                <div className="text-3xl font-bold">
                  {analysis?.scoreCount || 0}
                </div>
              </div>
            </Card>
          </Col>
          <Col xl={6} md={12} sm={24}>
            <Card title={<Space>{t("declare.item.experimentstime")}</Space>}>
              <div className="h-10 flex items-center justify-center">
                <img src={icon4} alt="" className="mr-8" />
                <div className="text-3xl font-bold">
                  {durationFormat((analysis?.scoreUseTimeSum || 0) * 1000)}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} className="pt-4">
          <Col span={24}>
            <Card title={t("declare.item.Personal")}>
              <Row gutter={16} className="pt-4">
                <Col md={12} sm={24} className="h-100">
                  <Chart
                    loading={loading}
                    option={getCompletedChartOptions()}
                  />
                </Col>
                <Col md={12} sm={24} className="h-100">
                  <Chart loading={loading} option={getScoreLast10Options()} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeDashBoard;
