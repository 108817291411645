const i18n = {
  "en-US": {
    "departmentTree.item.deletednode": "Deleted node",
    "departmentTree.item.addsuccess": "Successfully added node",
    "departmentTree.item.editsuccess": "Edit node succeeded",
    "departmentTree.item.refresh": "refresh",
    "departmentTree.item.adddepartment": "Add department",
    "departmentTree.item.addmajor": "Add major",
    "departmentTree.item.addclass": "Add class",
    "departmentTree.item.organization": "organization",
    "departmentTree.item.searchname": "Search name",
    "departmentTree.item.fullload": "Full load:",
    "departmentTree.item.notempty": "The organization name cannot be empty",
    "departmentTree.item.save": "save",
    "departmentTree.item.cancel": "cancel",
    "departmentTree.item.edit": "edit",
    "departmentTree.item.suredel":
      "This will delete the current node and all child nodes. Are you sure?",
    "departmentTree.item.delete": "delete",
  },
  "zh-CN": {
    "departmentTree.item.deletednode": "已删除节点",
    "departmentTree.item.addsuccess": "添加节点成功",
    "departmentTree.item.editsuccess": "编辑节点成功",
    "departmentTree.item.refresh": "刷新",
    "departmentTree.item.adddepartment": "添加院系",
    "departmentTree.item.addmajor": "添加专业",
    "departmentTree.item.addclass": "添加班级",
    "departmentTree.item.organization": "组织",
    "departmentTree.item.searchname": "搜索名称",
    "departmentTree.item.fullload": "全量加载：",
    "departmentTree.item.notempty": "组织名称不能为空",
    "departmentTree.item.save": "保存",
    "departmentTree.item.cancel": "撤销",
    "departmentTree.item.edit": "编辑",
    "departmentTree.item.suredel": "这将删除当前节点以及所有子节点，确定吗?",
    "departmentTree.item.delete": "删除",
  },
};

export default i18n;
