import { message } from "antd";
import { addScoreVideo } from "../api/LabSettingController";
import { setUserAndLabkey } from "../api/NewScoreController";
import useAuth from "./useAuth";

const useStartExp = () => {
  const { user } = useAuth();

  const startExp = (labid: number, url: string, type: number) => {
    if (!url) throw new Error("未上传实验");
    if (type === 9) {
      // 视频实验课程
      addScoreVideo({ labId: labid, cZyHome: url }).then(({ data }) => {
        if (data.code) {
          window.open(`/#/play/${data.data}`);
          return;
        }
        data?.msg && message.error(data.msg);
      });
      return;
    }

    setUserAndLabkey({ labid, labOption: true }).then(({ data }) => {
      if (data.key) {
        if (user?.areaKey !== "1") {
          url = url.replace("Course", "Courses");
        }
        window.open(`${url}?key=${data.key}`);
      }
    });
  };

  return { startExp };
};

export default useStartExp;
