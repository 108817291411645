import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";

const PermissionDeniedPage = () => {
  const navigate = useNavigate();
  const { t } = useLocale();

  return (
    <div className="flex flex-col items-center pt-8 text-dark-50">
      <div className="text-[200px] leading-normal font-bold">403</div>
      <div className="text-[18px] mt-2">{t("exception.page.403")}</div>
      <Space>
        <Button type="link" className="mt-6" onClick={() => navigate("/")}>
          {t("global.backtohome")}
        </Button>
        <Button type="link" className="mt-6" onClick={() => navigate(-1)}>
          {t("global.back")}
        </Button>
      </Space>
    </div>
  );
};

export default PermissionDeniedPage;
