import { DownloadOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Form, Space, Input, Button, Dropdown, Menu, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FC, useCallback, useEffect, useState } from "react";
import { listMaxByParamsExcel } from "@/api/NewScoreController";
import { downloadByURL } from "@/utils/download";
import { durationFormat } from "@/utils/format";
import { listExamScoreAllByLogin } from "@/api/ExamController";
import { useLocale } from "@/hooks/useLocale";
import RecordDetail from "./recordDetail";
// import VideoScoreRecordModal, {
//   type VideoScoreRecordModalProps,
// } from "./VideoScoreRecordModal";

export interface RecordProps {
  id: number;
  userId: number; // 答题者id
  userAccount: string; // 学号
  userName: string; // 姓名
  rightSum: number; // 正确数
  wrongSum: number; // 错题数
  sumScore: number; // 总分数
  sumQuestionScore: number; // 满分值
  courseId: number; // 关卡id
  courseName: string; // 关卡名称
  courseDescription: string; // 关卡描述
  courseNameParent: string; // 关卡分类
  courseDescriptionParent: string; // 关卡分类描述
  groupId1: number;
  groupId2: number;
  groupId3: number;
  groupName1: string;
  groupName2: string;
  groupName3: string;
  createTime: string; // 作答时间
  endTime: string; // 结束作答
  useTime: number;
  contentList: ContentDetail[]; // 答题详情
  questionListContent: [];
}

export interface ContentDetail {
  questionId: string;
  content: string; // XML试题内容
  answerResult: boolean; // 判定结果
  questionName: string; // 题干
  mtAnswer: string; // 正解
  userAnswer: string; // 用户答案
  fPoints: number; // 分值
  createTime: string; // 作答时间
}

const initPagination = {
  page: 1,
  rows: 10,
};

const ExamRecord: FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [detail, setDetail] = useState<RecordProps>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const [queryForm] = useForm();
  const { t } = useLocale();

  const getTableData = useCallback(async () => {
    const params = queryForm.getFieldsValue();

    listExamScoreAllByLogin({
      ...tablePagenation,
      ...params,
    })
      .then(({ data }) => {
        // console.log(data);
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [queryForm, tablePagenation]);

  const openDetail = (record: RecordProps) => {
    setDetail(record);
    setDetailOpen(true);
  };

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div>
      {/* <div className="mb-4">
        <Form layout="inline" form={queryForm}>
          <Space wrap>
            <Form.Item label="搜索" name="search">
              <Input className="w-60" allowClear placeholder="搜索" />
            </Form.Item>
            <Button onClick={() => queryForm.resetFields()}>清除</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setTablePagenation({ page: 1, rows: 10 })}
            >
              查询
            </Button>
          </Space>
        </Form>
      </div>
      <Space className="table-tool-bar">
        <Space>
          <Button icon={<DownloadOutlined />}>导出</Button>
        </Space>
      </Space> */}
      <Table
        size="small"
        rowKey="id"
        bordered
        scroll={{ x: 1700 }}
        loading={loading}
        dataSource={tableData}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: ["bottomCenter"],
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          total,
          showTotal: (total) =>
            `${t("table.pagination.all")}${total}${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        columns={[
          {
            title: t("table.index"),
            width: 70,
            fixed: "left",
            align: "center",
            render: (_val, _row, index) => index + 1,
          },
          {
            title: "考试名称",
            dataIndex: "examName",
            fixed: "left",
            width: 120,
          },
          {
            title: "描述",
            dataIndex: "examDescription",
            width: 120,
          },
          {
            title: "开始时间",
            dataIndex: "createTime",
          },
          {
            title: "提交时间",
            dataIndex: "endTime",
          },
          {
            title: "得分/满分",
            dataIndex: "percentage",
            render: (_val, row) => `${row.sumScore}/${row.sumQuestionScore}`,
          },
          {
            title: "正确数",
            dataIndex: "rightSum",
          },
          {
            title: "错误数",
            dataIndex: "wrongSum",
          },
          {
            title: "用时",
            dataIndex: "useTime",
            render: (val) => durationFormat(val),
          },
          {
            title: "操作",
            width: 140,
            align: "center",
            fixed: "right",
            render: (_val, row) => (
              <Button
                icon={<OrderedListOutlined />}
                onClick={() => openDetail(row)}
              >
                答题记录
              </Button>
            ),
          },
        ]}
      />
      <RecordDetail
        open={detailOpen}
        record={detail}
        onClose={() => {
          setDetail(undefined);
          setDetailOpen(false);
        }}
      />
    </div>
  );
};

export default ExamRecord;
