import req from "../configs/axios";

// 查询实验步骤列表
const listLabStep = (data?: any) => {
  return req.post("auth/NewLabStepController/listLabStep", data);
};

// 创建实验步骤
const addLabStep = (data: any) => {
  return req.post("auth/NewLabStepController/addLabStep", data);
};

// 更新实验步骤
const updateLabStep = (data: any) => {
  return req.post("auth/NewLabStepController/updateLabStep", data);
};

// 删除实验步骤
const deleteLabStep = (data: any) => {
  return req.post("auth/NewLabStepController/deleteLabStep", data, {
    headers: { "Content-Type": "application/json" },
  });
};

export { listLabStep, addLabStep, updateLabStep, deleteLabStep };
