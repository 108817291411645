import { useForm } from "antd/lib/form/Form";
import { useState, useEffect } from "react";
import AvatarCover from "../components/AvatarCover";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tag,
  Button,
  message,
} from "antd";
import useAuth from "../../hooks/useAuth";
import type { Rule } from "antd/lib/form";
import { useLocale } from "../../hooks/useLocale";
import i18n from "../login/i18n";

interface ProfilePanelProps {
  visible?: boolean;
  setVisible: (visible: boolean) => void;
}

const ProfilePanel = (prop: ProfilePanelProps) => {
  const { visible, setVisible } = prop;
  const [personalLoading, setPersonalLoading] = useState(false);
  const [userAvatar, setUserAvatar] = useState<string>();
  const [personalForm] = useForm();
  const { user, update } = useAuth();
  const { t } = useLocale(i18n);

  const personalFormRoles: { [key: string]: Rule[] } = {
    name: [{ required: true, message: t("login.roles.name") }],
    tel: [
      { required: false },
      // 国外手机号
      // {
      //   message: t("ogin.roles.tel2"),
      //   validator: (_rule, val) =>
      //     mobile.test(val) || val === ""
      //       ? Promise.resolve(val)
      //       : Promise.reject(),
      // },
    ],
    mail: [
      { required: false },
      { type: "email", message: t("login.roles.mail2") },
    ],
  };

  const onAvatarUploaded = (url: string) => {
    console.log("success:", url);
    setUserAvatar(url);
  };

  const commitPersonalForm = () => {
    personalForm.validateFields().then(async (d) => {
      d.id = user?.id;
      if (userAvatar) {
        d.myac = userAvatar;
      }
      setPersonalLoading(true);
      update(d)
        .then((_res) => {
          message.success(t("system.msg.savesuccess"));
          setVisible(false);
        })
        .catch((err) => {
          console.log(err);
          err?.msg && message.error(err.msg);
        })
        .finally(() => {
          setPersonalLoading(false);
        });
    });
  };

  useEffect(() => {
    personalForm.setFieldsValue(user);
  }, [personalForm, user]);

  return (
    <Modal
      title={t("header.item.profile")}
      footer={null}
      destroyOnClose
      open={visible}
      forceRender
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Form
        form={personalForm}
        labelAlign="left"
        labelCol={{ span: 5 }}
        preserve={false}
      >
        <Row>
          <Col span={6}>
            <AvatarCover src={user?.myac} onUploaded={onAvatarUploaded} />
            <div className="mt-2 text-center">{t("user.attr.avatar")}</div>
            <div className="mt-1 text-left text-gray-500">
              {t("user.attr.avatar.tip")}
            </div>
          </Col>
          <Col span={16} offset={2}>
            <Form.Item label={t("user.attr.loginId")} name="loginID">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={t("user.attr.name")}
              name="name"
              rules={personalFormRoles.name}
            >
              <Input
                showCount
                maxLength={15}
                placeholder={t("login.roles.name")}
              />
            </Form.Item>
            <Form.Item label={t("user.attr.sex")} name="sex">
              <Select placeholder={t("login.roles.sex")}>
                <Select.Option value={1}>
                  {t("user.attr.sex.male")} <Tag color="blue">♂</Tag>
                </Select.Option>
                <Select.Option value={2}>
                  {t("user.attr.sex.female")} <Tag color="magenta">♀</Tag>
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={t("user.attr.tel")}
              name="tel"
              rules={personalFormRoles.tel}
            >
              <Input placeholder={t("login.roles.tel")} />
            </Form.Item>
            <Form.Item
              label={t("user.attr.mail")}
              name="mail"
              rules={personalFormRoles.mail}
            >
              <Input placeholder={t("login.roles.mail")} />
            </Form.Item>
            <div className="text-right">
              <Button
                type="primary"
                htmlType="submit"
                onClick={commitPersonalForm}
                loading={personalLoading}
              >
                {t("user.attr.savechange")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProfilePanel;
