import {
  PlusOutlined,
  DeleteOutlined,
  FormOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { queryLabList } from "../../../api/NewLabInfoController";
import {
  listLabStep,
  deleteLabStep,
  updateLabStep,
  addLabStep,
} from "../../../api/NewLabStepController";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";

interface StepsModalProps {
  open?: boolean;
  onCancel?: () => void;
}

const { confirm } = Modal;
const { useForm } = Form;

const formRoles: FormRules = {
  labId: [{ required: true, message: "请绑定实验" }],
  score: [{ required: true, message: "请输入步骤满分值" }],
};

const listReducer = (data: any[], key: string = "cName") =>
  data.reduce((pre: any, crt: any) => ({ ...pre, [crt.id]: crt[key] }), {});

const StepsModal: React.FC<StepsModalProps> = ({ open, onCancel }) => {
  const { t } = useLocale(i18n);
  const [tableData, setTableData] = useState<any[]>([]);
  const [lablist, setLablist] = useState<{ [key: number]: string }>({});
  const [loading, setLoading] = useState(false);
  const [multipleEdit, setMultipleEdit] = useState(false);
  const [multipleSelectedRows, setMultipleSelectedRows] = useState<React.Key[]>(
    []
  );

  const getTableData = useCallback(async () => {
    setLoading(true);

    listLabStep()
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getLabList = useCallback(async () => {
    queryLabList().then(({ data }) => {
      if (data.code) {
        setLablist(listReducer(data.data.rows));
      }
    });
  }, []);

  useEffect(() => {
    if (open) {
      getTableData();
      getLabList();
    }
  }, [getLabList, getTableData, open]);

  const tableRowSelection = {
    onChange: (selectedRowKeys: React.Key[], _selectedRows: any) => {
      setMultipleSelectedRows(selectedRowKeys);
    },
  };

  const cancelTabRowSelection = () => {
    setMultipleEdit(false);
    setMultipleSelectedRows([]);
  };

  const deleteTableRows = () => {
    if (multipleSelectedRows.length === 0) {
      message.error(t("declare.item.selectlist"));
      return;
    }
    Modal.confirm({
      title: t("declare.item.deletion"),
      content: `${t("declare.item.willdelete")} ${
        multipleSelectedRows.length
      } ${t("table.pagination.total")}${t("declare.item.delstepset")}`,
      async onOk() {
        const { data } = await deleteLabStep(multipleSelectedRows);
        if (data.code) {
          message.success(t("declare.item.operationsuccess"));
          getTableData();
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  // 编辑窗口
  const [editform] = useForm();
  const [editModalShow, setEditModalShow] = useState(false);
  const [editingId, setEditingId] = useState<number>();
  const [editLoading, setEditLoading] = useState(false);

  const openToEdit = (rowData?: any) => {
    if (rowData) {
      editform.setFieldsValue(rowData);
      setEditingId(rowData.id);
    } else {
      setEditingId(undefined);
    }
    setEditModalShow(true);
  };

  const onUpdateSteps = () => {
    editform.validateFields().then(async (d) => {
      setEditLoading(true);
      if (editingId) {
        // 更新
        d.id = editingId;
        const { data } = await updateLabStep(d).finally(() => {
          setEditLoading(false);
        });
        if (data.code) {
          message.success(t("declare.item.modifysuccess"));
          setEditModalShow(false);
          getTableData();
          return;
        }
        data?.msg && message.error(data.msg);
        return;
      }

      // 创建
      const { data } = await addLabStep(d).finally(() => {
        setEditLoading(false);
      });
      if (data.code) {
        message.success(t("declare.item.addsuccess"));
        setEditModalShow(false);
        getTableData();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t("declare.item.stepset")}
      width={900}
      footer={null}
      destroyOnClose
    >
      <div>
        <Space className="table-tool-bar">
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => openToEdit()}
            >
              {t("declare.item.addstep")}
            </Button>
          </Space>
          <Space>
            {multipleEdit ? (
              <>
                <Button
                  danger
                  type="primary"
                  icon={<DeleteOutlined />}
                  onClick={deleteTableRows}
                >
                  {t("declare.item.delete")}
                </Button>
                <Button
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "arrow-rotate-right"]}
                      className="mr-2"
                    />
                  }
                  onClick={cancelTabRowSelection}
                >
                  {t("system.confirm.cancel")}
                </Button>
              </>
            ) : (
              <Button
                icon={<FormOutlined />}
                onClick={() => setMultipleEdit(true)}
              >
                {t("declare.item.batchoperation")}
              </Button>
            )}
          </Space>
        </Space>
        <Table
          size="small"
          rowKey="id"
          bordered
          scroll={{ x: 1300 }}
          loading={loading}
          dataSource={tableData}
          rowSelection={
            multipleEdit
              ? { type: "checkbox", ...tableRowSelection }
              : undefined
          }
          pagination={{
            size: "default",
            position: ["bottomCenter"],
            showTotal: (total) =>
              `${t("table.pagination.all")}${total}${t(
                "table.pagination.total"
              )}`,
          }}
          columns={[
            {
              title: t("table.index"),
              width: 50,
              fixed: "left",
              align: "center",
              render: (_val, _row, index) => index + 1,
            },
            {
              title: t("declare.item.bindexperiment"),
              dataIndex: "labId",
              render: (val) => (
                <>
                  {lablist[val] ?? (
                    <span className="text-gray-300">
                      {t("declare.item.unknown")}
                    </span>
                  )}
                </>
              ),
            },
            {
              title: t("declare.item.startend"),
              render: (_val, row) => {
                return (
                  <>
                    {row.labStepStart} - {row.labStepEnd}
                  </>
                );
              },
            },
            {
              title: t("declare.item.stepobjective"),
              dataIndex: "stepContent",
            },
            {
              title: t("declare.item.appropriate"),
              dataIndex: "stepUseTime",
              align: "center",
              width: 150,
            },
            {
              title: t("declare.item.scoringmodel"),
              ellipsis: true,
              dataIndex: "beiYong1",
              width: 300,
            },
            {
              title: t("declare.item.stepmark"),
              dataIndex: "score",
              align: "center",
              width: 100,
            },
            {
              title: t("declare.item.operation"),
              width: 150,
              fixed: "right",
              align: "center",
              render(_val, row) {
                return (
                  <Space>
                    <Button size="small" onClick={() => openToEdit(row)}>
                      {t("declare.item.edit")}
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      danger
                      onClick={() => {
                        confirm({
                          title: t("declare.item.suredel"),
                          icon: <ExclamationCircleOutlined />,
                          content: "",
                          centered: true,
                          async onOk() {
                            const { data } = await deleteLabStep([row.id]);
                            if (data.code) {
                              message.success(t("declare.item.deleted"));
                              getTableData();
                            } else {
                              data?.msg && message.error(data.msg);
                            }
                          },
                        });
                      }}
                    >
                      {t("declare.item.delete")}
                    </Button>
                  </Space>
                );
              },
            },
          ]}
        />
      </div>
      {/* 编辑 */}
      <Modal
        title={
          editingId
            ? t("declare.item.modifystep")
            : t("declare.item.createstep")
        }
        destroyOnClose
        open={editModalShow}
        footer={null}
        maskClosable={false}
        width={500}
        onCancel={() => setEditModalShow(false)}
      >
        <Form
          form={editform}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          preserve={false}
        >
          <Form.Item
            label={t("declare.item.bindexperiment")}
            name="labId"
            rules={formRoles.labId}
          >
            <Select
              placeholder={t("declare.item.selexperiment")}
              showSearch
              filterOption={(input, option) =>
                (option?.children as unknown as string)?.includes(input) ||
                false
              }
            >
              {Object.entries(lablist).map((x: any) => (
                <Select.Option value={Number(x[0])} key={x[0]}>
                  {x[1]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t("declare.item.selexperiment")}>
            <Form.Item className="inline-block w-1/3 mr-2" name="labStepStart">
              <Input placeholder={t("declare.item.startstep")} />
            </Form.Item>
            <span className="inline-block text-center leading-8 mr-2">-</span>
            <Form.Item className="inline-block w-1/3" name="labStepEnd">
              <Input placeholder={t("declare.item.endstep")} />
            </Form.Item>
          </Form.Item>
          <Form.Item label={t("declare.item.stepobjective")} name="stepContent">
            <Input.TextArea
              rows={3}
              placeholder={t("declare.item.enterstepobjective")}
            />
          </Form.Item>
          <Form.Item label={t("declare.item.appropriate")} name="stepUseTime">
            <InputNumber
              addonAfter={t("declare.item.minute")}
              placeholder={t("declare.item.enterappropriate")}
              className="w-full"
            />
          </Form.Item>
          <Form.Item label={t("declare.item.model")} name="beiYong1">
            <Input placeholder={t("declare.item.entermodel")} />
          </Form.Item>
          <Form.Item label={t("declare.item.stepmark")} name="score">
            <Input placeholder={t("declare.item.enterpoint")} />
          </Form.Item>
          <div className="text-right">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={editLoading}
                onClick={onUpdateSteps}
              >
                {t("user.attr.savechange")}
              </Button>
              <Button onClick={() => setEditModalShow(false)}>
                {t("system.confirm.cancel")}
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </Modal>
  );
};

export default StepsModal;
