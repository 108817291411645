import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  TreeSelect,
  Upload,
  Tree,
  Rate,
  message,
  Alert,
  Steps,
  Tag,
} from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  EditOutlined,
  LinkOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { showQuestion, removeQuestion } from "../../../api/QuestionController";
import { queryTagTree } from "../../../api/queryTagTree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadByURL } from "../../../utils/download";
import QuestionImportTemplate from "../../../assets/template/QuestionImportTemplate.xls";
import { RichDisplay } from "../../components/RichEditor";
import QuestionEditDrawer, { EditRef } from "./editDrawer";
import TagModal from "./TagModal";
import { flatMapDeep } from "lodash-es";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

export interface QuestionTag {
  n_tag_id: string;
  n_pid: string;
  c_tag_name: string;
  c_note: string;
  n_level?: 1 | 2;
}

export interface QuestionTagTree extends QuestionTag {
  children?: QuestionTagTree[];
}

export interface Question {
  n_question_id: string; // id
  c_question_name: string; // 题干
  c_content: string; // 试题XML
  n_difficulty: 0 | 1 | 2 | 3; // 难度系数
  mt_answer: string; // 正确(参考)答案
  n_question_type: QuestionTypes; // 试题类型
  f_points: number; // 默认分值
  rightPercent: number; // 正确率
  wrongPercent: number; // 错误率
  tagnameList: QuestionTag[]; // 标签列表
  c_analysis: string; // 解析
  questionContent?: {
    titleImg?: string; // 题图
    choiceList?: Record<string, string>; // 备选项
    choiceImgList?: Record<string, string>; // 备选项附图
  };
}

type TagContextValues = {
  tagTree?: QuestionTagTree[];
  flattenTagTree?: QuestionTagTree[];
  tagLoading: boolean;
  refresh: () => void;
} | null;

export type QuestionTypes = 1 | 2 | 3 | 4 | 5 | 6;

export const questionTypes: Map<QuestionTypes, string> = new Map([
  [1, "单选题"],
  [2, "多选题"],
  [3, "判断题"],
  [4, "填空题"],
  [5, "简答题"],
  [6, "论述题"],
]);

const initPagination = {
  page: 1,
  rows: 10,
};

export const TagContext = createContext<TagContextValues>(null);

export const useTags = () => {
  const context = useContext(TagContext);
  if (!context) {
    throw new Error(
      "RoleContext不在父状态树中。确保在AuthorityManagePage组件节点下使用。"
    );
  }
  return context;
};

const ExamLibraryPage = () => {
  const { t } = useLocale(i18n);
  const [queryForm] = Form.useForm();
  const [tagManageOpen, setTagManageOpen] = useState(false);
  const [importShow, setImportShow] = useState(false);
  const [tagTree, setTagTree] = useState<QuestionTagTree[]>([]);
  const [flattenTagTree, setFlattenTagTree] = useState<QuestionTagTree[]>([]);
  const [tagLoading, setTagLoading] = useState(false);
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const editRef = useRef<EditRef>(null);

  const getQuestionList = (params?: any) => {
    setLoading(true);
    if (!params) setTablePagenation(initPagination);
    showQuestion(params)
      .then(({ data }) => {
        setQuestionList(data.data.rows);
        setTotal(data.data.records);
      })
      .finally(() => setLoading(false));
  };

  const getTagTree = async () => {
    setTagLoading(true);
    const { data } = await queryTagTree({});
    setTagTree(data.data);
    setFlattenTagTree(() =>
      flatMapDeep(data.data, (item) => [item, item.children])
    );
    setTagLoading(false);
  };

  useEffect(() => {
    getTagTree();
    getQuestionList();
  }, []);

  const onSearch = () => {
    const d = queryForm.getFieldsValue();
    if (Array.isArray(d?.tagIds)) {
      d.tagIds = d.tagIds.map(({ value }: any) => value).join();
    }
    getQuestionList({ ...d, ...initPagination });
    setTablePagenation(initPagination);
  };

  const onClear = () => {
    setTablePagenation(initPagination);
    queryForm.resetFields();
  };

  return (
    <div className="page-main">
      <TagContext.Provider
        value={{ tagTree, tagLoading, flattenTagTree, refresh: getTagTree }}
      >
        <CustomBreadcrumb
          breads={[t("menu.exam"), t("menu.exam.lib")]}
        ></CustomBreadcrumb>
        <div className="main-content-inner">
          <div className="query-form bg-white p-4 rounded mb-4">
            <Form layout="inline" form={queryForm}>
              <Space wrap>
                <Form.Item label={t("library.item.stem")} name="search">
                  <Input
                    placeholder={t("library.item.searchstem")}
                    allowClear
                    style={{ width: "190px" }}
                    onPressEnter={onSearch}
                  />
                </Form.Item>
                <Form.Item label={t("library.item.type")} name="typeIds">
                  <Select
                    placeholder={t("library.item.searchtype")}
                    style={{ width: "190px" }}
                    allowClear
                    options={Array.from(questionTypes, ([value, label]) => ({
                      value,
                      label,
                    }))}
                  />
                </Form.Item>
                <Form.Item label={t("library.item.tag")} name="tagIds">
                  <TreeSelect
                    showSearch
                    showArrow
                    allowClear
                    treeCheckable
                    treeCheckStrictly
                    placeholder={t("library.item.selecttag")}
                    style={{ width: "300px" }}
                    maxTagCount="responsive"
                    treeData={tagTree}
                    treeDefaultExpandAll
                    // onSelect={onTreeNodeSelect}
                    fieldNames={{ label: "c_tag_name", value: "n_tag_id" }}
                  />
                </Form.Item>
                <Space>
                  <Button onClick={onClear}>{t("library.item.clear")}</Button>
                  <Button type="primary" onClick={onSearch}>
                    {t("library.item.query")}
                  </Button>
                </Space>
              </Space>
            </Form>
          </div>
          <div className="bg-white p-4 rounded">
            <Space className="mb-4 flex items-center justify-between">
              <Space>
                <Button
                  type="primary"
                  onClick={() => editRef.current?.openToEdit()}
                >
                  {t("library.item.addquestions")}
                </Button>
                <Button
                  onClick={() => {
                    setImportShow(true);
                  }}
                >
                  {t("library.item.import")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => setTagManageOpen(true)}
                  icon={<TagsOutlined />}
                >
                  {t("library.item.tagmanagement")}
                </Button>
              </Space>
            </Space>
            <Table
              size="small"
              rowKey="n_question_id"
              bordered
              dataSource={questionList}
              loading={loading}
              pagination={{
                size: "default",
                showQuickJumper: true,
                showSizeChanger: true,
                position: ["bottomCenter"],
                current: tablePagenation.page,
                pageSize: tablePagenation.rows,
                total,
                showTotal: (total) =>
                  `${t("table.pagination.all")}${total}${t(
                    "table.pagination.total"
                  )}`,
                onChange: (page, rows) => {
                  setTablePagenation({
                    page,
                    rows,
                  });
                  const d = queryForm.getFieldsValue();
                  if (Array.isArray(d?.tagIds)) {
                    d.tagIds = d.tagIds.map(({ value }: any) => value).join();
                  }
                  getQuestionList({ ...d, page, rows });
                },
              }}
              columns={[
                {
                  title: t("library.item.index"),
                  width: 50,
                  fixed: "left",
                  align: "center",
                  render: (val, row, index) => index + 1,
                },
                {
                  title: t("library.item.stem"),
                  dataIndex: "c_question_name",
                  width: 120,
                  render: (val) => <RichDisplay html={val} />,
                },
                {
                  title: t("library.item.texttype"),
                  dataIndex: "n_question_type",
                  width: 120,
                  render: (val) => questionTypes.get(val),
                },
                {
                  title: t("library.item.tag"),
                  dataIndex: "tagnameList",
                  width: 120,
                  render: (tagnameList) => (
                    <Space wrap>
                      {tagnameList.map((tag: any) => (
                        <Tag color="blue" key={tag.n_tag_id}>
                          {tag.c_tag_name}
                        </Tag>
                      ))}
                    </Space>
                  ),
                },
                {
                  title: t("library.item.score"),
                  dataIndex: "f_points",
                  width: 120,
                },
                {
                  title: t("library.item.accuracy"),
                  dataIndex: "n_right_times",
                  width: 120,
                  render: (val) => val * 100 + "%",
                },
                {
                  title: t("library.item.errorrate"),
                  dataIndex: "n_wrong_times",
                  width: 120,
                  render: (val) => val * 100 + "%",
                },
                {
                  title: t("library.item.difficulty"),
                  dataIndex: "n_difficulty",
                  width: 90,
                  align: "center",
                  render: (val) => <Rate disabled value={val} count={3} />,
                },
                {
                  title: t("library.item.operation"),
                  width: 200,
                  align: "center",
                  render(value, row: any) {
                    return (
                      <Space>
                        <Button
                          size="small"
                          onClick={() => editRef.current?.openToEdit(row)}
                        >
                          {t("library.item.modify")}
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          danger
                          onClick={() => {
                            Modal.confirm({
                              title: t("library.item.suredel"),
                              icon: <ExclamationCircleOutlined />,
                              content: t("library.item.notrestored"),
                              centered: true,
                              async onOk() {
                                const { data } = await removeQuestion({
                                  idList: row.n_question_id,
                                });
                                if (data.code) {
                                  getQuestionList();
                                  message.success(t("library.item.delsuccess"));
                                  return;
                                }
                                data?.msg && message.error(data.msg);
                              },
                            });
                          }}
                        >
                          {t("library.item.delete")}
                        </Button>
                      </Space>
                    );
                  },
                },
              ]}
            />
          </div>
        </div>
        {/* 编辑试题抽屉 */}
        <QuestionEditDrawer ref={editRef} onFinished={onSearch} />
        {/* 标签管理modal */}
        <TagModal
          open={tagManageOpen}
          onCancel={() => setTagManageOpen(false)}
        />
        {/* 导入 modal */}
        <Modal
          title={t("library.item.batchimport")}
          destroyOnClose
          maskClosable={false}
          width={430}
          open={importShow}
          footer={null}
          centered
          onCancel={() => {
            setImportShow(false);
          }}
        >
          <Steps direction="vertical" size="small">
            <Steps.Step
              title={t("library.item.firststep")}
              icon={<CloudDownloadOutlined />}
              description={
                <>
                  {t("library.item.download")}
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => downloadByURL(QuestionImportTemplate)}
                  >
                    <>
                      <LinkOutlined />
                      {t("library.item.templatefile")}
                    </>
                  </Button>
                  。
                </>
              }
              status="process"
            />
            <Steps.Step
              title={t("library.item.secondstep")}
              icon={<EditOutlined />}
              status="process"
              description={
                <>
                  {t("library.item.fallin")}
                  <Alert
                    className="mt-2"
                    type="warning"
                    showIcon
                    message={t("library.item.ignored")}
                  />
                </>
              }
            />
            <Steps.Step
              title={t("library.item.thirdstep")}
              icon={<CloudUploadOutlined />}
              status="process"
              description={
                <>
                  {t("library.item.worksheet")}
                  <Upload.Dragger
                    className="mt-2"
                    maxCount={1}
                    // fileList={experimentXlsxList}
                    // beforeUpload={beforeUpload}
                    // onRemove={onFileRemove}
                  >
                    <p className="ant-upload-drag-icon">
                      <FontAwesomeIcon
                        icon={["fas", "cloud-arrow-up"]}
                        size="3x"
                        className="text-gray-300"
                      />
                    </p>
                    <p className="ant-upload-text">{t("library.item.drag")}</p>
                  </Upload.Dragger>
                </>
              }
            />
          </Steps>
          <Button
            block
            type="primary"
            // onClick={manuallyUpload}
            // loading={importUploading}
          >
            {false ? t("library.item.wait") : t("library.item.submitsheet")}
          </Button>
        </Modal>
      </TagContext.Provider>
    </div>
  );
};

export default ExamLibraryPage;
