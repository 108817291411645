const i18n = {
  "en-US": {
    "register.item.confirmpass": "Please confirm to pass the operation",
    "register.item.surepass": "Are you sure to register this user?",
    "register.item.passed": "The operation was successful and passed",
    "register.item.confirmmark": "Please confirm the marking operation",
    "register.item.suremark":
      "Are you sure to mark the registration of this user?",
    "register.item.marked": "The operation was successful and marked",
    "register.item.confirmunmark": "Please confirm the unmark operation",
    "register.item.sureunmark": "Are you sure to unmark?",
    "register.item.canceled": "The operation was successful and canceled",
    "register.item.confirmreject": "Please confirm the rejection operation",
    "register.item.surereject":
      "Are you sure to reject the registration of this user?",
    "register.item.rejected": "The operation was successful and rejected",
    "register.item.enterpassword": "Please enter password",
    "register.item.nameorwork": "name/work number",
    "register.item.enternameornum": "Enter your name or work (school) number",
    "register.item.modifysuccess": "Modify successfully",
    "register.item.state": "state",
    "register.item.viewall": "view all",
    "register.item.newregister": "new registration",
    "register.item.mark": "marked",
    "register.item.clear": "clear",
    "register.item.query": "query",
    "register.item.putaway": "put away",
    "register.item.develop": "develop",
    "register.item.organizationtree": "User organization tree",
    "register.item.loginid": "Work number/student number",
    "register.item.currentstate": "current state",
    "register.item.reject": "rejected",
    "register.item.pass": "passed",
    "register.item.operation": "operation",
    "register.item.edit": "Edit",
    "register.item.audit": "audit",
    "register.item.apass": "pass",
    "register.item.amark": "mark",
    "register.item.areject": "reject",
    "register.item.unmark": "unmark",
    "register.item.invalid": "invalid",
    "register.item.modifyinf": "Modify registration information",
    "register.item.worknum": "Work (school) number",
    "register.item.modifyworknum": "Modify the work (school) number",
    "register.item.modifyname": "modify name",
    "register.item.usertype": "user type",
    "register.item.student": "student",
    "register.item.teacher": "teacher",
    "register.item.choosesex": "choosing the sex",
    "register.item.organization": "user organization",
    "register.item.selorganization": "Selecting the user organization",
    "register.item.seljob": "Select job",
    "register.item.modifyphone": "Modify phone",
    "register.item.mailbox": "Modify mailbox",
    "register.item.submit": "Submit",
  },
  "zh-CN": {
    "register.item.confirmpass": "请确认通过操作",
    "register.item.surepass": "确认通过此用户的注册吗？",
    "register.item.passed": "操作成功，已通过",
    "register.item.confirmmark": "请确认标记操作",
    "register.item.suremark": "确认标记此用户的注册吗？",
    "register.item.marked": "操作成功，已标记",
    "register.item.confirmunmark": "请确认取消标记操作",
    "register.item.sureunmark": "确认取消标记吗？",
    "register.item.canceled": "操作成功，已取消",
    "register.item.confirmreject": "请确认拒绝操作",
    "register.item.surereject": "确认拒绝此用户的注册吗？",
    "register.item.rejected": "操作成功，已拒绝",
    "register.item.enterpassword": "请输入密码",
    "register.item.nameorwork": "姓名/工号",
    "register.item.enternameornum": "输入姓名或工(学)号",
    "register.item.modifysuccess": "修改成功",
    "register.item.state": "状态",
    "register.item.viewall": "查看全部",
    "register.item.newregister": "新注册",
    "register.item.mark": "已标记",
    "register.item.clear": "清除",
    "register.item.query": "查询",
    "register.item.putaway": "收起",
    "register.item.develop": "展开",
    "register.item.organizationtree": "用户组织树",
    "register.item.loginid": "工号/学号",
    "register.item.currentstate": "当前状态",
    "register.item.reject": "已拒绝",
    "register.item.pass": "已通过",
    "register.item.operation": "操作",
    "register.item.edit": "编辑",
    "register.item.audit": "审核",
    "register.item.apass": "通过",
    "register.item.amark": "标记",
    "register.item.areject": "拒绝",
    "register.item.unmark": "取消标记",
    "register.item.invalid": "无效",
    "register.item.modifyinf": "修改注册信息",
    "register.item.worknum": "工(学)号",
    "register.item.modifyworknum": "修改工(学)号",
    "register.item.modifyname": "修改姓名",
    "register.item.usertype": "用户类型",
    "register.item.student": "学生",
    "register.item.teacher": "教师",
    "register.item.choosesex": "选择性别",
    "register.item.organization": "用户组织",
    "register.item.selorganization": "选择用户组织",
    "register.item.seljob": "选择职务",
    "register.item.modifyphone": "修改电话",
    "register.item.mailbox": "修改邮箱",
    "register.item.submit": "提交",
  },
};

export default i18n;
