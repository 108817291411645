import {
  CheckOutlined,
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Button,
  Checkbox,
  Tree,
  Form,
  Input,
  Popover,
  Select,
  Space,
  message,
  Empty,
  Tag,
  Spin,
} from "antd";
import { cloneDeep, groupBy } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import {
  addOneRole,
  deleteOneRole,
  listAllByParams,
  listRoleByParams,
  updateOneRole,
} from "../../../api/NewRightRoleController";
import { useLocale } from "../../../hooks/useLocale";
import { righttypes, useRights } from ".";
import type { defaultI18nKeys } from "../../../i18n/default";
import type { RightTypes, RightInstance } from ".";
import type { CheckboxGroupProps } from "antd/lib/checkbox";
import type { TreeProps } from "antd";
import { array2Tree } from "../../../utils/format";
import i18n from "./i18n";

interface RoleManageModalProps {
  open?: boolean;
  onCancel?: () => void;
}

interface RoleRight {
  rightId: number;
  rightType: RightTypes;
}

export interface RoleInstance {
  id: number;
  roleTitle: string;
  roleNote?: string;
  roleIcon?: string;
  dtoList?: RightInstance[];
}

const RoleManageModal = ({ open, onCancel }: RoleManageModalProps) => {
  // 角色栏状态
  const {
    roles,
    refreshRoles,
    rolesLoading,
    rightGroup,
    rightMap,
    rightLoading,
    refreshRights,
  } = useRights();
  const { t } = useLocale(i18n);
  const [addPopShow, setAddPopShow] = useState(false);
  const [addform] = Form.useForm();
  const [addformLoading, setAddformLoading] = useState(false);
  const [editingRoleName, setEditingRoleName] = useState<string>();
  const [editingRoleId, setEditingRoleId] = useState<number>();
  const [crtRole, setCrtRole] = useState<RoleInstance>();
  // 权限栏状态
  const [checkedRightSet, setCheckedRightSet] = useState<Set<number>>();
  const [rightEditView, setRightEditView] = useState(false);
  const [roleRightChecked, setRoleRightChecked] = useState();
  const [rightSaving, setRightSaving] = useState(false);

  // 所有权限
  const getRights = useCallback(() => {
    refreshRights();
  }, [refreshRights]);

  const getRoles = useCallback(
    (keepRoleId?: number) => {
      refreshRoles().then((data) => {
        setEditingRoleId(undefined);
        setCrtRole(data.find((x: any) => x.id === keepRoleId));
      });
    },
    [refreshRoles]
  );

  const addRole = () => {
    setAddformLoading(true);
    addform
      .validateFields()
      .then(addOneRole)
      .then(({ data }) => {
        if (data.code) {
          getRoles();
          message.success(t("authority.item.createrole"));
          setAddPopShow(false);
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setAddformLoading(false));
  };

  const onCheck = useCallback((rights: Set<number>) => {
    setCheckedRightSet(rights);
  }, []);

  const onRoleSelect = (role: RoleInstance) => {
    if (Object.is(role, crtRole)) return;
    setRightEditView(false);
    setCrtRole(role);
  };

  const onEditRoleName = (role: RoleInstance) => {
    setEditingRoleId(role.id);
    setEditingRoleName(role.roleTitle);
  };

  const onUpdateRoleName = () => {
    // 编辑角色名称
    if (!editingRoleName) {
      message.error(t("authority.item.entername"));
      return Promise.reject(t("authority.item.entername"));
    }
    return updateOneRole({
      id: editingRoleId!,
      roleTitle: editingRoleName,
    }).then(({ data }) => {
      if (data.code) {
        message.success(t("authority.item.modifyrolename"));
        setEditingRoleId(undefined);
        getRoles();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const onDelRole = ({ id, roleTitle }: RoleInstance) => {
    Modal.confirm({
      content: `${t("authority.item.suredel")} ${roleTitle} ${t(
        "authority.item.notchange"
      )} `,
      onOk() {
        deleteOneRole({ id }).then(({ data }) => {
          if (data.code) {
            message.success(t("authority.item.delsuccess"));
            setEditingRoleId(undefined);
            getRoles();
            return;
          }
          data?.msg && message.error(data.msg);
        });
      },
    });
  };

  // 权限栏处理函数
  const editCrtRight = () => {
    if (!crtRole) return;
    setRightEditView(true);
  };

  // 保存角色权限
  const onSaveCrtRight = () => {
    setRightSaving(true);
    if (!crtRole) return;
    const { id, roleTitle } = crtRole;
    let roleRightList: RoleRight[] = Array.from(checkedRightSet || []).map(
      (id) => ({
        rightId: id,
        rightType: rightMap!.get(id)!.rightType,
      })
    );
    // console.log(checkedRightSet);
    updateOneRole({ id, roleTitle, roleRightList })
      .then(({ data }) => {
        if (data.code) {
          setRightEditView(false);
          getRoles(crtRole?.id);
          message.success(t("authority.item.permissionsupdate"));
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setRightSaving(false));
  };

  useEffect(() => {
    const checked = crtRole?.dtoList?.map((x) => x.id);
    setCheckedRightSet(new Set(checked));
  }, [crtRole]);

  useEffect(() => {
    if (open) {
      setRightEditView(false);
      getRights();
      getRoles();
    }
  }, [getRights, getRoles, open]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t("authority.item.rolemanagement")}
      width={900}
      footer={null}
      destroyOnClose
    >
      <div className="flex">
        <div className="border-gray-200 border-1 border-solid rounded my-2 w-80 flex-shrink-0 mr-4">
          <div className="bg-gray-200 px-2 py-1 flex items-center justify-between">
            {t("authority.item.role")}
            <Popover
              trigger="click"
              placement="bottom"
              destroyTooltipOnHide
              open={addPopShow}
              onOpenChange={setAddPopShow}
              content={
                <Form labelAlign="right" preserve={false} form={addform}>
                  <Form.Item
                    label={t("authority.item.rolename")}
                    name="roleTitle"
                    rules={[
                      {
                        message: t("authority.item.entername"),
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t("authority.item.entername")} />
                  </Form.Item>

                  <div className="text-right">
                    <Space>
                      <Button onClick={() => setAddPopShow(false)}>
                        {t("system.confirm.cancel")}
                      </Button>
                      <Button
                        type="primary"
                        onClick={addRole}
                        loading={addformLoading}
                      >
                        {t("authority.item.createroles")}
                      </Button>
                    </Space>
                  </div>
                </Form>
              }
            >
              <Button type="link" size="small" icon={<PlusOutlined />}>
                {t("authority.item.createnewroles")}
              </Button>
            </Popover>
          </div>
          <Spin spinning={rolesLoading}>
            <div className="p-2 h-full">
              {roles?.length ? (
                roles.map((role) => (
                  <div
                    className="role-card"
                    key={role.id}
                    style={
                      crtRole?.id === role.id
                        ? {
                            background:
                              "linear-gradient(45deg, #ebf4f5, #b5c6e0)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }
                        : undefined
                    }
                    onClick={() => onRoleSelect(role)}
                  >
                    {editingRoleId === role.id ? (
                      <Input
                        placeholder={t("authority.item.entername")}
                        value={editingRoleName}
                        onChange={(e) => setEditingRoleName(e.target.value)}
                      />
                    ) : (
                      <span>{role.roleTitle}</span>
                    )}

                    <div className="opts">
                      {editingRoleId === role.id ? (
                        <>
                          <div
                            className="opt"
                            title={t("authority.item.save")}
                            onClick={(e) => {
                              e.stopPropagation();
                              onUpdateRoleName();
                            }}
                          >
                            <CheckOutlined />
                          </div>
                          <div
                            className="opt"
                            title={t("system.confirm.cancel")}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingRoleId(undefined);
                            }}
                          >
                            <StopOutlined />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="opt"
                            title={t("authority.item.modifyname")}
                            onClick={(e) => {
                              e.stopPropagation();
                              onEditRoleName(role);
                            }}
                          >
                            <FormOutlined />
                          </div>
                          <div
                            className="opt"
                            title={t("authority.item.deleteroles")}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelRole(role);
                            }}
                          >
                            <DeleteOutlined />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <Empty description={t("authority.item.norolecreate")} />
              )}
            </div>
          </Spin>
        </div>
        <div className="flex-grow border-gray-200 border-1 border-solid rounded my-2">
          <div className="bg-gray-200 px-2 py-1 flex items-center justify-between sticky top-0 z-2">
            <Space>
              {crtRole
                ? `${crtRole.roleTitle}${t("authority.item.allpermission")}`
                : t("authority.item.allpermissions")}
              {`${checkedRightSet?.size || 0}/${rightMap?.size || 0}`}
              {rightEditView && (
                <Tag color="warning">{t("authority.item.editview")}</Tag>
              )}
            </Space>
            {rightEditView ? (
              <Space>
                <Button
                  type="link"
                  size="small"
                  loading={rightSaving}
                  onClick={onSaveCrtRight}
                >
                  {t("authority.item.save")}
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => setRightEditView(false)}
                >
                  {t("authority.item.return")}
                </Button>
              </Space>
            ) : crtRole ? (
              <Button type="link" size="small" onClick={editCrtRight}>
                {t("authority.item.edit")}
              </Button>
            ) : null}
          </div>
          {rightEditView ? (
            <RightEditTree checkedKeys={checkedRightSet} onCheck={onCheck} />
          ) : (
            <div>
              {crtRole ? (
                <RightViewGroup
                  flatData={crtRole?.dtoList}
                  onEdit={editCrtRight}
                />
              ) : (
                <Empty
                  description={t("authority.item.selrole")}
                  className="my-4"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

interface RightEditTreeProps {
  checkedKeys?: Set<number>;
  onCheck?: (rights: Set<number>) => void;
  checkable?: boolean;
  disabledKeys?: Set<number>;
}

export const RightEditTree = ({
  checkedKeys,
  onCheck,
  disabledKeys,
}: RightEditTreeProps) => {
  const { t } = useLocale(i18n);
  const { rightGroup, rightMap } = useRights();

  const onNodeCheck: TreeProps<any>["onCheck"] = (
    _nodes,
    { checked, node }
  ) => {
    const s = new Set(checkedKeys);
    if (!rightMap) {
      console.warn(t("authority.item.rightmap"));
      return s;
    }
    if (checked) {
      s.add(node.id);

      // 向后遍历，选中所有子节点
      let rhead,
        rlist = Array.isArray(node.child) ? [...node.child] : [];

      while (rlist.length) {
        rhead = rlist.shift();
        s.add(rhead.id);
        if (rhead?.child?.length) {
          rlist.unshift(...rhead.child);
        }
      }

      // 向前访问祖先节点链并check，直到pid为0
      let elder = node.pid;
      while (elder !== 0 && rightMap.has(elder)) {
        s.add(elder);
        elder = rightMap.get(elder)?.pid;
      }
    } else {
      s.delete(node.id);

      // 向后遍历，取消所有子节点
      let rhead,
        rlist = Array.isArray(node.child) ? [...node.child] : [];
      while (rlist.length) {
        rhead = rlist.shift();
        s.delete(rhead.id);
        if (rhead?.child?.length) {
          rlist.unshift(...rhead.child);
        }
      }

      // 判断同辈节点有无check，无则取消父节点并继续向前判断，有则跳出循环
      let checked = Array.from(s);
      let elder = node.pid;
      let siblings;

      while (elder) {
        let elderNode = rightMap.get(elder);
        siblings = elderNode?.child;

        if (!siblings || siblings.some((x) => checked.includes(x.id))) break;

        s.delete(elder);
        checked.splice(checked.indexOf(elder), 1);
        elder = elderNode!.pid;
      }
    }

    onCheck?.(s);
  };

  const getTreeData = useCallback(
    (key: RightTypes): any => {
      const tree = cloneDeep(rightGroup?.[key]);
      // 遍历树节点并禁用
      let head: any,
        list = Array.isArray(tree) ? [...tree] : [];

      while (list.length) {
        head = list.shift();
        if (disabledKeys?.has(head.id)) {
          head.disabled = true;
        } else {
          head.disabled = false;
        }
        if (head?.child?.length) {
          list.unshift(...head.child);
        }
      }

      return tree;
    },
    [disabledKeys, rightGroup]
  );

  return (
    <div>
      {rightGroup &&
        Object.keys(rightGroup)?.map((k: unknown) => (
          <div key={k as string}>
            <div className="bg-gray-100 px-2 py-1 sticky top-6.5 z-1 font-bold">
              {`${
                righttypes?.[k as RightTypes] || t("authority.item.other")
              }${t("authority.item.authority")}`}
            </div>
            <div className="p-2">
              <Tree
                checkable
                checkStrictly
                defaultExpandAll
                treeData={getTreeData(k as RightTypes)}
                checkedKeys={Array.from(checkedKeys || [])}
                onCheck={onNodeCheck}
                fieldNames={{
                  title: "title",
                  key: "id",
                  children: "child",
                }}
                titleRender={(node) =>
                  node.rightType === 1
                    ? t(node.title as defaultI18nKeys)
                    : node.title
                }
              />
            </div>
          </div>
        ))}
    </div>
  );
};

interface RightViewGroupProps {
  flatData?: RightInstance[];
  onEdit?: () => void;
}

const RightViewGroup = ({ flatData, onEdit }: RightViewGroupProps) => {
  const { t } = useLocale(i18n);
  const [group, setGroup] = useState<{ [k in RightTypes]?: RightInstance[] }>();

  useEffect(() => {
    if (Array.isArray(flatData) && flatData.length) {
      const tree = array2Tree(flatData);
      const gro = groupBy(tree, "rightType");
      setGroup(gro);
      // console.log("flat: ", flatData, "\ntree: ", tree, "\ngroup: ", gro);
    } else {
      setGroup(undefined);
    }
  }, [flatData]);

  return (
    <div>
      {group ? (
        Object.keys(group).map((k: unknown) => (
          <div key={k as number}>
            <div className="bg-gray-100 px-2 py-1 sticky top-6.5 z-1 font-bold">
              {`${
                righttypes?.[k as RightTypes] || t("authority.item.other")
              }${t("authority.item.authority")}`}
            </div>
            <div className="p-2">
              <Tree
                defaultExpandAll
                treeData={group[k as RightTypes] as any}
                fieldNames={{
                  title: "title",
                  key: "id",
                }}
                titleRender={
                  // 菜单i8n
                  Number(k) === 1
                    ? (node) => t(node.title as defaultI18nKeys)
                    : undefined
                }
              />
            </div>
          </div>
        ))
      ) : (
        <Empty
          className="my-4"
          description={
            <>
              {t("authority.item.notassign")}
              <Button type="link" className="p-0" onClick={() => onEdit?.()}>
                {t("authority.item.clickedit")}
              </Button>
            </>
          }
        />
      )}
    </div>
  );
};

export default RoleManageModal;
