import { Card, Row, Col, Button, Empty, Image, message, Drawer } from "antd";
import { useCallback, useEffect, useState } from "react";
import { queryMyLab } from "../../api/NewLabInfoController";
import { queryLabScoreByLabId } from "../../api/NewScoreController";
import { useLocale } from "../../hooks/useLocale";
import LabReport from "../components/LabReport";
import i18n from "./i18n";
import picPlaceholder from "../../assets/images/pic-placeholder.png";
import know1 from "../../assets/know-zh/know1.png";
import know2 from "../../assets/know-zh/know2.png";
import know3 from "../../assets/know-zh/know3.png";
import enknow1 from "../../assets/know-en/enknow1.png";
import enknow2 from "../../assets/know-en/enknow2.png";
import enknow3 from "../../assets/know-en/enknow3.png";
import { useSystemInfo } from "../../hooks/useSystemInfo";
import { RichDisplay } from "../components/RichEditor";
import useStartExp from "../../hooks/useStartExp";
import CompositeScoreList from "../experiment/mine/CompositeScoreList";
import { addScoreVideo } from "../../api/LabSettingController";

const handbookImgs = {
  "zh-CN": [know1, know2, know3],
  "en-US": [enknow1, enknow2, enknow3],
} as const;

const LabList = () => {
  const { startExp } = useStartExp();

  const { t, lang } = useLocale(i18n);
  const [labList, setLabList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // 成绩列表
  const [labRptShow, setLabRptShow] = useState(false);
  const [scoreId, setScoreId] = useState<number>();
  const { sysInfo } = useSystemInfo();

  const [handbookShow, setHandbookShow] = useState(false);

  const [compositeScoreListOpen, setCompositeScoreListOpen] = useState(false);
  const [compositeScoreListParam, setCompositeScoreListParam] =
    useState<any>(null);

  const reviewScoreList = (labid: number, type: number) => {
    setCompositeScoreListParam({ labid, type });
    setCompositeScoreListOpen(true);
  };

  // 实验报告
  const reviewLabReport = (id: number) => {
    setScoreId(id);
    setLabRptShow(true);
  };

  // 查询个人实验列表
  const getLabList = useCallback(() => {
    setLoading(true);
    queryMyLab({ lang })
      .then(({ data }) => {
        if (data.code) {
          setLabList(data.data.rows);
        }
      })
      .finally(() => setLoading(false));
  }, [lang]);

  // 填写报告
  const fillReport = (labid: string) => {
    queryLabScoreByLabId({ labid }).then(({ data }) => {
      if (data.code) {
        setScoreId(data.data.id);
        setLabRptShow(true);
      } else {
        data.msg && message.error(data.msg);
      }
    });
  };

  const openVideo = (source: string) => {
    window.open(source);
  };

  const goPractice = (labId: number) => {
    addScoreVideo({ labId }).then(({ data }) => {
      if (data.code) {
        window.open(`/#/practice/${data.data}`);
        return;
      }
    });
  };

  useEffect(() => {
    getLabList();
  }, [getLabList]);

  return (
    <div className="declare-lab-list bg-white rounded-md py-5 mb-5">
      {labList.length > 0 ? (
        <>
          {labList.map((el, index) => {
            return (
              <Card
                className="mb-10 mx-auto"
                style={{ width: 1010 }}
                key={el.id}
                actions={[
                  index === 0 && sysInfo?.introVideo && (
                    <span onClick={() => openVideo(sysInfo.introVideo)}>
                      {t("declare.actions.introvideo")}
                    </span>
                  ),
                  index === 0 && sysInfo?.guideVideo && (
                    <span onClick={() => openVideo(sysInfo.guideVideo)}>
                      {t("declare.actions.guidevideo")}
                    </span>
                  ),
                  el.nlabtype !== 9 && (
                    <span onClick={() => fillReport(el.id)}>
                      {t("declare.actions.report")}
                    </span>
                  ),
                  <span
                    onClick={() => {
                      reviewScoreList(el.id, el.nlabtype);
                    }}
                  >
                    {t("declare.actions.results")}
                  </span>,
                  <span onClick={() => goPractice(el.id)}>
                    {t("declare.actions.practice")}
                  </span>,
                  <span onClick={() => setHandbookShow(true)}>
                    {t("declare.actions.instruction")}
                  </span>,
                ].filter((x) => Boolean(x))}
              >
                <Row gutter={[20, 0]}>
                  <Col span={10}>
                    <Image
                      preview={false}
                      src={el.cImgid}
                      height={270}
                      width={396}
                      fallback={picPlaceholder}
                    />
                  </Col>
                  <Col span={14} className="relative">
                    <div className="text-2xl">
                      {el?.langList?.[0]?.[lang]?.kcType || el.kcType}
                    </div>
                    <RichDisplay
                      className="font-bold text-xl"
                      html={el?.langList?.[0]?.[lang]?.["name"] || el.cName}
                    />
                    <RichDisplay
                      className="text-base mt-2 indent-2em"
                      html={el?.langList?.[0]?.[lang]?.["note"] || el.cNote}
                    />
                    <Button
                      className="absolute bottom-0 right-10 font-bold border-none"
                      size="large"
                      type="primary"
                      onClick={() =>
                        startExp(
                          el.id,
                          el?.langList?.[0]?.[lang]?.labPath || el.cZyhome,
                          el.nlabtype
                        )
                      }
                    >
                      {el?.nlabtype === 9
                        ? t("declare.actions.play")
                        : t("declare.actions.start")}
                    </Button>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </>
      ) : (
        <Empty />
      )}
      <CompositeScoreList
        open={compositeScoreListOpen}
        onCancel={() => setCompositeScoreListOpen(false)}
        param={compositeScoreListParam}
        onReportView={(id) => reviewLabReport(id)}
      />
      <LabReport
        visible={labRptShow}
        setVisible={setLabRptShow}
        scoreid={scoreId}
        allowEdit
      />
      <Drawer
        title={t("declare.actions.instruction")}
        width="50%"
        placement="right"
        onClose={() => setHandbookShow(false)}
        open={handbookShow}
      >
        <div className="bg-white px-5 rounded">
          {handbookImgs[lang].map((img) => (
            <Image key={img} src={img} preview={false}></Image>
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default LabList;
