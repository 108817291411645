import req from "../configs/axios";

// 查询实验列表
const queryLabList = (data?: any) => {
  return req.post("auth/NewLabInfoController/queryLabList", data);
};

// 学生查询个人实验列表
const queryLabListByMyLab = (data?: any) => {
  return req.post("auth/NewLabInfoController/queryLabListByMyLab", data);
};

// 查询实验列表(结构简化)
const listLabIdAndLabNameByParam = (data?: any) => {
  return req.post("auth/NewLabInfoController/listLabIdAndLabNameByParam", data);
};

// 批量删除实验
const deleteBatchLab = (data?: any) => {
  return req.post("auth/NewLabInfoController/deleteBatchLab", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 创建实验
const addOneLab = (data?: any) => {
  return req.post("auth/NewLabInfoController/addOneLab", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 更新单个实验
const updateOneLab = (data?: any) => {
  return req.post("auth/NewLabInfoController/updateOneLab", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 查询课程列表
const listAllKcByParams = (data?: any) => {
  return req.post("auth/NewLabInfoController/listAllKcByParams", data);
};

// 新增课程
const addOneKc = (data?: any) => {
  return req.post("auth/NewLabInfoController/addOneKc", data);
};

// 编辑课程
const updateOneKc = (data?: any) => {
  return req.post("auth/NewLabInfoController/updateOneKc", data);
};

// 删除课程
const deleteOneKc = (data?: any) => {
  return req.post("auth/NewLabInfoController/deleteOneKc", data);
};

// 查询个人实验列表
const queryMyLab = (data?: any) => {
  return req.post("auth/NewLabInfoController/queryMyLab", data);
};

// 通过二级密码，查询实验编号和实验路径
const sypasswordTorF = (data?: any) => {
  return req.post("auth/NewLabInfoController/sypasswordTorF", data);
};

// 设置实验类型或子模块
const updateLabTypeById = (data?: any) => {
  return req.post("auth/NewLabInfoController/updateLabTypeById", data);
};

// 设置实验步骤信息和实验地址
const updateLabHomeAndJSON = (
  data?: FormData,
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  return req.post("auth/NewLabInfoController/updateLabHomeAndJSON", data, {
    headers: { "Content-Type": "multipart/form-data;" },
    timeout: 0,
    onUploadProgress,
  });
};

// 添加virtools编号和实验关系
const addLabidTovirtoolsByexpid = (data?: any) => {
  return req.post("auth/NewLabInfoController/addLabidTovirtoolsByexpid", data);
};

// 查询实验步骤树
const showStepTree = (data?: any) => {
  return req.post("auth/NewLabInfoController/showStepTree", data);
};

// 查询教学提示树
const showTeachTree = (data?: any) => {
  return req.post("auth/NewLabInfoController/showTeachTree", data);
};

// 获取实验模块设置
const findLabAssociatedAllByPid = (data?: any) => {
  return req.post("auth/NewLabInfoController/findLabAssociatedAllByPid", data);
};

// 实验设置-多模块设置
const addLabSetupModeByLabId = (data?: any) => {
  return req.post("auth/NewLabInfoController/addLabSetupModeByLabId", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 实验设置-初始化多模块设置
const deleteLabSetupModeAndLabDeblockingByLabId = (data?: any) => {
  return req.post(
    "auth/NewLabInfoController/deleteLabSetupModeAndLabDeblockingByLabId",
    data
  );
};

// 实验设置-实验步骤-查询步骤提示信息与试题
const showtishiinfoByPmidAndStepindex = (data?: any) => {
  return req.post(
    "auth/NewLabInfoController/showtishiinfoByPmidAndStepindex",
    data
  );
};

// 更新实验步骤信息设置
const updateStepInfoByPmid = (data?: any) => {
  return req.post("auth/NewLabInfoController/updateStepInfoByPmid", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 实验设置-备份还原
const restoreBackUp = (data: {
  pmid: number;
  backType: "backstep" | "backteach" | "backautoscore";
}) => {
  return req.post("auth/NewLabInfoController/restoreBackUp", data);
};

// 实验设置-查询实验自动扣分与实验时长设置
const showAutoScore = (data: { pmid: number }) => {
  return req.post("auth/NewLabInfoController/showAutoScore", data);
};

// 实验设置-修改实验自动扣分与实验时长设置
const addLabSetUpTimeByLabId = (data?: any) => {
  return req.post("auth/NewLabInfoController/addLabSetUpTimeByLabId", data);
};

// 实验设置-查询手动提交分数设置
const findBasicScoreByLabId = (data: { tLabId: number }) => {
  return req.post("auth/NewLabInfoController/findBasicScoreByLabId", data);
};

// 实验设置-更新手动提交分数设置
const addBasicScore = (data: { tLabId: number }) => {
  return req.post("auth/NewLabInfoController/addBasicScore", data);
};

// 实验设置-查询教学图片列表
const queryjiaoxueimgBypid = (data?: any) => {
  return req.post("auth/NewLabInfoController/queryjiaoxueimgBypid", data);
};

// 实验设置-新增一份教学图片
const addImgTableFile = (
  data: FormData,
  onUploadProgress?: (event: ProgressEvent) => void
) => {
  return req.post("auth/NewLabInfoController/addImgTableFile", data, {
    headers: { "Content-Type": "multipart/form-data;" },
    timeout: 0,
    onUploadProgress,
  });
};

// 实验设置-删除指定教学图片
const deleteImgTableFile = (data?: any) => {
  return req.post("auth/NewLabInfoController/deleteImgTableFile", data);
};

// 实验设置-查询实验教学信息
const getLearnContentById = (data?: any) => {
  return req.post("auth/NewLabInfoController/getLearnContentById", data);
};

// 实验设置-更新实验教学信息
const updateLearnContentById = (data?: any) => {
  return req.post("auth/NewLabInfoController/updateLearnContentById", data);
};

export {
  queryLabList,
  queryLabListByMyLab,
  listLabIdAndLabNameByParam,
  deleteBatchLab,
  addOneLab,
  updateOneLab,
  listAllKcByParams,
  addOneKc,
  updateOneKc,
  deleteOneKc,
  queryMyLab,
  sypasswordTorF,
  updateLabTypeById,
  updateLabHomeAndJSON,
  addLabidTovirtoolsByexpid,
  showStepTree,
  showTeachTree,
  findLabAssociatedAllByPid,
  addLabSetupModeByLabId,
  deleteLabSetupModeAndLabDeblockingByLabId,
  showtishiinfoByPmidAndStepindex,
  updateStepInfoByPmid,
  restoreBackUp,
  showAutoScore,
  addLabSetUpTimeByLabId,
  findBasicScoreByLabId,
  addBasicScore,
  queryjiaoxueimgBypid,
  addImgTableFile,
  deleteImgTableFile,
  getLearnContentById,
  updateLearnContentById,
};
