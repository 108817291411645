import req from "../configs/axios";

// 查询申报导航菜单
const listSubject = (data?: any) => {
  return req.post("auth/SubjectController/listSubject", data);
};

// 更新申报导航菜单
const updateSubject = (data?: any) => {
  return req.post("auth/SubjectController/updateSubject", data);
};

// 新增申报导航菜单
const addSubject = (data?: any) => {
  return req.post("auth/SubjectController/addSubject", data);
};

// 删除申报导航菜单
const deleteSubject = (data?: any) => {
  return req.post("auth/SubjectController/deleteSubject", data);
};

export { listSubject, updateSubject, addSubject, deleteSubject };
