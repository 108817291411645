import { PlusOutlined } from "@ant-design/icons";
import { Form, Spin, Button, Switch, message } from "antd";
import { useCallback, useEffect, useState, type FC } from "react";
import { useExperimentManage } from "..";
import {
  addBatchLabQuestion,
  listLabQuestionByLabId,
} from "../../../../api/LabSettingController";
import QuestionPicker from "../../../components/QuestionPicker";
import { useLocale } from "../../../../hooks/useLocale";
import i18n from "./i18n";

interface VideoPanelProps {
  labId: number;
}

const VideoPanel: FC<VideoPanelProps> = ({ labId }) => {
  const { t } = useLocale(i18n);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { setQuestionPickerOpen, questionPicked, setQuestionPicked, qRef } =
    useExperimentManage();

  const getSetting = useCallback(() => {
    setLoading(true);
    listLabQuestionByLabId({ labId })
      .then(({ data }) => {
        if (data.code) {
          setQuestionPicked(data.data);
        }
      })
      .finally(() => setLoading(false));
  }, [labId, setQuestionPicked]);

  useEffect(() => {
    const questionList = questionPicked.map((x, i) => ({
      c_question_name: x.c_question_name,
      n_question_id: x.n_question_id,
      n_question_type: x.n_question_type,
      f_points: x.f_points,
      order: i,
    }));

    form.setFieldsValue({ questionList });
  }, [form, questionPicked]);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  const save = () => {
    const d = form.getFieldsValue();
    const questionList = d.questionList
      ?.map((x: any) => x.n_question_id)
      ?.join();

    setSaving(true);
    addBatchLabQuestion({
      labId,
      questionList,
    })
      .then(({ data }) => {
        if (data.code) {
          message.success(t("Setupanels.item.savesuccess"));
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setSaving(false));
  };

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Form.Item
          name="questionList"
          label={t("Setupanels.item.testqu")}
          tooltip={t("Setupanels.item.afterset")}
        >
          <QuestionPicker.FormItem
            onDelete={(qid) => qRef.current?.unpick(qid)}
          />
        </Form.Item>
        <Button
          type="dashed"
          className="mb-4"
          block
          icon={<PlusOutlined />}
          onClick={() => setQuestionPickerOpen(true)}
        >
          {t("Setupanels.item.addquestion")}
        </Button>
      </Form>
      <Button type="primary" loading={saving} onClick={save}>
        {t("Setupanels.item.savechange")}
      </Button>
    </Spin>
  );
};

export default VideoPanel;
