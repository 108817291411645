import {
  Button,
  Layout,
  Badge,
  Space,
  Avatar,
  Dropdown,
  Menu,
  Tooltip,
  message,
  Modal,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BarsOutlined,
  UnlockOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
  CommentOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { type FC, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import ProfilePanel from "./ProfilePanel";
import ChangePwdPanel from "./ChangePwdPanel";
import useGuide from "../../hooks/useGuide";
import { useLocale } from "../../hooks/useLocale";
import defaultLocale from "../../i18n/default";
import defaultAvatar from "../../assets/images/avatar-default.png";
import MyLogPanel from "./MyLogPanel";
import NoticePopover from "../components/NoticePopover";
import { useSystemInfo } from "../../hooks/useSystemInfo";
import useNotice from "../../hooks/useNotice";
import MyQuestion from "../declare/myQuestion";
import { listQaAllByLogin } from "../../api/NewUserController";
import QuestionList from "../declare/onlineQuestion";

interface CustomHeaderProps {
  onCollapse: () => void;
  collapsed: boolean;
  showCollapse?: boolean;
  logo?: boolean;
}

const steps = [
  {
    element: "#header-collapse-toggle",
    popover: {
      title: "折叠菜单",
      description: "您可以点击这里折叠或展开左侧菜单栏。",
      position: "right",
    },
  },
  {
    element: "#header-home",
    popover: {
      title: "返回首页",
      description: "您可以点击logo图标从任何位置返回首页。",
      position: "bottom",
    },
  },
  {
    element: "#header-language",
    popover: {
      title: "语言",
      description: "从这里切换界面语言",
      position: "left",
    },
  },
  {
    element: "#header-chatroom",
    popover: {
      title: "聊天室",
      description: "从这里进入聊天室与其他在线用户交流。",
      position: "left",
    },
  },
  {
    element: "#header-notification",
    popover: {
      title: "通知消息面板",
      description:
        "在这里唤出消息面板，查看系统和管理员发送的通知，以及您的工作进展更新。",
      position: "left",
    },
  },
  {
    element: "#header-user-actions",
    popover: {
      title: "个人面板",
      description: "从您的头像唤出菜单，可以修改个人信息、密码或登出系统。",
      position: "left",
    },
  },
];

const CustomHeader: FC<CustomHeaderProps> = ({
  onCollapse,
  collapsed,
  showCollapse = true,
  logo = false,
}) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { startGuide } = useGuide(steps);
  const { lang, setLang, t } = useLocale();
  const { sysInfo } = useSystemInfo();
  const { count } = useNotice();

  // 个人资料
  const [personalShow, setPersonalShow] = useState(false);
  // 修改密码
  const [pswShow, setPswShow] = useState(false);
  //登录日志
  const [logTableShow, setLogTableShow] = useState(false);

  const [askShow, setAskShow] = useState(false);
  const [loginQaList, setLoginQaList] = useState<any[]>([]);

  //在线问答
  const [onlineModalShow, setOnlineModalShow] = useState(false);

  const closeOnlineModal = () => {
    setOnlineModalShow(false);
  };

  const dologout = () =>
    logout()
      .then((_res) => {
        navigate("/");
        message.success(t("system.msg.logout.success"));
      })
      .catch((err) => {
        console.log(err);
        err?.msg && message.error(err.msg);
      });

  const changeLanguage = (language: SupportedLangType) => {
    if (language !== lang) {
      setLang(language);
      const nextLang = defaultLocale[language];
      message.info(`${nextLang["system.msg.switchlang"]} ${language}`);
    }
  };

  //登录人自己的提问列表
  const LoginQaList = async () => {
    const { data } = await listQaAllByLogin({ page: 1, rows: 10 });
    if (data.code) {
      setLoginQaList(data.data.rows);
    }
  };

  useEffect(() => {
    LoginQaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Layout.Header className="bg-white h-16 px-4 leading-none z-5 flex items-center justify-between shadow select-none">
      {showCollapse && (
        <Button onClick={onCollapse} id="header-collapse-toggle">
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      )}
      {logo && (
        <img
          src={sysInfo?.logo}
          className="h-2/3 object-contain"
          id="header-home"
          alt=""
        />
      )}
      <div className="flex-grow text-right">
        <Space size={17}>
          <Tooltip title={t("header.tips.online")}>
            <Button
              shape="circle"
              className="header-action-btn"
              icon={<EditOutlined />}
              onClick={(e) => {
                setOnlineModalShow(true);
              }}
            />
          </Tooltip>
          <Tooltip title={t("header.tips.guide")}>
            <Button
              shape="circle"
              className="header-action-btn"
              icon={<FontAwesomeIcon icon={["far", "question-circle"]} />}
              onClick={(e) => {
                e.stopPropagation();
                startGuide();
              }}
            />
          </Tooltip>
          <NoticePopover>
            <Badge count={count?.unread} size="small">
              <Button
                shape="circle"
                id="header-notification"
                className="header-action-btn"
                icon={<FontAwesomeIcon icon={["far", "bell"]} />}
              />
            </Badge>
          </NoticePopover>
          <Dropdown
            arrow
            placement="bottomRight"
            overlayClassName="!min-w-20"
            menu={{
              items: [
                {
                  key: 1,
                  label: t("header.item.profile"),
                  icon: <BarsOutlined />,
                  onClick: () => setPersonalShow(true),
                },
                {
                  key: 2,
                  label: t("header.item.password"),
                  icon: <UnlockOutlined />,
                  onClick: () => setPswShow(true),
                },
                {
                  key: 3,
                  label: t("header.item.rizhi"),
                  icon: <FileTextOutlined />,
                  onClick: () => setLogTableShow(true),
                },
                {
                  key: 4,
                  label: t("header.item.question"),
                  icon: <QuestionCircleOutlined />,
                  onClick: () => setAskShow(true),
                },
                //<FileTextOutlined />
                { type: "divider" },
                {
                  key: 5,
                  label: t("header.item.logout"),
                  icon: (
                    <FontAwesomeIcon
                      icon="power-off"
                      color="#FF5C5F"
                      className="text-l"
                    />
                  ),
                  onClick: dologout,
                },
              ],
            }}
          >
            <div
              id="header-user-actions"
              className="cursor-pointer hover:bg-gray-200 p-1 rounded inline-block"
            >
              <Avatar
                size={32}
                src={user?.myac}
                icon={<img src={defaultAvatar} alt="avatar" />}
              />
              <span className="ml-2 text-sm">{user?.name}</span>
            </div>
          </Dropdown>
        </Space>
      </div>
      <ProfilePanel visible={personalShow} setVisible={setPersonalShow} />
      <ChangePwdPanel visible={pswShow} setVisible={setPswShow} />
      <MyLogPanel visible={logTableShow} setVisible={setLogTableShow} />
      <MyQuestion
        visible={askShow}
        setVisible={setAskShow}
        dataSource={loginQaList}
      />
      <Modal
        title={t("header.tips.online")}
        destroyOnClose
        open={onlineModalShow}
        footer={null}
        maskClosable={false}
        width={1200}
        onCancel={closeOnlineModal}
      >
        <QuestionList />
      </Modal>
    </Layout.Header>
  );
};

export default CustomHeader;
