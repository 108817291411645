import { Col, Row } from "antd";
import classNames from "classnames";
import { type FC } from "react";
import { type Answer } from ".";

interface QuestionMapProps {
  questions: Answer[];
  crt?: Answer;
  onSelect?: (id: string) => void;
}

const QuestionMap: FC<QuestionMapProps> = ({ questions, crt, onSelect }) => {
  const getCellClasses = (q: Answer) => {
    return classNames(
      "rounded bg-white cursor-pointer h-full flex items-center justify-center",
      {
        "border-gray-500 border-2 border-solid":
          q.n_question_id === crt?.n_question_id,
        "bg-green-200": Boolean(q._userAnswer),
      }
    );
  };

  return (
    <div className="p-4 bg-gray-200 rounded">
      <div className="title text-lg mb-2">答题卡</div>
      <Row gutter={[10, 10]}>
        {questions.map((q, i) => (
          <Col span={6} key={q.n_question_id} className="h-10">
            <div
              className={getCellClasses(q)}
              onClick={() => onSelect?.(q.n_question_id)}
            >
              {i + 1}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default QuestionMap;
