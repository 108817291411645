import {
  CheckOutlined,
  RedoOutlined,
  FormOutlined,
  DeleteOutlined,
  ReloadOutlined,
  PlusOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Tree,
  type TreeProps,
  type MenuProps,
  message,
  Alert,
} from "antd";
import { cloneDeep } from "lodash-es";
import React, { useCallback, useEffect, useState, type FC } from "react";
import { type QuestionTag, type QuestionTagTree, useTags } from ".";
import { addTag, deleteTag, updateTag } from "../../../api/QuestionController";
import HighligntKeyNode from "../../components/HighligntKeyNode";
import Transition from "../../components/Transition";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

interface TagModalProps {
  open?: boolean;
  onCancel?: () => void;
}

const getParentKey = (key: React.Key, tree: QuestionTagTree[]): React.Key => {
  let parentKey: React.Key = "";
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.n_tag_id === key)) {
        parentKey = node.n_tag_id;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

const TagModal: FC<TagModalProps> = ({ open, onCancel }) => {
  const { t } = useLocale(i18n);
  const { tagLoading, tagTree, flattenTagTree, refresh } = useTags();
  const [cloneTagTree, setCloneTagTree] = useState<QuestionTagTree[]>([]);
  const [editingNodeKey, setEditingNodeKey] = useState<React.Key>();
  const [contextNode, setContextNode] = useState<QuestionTagTree>();
  const [tagSearch, setTagSearch] = useState<string>();
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    if (open) {
      setCloneTagTree(cloneDeep(tagTree || []));
    }
  }, [open, tagTree]);

  const onTagSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!Array.isArray(flattenTagTree)) return;
    const { value } = e.target;
    let newExpandedKeys: (React.Key | null)[] = [];

    if (value) {
      newExpandedKeys = flattenTagTree
        .map((item) =>
          item.c_tag_name.includes(value)
            ? getParentKey(item.n_tag_id, cloneTagTree)
            : null
        )
        .filter((item, i, self) => item && self.indexOf(item) === i);
    }

    setExpandedKeys(newExpandedKeys);
    setTagSearch(value);
    setAutoExpandParent(true);
  };

  const onNodeDelete = (id: React.Key) => {
    deleteTag({ idList: id }).then(({ data }) => {
      if (data.code) {
        message.success(t("library.item.delnode"));
        refresh();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const onNodeUpdate = (node: QuestionTag) => {
    const { n_tag_id, n_pid, c_note, c_tag_name } = node;
    if (n_tag_id === "_edit") {
      return addTag({ n_pid, c_tag_name }).then(({ data }) => {
        if (data.code) {
          message.success(t("library.item.addnode"));
          refresh();
          return;
        }
        data?.msg && message.error(data.msg);
      });
    }
    return updateTag({ n_tag_id, n_pid, c_tag_name }).then(({ data }) => {
      if (data.code) {
        message.success(t("library.item.editnode"));
        refresh();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  // context menu
  const handleAddNode = useCallback(
    (level?: 1) => {
      const addNode = (
        tree: QuestionTagTree[],
        id: React.Key,
        node: QuestionTagTree
      ) => {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].n_tag_id === id) {
            if (Array.isArray(tree[i].children)) {
              tree[i].children?.push(node);
            } else {
              tree[i].children = [node];
            }
            break;
          }
          addNode(tree[i]?.children || [], id, node);
        }
      };

      const node: QuestionTag = {
        n_tag_id: "_edit",
        n_pid: "-1",
        c_tag_name: "",
        c_note: "",
      };

      if (level === 1) {
        node.n_pid = "0";
        setCloneTagTree((tree) => {
          tree.push(node);
          return tree;
        });
      } else {
        node.n_pid = contextNode!.n_tag_id;
        setCloneTagTree((tree) => {
          addNode(tree, contextNode!.n_tag_id, node);
          return tree;
        });
        setExpandedKeys((keys) => [...keys, contextNode!.n_tag_id]);
      }

      setEditingNodeKey("_edit");
    },
    [contextNode]
  );

  const deleteEditNode = () => {
    if (!Array.isArray(flattenTagTree)) return;

    flattenTagTree.find((node) => node.n_tag_id === "_edit");

    const rmTreeDataNode = (tree: QuestionTagTree[]) => {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].n_tag_id === "_edit") {
          tree.splice(i, 1);
          break;
        }
        rmTreeDataNode(tree[i]?.children || []);
      }
    };

    setCloneTagTree((tree) => {
      rmTreeDataNode(tree);
      return tree;
    });
  };

  const onNodeExpand: TreeProps["onExpand"] = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const ctxMenu = useCallback(() => {
    return [
      {
        key: 1,
        label: (
          <Space onClick={refresh}>
            <ReloadOutlined /> {t("library.item.refresh")}
          </Space>
        ),
      },
      {
        key: 2,
        label: (
          <Space onClick={() => handleAddNode(1)}>
            <PlusOutlined /> {t("library.item.creattag")}
          </Space>
        ),
      },
      contextNode?.n_level === 1 && {
        key: 3,
        label: (
          <Space onClick={() => handleAddNode()}>
            <TagsOutlined /> {t("library.item.creatchildtag")}
          </Space>
        ),
      },
    ].filter((x) => Boolean(x)) as MenuProps["items"];
  }, [contextNode, handleAddNode, refresh, t]);

  const setTreeNodeData = (data: QuestionTagTree[]): any =>
    data?.map((item) => ({
      ...item,
      title: (
        <Node
          nodedata={item}
          editkey={editingNodeKey}
          setEditing={setEditingNodeKey}
          search={tagSearch}
          onNodeContextMenu={(node) => {
            setContextNode(node);
          }}
          onDelete={onNodeDelete}
          onUpdate={onNodeUpdate}
          onEmptyNodeUndo={deleteEditNode}
        />
      ),
      children: item.children?.length ? setTreeNodeData(item.children) : null,
    }));

  return (
    <Modal
      title={t("library.item.tagmanagement")}
      destroyOnClose
      open={open}
      onCancel={onCancel}
    >
      <Input.Search
        className="mb-2"
        placeholder={t("library.item.searchtag")}
        onInput={onTagSearch}
      />
      <Spin spinning={tagLoading}>
        <Dropdown trigger={["contextMenu"]} menu={{ items: ctxMenu() }}>
          <div className="overflow-y-auto overflow-x-hidden">
            <Tree
              fieldNames={{ key: "n_tag_id" }}
              defaultExpandAll
              className="h-120 overflow-y-auto"
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onExpand={onNodeExpand}
              treeData={setTreeNodeData(cloneTagTree || []) as any}
            />
          </div>
        </Dropdown>
      </Spin>
    </Modal>
  );
};

type NodeProps = {
  nodedata: QuestionTagTree;
  editkey?: React.Key;
  search?: string;
  setEditing: (editing?: React.Key) => void;
  onNodeContextMenu?: (node: QuestionTagTree) => void;
  onDelete?: (id: React.Key) => void;
  onEmptyNodeUndo?: () => void;
  onUpdate?: (node: QuestionTag) => Promise<any>;
};

const Node: FC<NodeProps> = ({
  nodedata,
  editkey,
  search,
  setEditing,
  onNodeContextMenu,
  onDelete,
  onEmptyNodeUndo,
  onUpdate,
}) => {
  const { t } = useLocale(i18n);
  const [nodeHover, setNodeHover] = useState(false);
  const [newName, setNewName] = useState<string>();
  const onInputDone = () => {
    const { n_pid, n_tag_id, c_note } = nodedata;
    if (!newName) {
      message.warn(t("library.item.notempty"));
      return;
    }
    onUpdate?.({
      n_pid,
      n_tag_id,
      c_note,
      c_tag_name: newName,
    }).finally(undo);
  };

  const onDeleteNode = () => {
    const { n_tag_id } = nodedata;
    onDelete?.(n_tag_id);
  };

  const undo = () => {
    setNewName(nodedata.c_tag_name);
    setNodeHover(false);
    setEditing();
    if (nodedata.n_tag_id === "_edit") {
      onEmptyNodeUndo?.();
    }
  };

  return (
    <Space
      onMouseOver={() => setNodeHover(true)}
      onMouseOut={() => setNodeHover(false)}
      onContextMenu={(e) => {
        onNodeContextMenu?.(nodedata);
      }}
      className="break-all"
    >
      {editkey === nodedata.n_tag_id ? (
        <Space
          onBlur={(e) => {
            const nextDom = e.relatedTarget;
            if (!(nextDom && e.currentTarget.contains(nextDom))) {
              undo();
            }
          }}
        >
          <Input
            autoFocus
            size="small"
            value={newName}
            defaultValue={nodedata.c_tag_name}
            onPressEnter={onInputDone}
            onInput={(e: any) => {
              setNewName(e.target.value);
            }}
          />
          <CheckOutlined
            title={t("library.item.save")}
            className="edit-icon "
            onClick={onInputDone}
          />
          <RedoOutlined
            title={t("library.item.revoke")}
            className="edit-icon"
            onClick={undo}
          />
        </Space>
      ) : (
        <Space>
          <HighligntKeyNode label={nodedata.c_tag_name} keyword={search} />
          <Transition in={nodeHover} timeout={300} animation="slide-in">
            <Space>
              <FormOutlined
                title={t("library.item.edit")}
                className="edit-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setNewName(nodedata.c_tag_name);
                  setEditing(nodedata.n_tag_id);
                }}
              />
              <Popconfirm
                title={t("library.item.suredelnode")}
                placement="top"
                onConfirm={onDeleteNode}
              >
                <DeleteOutlined
                  title={t("library.item.delete")}
                  className="edit-icon"
                />
              </Popconfirm>
            </Space>
          </Transition>
        </Space>
      )}
    </Space>
  );
};

export default TagModal;
