import React from "react";
import req from "../configs/axios";
import type { RightTypes } from "../views/system/authority";

// 查询登录者权限
const listRightByLogin = (data?: any) => {
  return req.post("auth/NewRightRoleController/listRightByLogin", data);
};

// 查询登录者菜单
const listRightMenuByLogin = (data?: any) => {
  return req.post("auth/NewRightRoleController/listRightMenuByLogin", data);
};

// 管理员查询所有权限
const listAllByParams = () => {
  return req.post("auth/NewRightRoleController/listAllByParams");
};

// 查询指定用户的权限
const listAllRightByUserId = (data: {
  userId: React.Key;
  rightType?: RightTypes;
}) => {
  return req.post("auth/NewRightRoleController/listAllRightByUserId", data);
};

// 查询角色列表(权限组)
const listRoleByParams = (data?: {
  search?: string;
  pages?: number;
  rows?: number;
}) => {
  return req.post("auth/NewRightRoleController/listRoleByParams", data);
};

// 创建角色
const addOneRole = (data: {
  roleTitle: string; // 角色名
  roleNote?: string; // 备注
  roleIcon?: string; // 图标
  roleRightList?: { rightId: number; rightType: RightTypes }[]; // 权限列表
}) => {
  return req.post("auth/NewRightRoleController/addOneRole", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 编辑角色
const updateOneRole = (data: {
  id: number;
  roleTitle: string;
  roleNote?: string;
  roleIcon?: string;
  roleRightList?: { rightId: number; rightType: RightTypes }[];
}) => {
  return req.post("auth/NewRightRoleController/updateOneRole", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 删除角色
const deleteOneRole = (data: { id: number }) => {
  return req.post("auth/NewRightRoleController/deleteOneRole", data);
};

// 批量设置用户权限
const addBatchNRightUser = (data: any) => {
  return req.post("auth/NewRightRoleController/addBatchNRightUser", data, {
    headers: { "Content-Type": "application/json" },
  });
};

// 为组织所有用户设置权限
const addBatchNRightUserByDept = (data: any) => {
  return req.post(
    "auth/NewRightRoleController/addBatchNRightUserByDept",
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export {
  listRightByLogin,
  listRightMenuByLogin,
  listAllByParams,
  listAllRightByUserId,
  listRoleByParams,
  addOneRole,
  updateOneRole,
  deleteOneRole,
  addBatchNRightUser,
  addBatchNRightUserByDept,
};
