import { UserOutlined, LockOutlined, PictureOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Row,
  Tooltip,
} from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCaptcha } from "../../api/LoginController";
import useAuth from "../../hooks/useAuth";
import { useLocale } from "../../hooks/useLocale";
import i18n from "./i18n";
import defaultLocale from "../../i18n/default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSystemInfo } from "../../hooks/useSystemInfo";
import { RichDisplay } from "../components/RichEditor";
// import schoolLogo from "../../assets/images/logo.png";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState<string>();
  const [captchaUuid, setCaptchaUuid] = useState<string>();
  const [form] = Form.useForm();
  const [capsLockTipShow, setCapsLockTipShow] = useState(false);
  const { baseInfo, sysInfo } = useSystemInfo();

  const { login } = useAuth();
  const { t, lang, setLang } = useLocale(i18n);

  const formRoles = {
    username: [{ required: true, message: t("login.roles.username") }],
    password: [{ required: true, message: t("login.roles.password") }],
    captchaCode: [{ required: true, message: t("login.roles.code") }],
  };

  const navigate = useNavigate();

  const getCode = async () => {
    const {
      headers: { captchauuid },
      data,
    } = await getCaptcha();
    setCaptchaUuid(captchauuid);
    setCaptcha(URL.createObjectURL(data));
  };

  const onLogin = () => {
    setLoading(true);
    form
      .validateFields()
      .then((data) => login({ captchaUuid, ...data, lang }))
      .then((res) => {
        navigate(res.data.url);
        message.success(
          `${t("system.msg.welcome")}${res.data.userInfo.name}！`
        );
      })
      .catch((err) => {
        getCode();
        err?.msg && message.error(err.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onLoginExpert = () => {
    form.setFieldsValue({
      username: "2019000",
      password: "tyzh666666",
    });
    onLogin();
  };

  const changeLanguage = (language: SupportedLangType) => {
    if (language !== lang) {
      setLang(language);
      const nextLang = defaultLocale[language];
      message.info(`${nextLang["system.msg.switchlang"]} ${language}`);
    }
  };

  useEffect(() => {
    getCode();
  }, []);

  return (
    <div
      className="bg-cover bg-no-repeat flex flex-col justify-center items-center min-h-screen"
      style={{
        backgroundImage: `url(${sysInfo?.bg})`,
      }}
    >
      <div className="w-[480px] m-auto">
        <Card className="bg-white bg-opacity-85">
          <div className="flex w-full justify-between items-center">
            <div className="flex-grow">
              <img
                src={sysInfo?.logo}
                className="h-12.5 max-w-full object-contain"
                alt=""
              />
            </div>
          </div>
          <div className="text-[26px] text-center my-4">
            {baseInfo?.greeting}
          </div>
          <Form className="w-17/20 my-6 mx-auto" size="large" form={form}>
            <Form.Item name="username" rules={formRoles.username}>
              <Input
                prefix={<UserOutlined />}
                placeholder={t("login.roles.username")}
              />
            </Form.Item>
            <Tooltip
              open={capsLockTipShow}
              title={t("system.tips.capslock")}
              color="rgba(0,0,0, 0.7)"
            >
              <Form.Item name="password" rules={formRoles.password}>
                <Input.Password
                  prefix={<LockOutlined />}
                  visibilityToggle
                  autoComplete="password"
                  placeholder={t("login.roles.password")}
                  onBlur={() => setCapsLockTipShow(false)}
                  onKeyUp={(e) =>
                    setCapsLockTipShow(e.getModifierState("CapsLock"))
                  }
                />
              </Form.Item>
            </Tooltip>
            <Form.Item name="captchaCode" rules={formRoles.captchaCode}>
              <Row>
                <Col span={15}>
                  <Input
                    prefix={<PictureOutlined />}
                    maxLength={5}
                    placeholder={t("login.roles.code")}
                  />
                </Col>
                <Col span={7} offset={2}>
                  <Button className="p-0" block onClick={getCode}>
                    <img src={captcha} className="h-full w-full" alt="" />
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item noStyle name="remember" valuePropName="checked">
              <Checkbox>{t("login.item.remember")}</Checkbox>
            </Form.Item>
            <Button
              block
              className="mt-3"
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={onLogin}
            >
              {t("login.item.login")}
            </Button>
            <Button
              block
              className="mt-3"
              disabled={loading}
              onClick={onLoginExpert}
            >
              {t("login.item.loginz")}
            </Button>
            <div className="mt-2">
              {t("login.item.noaccount")}
              <Button
                type="link"
                size="small"
                onClick={() => navigate("/register")}
              >
                {t("login.item.registernow")}
              </Button>
            </div>
          </Form>
        </Card>
      </div>
      <div className="mb-8 mx-4 text-center">
        <RichDisplay html={baseInfo?.footer} />
      </div>
    </div>
  );
};

export default LoginPage;
