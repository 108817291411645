import {
  Form,
  Button,
  Empty,
  Row,
  Col,
  Pagination,
  Space,
  Input,
  Spin,
  Select,
  Dropdown,
  Menu,
} from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import {
  listAllKcByParams,
  queryLabListByMyLab,
} from "../../../api/NewLabInfoController";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { useForm } from "antd/lib/form/Form";
import { addScoreVideo } from "../../../api/LabSettingController";
import { type Course } from "../manage";
import Sorter from "../../components/Sorter";
import LabReport from "../../components/LabReport";
import { cleanObject, timeFormat } from "../../../utils/format";
import labImgFallback from "./../../../assets/images/lab-placeholder.png";
import TeachInfoModal from "./TeachInfoModal";
import useStartExp from "../../../hooks/useStartExp";
import CompositeScoreList from "./CompositeScoreList";
import i18n from "./i18n";

const initPagination = {
  page: 1,
  rows: 12,
};

const MineExperimentPage = () => {
  const { t } = useLocale(i18n);
  const [queryForm] = useForm();
  // const [siderShow, setSiderShow] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [labList, setLabList] = useState<any[]>([]);
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);

  // 查询个人实验列表
  const getLabList = useCallback(() => {
    setLoading(true);
    const params = queryForm.getFieldsValue();
    if ("_lang" in params) {
      params.lang = params._lang ? "lang" : "";
      delete params._lang;
    }

    queryLabListByMyLab({ ...tablePagenation, ...cleanObject(params) })
      .then(({ data }) => {
        if (data.code) {
          setLabList(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => setLoading(false));
  }, [queryForm, tablePagenation]);

  const getCourseList = () => {
    listAllKcByParams().then(({ data }) => {
      if (data.code) {
        setCourseList(data.data);
      }
    });
  };

  // 成绩列表
  const [teachInfoOpen, setTeachInfoOpen] = useState(false);
  const [teachInfo, setTeacInfo] = useState<any>();

  const [compositeScoreListOpen, setCompositeScoreListOpen] = useState(false);
  const [compositeScoreListParam, setCompositeScoreListParam] =
    useState<any>(null);

  const reviewScoreList = (labid: number, type: number) => {
    setCompositeScoreListParam({ labid, type });
    setCompositeScoreListOpen(true);
  };

  const onTeachInfo = (labId: number, labName: string) => {
    setTeacInfo({ labId, labName });
    setTeachInfoOpen(true);
  };

  // 实验报告
  const [labRptShow, setLabRptShow] = useState(false);
  const [scoreId, setScoreId] = useState<number>();

  const reviewLabReport = (id: number) => {
    setScoreId(id);
    setLabRptShow(true);
  };

  const onQuery = () => {
    setTablePagenation(initPagination);
    getLabList();
  };

  useEffect(() => {
    getLabList();
    getCourseList();
  }, [getLabList]);

  return (
    <div className="page-main">
      <CustomBreadcrumb
        breads={[t("menu.experiment"), t("menu.experiment.mine")]}
      />
      <div className="flex">
        <div className="overflow-hidden flex-grow">
          <div className="table-query-bar">
            <Form
              layout="inline"
              form={queryForm}
              onValuesChange={(crt) => {
                if ("search" in crt) return;
                onQuery();
              }}
            >
              <Form.Item name="nKcid">
                <Sorter
                  title={t("mine.item.course")}
                  options={courseList.map((course) => ({
                    label: course.cName,
                    value: course.id,
                  }))}
                />
              </Form.Item>
              <Form.Item name="nlabtype">
                <Select
                  placeholder={t("mine.item.selecttype")}
                  className="!w-50"
                  allowClear
                  options={[
                    { label: t("mine.item.all"), value: "" },
                    {
                      label: t("mine.item.virtual"),
                      value: 0,
                    },
                    {
                      label: t("mine.item.videocourse"),
                      value: 9,
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item name="search">
                <Input.Search
                  className="w-50"
                  allowClear
                  placeholder={t("mine.item.entername")}
                  onSearch={() => onQuery()}
                />
              </Form.Item>
            </Form>
          </div>
          <Spin spinning={loading}>
            <div className="table-wrapper">
              <div className="lab-list">
                {labList.length > 0 ? (
                  <Row gutter={[16, 16]}>
                    {labList.map((el) => {
                      return (
                        <Col xxl={6} xl={8} md={12} sm={24} key={el.id}>
                          <LabCard
                            data={el}
                            onListScore={reviewScoreList}
                            onTeachInfo={onTeachInfo}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <Empty />
                )}
                <div className="my-4 text-center">
                  <Pagination
                    showQuickJumper={true}
                    showSizeChanger={true}
                    pageSizeOptions={[12, 24, 40, 80]}
                    current={tablePagenation.page}
                    pageSize={tablePagenation.rows}
                    total={total}
                    showTotal={(total) =>
                      `${t("table.pagination.all")}${total}${t(
                        "table.pagination.total"
                      )}`
                    }
                    onChange={(page, rows) => {
                      setTablePagenation({ page, rows });
                    }}
                  />
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
      <CompositeScoreList
        open={compositeScoreListOpen}
        onCancel={() => setCompositeScoreListOpen(false)}
        param={compositeScoreListParam}
        onReportView={(id) => reviewLabReport(id)}
      />
      <LabReport
        visible={labRptShow}
        setVisible={setLabRptShow}
        scoreid={scoreId}
        allowEdit
      />
      <TeachInfoModal
        open={teachInfoOpen}
        onCancel={() => setTeachInfoOpen(false)}
        labId={teachInfo?.labId}
        labName={teachInfo?.labName}
      />
    </div>
  );
};

export default MineExperimentPage;

interface LabCardProps {
  data: any;
  onListScore?: (labId: number, type: number) => void;
  onTeachInfo?: (labId: number, labName: string) => void;
}

const LabCard: FC<LabCardProps> = ({ data, onListScore, onTeachInfo }) => {
  const { t } = useLocale(i18n);
  const { startExp } = useStartExp();

  const goPractice = (labId: number) => {
    addScoreVideo({ labId }).then(({ data }) => {
      if (data.code) {
        window.open(`/#/practice/${data.data}`);
        return;
      }
    });
  };

  return (
    <div className="declare-lab-card">
      <div className="win">
        <img
          className="object-cover w-full h-full"
          src={data.cImgid || labImgFallback}
          alt=""
        />
        <div className="intro">
          <div className="intro-title">{t("mine.item.detail")}</div>
          <div className="intro-des">{data.cNote}</div>
        </div>
        <CardCorner data={data} />
      </div>
      <div className="px-5">
        <div className="title" title={data.cName}>
          {data.cName}
        </div>
        <div className="btm">
          <Space>
            <Dropdown.Button
              onClick={() => startExp(data.id, data.cZyhome, data.nlabtype)}
              menu={{
                items: [
                  {
                    key: "1",
                    label: t("mine.item.teachinginf"),
                    onClick: () => onTeachInfo?.(data.id, data.cName),
                  },
                  {
                    key: "2",
                    label: t("mine.item.exercises"),
                    onClick: () => goPractice(data.id),
                  },
                ],
              }}
            >
              {data.cZyhome
                ? data?.nlabtype === 9
                  ? t("mine.item.watchvideo")
                  : t("mine.item.startexperiment")
                : t("mine.item.Unuploaded")}
            </Dropdown.Button>
            <Button onClick={() => onListScore?.(data.id, data.nlabtype)}>
              {t("mine.item.mygrades")}
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

const CardCorner: FC<{ data: any }> = ({ data }) => {
  const { t } = useLocale(i18n);
  const [text, setText] = useState<string | number>();

  useEffect(() => {
    let timer: any;
    if (data?.labSettingEndTime) {
      const day = 24 * 60 * 60 * 1000;
      const endTime = new Date(data.labSettingEndTime).getTime();
      const duration = endTime - Date.now();

      if (duration > day) {
        setText(Math.floor(duration / day));
      }

      if (duration > 0 && duration <= day) {
        timer = setInterval(() => {
          const now = Date.now();
          const crt = endTime - now;
          setText(timeFormat(crt, "hh:mm:ss"));
        }, 1000);
      }
    }

    return () => clearInterval(timer);
  }, [data.labSettingEndTime]);

  if (!data?.labSettingEndTime) return null;
  const endTime = new Date(data.labSettingEndTime).getTime();
  const now = Date.now();
  const duration = endTime - now;
  const day = 24 * 60 * 60 * 1000;

  if (duration <= 0) {
    return (
      <div className="corner !text-gray-500">
        {t("mine.item.since")}
        {data.labSettingEndTime}
      </div>
    );
  }

  if (duration > day) {
    return <div className="corner">{text}天后截止</div>;
  }

  return <div className="corner">{text}后截止</div>;
};
