import { Popover, Button } from "antd";
import { ChromePicker } from "react-color";

interface ColorPickerProps {
  value?: string;
  onChange?: (color: string) => void;
}

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  return (
    <Popover
      trigger="click"
      overlayClassName="pop-padding-none"
      content={
        <ChromePicker
          disableAlpha
          color={value}
          onChange={(color) => onChange?.(color.hex)}
        />
      }
    >
      <Button style={{ padding: 5 }}>
        <div
          style={{
            background: value || "#000",
            width: 50,
            height: 20,
          }}
        ></div>
      </Button>
    </Popover>
  );
};

export default ColorPicker;
