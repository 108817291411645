// 返回两个数之间的一个随机整数(闭集)
const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max) + 1;
  return Math.floor(Math.random() * (max - min)) + min;
};

// 返回指定长度的随机整数集合
const getRandomCollection = (min: number, max: number, len: number) => {
  const set = new Set<number>();
  while (set.size !== len) set.add(getRandomInt(min, max));
  return Array.from(set).sort((a, b) => a - b);
};

export { getRandomInt, getRandomCollection };
