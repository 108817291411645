import {
  Button,
  List,
  Popover,
  Skeleton,
  Space,
  Tabs,
  Typography,
  type TooltipProps,
} from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { updateHaveReadByLogin } from "../../../api/NoticeController";
import useNotice from "../../../hooks/useNotice";
import { useLocale } from "../../../hooks/useLocale";

interface NoticeCardProps {
  children?: React.ReactNode;
  trigger?: TooltipProps["trigger"];
  placement?: TooltipProps["placement"];
}

const i18n = {
  "en-US": {
    notification: "System notification",
    unread: "unread",
    all: "all",
    allread: "All read",
  },
  "zh-CN": {
    notification: "系统通知",
    unread: "未读",
    all: "全部",
    allread: "全部已读",
  },
};

const NoticePopover: React.FC<NoticeCardProps> = ({ children, ...rest }) => {
  const { t } = useLocale(i18n);
  const [open, setOpen] = useState(false);
  const { noticeLoading, noticeList, retry, count } = useNotice();
  const [acitve, setActive] = useState<0 | undefined>(0);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) retry({ haveRead: acitve });
  };

  const getClasses = (value?: number) =>
    classNames(
      acitve === value && "bg-gray-200 hover:bg-gray-200 focus:bg-gray-200"
    );

  const handleChange = (current?: 0) => {
    setActive(current);
    retry({ haveRead: current });
  };

  const doRead = (id: number) => {
    updateHaveReadByLogin({ idList: id }).then(() => {
      retry();
    });
  };

  const readAll = () => {
    const idList = noticeList?.map((n) => n.id).join();
    updateHaveReadByLogin({ idList }).then(() => {
      retry();
    });
  };

  useEffect(() => {
    retry();
  }, [retry]);

  const content = (
    <div className="notice-popover">
      <div className="notice-pop-title">{t("notification")}</div>

      <div className="notice-top">
        <Space>
          <Button
            shape="round"
            type="text"
            className={getClasses(0)}
            onClick={() => handleChange(0)}
          >
            {t("unread")} {count?.unread ? `(${count.unread})` : ""}
          </Button>
          <Button
            shape="round"
            type="text"
            className={getClasses()}
            onClick={() => handleChange()}
          >
            {t("all")} {count?.total ? `(${count.total})` : ""}
          </Button>
        </Space>
      </div>
      <List
        dataSource={noticeList}
        className="notice-list"
        itemLayout="vertical"
        renderItem={(item) => (
          <Skeleton
            loading={noticeLoading}
            className="p-4"
            paragraph={{ rows: 2 }}
            active
          >
            <div className="notice-list-item">
              <div className="notice-area" onClick={() => doRead(item.id)}>
                <Typography.Text className="block mb-2 text-base">
                  {item.noticeTitle}
                </Typography.Text>
                <Typography.Text>{item.noticeContent}</Typography.Text>
              </div>
              <Typography.Text type="secondary" className="block">
                {item.createTime}
              </Typography.Text>
            </div>
          </Skeleton>
        )}
      />
      {(noticeList?.length || 0) > 0 && (
        <div className="notice-btm">
          <Button type="link" onClick={() => readAll()}>
            {t("allread")}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Popover
      showArrow={false}
      overlayClassName="pop-padding-none"
      content={content}
      open={open}
      onOpenChange={handleOpenChange}
      {...rest}
    >
      {children}
    </Popover>
  );
};

NoticePopover.defaultProps = {
  placement: "bottomRight",
};

export default NoticePopover;
