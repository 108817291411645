import { Empty, Modal, Spin } from "antd";
import { useCallback, useEffect, useState, type FC } from "react";
import { getLearnContentById } from "../../../api/NewLabInfoController";
import { RichDisplay } from "../../components/RichEditor";
import i18n from "./i18n";
import { useLocale } from "../../../hooks/useLocale";

interface TeachInfoModalProps {
  open?: boolean;
  onCancel?: () => void;
  labId?: number;
  labName?: string;
}

const TeachInfoModal: FC<TeachInfoModalProps> = ({
  open,
  onCancel,
  labId,
  labName,
}) => {
  const { t } = useLocale(i18n);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<string>();

  const getInfo = useCallback(() => {
    setLoading(true);
    getLearnContentById({ labId })
      .then(({ data }) => {
        if (data.code) {
          setInfo(data.data);
          return;
        }
        setInfo("");
      })
      .finally(() => setLoading(false));
  }, [labId]);

  useEffect(() => {
    if (open) getInfo();
  }, [getInfo, open]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={
        labName
          ? `${labName}${t("mine.item.deteaching")}`
          : t("mine.item.teaching")
      }
      footer={null}
    >
      <Spin spinning={loading}>
        {info ? <RichDisplay html={info} /> : <Empty />}
      </Spin>
    </Modal>
  );
};

export default TeachInfoModal;
