import {
  CopyOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Space,
  Table,
  message,
  Tag,
  Popover,
  Select,
  Row,
  Col,
  Avatar,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import copy from "copy-to-clipboard";
import { useCallback, useEffect, useState } from "react";
import {
  listQaAllByParams,
  getOneQaById,
  deleteQaById,
  updateOneQaById,
} from "../../../api/NewUserController";
import ReplyList from "./replyList";
import defaultAvatar from "../../../assets/images/avatar-default.png";
import { RichDisplay } from "../../components/RichEditor";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";

const { confirm } = Modal;

const QuestionList = () => {
  const { t } = useLocale(i18n);
  const [queryForm] = useForm();
  const [search, setSearch] = useState<string>();
  const [qaList, setQaList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [questionVisible, setQuestionVisible] = useState(false);
  const [qutitle, setQuTitle] = useState<string>();
  const [quId, setQuId] = useState<string>();
  const [qaContent, setQaContent] = useState<any>();
  const [isValid, setIsValid] = useState<string>();
  const [createTime, setCreateTime] = useState<string>();
  const [closeTime, setCloseTime] = useState<string>();
  const [isUrgent, setIsUrgent] = useState<string>();
  const [userName, setuserName] = useState<string>();
  const [yName, setYName] = useState<string>();
  const [userAvatar, setUserAvatar] = useState<string>();
  const [selectValue, setSelectValue] = useState<any>();
  const [validValue, setValidValue] = useState<any>();
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const listQa = useCallback(async () => {
    const { data } = await listQaAllByParams({
      ...tablePagenation,
      qaTitle: search,
      isUrgent: selectValue,
      isValid: validValue,
    });
    if (data.code) {
      setQaList(data.data.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation, search]);

  const onClose = () => {
    setQuId("");
    setQuestionVisible(false);
  };
  const onSearch = () => {
    setTablePagenation({ page: 1, rows: 10 });
  };

  const showQuestion = async (id: any) => {
    setQuId(id);
    setQuestionVisible(true);
    const { data } = await getOneQaById({ id: id });
    setuserName(data.data.userName);
    setYName(data.data.yName);
    setUserAvatar(data.data.userAvatar);
    setCreateTime(data.data.createTime);
    setQaContent(data.data.qaContent);
    setCloseTime(data.data.closeTime);
    setIsUrgent(data.data.isUrgent);
    if (data.data.isUrgent === 0) {
      data.data.isUrgent = (
        <Tag color="green">{t("declare.item.ordinary")}</Tag>
      );
    } else if (data.data.isUrgent === 1) {
      data.data.isUrgent = <Tag color="red">{t("declare.item.urgency")}</Tag>;
    }
    setIsUrgent(data.data.isUrgent);

    if (data.data.isValid === 1) {
      data.data.isValid = t("declare.item.resolved");
    } else if (data.data.isValid === 2) {
      data.data.isValid = t("declare.item.processing");
    } else if (data.data.isValid === 3) {
      data.data.isValid = t("declare.item.waitReply");
    }
    setIsValid(data.data.isValid);
  };

  useEffect(() => {
    listQa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation]);

  //复制
  const copyCot = (id: any) => {
    copy(id);
    message.success(t("declare.item.copyMsg"));
  };

  return (
    <div>
      <div className="query-form bg-white  rounded mb-4 float-right">
        <Form layout="inline" form={queryForm}>
          <Form.Item label={t("declare.item.state")} name="isUrgent">
            <Select
              allowClear
              placeholder={t("declare.item.selectstate")}
              style={{ width: "100px" }}
              onChange={(value: { value: string }) => {
                console.log(value);
                setSelectValue(value);
                if (!value) {
                  setTablePagenation({ page: 1, rows: 10 });
                }
              }}
            >
              <Select.Option
                value="0"
                style={{ background: "#f6ffed", color: "#389e0d" }}
              >
                {t("declare.item.ordinary")}{" "}
              </Select.Option>
              <Select.Option
                value="1"
                style={{ background: "#fff1f0", color: "#cf1322" }}
              >
                {t("declare.item.urgency")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("declare.item.schedule")} name="isValid">
            <Select
              allowClear
              placeholder={t("declare.item.selschedule")}
              style={{ width: "100px" }}
              onChange={(value: { value: string }) => {
                console.log(value);
                setValidValue(value);
                if (!value) {
                  setTablePagenation({ page: 1, rows: 10 });
                }
              }}
            >
              <Select.Option value="1">
                {t("declare.item.resolved")}
              </Select.Option>
              <Select.Option value="2">
                {t("declare.item.processing")}
              </Select.Option>
              <Select.Option value="3">
                {t("declare.item.waitReply")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("declare.item.description")} name="search">
            <Input
              placeholder={t("declare.item.inpdescription")}
              allowClear
              style={{ width: "230px" }}
              onPressEnter={onSearch}
              onChange={({ target: { value } }) => {
                setSearch(value);
                if (!value) {
                  setTablePagenation({ page: 1, rows: 10 });
                }
              }}
            />
          </Form.Item>
          <Space>
            <Button onClick={onSearch} htmlType="submit" type="primary">
              {t("declare.item.query")}
            </Button>
          </Space>
        </Form>
      </div>
      <Table
        size="middle"
        rowKey="id"
        bordered
        dataSource={qaList}
        loading={loading}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: ["bottomCenter"],
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          total,
          showTotal: (total) =>
            `${t("table.pagination.all")}${total}${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({
              page,
              rows,
            });
          },
        }}
        columns={[
          {
            title: t("table.index"),
            width: 50,
            fixed: "left",
            align: "center",
            render: (val, row, index) => index + 1,
          },
          {
            title: t("declare.item.description"),
            dataIndex: "qaTitle",
            width: 150,
            render(text, row) {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    showQuestion(row.id);
                    setQuTitle(text);
                  }}
                >
                  {text}
                </a>
              );
            },
          },
          {
            title: t("declare.item.Qtime"),
            dataIndex: "createTime",
            width: 120,
          },
          {
            title: t("declare.item.endTime"),
            dataIndex: "closeTime",
            width: 120,
          },
          {
            title: t("declare.item.state"),
            dataIndex: "isUrgent",
            width: 120,
            render(val, row) {
              if (val === 0) {
                return <Tag color="green">{t("declare.item.ordinary")}</Tag>;
              } else if (val === 1) {
                return <Tag color="red">{t("declare.item.urgency")}</Tag>;
              }
            },
          },
          {
            title: t("declare.item.schedule"),
            dataIndex: "isValid",
            width: 120,
            render(val, row) {
              if (val === 1) {
                return t("declare.item.resolved");
              } else if (val === 2) {
                return t("declare.item.processing");
              } else if (val === 3) {
                return t("declare.item.waitReply");
              }
            },
          },
          {
            title: t("declare.item.operation"),
            width: 200,
            align: "center",
            render(value, row: any) {
              return (
                <Space>
                  {row.isValid !== 1 ? (
                    <Button
                      size="small"
                      danger
                      onClick={() => {
                        confirm({
                          title: t("declare.item.sureclose"),
                          icon: <ExclamationCircleOutlined />,
                          content: t("declare.item.notreply"),
                          centered: true,
                          async onOk() {
                            const { data } = await updateOneQaById({
                              id: row.id,
                            });
                            if (data.code) {
                              listQa();
                              message.success(t("declare.item.closesuccess"));
                            } else {
                              message.error(t("declare.item.latertry"));
                            }
                          },
                          onCancel() {},
                        });
                      }}
                    >
                      {t("declare.item.close")}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    size="small"
                    danger
                    onClick={() => {
                      confirm({
                        title: t("declare.item.suredel"),
                        icon: <ExclamationCircleOutlined />,
                        content: t("declare.item.restored"),
                        centered: true,
                        async onOk() {
                          const { data } = await deleteQaById({
                            id: row.id,
                          });
                          if (data.code) {
                            listQa();
                            message.success(t("declare.item.successdel"));
                          } else {
                            message.error(t("declare.item.latertry"));
                          }
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    {t("declare.item.delete")}
                  </Button>
                </Space>
              );
            },
          },
        ]}
      ></Table>
      <Drawer
        title={
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <div className="flex">
              <div>{t("declare.item.description") + "：" + qutitle}</div>
              <div className="ml-3 text-sm">
                <Popover
                  className="relative"
                  placement="bottom"
                  title={t("declare.item.personalDetails")}
                  content={
                    <div className="max-h-60 max-w-md overflow-auto">
                      <p>
                        {t("declare.item.number")}：{quId}{" "}
                        <CopyOutlined
                          className="cursor-pointer hover:text-blue-500 "
                          onClick={() => copyCot(quId)}
                        />
                      </p>
                      <p>
                        {t("declare.item.Questioner")}： {userName}
                        <br />
                        {yName === null ? (
                          ""
                        ) : (
                          <span className="ml-15">{yName}</span>
                        )}
                      </p>
                      <p>
                        {t("declare.item.Qtime")}： {createTime}
                      </p>
                      <p>
                        {t("declare.item.schedule")}： {isValid}
                      </p>
                    </div>
                  }
                >
                  <InfoCircleOutlined className="text-blue-500 text-lg cursor-pointer" />
                </Popover>
              </div>
            </div>
            <div className="flex">
              <div className="text-sm mr-2 mt-1">
                {t("declare.item.state")}： {isUrgent}
              </div>
              <div className="mr-6 mt-1 text-sm">
                {t("declare.item.schedule")}：{isValid}
              </div>
            </div>
          </div>
        }
        width="50%"
        placement="right"
        onClose={onClose}
        open={questionVisible}
      >
        <div className="bg-white px-5 rounded">
          <div>
            <Row className="py-6 pl-3 mb-4 border-solid border-1 border-gray-200 relative">
              <Col span={1} className="text-center">
                <Avatar
                  src={userAvatar}
                  icon={<img src={defaultAvatar} alt="avatar" />}
                />
              </Col>
              <Col span={22} className="ml-4">
                <h4 className="text-sm text-gray-400">
                  {userName} <span className="ml-1">{createTime}</span>
                </h4>
                <div className="flex break-all">
                  <RichDisplay
                    html={qaContent}
                    className="text-base text-gray-800"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <ReplyList showQuestion={showQuestion} quId={quId}></ReplyList>
        </div>
      </Drawer>
    </div>
  );
};

export default QuestionList;
