const i18n = {
  "en-US": {
    "manage.item.selectlist": "You need to select the list item first",
    "manage.item.confirmdel": "Please confirm the delete operation",
    "manage.item.operationsuccess":
      "The operation was successful and the user has been deleted",
    "manage.item.onlyexcel": "Only Excel files can be uploaded",
    "manage.item.selectfirst": "Select the file first",
    "manage.item.coverage": "coverage",
    "manage.item.duplicate": "duplicate data",
    "manage.item.checkerror":
      "There are duplicate or incorrect data in the file, please check them",
    "manage.item.uploaded": "You uploaded",
    "manage.item.data": "data.",
    "manage.item.errorline": "error line",
    "manage.item.type": "type",
    "manage.item.repetition": "repetition",
    "manage.item.error": "error",
    "manage.item.errorcontent": "error content",
    "manage.item.automatically": "automatically correct content",
    "manage.item.close": "close",
    "manage.item.importsuccess": "Import successfully",
    "manage.item.changed": "You modified",
    "manage.item.systemdata": "system data",
    "manage.item.unknownerror": "unknown error",
    "manage.item.allenter": "All have been entered in the system.",
    "manage.item.wrong": "Sorry, something went wrong",
    "manage.item.enterpassword": "Please enter password",
    "manage.item.modifysuccess": "Modify successfully",
    "manage.item.addsuccess": "successfully added",
    "manage.item.nameorwork": "name/work number",
    "manage.item.enternameornum": "Enter your name or work (school) number",
    "manage.item.clear": "clear",
    "manage.item.query": "query",
    "manage.item.account": "Create an account",
    "manage.item.batchimport": "Batch import",
    "manage.item.delete": "delete",
    "manage.item.cancel": "cancel",
    "manage.item.batchoperation": "batch operation",
    "manage.item.loginid": "Work number/student number",
    "manage.item.avatar": "avatar",
    "manage.item.currency": "virtual currency",
    "manage.item.logintimes": "login times",
    "manage.item.usetime": "time of use",
    "manage.item.operation": "operation",
    "manage.item.edit": "Edit",
    "manage.item.suredeluser": "Are you sure you want to delete the user?",
    "manage.item.loggedoff":
      "This user will be logged off and all data of this user will be deleted",
    "manage.item.confirm": "confirm",
    "manage.item.deluser":
      "The operation was successful and the user has been deleted",
    "manage.item.batchcreat": "Create user accounts in batches",
    "manage.item.firststep": "first step",
    "manage.item.download": "download",
    "manage.item.secondstep": "second step",
    "manage.item.thirdstep": "third step",
    "manage.item.templatefile": "user list Excel template file",
    "manage.item.fallin":
      "Fill in the user information according to the Excel template format.",
    "manage.item.worksheet": "Upload the Excel worksheet below.",
    "manage.item.ignored":
      "The system will only read the data of the default sheet. If multiple sheets are added, they will be ignored.",
    "manage.item.drag": "Click or drag the file to this area",
    "manage.item.wait": "Uploading, please wait",
    "manage.item.submitsheet": "Submit data sheet",
    "manage.item.modifyinf": "Modify user information",
    "manage.item.creataccount": "Create an account",
    "manage.item.worknum": "Work (school) number",
    "manage.item.modifyworknum": "Modify the work (school) number",
    "manage.item.modifyname": "modify name",
    "manage.item.usertype": "user type",
    "manage.item.student": "student",
    "manage.item.teacher": "teacher",
    "manage.item.choosesex": "choosing the sex",
    "manage.item.organization": "user organization",
    "manage.item.selorganization": "Selecting the user organization",
    "manage.item.seljob": "Select job",
    "manage.item.modifyphone": "Modify phone",
    "manage.item.mailbox": "Modify mailbox",
    "manage.item.submit": "Submit",
    "manage.item.changepassword": "Change user password",
    "manage.item.role": "role",
    "manage.item.selrole": "Select Role",
    "manage.item.weak": "weak",
    "manage.item.centre": "centre",
    "manage.item.strong": "strong",
    "manage.item.loginlog": "Login log",
    "manage.item.eversince": "ever since",
    "manage.item.sofar": "so far",
    "manage.item.logintime": "Login time",
    "manage.item.exittime": "Exit time",
    "manage.item.loginip": "Login ip",
    "manage.item.loginMAC": "Log in to MAC",
    "manage.item.loginnum": "Number of logins",
    "manage.item.thistime": "Time of use",
  },
  "zh-CN": {
    "manage.item.selectlist": "需要先选择列表项",
    "manage.item.confirmdel": "请确认删除操作",
    "manage.item.operationsuccess": "操作成功，已删除用户",
    "manage.item.onlyexcel": "只能上传Excel文件",
    "manage.item.selectfirst": "请先选择文件",
    "manage.item.coverage": "覆盖",
    "manage.item.duplicate": "重复数据",
    "manage.item.checkerror": "文件中存在重复或错误的数据，请检查它们",
    "manage.item.uploaded": "你上传了",
    "manage.item.data": "数据。",
    "manage.item.errorline": "错误行",
    "manage.item.type": "类型",
    "manage.item.repetition": "重复",
    "manage.item.error": "错误",
    "manage.item.errorcontent": "错误内容",
    "manage.item.automatically": "自动修正内容",
    "manage.item.close": "关闭",
    "manage.item.importsuccess": "导入成功",
    "manage.item.changed": "你修改了",
    "manage.item.systemdata": "系统数据",
    "manage.item.unknownerror": "未知错误",
    "manage.item.allenter": "系统已全部录入。",
    "manage.item.wrong": "抱歉，发生了一些错误",
    "manage.item.enterpassword": "请输入密码",
    "manage.item.modifysuccess": "修改成功",
    "manage.item.addsuccess": "添加成功",
    "manage.item.nameorwork": "姓名/工号",
    "manage.item.enternameornum": "输入姓名或工(学)号",
    "manage.item.clear": "清除",
    "manage.item.query": "查询",
    "manage.item.account": "创建账号",
    "manage.item.batchimport": "批量导入",
    "manage.item.delete": "删除",
    "manage.item.cancel": "取消",
    "manage.item.batchoperation": "批量操作",
    "manage.item.loginid": "工号/学号",
    "manage.item.avatar": "头像",
    "manage.item.currency": "虚拟币",
    "manage.item.logintimes": "登录次数",
    "manage.item.usetime": "使用时间",
    "manage.item.operation": "操作",
    "manage.item.edit": "编辑",
    "manage.item.suredeluser": "确定要删除用户吗？",
    "manage.item.loggedoff": "将注销此用户，并删除该用户的所有数据",
    "manage.item.confirm": "确认",
    "manage.item.deluser": "操作成功，已删除用户",
    "manage.item.batchcreat": "批量创建用户账号",
    "manage.item.firststep": "第一步",
    "manage.item.download": "下载",
    "manage.item.secondstep": "第二步",
    "manage.item.thirdstep": "第三步",
    "manage.item.templatefile": "用户列表Excel模板文件",
    "manage.item.fallin": "按照Excel模板格式填写用户信息 。",
    "manage.item.worksheet": "在下方上传Excel工作表。",
    "manage.item.ignored":
      "系统只会读取默认sheet的数据，若添加多个sheet将会忽略。",
    "manage.item.drag": "点击或拖拽文件到此区域",
    "manage.item.wait": "上传中，请稍候",
    "manage.item.submitsheet": "提交数据表",
    "manage.item.modifyinf": "修改用户信息",
    "manage.item.creataccount": "创建账号",
    "manage.item.worknum": "工(学)号",
    "manage.item.modifyworknum": "修改工(学)号",
    "manage.item.modifyname": "修改姓名",
    "manage.item.usertype": "用户类型",
    "manage.item.student": "学生",
    "manage.item.teacher": "教师",
    "manage.item.choosesex": "选择性别",
    "manage.item.organization": "用户组织",
    "manage.item.selorganization": "选择用户组织",
    "manage.item.seljob": "选择职务",
    "manage.item.modifyphone": "修改电话",
    "manage.item.mailbox": "修改邮箱",
    "manage.item.submit": "提交",
    "manage.item.changepassword": "修改用户密码",
    "manage.item.role": "角色",
    "manage.item.selrole": "选择角色",
    "manage.item.weak": "弱",
    "manage.item.centre": "中",
    "manage.item.strong": "强",
    "manage.item.loginlog": "登录日志",
    "manage.item.eversince": "有史以来",
    "manage.item.sofar": "至今",
    "manage.item.logintime": "登录时间",
    "manage.item.exittime": "退出时间",
    "manage.item.loginip": "登陆ip",
    "manage.item.loginMAC": "登陆MAC",
    "manage.item.loginnum": "登陆次数",
    "manage.item.thistime": "本次使用时间",
  },
};

export default i18n;
