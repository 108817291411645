import { EllipsisOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Dropdown,
  Empty,
  Menu,
  message,
  Modal,
  Space,
  Spin,
  Tag,
  Tooltip,
  Tree,
} from "antd";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  addBatchNRightUser,
  addBatchNRightUserByDept,
  listAllByParams,
  listAllRightByUserId,
  listRoleByParams,
} from "../../../api/NewRightRoleController";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import DepartmentTree, {
  type NodeDataType,
} from "../../components/DepartmentTree";
import PageMain from "../../components/PageMain";
import { groupBy } from "lodash-es";
import type { defaultI18nKeys } from "../../../i18n/default";
import RoleManageModal, {
  RightEditTree,
  type RoleInstance,
} from "./RoleManageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { nodeIcon } from "../../components/DepartmentTree";
import i18n from "./i18n";

export type RightInstance = {
  id: number;
  pid: number;
  title: string;
  rightIcon?: string;
  rightKey: string;
  rightType: RightTypes;
  child: RightInstance[];
};

export type RightTypes = keyof typeof righttypes;

type RoleContextValues = {
  roles?: RoleInstance[];
  roleMap?: Map<number, RoleInstance>;
  rolesLoading: boolean;
  refreshRoles: () => Promise<any>;
  rightGroup?: { [k in RightTypes]?: RightInstance[] };
  rightMap?: Map<number, RightInstance>;
  rightLoading: boolean;
  refreshRights: () => Promise<any>;
} | null;

export const RoleContext = createContext<RoleContextValues>(null);

export const useRights = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error(
      "RoleContext不在父状态树中。确保在AuthorityManagePage组件节点下使用。"
    );
  }
  return context;
};

export const righttypes = {
  0: "角色", // 已分组(Roles)
  1: "菜单",
  2: "课程/实验",
  3: "实验",
  4: "审批员",
  5: "组织管理",
  6: "专业",
  7: "班级",
};

const AuthorityManagePage = () => {
  const { t } = useLocale(i18n);
  const [roleModalShow, setRoleModalShow] = useState(false);
  const [editView, setEditView] = useState(false);
  // 组织树当前选中的实体
  const [crtEntity, setCrtEntity] = useState<NodeDataType>();
  const [alertShow, setAlertShow] = useState(false);
  // 角色
  const [roles, setRoles] = useState<RoleInstance[]>();
  const [roleMap, setRoleMap] = useState<Map<number, RoleInstance>>();
  const [rolesLoading, setRolesLoading] = useState(false);
  const [roleChecked, setRoleChecked] = useState<number[]>();
  // 权限
  const [rightGroup, setRightGroup] =
    useState<{ [k in RightTypes]?: RightInstance[] }>();
  const [rightLoading, setRightsLoading] = useState(false);
  const [rightMap, setRightMap] = useState<Map<number, RightInstance>>();
  const [checkedRightSet, setCheckedRightSet] = useState<Set<number>>();
  const [roleRightKeys, setRoleRightKeys] = useState<Set<number>>();
  const [rightSaving, setRightSaving] = useState(false);
  const [rightViewGroup, setRightViewGroup] =
    useState<{ [k in RightTypes]?: RightInstance[] }>();
  const [personLoading, setPersonLoading] = useState(false);

  // 获取所有角色
  const getRoles = useCallback(async () => {
    setRolesLoading(true);
    const { data } = await listRoleByParams();
    setRolesLoading(false);
    if (data?.code) {
      setRoles(data.data.rows);

      const map = new Map();
      data.data?.rows?.forEach((el: RoleInstance) => {
        map.set(el.id, el);
      });
      setRoleMap(map);
      return Promise.resolve(data.data.rows);
    }
    setRoles(undefined);
    return Promise.reject(data);
  }, []);

  // 获取所有权限
  const getAuthorities = useCallback(async () => {
    setRightsLoading(true);
    const { data } = await listAllByParams();
    setRightsLoading(false);

    if (data.code) {
      const rights = groupBy<RightInstance>(data.data, "rightType");
      setRightGroup(rights);

      const map = new Map();
      let item,
        list = [...data.data];
      while (list.length) {
        item = list.shift();
        map.set(item!.id, item);

        if (item?.child?.length) {
          list.unshift(...item.child);
        }
      }
      setRightMap(map);
      return Promise.resolve(data.data);
    }
    setRightGroup(undefined);
    setRightMap(undefined);
    return Promise.reject(data);
  }, []);

  const getUserAuthority = (userId: React.Key) => {
    setPersonLoading(true);
    listAllRightByUserId({ userId })
      .then(({ data }) => {
        if (data.code) {
          const { roleCollect = [], rightCollect = [] } = data.data;
          const roles = roleCollect.map((role: RoleInstance) => role.id);
          setRightViewGroup(groupBy(rightCollect, "rightType"));
          setRoleChecked(roles);
          // 设置选中和禁用权限
          const rights: number[] = [];
          let head: any,
            list = [...rightCollect];

          while (list.length) {
            head = list.shift();
            rights.push(head.id);
            if (head?.child?.length) {
              list.unshift(...head.child);
            }
          }

          setRoleRightKeys(getRoleRightSet(roles));
          setCheckedRightSet(new Set(rights));
        }
      })
      .finally(() => setPersonLoading(false));
  };

  useEffect(() => {
    getAuthorities();
    getRoles();
  }, [getAuthorities, getRoles]);

  const onEntitySelect = (node?: NodeDataType) => {
    setCrtEntity(node);
    closeEditView(node);
    if (!node) {
      setAlertShow(false);
      return;
    }

    if (node.level === 4) {
      getUserAuthority(node.id);
      setAlertShow(false);
    } else {
      setRightViewGroup(undefined);
      setAlertShow(true);
    }
  };

  const openEditView = () => {
    if (crtEntity) {
      setEditView(true);
      return;
    }
    message.warning(t("authority.item.selectentity"));
  };

  const closeEditView = (entity?: NodeDataType) => {
    setRoleChecked(undefined);
    setCheckedRightSet(undefined);
    setRoleRightKeys(undefined);
    setEditView(false);

    if (entity?.level === 4) {
      getUserAuthority(entity.id);
    }
  };

  // 获取角色列表包含的所有权限集合
  const getRoleRightSet = (roles: number[]) => {
    const rightKeysAll = new Set<number>();
    for (let roleId of roles) {
      const rightkeys = roleMap?.get(roleId)?.dtoList?.map((x) => x.id);
      for (let right of rightkeys || []) {
        rightKeysAll.add(right);
      }
    }
    return rightKeysAll;
  };

  // 选择/取消角色
  const onRoleChecked: RoleListProps["onChange"] = (checked) => {
    setRoleChecked(checked);

    const all = getRoleRightSet(checked);
    setRoleRightKeys(all);
    setCheckedRightSet(all);
  };

  // 选择/取消权限
  const onRightCheck = useCallback((rights: Set<number>) => {
    setCheckedRightSet(rights);
  }, []);

  const askSave = () => {
    if (!crtEntity) return;

    Modal.confirm({
      title: t("authority.item.savepermission"),
      content:
        crtEntity.level === 4
          ? `${t("authority.item.overwriteuser")}${crtEntity.name}${t(
              "authority.item.allpermissionset"
            )}`
          : `${t("authority.item.cover")}${crtEntity.name}${t(
              "authority.item.all"
            )}`,
      onOk: doSave,
    });
  };

  // 保存编辑实体
  const doSave = () => {
    if (!crtEntity) return;
    setRightSaving(true);

    const rights = Array.from(checkedRightSet || []);
    const diff = rights.filter((r) => !roleRightKeys?.has(r));

    const rolepart =
      roleChecked?.map((rightId) => ({
        rightId,
        rightType: 0,
      })) || [];
    const rightpart =
      diff.map((rightId) => ({
        rightId,
        rightType: rightMap?.get(rightId)?.rightType,
      })) || [];

    const payload = [...rolepart, ...rightpart];

    if (crtEntity.level === 4) {
      addBatchNRightUser([
        {
          id: crtEntity.id,
          dtoList: payload,
        },
      ])
        .then(({ data }) => {
          if (data.code) {
            message.success(t("authority.item.opreationsuccess"));
            closeEditView();
            return;
          }
          data?.msg && message.error(data.msg);
        })
        .finally(() => setRightSaving(false));
    } else {
      addBatchNRightUserByDept({
        id: crtEntity.id,
        level: crtEntity.level,
        dtoList: payload,
      })
        .then(({ data }) => {
          if (data.code) {
            message.success(
              `${t("authority.item.operationsuccess")}${data.data}${t(
                "authority.item.updated"
              )}`
            );
            closeEditView();
            return;
          }
          data?.msg && message.error(data.msg);
        })
        .finally(() => setRightSaving(false));
    }
  };

  const checkedCount = checkedRightSet?.size || 0;
  const rightCount = rightMap?.size || 0;

  return (
    <PageMain>
      <RoleContext.Provider
        value={{
          roles,
          roleMap,
          rolesLoading,
          refreshRoles: getRoles,
          rightGroup,
          rightMap,
          rightLoading,
          refreshRights: getAuthorities,
        }}
      >
        <CustomBreadcrumb
          breads={[t("menu.system"), t("menu.system.authority")]}
        />
        <div className="flex flex-grow">
          <DepartmentTree depth={4} allowSearch onNodeSelect={onEntitySelect} />
          <div className="table-wrapper flex-grow">
            <div className="flex items-center justify-between">
              <Space>
                <Tooltip title={t("authority.item.collection")}>
                  <Space>
                    {crtEntity && (
                      <>
                        {nodeIcon[crtEntity.level]}
                        <span className="font-bold">{crtEntity?.name}</span>
                      </>
                    )}
                    <span>{t("authority.item.roles")}</span>
                    <QuestionCircleOutlined />
                  </Space>
                </Tooltip>
                {editView && (
                  <Tag color="warning">{t("authority.item.editview")}</Tag>
                )}
              </Space>
              <Space>
                {editView ? (
                  <>
                    <Button
                      type="primary"
                      onClick={askSave}
                      loading={rightSaving}
                    >
                      {t("authority.item.save")}
                    </Button>
                    <Button danger onClick={() => closeEditView()}>
                      {t("system.confirm.cancel")}
                    </Button>
                  </>
                ) : (
                  <Button type="primary" onClick={openEditView}>
                    {t("authority.item.edit")}
                  </Button>
                )}

                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: t("authority.item.rolemanagement"),
                        onClick() {
                          setRoleModalShow(true);
                        },
                      },
                    ],
                  }}
                >
                  <Button icon={<EllipsisOutlined />} />
                </Dropdown>
              </Space>
            </div>
            <div>
              {alertShow && (
                <Alert
                  className="mt-2"
                  message={t("authority.item.unableview")}
                  banner
                  closable
                />
              )}
              <div className="flex">
                <div className="flex-shrink-0 w-90">
                  <RoleList
                    loading={personLoading}
                    editView={editView}
                    checked={roleChecked}
                    onChange={onRoleChecked}
                    showAll={!Boolean(crtEntity)}
                    onEdit={openEditView}
                  />
                </div>
                <div className="flex-grow">
                  <div className="border-gray-200 border-1 border-solid rounded my-2">
                    <div className="bg-gray-200 px-2 py-1 flex items-center justify-between sticky top-0 z-2">
                      {t("authority.item.authority")}（
                      {editView
                        ? `${checkedCount}/${rightCount}`
                        : checkedCount}
                      ）
                    </div>
                    <div className="h-auto">
                      {editView ? (
                        <RightEditTree
                          checkedKeys={checkedRightSet}
                          onCheck={onRightCheck}
                          disabledKeys={roleRightKeys}
                        />
                      ) : (
                        <RightViewGroup
                          loading={personLoading}
                          onEdit={openEditView}
                          group={
                            Boolean(crtEntity) ? rightViewGroup : rightGroup
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RoleManageModal
          open={roleModalShow}
          onCancel={() => setRoleModalShow(false)}
        />
      </RoleContext.Provider>
    </PageMain>
  );
};

interface RoleListProps {
  showAll?: boolean;
  loading?: boolean;
  editView?: boolean;
  checked?: number[];
  onChange?: (checked: number[], isCheck: boolean, current: number) => void;
  onEdit?: () => void;
}

const RoleList: React.FC<RoleListProps> = ({
  showAll,
  loading = false,
  editView,
  checked,
  onChange,
  onEdit,
}) => {
  const { t } = useLocale(i18n);
  const { roles, rolesLoading, roleMap } = useRights();

  const handleCheck = (role: RoleInstance) => {
    if (!editView) return;
    const check = [...(checked || [])];
    let isCheck;
    if (check.includes(role.id)) {
      isCheck = false;
      check.splice(check.indexOf(role.id), 1);
    } else {
      isCheck = true;
      check.push(role.id);
    }
    onChange?.(check, isCheck, role.id);
  };

  const render = () => {
    if (!roles?.length) {
      return <Empty description={t("authority.item.norole")} />;
    }

    if (editView) {
      return roles.map((role) => (
        <div
          className={classNames("role-card", {
            checked: checked?.includes(role.id),
          })}
          onClick={() => handleCheck(role)}
          key={role.id}
        >
          {role.roleTitle}
          <div className="opts">
            {checked?.includes(role.id) ? (
              <FontAwesomeIcon icon={["fas", "check"]} color="green" />
            ) : (
              <FontAwesomeIcon icon={["far", "square"]} />
            )}
          </div>
        </div>
      ));
    }

    if (showAll) {
      return roles.map((role) => (
        <div className="role-card" key={role.id}>
          {role.roleTitle}
        </div>
      ));
    }

    return (
      checked?.map((roleId) => (
        <div className="role-card" key={roleId}>
          {roleMap?.get(roleId)?.roleTitle}
        </div>
      )) || (
        <Empty
          className="my-2"
          description={
            <>
              {t("authority.item.npassign")}
              <Button type="link" className="p-0" onClick={onEdit}>
                {t("authority.item.clickedit")}
              </Button>
            </>
          }
        />
      )
    );
  };

  const checkedCount = checked?.length || 0;
  const rolesCount = roles?.length || 0;

  return (
    <div className="border-gray-200 border-1 border-solid rounded my-2 mr-4">
      <div className="bg-gray-200 px-2 py-1 flex items-center justify-between">
        {t("authority.item.role")} （
        {editView ? `${checkedCount}/${rolesCount}` : checkedCount}）
      </div>
      <Spin spinning={rolesLoading || loading}>
        <div className="p-2 h-full">{render()}</div>
      </Spin>
    </div>
  );
};

RoleList.defaultProps = {
  editView: false,
};

interface RightViewGroupProps {
  loading?: boolean;
  group?: { [k in RightTypes]?: RightInstance[] };
  onEdit?: () => void;
}

const RightViewGroup = ({
  group,
  onEdit,
  loading = false,
}: RightViewGroupProps) => {
  const { t } = useLocale(i18n);

  return (
    <div>
      <Spin spinning={loading}>
        {group ? (
          Object.keys(group).map((k: unknown) => (
            <div key={k as number}>
              <div className="bg-gray-100 px-2 py-1 sticky top-6.5 z-1 font-bold">
                {`${
                  righttypes?.[k as RightTypes] || t("authority.item.other")
                }${t("authority.item.authority")}`}
              </div>
              <div className="p-2">
                <Tree
                  defaultExpandAll
                  treeData={group[k as RightTypes] as any}
                  fieldNames={{
                    title: "title",
                    key: "id",
                    children: "child",
                  }}
                  titleRender={
                    // 菜单i8n
                    Number(k) === 1
                      ? (node) => t(node.title as defaultI18nKeys)
                      : undefined
                  }
                />
              </div>
            </div>
          ))
        ) : (
          <Empty
            className="my-4"
            description={
              <>
                {t("authority.item.npassign")}
                <Button type="link" className="p-0" onClick={onEdit}>
                  {t("authority.item.clickedit")}
                </Button>
              </>
            }
          />
        )}
      </Spin>
    </div>
  );
};

export default AuthorityManagePage;
