import { LockOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  Space,
  Button,
  Input,
  message,
  Select,
  Divider,
  Upload,
  type UploadProps,
  Progress,
  Image,
} from "antd";
import { type ValidateStatus } from "antd/es/form/FormItem";
import { ChangeEventHandler, useEffect, useState } from "react";
import {
  addLabidTovirtoolsByexpid,
  listLabIdAndLabNameByParam,
  sypasswordTorF,
  updateLabHomeAndJSON,
  updateLabTypeById,
} from "../../../api/NewLabInfoController";
import { useLocale } from "../../../hooks/useLocale";
import { keyEncode } from "../../../utils/format";
import i18n from "./i18n";
import VideoUploader from "./VideoUploader";
import path_help_img from "../../../assets/images/path_help.png";

const AdvancedSetting = ({ labId }: { labId: number }) => {
  const { t } = useLocale(i18n);
  const [sypassword, setSypassword] = useState<string>();
  const [authed, setAuthed] = useState(false);
  const [advancedform] = Form.useForm();
  const [typelist, setTypelist] = useState<any[]>();
  const [lablist, setLablist] = useState<any[]>();
  const nlabtype = Form.useWatch("nlabtype", advancedform);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [zipFile, setZipFile] = useState<any[]>();
  const [pathHelp, setPathHelp] = useState<string>();
  const [pathStatus, setPathStatus] = useState<ValidateStatus>();
  const cZyhome = Form.useWatch("cZyhome", advancedform);

  const validate = () => {
    sypasswordTorF({ labId, sypassword }).then(({ data }) => {
      if (data.code) {
        setAuthed(true);
        setTypelist(data.data.labTypeList);
        advancedform.setFieldsValue(data.data);
        getlablist();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const getlablist = () => {
    listLabIdAndLabNameByParam().then(({ data }) => {
      if (data.code) {
        setLablist(data.data);
      } else {
        setLablist([]);
      }
    });
  };

  const saveTypeSetting = () => {
    const payload = advancedform.getFieldsValue(["nlabtype", "labList"]);
    if (Array.isArray(payload.labList))
      payload.labList = payload.labList.join();

    updateLabTypeById({ ...payload, labId }).then(({ data }) => {
      if (data.code) {
        message.success(t("experiment.item.savetype"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const saveNumbersSetting = () => {
    const payload = advancedform.getFieldsValue([
      "virtoolsexpid",
      "virtoolspid",
    ]);
    addLabidTovirtoolsByexpid({ ...payload, labid: labId }).then(({ data }) => {
      if (data.code) {
        message.success(t("experiment.item.savenumber"));
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const saveStepSetting = () => {
    const raw = advancedform.getFieldsValue(["cZyhome", "json"]);
    if (pathStatus === "error") return;

    if (raw.json) raw.json = keyEncode(raw.json);

    const payload = new FormData();
    payload.append("labId", labId.toString());
    payload.append("cZyhome", raw.cZyhome);
    payload.append("json", raw.json);
    if (zipFile) {
      payload.append("zipFile", zipFile[0]);
    }

    setUploading(true);
    updateLabHomeAndJSON(payload, (e) =>
      setProgress(Math.floor((e.loaded / e.total) * 100))
    )
      .then(({ data }) => {
        if (data.code) {
          message.success(t("experiment.item.updatesuccess"));
          return;
        }
      })
      .finally(() => setUploading(false));
  };

  const validatePath: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    if (!value) {
      setPathStatus("error");
      setPathHelp(t("experiment.item.enterpath"));
      return;
    }

    const last = value.lastIndexOf("/");
    if (last < 0) {
      setPathStatus("error");
      setPathHelp(t("experiment.item.containone"));
      return;
    }

    if (/\/{2,}/.test(value)) {
      setPathStatus("error");
      setPathHelp(t("experiment.item.containtwo"));
      return;
    }

    setPathStatus("validating");
    setPathHelp(
      `${t("experiment.item.resource")} ${value.substring(0, last)} 下`
    );
  };

  const beforeUpload: UploadProps["beforeUpload"] = (file) => {
    const suffix = file.name.substring(file.name.lastIndexOf("."));
    if (!suffix.includes("zip")) {
      message.error(t("experiment.item.onlyzip"));
      return Upload.LIST_IGNORE;
    }
    setZipFile([file]);
    return false;
  };

  const onFileRemove: UploadProps["onRemove"] = () => {
    setZipFile([]);
  };

  return (
    <>
      {authed ? (
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          preserve={false}
          form={advancedform}
        >
          <Form.Item label={t("experiment.item.extype")} name="nlabtype">
            <Select
              placeholder={t("experiment.item.selecttype")}
              options={typelist?.map(({ id, name }: any) => ({
                value: Number(id),
                label: name,
              }))}
            />
          </Form.Item>
          {nlabtype === 4 ? (
            <Form.Item label={t("experiment.item.submodule")} name="labList">
              <Select
                mode="multiple"
                placeholder={t("experiment.item.selectsubmodule")}
              >
                {lablist
                  ?.filter((el) => el.labId !== labId)
                  ?.map((el: any) => (
                    <Select.Option key={el.labId} value={el.labId}>
                      {el.cName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          ) : null}
          <div className="text-right mb-6">
            <Button type="primary" onClick={saveTypeSetting}>
              {t("experiment.item.savesetting")}
            </Button>
          </div>
          <Divider orientation="left" plain>
            {t("experiment.item.numberset")}
          </Divider>
          <Form.Item label={t("experiment.item.exnumber")}>
            <Form.Item className="inline-block w-1/3 mr-2" name="virtoolsexpid">
              <Input placeholder={t("experiment.item.number")} />
            </Form.Item>
            <span className="inline-block text-center leading-8 mr-2">-</span>
            <Form.Item className="inline-block w-1/3" name="virtoolspid">
              <Input placeholder={t("experiment.item.smallnumber")} />
            </Form.Item>
          </Form.Item>
          <div className="text-right mb-6">
            <Button type="primary" onClick={saveNumbersSetting}>
              {t("experiment.item.savenumberset")}
            </Button>
          </div>
          <Divider orientation="left" plain>
            {t("experiment.item.set")}
          </Divider>
          {nlabtype === 9 ? (
            <Form.Item
              label={t("experiment.item.video")}
              name="cZyhome"
              valuePropName="vid"
            >
              <VideoUploader />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label={t("experiment.item.path")}
                name="cZyhome"
                tooltip={t("experiment.item.decompression")}
                validateStatus={pathStatus}
                help={pathHelp}
                required
              >
                <Input
                  placeholder={t("experiment.item.enterpath")}
                  onChange={validatePath}
                />
              </Form.Item>
              <Form.Item
                label={t("experiment.item.filepackage")}
                name="zipFile"
                tooltip={
                  <>
                    <div>{t("experiment.item.rootdirectory")}</div>
                    <Image src={path_help_img} />
                  </>
                }
              >
                <Upload.Dragger
                  className="uploader"
                  maxCount={1}
                  fileList={zipFile}
                  beforeUpload={beforeUpload}
                  onRemove={onFileRemove}
                >
                  <div className="p-2">
                    <p className="ant-upload-drag-icon">
                      <FontAwesomeIcon
                        icon={["fas", "cloud-arrow-up"]}
                        size="3x"
                        className="text-gray-300"
                      />
                    </p>
                    <p className="text-gray-500">
                      {t("experiment.item.Uploadzip")}
                    </p>
                  </div>
                </Upload.Dragger>
              </Form.Item>
            </>
          )}
          <Form.Item label={t("experiment.item.stepinf")} name="json">
            <Input.TextArea
              rows={8}
              placeholder={t("experiment.item.enterstepinf")}
            />
          </Form.Item>
          <div className="text-right mb-6">
            <Button
              type="primary"
              onClick={saveStepSetting}
              loading={uploading}
            >
              {t("experiment.item.upasave")} {uploading ? `${progress}%` : ""}
            </Button>
          </div>
        </Form>
      ) : (
        <div className="w-full h-full bg-gray-200 border-dark-100 flex justify-center items-center">
          <Space>
            <Input.Password
              prefix={<LockOutlined />}
              allowClear
              visibilityToggle
              placeholder={t("experiment.item.entersec")}
              onPressEnter={validate}
              value={sypassword}
              onChange={(e) => setSypassword(e.target.value)}
            />
            <Button type="primary" onClick={validate}>
              {t("experiment.item.confirm")}
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};

export default AdvancedSetting;
