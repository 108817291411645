import Utf8 from "crypto-js/enc-utf8";
import Base64 from "crypto-js/enc-base64";
import crypto, { AES, mode, pad } from "crypto-js";
import { cloneDeep } from "lodash-es";

// 返回给定值的布尔类型，其中0被认为是真值
const isFalsy = (value: unknown): boolean => (value === 0 ? false : !value);

// 返回给定值是否为空值
const isVoid = (value: unknown) =>
  value === undefined || value === null || value === "";

const isNullish = (value: unknown) => value === undefined || value === null;

// 清理对象中的空键
const cleanObject = (object?: { [key: string]: unknown }) => {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    const value = result[key];
    isVoid(value) && delete result[key];
  });

  return result;
};

// 将毫秒级时间戳转换为给定的时间格式
const timeFormat = (
  timestamp: string | number,
  fmt: string = "YYYY-MM-DD hh:mm:ss"
) => {
  const date = new Date(timestamp);
  const opt: { [key: string]: string } = {
    "Y+": date.getFullYear().toString(),
    "M+": (date.getMonth() + 1).toString(),
    "D+": date.getDate().toString(),
    "h+": date.getHours().toString(),
    "m+": date.getMinutes().toString(),
    "s+": date.getSeconds().toString(),
  };

  for (let k in opt) {
    const ret = new RegExp(`(${k})`).exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }

  return fmt;
};

// 将时长(ms)转换为 hh:mm:ss
const durationFormat = (duration: number) => {
  const S = 1000,
    M = 60 * S,
    H = 60 * M;

  const h = Math.floor(duration / H);
  const m = Math.floor((duration - h * H) / M);
  const s = Math.floor((duration - h * H - m * M) / S);
  // const ms = duration - h * H - m * M - s * S;

  return `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}:${String(
    s
  ).padStart(2, "0")}`;
};

/**
 * XSS敏感字符转义
 * ' -> " "
 * " -> " "
 * < -> "&lt;"
 * > -> "&gt;"
 * eval() -> ""
 * *javascript: -> ""
 * \* -> ""
 * etc:sql keywords
 */
const HTMLtoEscape = (html: string) => {};

// html反转义
const EscapeReverseHTML = (escape: string) => {
  if (typeof escape === "string")
    return escape
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .replaceAll("&quot;", '"')
      .replaceAll("&apos;", "'")
      .replaceAll("&lowast;", "*")
      .replaceAll("& #40;", "(")
      .replaceAll("& #41;", ")");

  return escape;
};

// 将utf8字符编码为base64
const keyEncode = (key: string) => {
  const wordArray = Utf8.parse(key);
  return Base64.stringify(wordArray);
};

// 将base64解码为utf8字符
const keyDecode = (base64: string) => {
  const wordArray = Base64.parse(base64);
  return wordArray.toString(Utf8);
};

// 字符串AES加密
const encryptAES128 = (src: string, key: string) => {
  const bytes = Utf8.parse(src);
  // CipherParams.toString() 等价于 Base64.stringify(CipherParams.ciphertext)
  return AES.encrypt(bytes, Utf8.parse(key), {
    mode: mode.ECB,
    padding: pad.Pkcs7,
  }).toString();
};

const decryptAES128 = (cipher: string, key: string) => {
  const bytes = AES.decrypt(cipher, Utf8.parse(key), {
    mode: mode.ECB,
    padding: pad.Pkcs7,
  });
  return Utf8.stringify(bytes);
};

interface ArrayItem {
  id: number;
  pid: number;
}

interface TreeItem extends ArrayItem {
  children?: ArrayItem[];
}

type Array2Tree = <T>(arr: (T extends ArrayItem ? T : never)[]) => TreeItem[];

// 扁平数组转Tree结构
const array2Tree: Array2Tree = (raw) => {
  const res: TreeItem[] = [];
  const map = new Map<PropertyKey, TreeItem>();
  const arr = cloneDeep(raw);
  arr.forEach((item) => {
    map.set(item.id, item);
  });
  arr.forEach((item) => {
    const parent = map.get(item.pid);
    if (parent) {
      if (parent?.children) {
        parent.children.push(item);
      } else {
        parent.children = [item];
      }
    } else {
      res.push(item);
    }
  });
  return res;
};

// tree 扁平化
const getFlatten = (array: any[]): any[] => {
  const res = [];
  for (let i of array) {
    res.push(i);
    if (i.children && i.children?.length > 0) {
      res.push(...getFlatten(i.children));
    }
  }
  return res;
};

// tree扁平化，返回 Map
const getFlattenMap = <T extends Record<string, any>>(
  array: T[],
  keyName: keyof T,
  childName: keyof T = "children"
) => {
  const map = new Map();

  let item: T;
  const list = [...array];

  while (list.length > 0) {
    item = list.shift()!;
    map.set(item[keyName], item);

    if (item?.[childName]?.length) {
      list.unshift(...item[childName]);
    }
  }

  return map;
};

export {
  isFalsy,
  isVoid,
  isNullish,
  cleanObject,
  timeFormat,
  durationFormat,
  HTMLtoEscape,
  EscapeReverseHTML,
  keyEncode,
  keyDecode,
  array2Tree,
  getFlatten,
  getFlattenMap,
  encryptAES128,
  decryptAES128,
};
