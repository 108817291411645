import {
  listExamScoreAllByParams,
  listExamScoreAllByParamsExcel,
} from "@/api/ExamController";
import { cleanObject } from "@/utils/format";
import CustomBreadcrumb from "@/views/components/CustomBreadcrumb";
import DepartmentTree, {
  type NodeDataType,
} from "@/views/components/DepartmentTree";
import { ClusterOutlined } from "@ant-design/icons";
import { Form, Space, Input, Button, Table, DatePicker, Tooltip } from "antd";
import { type Moment } from "moment";
import { useCallback, useEffect, useState, type FC } from "react";
import { type RecordProps } from "../exam/examRecord";
import RecordDetail from "../exam/recordDetail";

const initPagination = {
  page: 1,
  rows: 10,
};

const ExamScorePage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [queryForm] = Form.useForm();
  const [tableData, setTableData] = useState();
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const [detailOpen, setDetailOpen] = useState(false);
  const [detail, setDetail] = useState<RecordProps>();
  const [tableSiderShow, setTableSiderShow] = useState(false);
  const [nodeSelected, setNodeSelected] = useState<NodeDataType>();

  const getTableData = (params?: object) => {
    setLoading(true);
    if (!params) {
      setTablePagenation(initPagination);
      params = initPagination;
    }
    listExamScoreAllByParams({ ...params })
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => setLoading(false));
  };

  const onSearch = useCallback(
    (groupId3?: number) => {
      const d = queryForm.getFieldsValue();

      if (groupId3) {
        d.groupId3 = groupId3;
      }

      if (d._time) {
        const [startTime, endTime] = d._time.map((day: Moment) =>
          day.format("YYYY-MM-DD HH:mm:ss")
        );
        d.startTime = startTime;
        d.endTime = endTime;
        delete d._time;
      }

      getTableData({ ...d, ...initPagination });
      setTablePagenation(initPagination);
    },
    [queryForm]
  );

  const openDetail = (record: RecordProps) => {
    setDetail(record);
    setDetailOpen(true);
  };

  const downloadRecord = () => {
    const d = queryForm.getFieldsValue();

    if (nodeSelected?.level === 3) {
      d.groupId3 = nodeSelected.id;
    }

    if (d._time) {
      const [startTime, endTime] = d._time.map((day: Moment) =>
        day.format("YYYY-MM-DD HH:mm:ss")
      );
      d.startTime = startTime;
      d.endTime = endTime;
      delete d._time;
    }

    listExamScoreAllByParamsExcel(cleanObject(d));
  };

  const onNodeSelect = (node?: NodeDataType) => {
    setNodeSelected(node);
  };

  useEffect(() => {
    if (nodeSelected?.level === 3) {
      onSearch(nodeSelected.id as number);
    } else {
      onSearch();
    }
  }, [nodeSelected, onSearch]);

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={["考试", "成绩汇总"]} />
      <div className="flex">
        {tableSiderShow ? (
          <DepartmentTree depth={3} allowSearch onNodeSelect={onNodeSelect} />
        ) : null}
        <div className="overflow-hidden flex-grow">
          <div className="table-query-bar">
            <Form layout="inline" form={queryForm}>
              <Space wrap>
                <Form.Item label="搜索" name="search">
                  <Input allowClear placeholder="搜索用户" />
                </Form.Item>
                <Form.Item label="完成时间" name="_time">
                  <DatePicker.RangePicker showTime />
                </Form.Item>
                <Button onClick={() => queryForm.resetFields()}>清除</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => onSearch()}
                >
                  查询
                </Button>
              </Space>
            </Form>
          </div>
          <div className="table-wrapper">
            <Space className="table-tool-bar">
              <Space>
                <Tooltip
                  title={`${tableSiderShow ? "收起" : "展开"}用户组织树`}
                  placement="top"
                >
                  <Button
                    icon={<ClusterOutlined />}
                    onClick={() => setTableSiderShow((x) => !x)}
                  />
                </Tooltip>
                <Button type="primary" onClick={downloadRecord}>
                  导出考试记录
                </Button>
              </Space>
            </Space>
            <Table
              size="small"
              rowKey="id"
              bordered
              scroll={{ x: 1300 }}
              loading={loading}
              dataSource={tableData}
              pagination={{
                size: "default",
                showQuickJumper: true,
                showSizeChanger: true,
                position: ["bottomCenter"],
                current: tablePagenation.page,
                pageSize: tablePagenation.rows,
                total,
                showTotal: (total) => `共${total}条`,
                onChange: (page, rows) => {
                  setTablePagenation({ page, rows });
                  getTableData({
                    ...queryForm.getFieldsValue(),
                    page,
                    rows,
                  });
                },
              }}
              columns={[
                {
                  title: "序号",
                  width: 50,
                  align: "center",
                  render: (_val, _row, index) => index + 1,
                },
                {
                  title: "姓名",
                  dataIndex: "userName",
                  align: "center",
                  width: 120,
                },
                {
                  title: "账号",
                  dataIndex: "userAccount",
                  align: "center",
                  width: 120,
                },
                {
                  title: "试卷名称",
                  dataIndex: "examName",
                  align: "center",
                  width: 200,
                },
                {
                  title: "分数",
                  dataIndex: "sumScore",
                  align: "center",
                  width: 80,
                  sorter: (a, b) => a.sumScore - b.sumScore,
                },
                {
                  title: "总分值",
                  dataIndex: "sumQuestionScore",
                  align: "center",
                  width: 80,
                  sorter: (a, b) => a.sumQuestionScore - b.sumQuestionScore,
                },
                {
                  title: "院系",
                  dataIndex: "groupName1",
                },
                {
                  title: "专业",
                  dataIndex: "groupName2",
                  width: 120,
                },
                {
                  title: "班级",
                  dataIndex: "groupName3",
                  width: 120,
                },
                {
                  title: "查看",
                  width: 90,
                  align: "center",
                  fixed: "right",
                  render: (_, row) => (
                    <Space>
                      <Button size="small" onClick={() => openDetail(row)}>
                        答题记录
                      </Button>
                    </Space>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <RecordDetail
        open={detailOpen}
        record={detail}
        onClose={() => {
          setDetail(undefined);
          setDetailOpen(false);
        }}
      />
    </div>
  );
};

export default ExamScorePage;
