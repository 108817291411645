import { Button, Modal, Table, Tabs, Tag, type TabsProps } from "antd";
import { useCallback, useEffect, useState, type FC } from "react";
import {
  queryMyScoreByLabIDAndUserID,
  queryScoreByLabIDAndUserID,
} from "../../../api/NewScoreController";
import {
  listAllByParams,
  listAllByParamsByLogin,
} from "../../../api/LabSettingController";
import { useLocale } from "../../../hooks/useLocale";
import { durationFormat } from "../../../utils/format";
import i18n from "../score/i18n";
import { AnswerDetailModal } from "../score/VideoScoreRecordModal";

interface CompositeScoreListProps {
  open?: boolean;
  onCancel?: () => void;
  // 查看实验报告
  onReportView?: (id: number) => void;
  param?: {
    // 如果没有userid, 则查询登录者个人的成绩列表
    userid?: number;
    labid: number;
    // 实验类型；9为视频实验
    type: number;
    userName?: string;
  };
}

const initPagination = {
  page: 1,
  rows: 10,
};

const CompositeScoreList: FC<CompositeScoreListProps> = ({
  open,
  onCancel,
  onReportView,
  param,
}) => {
  const [tabActive, setTabActive] = useState<string>();
  const { t } = useLocale(i18n);
  // 实验成绩列表
  const [scoreloading, setScoreLoading] = useState(false);
  const [scoreTableData, setScoreTableData] = useState<any[]>([]);
  const [scoreTotal, setScoreTotal] = useState(0);
  const [scoreTablePagenation, setScoreTablePagenation] =
    useState(initPagination);
  // 试题考核成绩列表
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [tablePagenation, setTablePagenation] = useState(initPagination);
  const [detailOpen, setDetailOpen] = useState(false);
  const [recordId, setRecordId] = useState<string>();

  const onTabChange: TabsProps["onChange"] = (key) => {
    setTabActive(key);
    if (key === "1") getScoreList();
    if (key === "2") getTableData();
  };

  // 实验成绩
  const getScoreList = useCallback(() => {
    // 如果查询指定用户的成绩列表。没有userid, 则查询登录者个人的成绩列表
    const imap = () => {
      return param?.userid
        ? queryScoreByLabIDAndUserID
        : queryMyScoreByLabIDAndUserID;
    };

    setScoreLoading(true);

    imap()({ ...scoreTablePagenation, ...param })
      .then(({ data }) => {
        if (data.code) {
          setScoreTableData(data.data.rows);
          setScoreTotal(data.data.records);
        }
      })
      .finally(() => setScoreLoading(false));
  }, [param, scoreTablePagenation]);

  const viewReport = (id: number) => {
    onReportView?.(id);
  };

  // 试题考核成绩
  const getTableData = useCallback(async () => {
    const imap = () => {
      return param?.userid ? listAllByParams : listAllByParamsByLogin;
    };

    setLoading(true);
    imap()({ ...tablePagenation, labId: param?.labid })
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => setLoading(false));
  }, [param, tablePagenation]);

  const openDetail = (id: string) => {
    setRecordId(id);
    setDetailOpen(true);
  };

  useEffect(() => {
    if (open && param) {
      if (param.type === 9) {
        getTableData();
      } else {
        getScoreList();
      }
    }
  }, [param, open, getTableData, getScoreList]);

  useEffect(() => {
    if (open) {
      setScoreTablePagenation(initPagination);
      setTablePagenation(initPagination);
      setTabActive(() => (param?.type === 9 ? "2" : "1"));
    }
  }, [open, param?.type]);

  return (
    <Modal
      title={t("table.head.scorelist")}
      width={1100}
      open={open}
      footer={null}
      destroyOnClose
      onCancel={() => onCancel?.()}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs
        activeKey={tabActive}
        onChange={onTabChange}
        items={
          [
            param?.type !== 9 && {
              label: t("declare.results.simulation"),
              key: "1",
              children: (
                <Table
                  size="small"
                  bordered
                  rowKey="id"
                  dataSource={scoreTableData}
                  loading={scoreloading}
                  pagination={{
                    size: "default",
                    showQuickJumper: true,
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                    current: scoreTablePagenation.page,
                    pageSize: scoreTablePagenation.rows,
                    total: scoreTotal,
                    showTotal: (total) =>
                      `${t("table.pagination.all")} ${total} ${t(
                        "table.pagination.total"
                      )}`,
                    onChange: (page, rows) => {
                      setScoreTablePagenation({ page, rows });
                    },
                  }}
                  columns={[
                    {
                      title: t("declare.results.experimentTimes"),
                      dataIndex: "nLabnumber",
                      align: "center",
                      width: 50,
                      sorter: (a, b) => a.nLabnumber - b.nLabnumber,
                    },
                    {
                      title: t("declare.results.score"),
                      dataIndex: "fScore",
                      align: "center",
                      width: 50,
                      sorter: (a, b) => a.fScore - b.fScore,
                    },
                    {
                      title: t("declare.results.startingTime"),
                      dataIndex: "dStartime",
                      align: "center",
                      width: 120,
                      sorter: (a, b) =>
                        new Date(a.dStartime)?.getTime() -
                        new Date(b.dStartime)?.getTime(),
                    },
                    {
                      title: t("declare.results.endingTime"),
                      dataIndex: "dEndtime",
                      align: "center",
                      width: 120,
                    },
                    {
                      title: t("declare.results.experimentTime"),
                      dataIndex: "dUsetime",
                      align: "center",
                      width: 120,
                      render: (val) => durationFormat(val),
                      sorter: (a, b) => a.dUsetime - b.dUsetime,
                    },
                    {
                      title: t("declare.results.experimentreport"),
                      align: "center",
                      width: 70,
                      render(_val, row) {
                        return (
                          <Button
                            type="link"
                            onClick={() => viewReport(row.id)}
                          >
                            {t("declare.results.view")}
                            {!row?.labReportId &&
                              t("declare.results.unfinished")}
                          </Button>
                        );
                      },
                    },
                    {
                      title: t("declare.results.type"),
                      dataIndex: "clabexamId",
                      width: 50,
                      render(val) {
                        return val === null
                          ? t("declare.results.exercise")
                          : t("declare.results.exam");
                      },
                    },
                    {
                      title: t("declare.results.result"),
                      dataIndex: "fScore",
                      width: 50,
                      render(val) {
                        return val < 60 ? (
                          <Tag color="#cd201f">
                            {t("declare.results.failed")}
                          </Tag>
                        ) : (
                          <Tag color="#87d068">
                            {t("declare.results.passed")}
                          </Tag>
                        );
                      },
                    },
                  ]}
                />
              ),
            },
            {
              label: t("declare.results.exerciseresult"),
              key: "2",
              children: (
                <Table
                  size="small"
                  bordered
                  rowKey="id"
                  dataSource={tableData}
                  loading={loading}
                  pagination={{
                    size: "default",
                    showQuickJumper: true,
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                    current: tablePagenation.page,
                    pageSize: tablePagenation.rows,
                    total,
                    showTotal: (total) =>
                      `${t("table.pagination.all")} ${total} ${t(
                        "table.pagination.total"
                      )}`,
                    onChange: (page, rows) => {
                      setTablePagenation({ page, rows });
                    },
                  }}
                  columns={[
                    {
                      title: t("declare.results.starttime"),
                      dataIndex: "createTime",
                      align: "center",
                      width: 120,
                    },
                    {
                      title: t("declare.results.answer"),
                      dataIndex: "questionDetail",
                      align: "center",
                      width: 110,
                    },
                    {
                      title: t("declare.results.duration"),
                      dataIndex: "watchDuration",
                      align: "center",
                      width: 120,
                      render: (val) => durationFormat(val * 1000),
                    },

                    {
                      title: t("declare.results.effectiveness"),
                      dataIndex: "isValid",
                      align: "center",
                      width: 120,
                      render(val) {
                        return val === 1 ? (
                          <Tag color="#87d068">
                            {t("declare.results.effective")}
                          </Tag>
                        ) : (
                          <Tag color="#cd201f">
                            {t("declare.results.invalid")}
                          </Tag>
                        );
                      },
                    },
                    {
                      title: t("declare.results.answerdetails"),
                      width: 50,
                      align: "center",
                      render: (_val, row) => (
                        <Button onClick={() => openDetail(row.id)}>
                          {t("declare.results.answerdetails")}
                        </Button>
                      ),
                    },
                  ]}
                />
              ),
            },
          ].filter((x) => Boolean(x)) as TabsProps["items"]
        }
      />
      <AnswerDetailModal
        open={detailOpen}
        setOpen={setDetailOpen}
        recordId={recordId}
      />
    </Modal>
  );
};

export default CompositeScoreList;
