import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const SystemSettingPage = () => {
  const { t } = useLocale();

  return (
    <div className="page-main">
      <CustomBreadcrumb breads={[t("menu.system"), t("menu.system.setting")]} />
      <div>系统设置</div>
    </div>
  );
};

export default SystemSettingPage;
