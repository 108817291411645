const i18n = {
  "en-US": {
    "Setupanels.item.savedsetting": "Saved automatic score deduction settings",
    "Setupanels.item.resetsetting":
      "Automatic score deduction settings have been reset",
    "Setupanels.item.automatic": "automatic score deduction",
    "Setupanels.item.score": "Score 0-100",
    "Setupanels.item.duration": "duration of experiment",
    "Setupanels.item.enterduration":
      "Please enter the duration of the experiment",
    "Setupanels.item.minute": "minute",
    "Setupanels.item.suredel":
      "This will delete the current automatic score deduction settings. Are you sure?",
    "Setupanels.item.reset": "Reset to initial value",
    "Setupanels.item.savechange": "Save changes",
    "Setupanels.item.setting": "experimental setting",
    "Setupanels.item.currency": "currency",
    "Setupanels.item.experimentsteps": "experimental steps",
    "Setupanels.item.teachingtips": "teaching tips",
    "Setupanels.item.submitscore": "submit score",
    "Setupanels.item.exercisesetting": "exercise setting",
    "Setupanels.item.teachinginf": "Teaching information",
    "Setupanels.item.modulesetting": "Multi-module setting",
    "Setupanels.item.savedscore": "Saved submission score settings",
    "Setupanels.item.maximum": "Submit maximum score",
    "Setupanels.item.entermaximum":
      "Please enter the maximum score for submission",
    "Setupanels.item.basisscore": "Experimental basis score",
    "Setupanels.item.enterbasisscore":
      "Please enter the experimental basis score",
    "Setupanels.item.effective": "Effective step score",
    "Setupanels.item.entereffective": "Please enter valid step score",
    "Setupanels.item.submitbtn": "Submit button",
    "Setupanels.item.selectbtn":
      "Please select the Submit button to show the status",
    "Setupanels.item.display": "display",
    "Setupanels.item.hide": "hide",
    "Setupanels.item.selectmode": "Please select the experiment mode",
    "Setupanels.item.selectexperiment": "Please select experiment",
    "Setupanels.item.setmodule":
      "The multi-module Settings are saved successfully",
    "Setupanels.item.setupmodule":
      "The multi-module setup has been initialized",
    "Setupanels.item.suredelmodule":
      "This will delete the saved multi-module settings. Are you sure?",
    "Setupanels.item.Initialization": "Initialization setting",
    "Setupanels.item.mode": "mode",
    "Setupanels.item.taskmode": "task mode",
    "Setupanels.item.assessmentmode": "assessment mode",
    "Setupanels.item.cycles": "number of cycles",
    "Setupanels.item.assessable": "assessable number",
    "Setupanels.item.selectex": "select experiment",
    "Setupanels.item.sequence": "sequence of experiments",
    "Setupanels.item.deleted": "The module has been deleted",
    "Setupanels.item.unlock": "No unlock condition",
    "Setupanels.item.unlockscore": "unlock score",
    "Setupanels.item.prompt": "reference prompt",
    "Setupanels.item.inpprompt": "Input reference prompt",
    "Setupanels.item.savestep": "The step Settings are saved successfully",
    "Setupanels.item.resetstep":
      "All experimental step settings have been reset",
    "Setupanels.item.searchstep": "search step information",
    "Setupanels.item.selectstep":
      "Select the experiment step tree node to edit",
    "Setupanels.item.delstep":
      "This will delete all currently saved experimental step settings. Are you sure?",
    "Setupanels.item.resetinitial": "Reset to initial value",
    "Setupanels.item.stepinf": "step information",
    "Setupanels.item.voiceprompt": "voice prompt",
    "Setupanels.item.flashing": "Points deducted for flash prompt",
    "Setupanels.item.deduct": "Deduct points for operation prompt",
    "Setupanels.item.stepprompt": "step prompt information",
    "Setupanels.item.prompttiming": "prompt timing",
    "Setupanels.item.before": "before",
    "Setupanels.item.after": "after",
    "Setupanels.item.stepbefore": "Before the step starts",
    "Setupanels.item.stepafter": "After the step starts",
    "Setupanels.item.promptinf": "prompt information",
    "Setupanels.item.delmessage":
      "Are you sure you want to delete this message?",
    "Setupanels.item.delete": "delete",
    "Setupanels.item.addprompt": "Add prompt information",
    "Setupanels.item.testquestion": "test questions",
    "Setupanels.item.addquestion": "Add questions",
    "Setupanels.item.savesuccess": "save successfully",
    "Setupanels.item.resetteach":
      "All teaching prompt Settings have been reset",
    "Setupanels.item.searchprompt": "Search prompt information",
    "Setupanels.item.saveprompt":
      "This will delete the currently saved teaching prompt settings. Are you sure?",
    "Setupanels.item.promptpic": "Prompt picture list",
    "Setupanels.item.promptnode": "Select the prompt information node to edit",
    "Setupanels.item.uploadimage":
      "Please upload images in png/jpg/gif format ",
    "Setupanels.item.uploadpic": "Please upload pictures less than 1MB",
    "Setupanels.item.uploadsuccess": "upload successful",
    "Setupanels.item.clickordrag": "Click to upload or drag image to this area",
    "Setupanels.item.suredelprompt":
      "Are you sure you want to delete this prompt image?",
    "Setupanels.item.promptnotupload": "Prompt picture not uploaded",
    "Setupanels.item.updategeneral":
      "The experimental general Settings have been updated",
    "Setupanels.item.setgeneral":
      "Creation of experiment general settings succeeded",
    "Setupanels.item.opennotification": "open notification",
    "Setupanels.item.afteropen":
      "After opening, send a notice to the owner of this experiment permission under the following circumstances",
    "Setupanels.item.changetime": "1. Change of learning time",
    "Setupanels.item.changeauthority": "2. Change of experiment authority",
    "Setupanels.item.deadline": "3. Near the learning deadline",
    "Setupanels.item.learntime": "learning time",
    "Setupanels.item.specify": "Specify the study time of the experiment.",
    "Setupanels.item.continue":
      "You can continue to answer questions after the deadline",
    "Setupanels.item.testqu": "Set of test questions",
    "Setupanels.item.afterset":
      "After setting, for the video course, it will pop up randomly from the test set when watching, and ask the viewer to answer",
  },
  "zh-CN": {
    "Setupanels.item.savedsetting": "已保存自动扣分设置",
    "Setupanels.item.resetsetting": "已重置自动扣分设置",
    "Setupanels.item.automatic": "自动扣分",
    "Setupanels.item.score": "分值0-100",
    "Setupanels.item.duration": "实验时长",
    "Setupanels.item.enterduration": "请输入实验时长",
    "Setupanels.item.minute": "分钟",
    "Setupanels.item.suredel": "这将删除当前的自动扣分设置，确定吗?",
    "Setupanels.item.reset": "重置为初值",
    "Setupanels.item.savechange": "保存更改",
    "Setupanels.item.setting": "实验设置",
    "Setupanels.item.currency": "通用",
    "Setupanels.item.experimentsteps": "实验步骤",
    "Setupanels.item.teachingtips": "教学提示",
    "Setupanels.item.submitscore": "提交分数",
    "Setupanels.item.exercisesetting": "习题设置",
    "Setupanels.item.teachinginf": "教学信息",
    "Setupanels.item.modulesetting": "多模块设置",
    "Setupanels.item.savedscore": "已保存提交分数设置",
    "Setupanels.item.maximum": "提交最大得分",
    "Setupanels.item.entermaximum": "请输入提交最大得分",
    "Setupanels.item.basisscore": "实验基础得分",
    "Setupanels.item.enterbasisscore": "请输入实验基础得分",
    "Setupanels.item.effective": "有效步骤得分",
    "Setupanels.item.entereffective": "请输入有效步骤得分",
    "Setupanels.item.submitbtn": "提交按钮",
    "Setupanels.item.selectbtn": "请选择提交按钮展示状态",
    "Setupanels.item.display": "显示",
    "Setupanels.item.hide": "隐藏",
    "Setupanels.item.selectmode": "请选择实验模式",
    "Setupanels.item.selectexperiment": "请选择实验",
    "Setupanels.item.setmodule": "保存多模块设置成功",
    "Setupanels.item.setupmodule": "已初始化多模块设置",
    "Setupanels.item.suredelmodule": "这将删除已保存的多模块设置，确定吗?",
    "Setupanels.item.Initialization": "初始化设置",
    "Setupanels.item.mode": "模式",
    "Setupanels.item.taskmode": "任务模式",
    "Setupanels.item.assessmentmode": "考核模式",
    "Setupanels.item.cycles": "可循环次数",
    "Setupanels.item.assessable": "可考核次数",
    "Setupanels.item.selectex": "选择实验",
    "Setupanels.item.sequence": "实验顺序",
    "Setupanels.item.deleted": "已删除的模块",
    "Setupanels.item.unlock": "无解锁条件",
    "Setupanels.item.unlockscore": "解锁分数",
    "Setupanels.item.prompt": "参考提示",
    "Setupanels.item.inpprompt": "输入参考提示",
    "Setupanels.item.savestep": "保存步骤设置成功",
    "Setupanels.item.resetstep": "已重置所有实验步骤设置",
    "Setupanels.item.searchstep": "搜索步骤信息",
    "Setupanels.item.selectstep": "选择实验步骤树节点以编辑",
    "Setupanels.item.delstep": "这将删除当前所有已保存的实验步骤设置，确定吗？",
    "Setupanels.item.resetinitial": "重置为初值",
    "Setupanels.item.stepinf": "步骤信息",
    "Setupanels.item.voiceprompt": "语音提示",
    "Setupanels.item.flashing": "闪光提示扣分",
    "Setupanels.item.deduct": "操作提示扣分",
    "Setupanels.item.stepprompt": "步骤提示信息",
    "Setupanels.item.prompttiming": "提示时序",
    "Setupanels.item.before": "前",
    "Setupanels.item.after": "后",
    "Setupanels.item.stepbefore": "步骤开始前",
    "Setupanels.item.stepafter": "步骤开始后",
    "Setupanels.item.promptinf": "提示信息",
    "Setupanels.item.delmessage": "确定要删除此提示信息吗?",
    "Setupanels.item.delete": "删除",
    "Setupanels.item.addprompt": "添加提示信息",
    "Setupanels.item.testquestion": "试题",
    "Setupanels.item.addquestion": "添加试题",
    "Setupanels.item.savesuccess": "保存成功",
    "Setupanels.item.resetteach": "已重置所有教学提示设置",
    "Setupanels.item.searchprompt": "搜索提示信息",
    "Setupanels.item.saveprompt": "这将删除当前已保存的教学提示设置，确定吗?",
    "Setupanels.item.promptpic": "提示图片列表",
    "Setupanels.item.promptnode": "选择提示信息节点进行编辑",
    "Setupanels.item.uploadimage": "请上传png/jpg/gif格式的图片",
    "Setupanels.item.uploadpic": "请上传1MB以内的图片",
    "Setupanels.item.uploadsuccess": "上传成功",
    "Setupanels.item.clickordrag": "点击上传或拖拽图片到此区域",
    "Setupanels.item.suredelprompt": "确定要删除此提示图片吗?",
    "Setupanels.item.promptnotupload": "未上传提示图片",
    "Setupanels.item.updategeneral": "已更新实验通用设置",
    "Setupanels.item.setgeneral": "创建实验通用设置成功",
    "Setupanels.item.opennotification": "开启通知",
    "Setupanels.item.afteropen":
      "开启后，在以下情况，向此实验权限的拥有者发送通知",
    "Setupanels.item.changetime": "1.学习时间变更",
    "Setupanels.item.changeauthority": "2.实验权限变更",
    "Setupanels.item.deadline": "3.临近学习截止时间",
    "Setupanels.item.learntime": "学习时间",
    "Setupanels.item.specify": "指定实验的学习时间。",
    "Setupanels.item.continue": "截止时间后可继续答题",
    "Setupanels.item.testqu": "试题集",
    "Setupanels.item.afterset":
      "设置后，对于视频课程，会在观看时从试题集中随机弹出，要求观看者作答",
  },
};

export default i18n;
