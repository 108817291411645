const i18n = {
  "en-US": {
    "modal.title": "Experiment Report",
    "report.export": "Export Experiment Report",
    "report.title": "Experiment Title",
    "report.score": "Experiment Score",
    "report.name": "Submitter",
    "report.number": "Student Number",
    "report.starttime": "Starting Time",
    "report.endtime": "Ending Time",
    "report.record": "Experiment Record",
    "report.param": "Parameter Result Record",
    "report.content": "Experiment Report",
    "report.comment": "Teacher Comments",
    "report.reviewer": "Reviewer",
    "report.reviewtime": "Review Time",
    "report.item.savecomment": "Save Comment",
    "report.item.editcomment": "Edit Comment",
    "report.item.reset": "Reset",
    "report.item.savereport": "Save Report",
    "report.item.editreport": "Edit Report",
    "report.msg.not-filled": "The report did not fill in, unable to comment",
    "report.msg.cannot-save": "Unable to save comments",
    "report.msg.reset": "Reset the experiment report",
    "report.msg.resetconfirm":
      "Reset the experiment report to an empty template. Are you sure?",
    "report.msg.resetdone": "Has been reset to an empty template.",
    "declare.results.unfinished": "Unfinished",
  },
  "zh-CN": {
    "modal.title": "实验报告",
    "report.export": "导出实验报告",
    "report.title": "实验名称",
    "report.score": "分数",
    "report.name": "姓名",
    "report.number": "学号",
    "report.starttime": "开始时间",
    "report.endtime": "结束时间",
    "report.record": "实验记录",
    "report.param": "参数结果记录",
    "report.content": "实验报告",
    "report.comment": "教师评语",
    "report.reviewer": "批阅人",
    "report.reviewtime": "批阅时间",
    "report.item.savecomment": "保存评语",
    "report.item.editcomment": "编辑评语",
    "report.item.reset": "重置",
    "report.item.savereport": "保存实验报告",
    "report.item.editreport": "编辑实验报告",
    "report.msg.not-filled": "此报告未填写，无法进行评语",
    "report.msg.cannot-save": "无法保存评语",
    "report.msg.reset": "重置实验报告",
    "report.msg.resetconfirm": "将实验报告重置为空模板。确定吗？",
    "report.msg.resetdone": "已重置为空模板",
    "declare.results.unfinished": "未填写",
  },
};

export default i18n;
