import { useEffect, useState } from "react";
import { Avatar, message, Upload } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { UPLOAD, FileAcceptList } from "../../../api/NewUpLoadController";
import { useLocale } from "../../../hooks/useLocale";
import defaultAvatar from "../../../assets/images/avatar-default.png";

interface AvatarCoverProps {
  src?: string;
  size?: number;
  onUploaded?: (url: string) => void;
}

const i18n = {
  "en-US": {
    error1: "JPG, PNG, GIF images Only",
    error2: "Please upload pictures up to 5MB",
    error3: "Service Exception",
    success: "Avatar uploaded successfully! Save and take effect.",
  },
  "zh-CN": {
    error1: "请上传png/jpg/gif格式的图片",
    error2: "请上传5MB以内的图片",
    error3: "服务异常",
    success: "头像上传成功！保存后生效",
  },
};

const AvatarCover = (avatarProps: AvatarCoverProps) => {
  const { src, onUploaded, size = 90 } = avatarProps;
  const [avatar, setAvatar] = useState(src);
  const [maskShow, setMaskShow] = useState(false);
  const { t } = useLocale(i18n);

  useEffect(() => {
    setAvatar(src);
  }, [src]);

  const onMouseEnter = () => {
    setMaskShow(true);
  };

  const onMouseLeave = () => {
    setMaskShow(false);
  };

  const beforeImgUpload = (file: File) =>
    new Promise<boolean>((resolve, reject) => {
      if (!FileAcceptList.image.includes(file.type)) {
        message.error(t("error1"));
        return reject(false);
      }
      if (file.size / 1024 / 1024 > 5) {
        message.error(t("error2"));
        return reject(false);
      }
      return resolve(true);
    });

  const onUpload = (stat: any) => {
    const { file } = stat;
    if (file?.error) {
      message.error(t("error3"));
      return;
    }

    if (file?.response?.code) {
      setAvatar(file.response.data);
      onUploaded?.(file.response.data);
      message.success(t("success"));
      return;
    }
  };

  return (
    <div className="text-center">
      <span
        className="inline-block relative overflow-hidden rounded-full cursor-pointer"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Avatar
          size={size}
          src={avatar}
          icon={<img src={defaultAvatar} alt="avatar" />}
        />
        <Upload
          action={UPLOAD}
          withCredentials
          maxCount={1}
          showUploadList={false}
          accept={FileAcceptList.image.join()}
          data={{ keyName: "userAvatar" }}
          beforeUpload={beforeImgUpload}
          onChange={onUpload}
        >
          {maskShow ? (
            <div className="absolute flex flex-col justify-center items-center text-white select-none h-full w-full top-0 left-0 p-2 bg-black bg-opacity-50">
              <div>
                <PlusCircleOutlined size={40} />
              </div>
              {t("user.attr.changeavatar")}
            </div>
          ) : null}
        </Upload>
      </span>
    </div>
  );
};

export default AvatarCover;
