import { useContext } from "react";
import { SystemInfoContext } from "../context/SystemInfoContext";

export const useSystemInfo = () => {
  const ctx = useContext(SystemInfoContext);
  if (!ctx)
    throw new Error(
      "SystemInfoContext不在父状态树中.你是否将Provider挂载在了错误的位置?"
    );

  return ctx;
};
