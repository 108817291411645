const i18n = {
  "en-US": {
    "login.roles.username": "Input user name here",
    "login.roles.password": "Input your password here",
    "login.roles.code": "Input captcha",
    "login.roles.password2": "Please confirm password",
    "login.roles.password3":
      "Password too short.Please set at least eight of the password",
    "login.roles.password4":
      "Passwords must contain numbers and either uppercase or lowercase letters or symbols",
    "login.roles.password5": "The passwords entered two does not match",
    "login.roles.password6": "Please input your old password",
    "login.roles.password7": "Please input new password",
    "login.roles.name": "Please enter your name",
    "login.roles.sex": "Please select your gender",
    "login.roles.yid": "Please select the department",
    "login.roles.zid": "Please select the Subject",
    "login.roles.bid": "Please select the class",
    "login.roles.zwid": "Please select the job",
    "login.roles.tel": "Please enter your tel",
    "login.roles.tel2": "Mobile phone number format is incorrect",
    "login.roles.mail": "Please enter your E-mail",
    "login.roles.mail2": "Email address format is incorrect",
    "login.roles.weak": "Weak",
    "login.roles.medium": "Medium",
    "login.roles.strong": "Strong",
    "login.welcome": "Welcome to the Virtual Simulation Experiment Platform",
    "login.item.remember": "Remember Password",
    "login.item.login": "Login",
    "login.item.loginz": "Expert login",
    "login.item.noaccount": "No account yet?",
    "login.item.registernow": "Register Now",
    "login.item.back": "Back",
    "login.item.register": "Register",
    "login.item.changepwd": "Change Password",
    "login.tips.changesuccess":
      "Password change is successful, please login again",
    "login.lang": "UI Language:",
    "login.item.operation":
      "Operation Manual for Virtual Simulation Experiment",
    "login.item.loginlog": "Login log",
    "login.item.eversince": "ever since",
    "login.item.sofar": "so far",
    "login.item.logintime": "Login time",
    "login.item.exittime": "Exit time",
    "login.item.loginip": "Login ip",
    "login.item.loginMAC": "Log in to MAC",
    "login.item.loginnum": "Number of logins",
    "login.item.thistime": "Time of use",
    "login.item.usertype": "User type",
    "login.item.student": "student",
    "login.item.teacher": "teacher",
    "login.item.organization": "organization",
    "login.item.selorganization": "Please select organization",
  },
  "zh-CN": {
    "login.roles.username": "请输入学号/工号",
    "login.roles.password": "请输入密码",
    "login.roles.code": "请输入验证码",
    "login.roles.password2": "请确认密码",
    "login.roles.password3": "口令过短。请设置至少8位的密码",
    "login.roles.password4": "密码必须包含数字和大、小写字母、符号中的一种",
    "login.roles.password5": "两次输入的密码不匹配",
    "login.roles.password6": "请输入旧密码",
    "login.roles.password7": "请输入新密码",
    "login.roles.name": "请输入姓名",
    "login.roles.sex": "请选择性别",
    "login.roles.yid": "请选择院系",
    "login.roles.zid": "请选择专业",
    "login.roles.bid": "请选择班级",
    "login.roles.zwid": "请选择职务",
    "login.roles.tel": "请输入手机号码",
    "login.roles.tel2": "手机号码格式不正确",
    "login.roles.mail": "请输入邮箱",
    "login.roles.mail2": "邮箱格式不正确",
    "login.roles.weak": "弱",
    "login.roles.medium": "中",
    "login.roles.strong": "强",
    "login.welcome": "欢迎使用虚拟仿真实验平台",
    "login.item.remember": "记住密码",
    "login.item.login": "登录",
    "login.item.loginz": "专家登录",
    "login.item.noaccount": "还没有账号？",
    "login.item.registernow": "立即注册",
    "login.item.back": "返回",
    "login.item.register": "注册",
    "login.item.changepwd": "修改密码",
    "login.tips.changesuccess": "密码修改成功，请重新登陆",
    "login.lang": "界面语言:",
    "login.item.operation": "虚拟仿真实验操作手册",
    "login.item.loginlog": "登录日志",
    "login.item.eversince": "有史以来",
    "login.item.sofar": "至今",
    "login.item.logintime": "登录时间",
    "login.item.exittime": "退出时间",
    "login.item.loginip": "登陆ip",
    "login.item.loginMAC": "登陆MAC",
    "login.item.loginnum": "登陆次数",
    "login.item.thistime": "本次使用时间",
    "login.item.usertype": "用户类型",
    "login.item.student": "学生",
    "login.item.teacher": "教师",
    "login.item.organization": "组织",
    "login.item.selorganization": "请选择组织",
  },
};

export default i18n;
