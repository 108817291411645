const i18n = {
  "en-US": {
    "declare.item.Platform": "Platform Data Statistics",
    "declare.item.Organization": "Choosing an Organization",
    "declare.item.history": "throughout history",
    "declare.item.sofar": "so far",
    "declare.item.allexperiments": "All experiments",
    "declare.item.view": "Page views / person-times",
    "declare.item.Experimenters": "Number of Experimenters",
    "declare.item.Experiment": "Number of Experiment",
    "declare.item.platformuser": "Total platform users",
    "declare.item.platformex": "Total number of platform experiments",
    "declare.item.Cumulative":
      "Cumulative experiment duration / Hours and seconds",
  },
  "zh-CN": {
    "declare.item.Platform": "平台数据统计",
    "declare.item.Organization": "选择组织",
    "declare.item.history": "有史以来",
    "declare.item.sofar": "至今",
    "declare.item.allexperiments": "所有实验",
    "declare.item.view": "浏览量 / 人次",
    "declare.item.Experimenters": "实验参与人数",
    "declare.item.Experiment": "实验参与人次",
    "declare.item.platformuser": "平台用户总数",
    "declare.item.platformex": "平台实验总数",
    "declare.item.Cumulative": "累计实验时长 / 时分秒",
  },
};

export default i18n;
