const i18n = {
  "en-US": {
    "site.item.basicinf": "Basic site Information",
    "site.item.systentitle": "System Title",
    "site.item.entersystentitle": "Please enter the system title",
    "site.item.welcomemessage": "Welcome message of landing page",
    "site.item.enterwelcomemessage": "Please enter the welcome message",
    "site.item.projectname": "Project name",
    "site.item.enterprojectname": "Please enter the project name",
    "site.item.footerinf": "Footer information",
    "site.item.systentitleen": "System Title(en)",
    "site.item.entersystentitleen": "Please enter the system title(en)",
    "site.item.welcomemessageen": "Welcome message of landing page(en)",
    "site.item.enterwelcomemessageen": "Please enter the welcome message(en)",
    "site.item.projectnameen": "Project name(en)",
    "site.item.enterprojectnameen": "Please enter the project name(en)",
    "site.item.footerinfen": "Footer information(en)",
    "site.item.save": "save",
    "site.item.sitesetting": "Site Settings",
    "site.item.loginlogo": "Login page logo",
    "site.item.declarelogo": "Declaration page logo",
    "site.item.loginbg": "Background image of login page",
    "site.item.bannerimg": "Banner image of declaration page",
    "site.item.miscellaneous": "Miscellaneous declaration page",
    "site.item.free": "Free registration entry (Expert login)",
    "site.item.btncolor": "Page button color",
    "site.item.btnhovercolor": "Page button hover color",
    "site.item.freebtncolor": "Free registration button color",
    "site.item.freebtnhovercolor": "Free registration button hover color",
    "site.item.saveset": "Save miscellaneous Settings",
    "site.item.setup": "Setup successful",
    "site.item.uploadpic": "Please upload pictures less than 5MB",
    "site.item.uploadfail": "Upload failed",
    "site.item.uploadhere": "Upload the file here",
    "site.item.uploadimage": "Please upload images in png/jpg/gif format ",
  },
  "zh-CN": {
    "site.item.basicinf": "站点基本信息",
    "site.item.systentitle": "系统标题",
    "site.item.entersystentitle": "请输入系统标题",
    "site.item.welcomemessage": "登录页欢迎语",
    "site.item.enterwelcomemessage": "请输入欢迎语",
    "site.item.projectname": "项目名称",
    "site.item.enterprojectname": "请输入项目名称",
    "site.item.footerinf": "页脚信息",
    "site.item.systentitleen": "系统标题(en)",
    "site.item.entersystentitleen": "请输入系统标题(en)",
    "site.item.welcomemessageen": "登录页欢迎语(en)",
    "site.item.enterwelcomemessageen": "请输入欢迎语(en)",
    "site.item.projectnameen": "项目名称(en)",
    "site.item.enterprojectnameen": "请输入项目名称(en)",
    "site.item.footerinfen": "页脚信息(en)",
    "site.item.save": "保存",
    "site.item.sitesetting": "站点设置",
    "site.item.loginlogo": "登录页logo",
    "site.item.declarelogo": "申报页logo",
    "site.item.loginbg": "登录页背景图",
    "site.item.bannerimg": "申报页banner图片",
    "site.item.miscellaneous": "申报页面杂项",
    "site.item.free": "免注册入口(专家登录)",
    "site.item.btncolor": "页面按钮颜色",
    "site.item.btnhovercolor": "页面按钮hover颜色",
    "site.item.freebtncolor": "免注册按钮颜色",
    "site.item.freebtnhovercolor": "免注册按钮hover颜色",
    "site.item.saveset": "保存杂项设置",
    "site.item.setup": "设置成功",
    "site.item.uploadpic": "请上传5MB以内的图片",
    "site.item.uploadfail": "上传失败",
    "site.item.uploadhere": "在此处上传文件",
    "site.item.uploadimage": "请上传png/jpg/gif格式的图片",
  },
};

export default i18n;
