const i18n = {
  "en-US": {
    "mobile.item.personalDetails": "Personal details",
    "mobile.item.questionNumber": "Number",
    "mobile.item.questioner": "Questioner",
    "mobile.item.questionDetails": "Details",
    "mobile.item.questionTime": "Question time",
    "mobile.item.state": "State",
    "mobile.item.schedule": "Schedule",
    "mobile.item.urgency": "Urgency",
    "mobile.item.ordinary": "Ordinary",
    "mobile.item.resolved": "Resolved",
    "mobile.item.processing": "Processing",
    "mobile.item.waitReply": "Forward to your reply",
    "mobile.item.copyMsg": "Copy successful",
    "mobile.item.replysuccess": "Reply Success!",
    "mobile.item.footer": "Technical Support",
    "mobile.item.submit": "Submit",
  },
  "zh-CN": {
    "mobile.item.personalDetails": "个人详情",
    "mobile.item.questionNumber": "编号",
    "mobile.item.questioner": "提问者",
    "mobile.item.questionDetails": "详情",
    "mobile.item.questionTime": "提问时间",
    "mobile.item.state": "状态",
    "mobile.item.schedule": "进度",
    "mobile.item.urgency": "紧急",
    "mobile.item.ordinary": "普通",
    "mobile.item.resolved": "已解决",
    "mobile.item.processing": "处理中",
    "mobile.item.waitReply": "待您回复",
    "mobile.item.copyMsg": "复制成功",
    "mobile.item.replysuccess": "回复成功！",
    "mobile.item.footer": "技术支持",
    "mobile.item.submit": "提交",
  },
};

export default i18n;
