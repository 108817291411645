import { DownOutlined } from "@ant-design/icons";
import {
  Space,
  DatePicker,
  Card,
  Col,
  Row,
  message,
  Select,
  Form,
  type FormProps,
  Spin,
  TreeSelect,
} from "antd";
import { FC, useEffect, useState } from "react";
import { statisticsData } from "../../../api/NewHomeController";
import { queryLabList } from "../../../api/NewLabInfoController";
import { useLocale } from "../../../hooks/useLocale";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { type AnalysisData } from "../../dashboard";
import icon20 from "../../../assets/images/icons/20.png";
import icon21 from "../../../assets/images/icons/21.png";
import icon22 from "../../../assets/images/icons/22.png";
import icon23 from "../../../assets/images/icons/23.png";
import icon24 from "../../../assets/images/icons/24.png";
import icon25 from "../../../assets/images/icons/25.png";

import { durationFormat } from "../../../utils/format";
import { queryDeptTree } from "../../../api/NewDeptController";
import { type NodeDataType } from "../../components/DepartmentTree";
import { flatMapDeep } from "lodash-es";
import i18n from "./i18n";
import DepartmentSelector from "../../components/DepartmentTreeSelector";

interface StatisticData extends AnalysisData {
  userCount: number;
  labCount: number;
}

const getDepartmentId = (node?: NodeDataType) => {
  const key = {
    1: "yid",
    2: "zid",
    3: "bid",
    4: "uid",
  };

  return node?.level ? { [key[node.level]]: node.id } : {};
};

const { RangePicker } = DatePicker;

const StatisticPage = () => {
  const { t } = useLocale(i18n);
  const [loading, setLoading] = useState(false);
  const [lablist, setLabList] = useState<any[]>();
  const [statistic, setStatistic] = useState<StatisticData>();
  const [form] = Form.useForm();

  const getlablist = () => {
    queryLabList({ page: 1, rows: 999 }).then(({ data }) => {
      if (data.code) {
        setLabList(data?.data?.rows);
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const getAnalysis = (payload?: any) => {
    setLoading(true);
    statisticsData(payload)
      .then(({ data }) => {
        if (data.code) {
          setStatistic(data.data);
          return;
        }
        data?.msg && message.error(data.msg);
      })
      .finally(() => setLoading(false));
  };

  const onValuesChange: FormProps["onValuesChange"] = (_changed, values) => {
    const { time, labId, _org } = values;
    const dept = getDepartmentId(_org);

    const startTime = time?.[0]?.format("YYYY-MM-DD");
    const endTime = time?.[1]?.format("YYYY-MM-DD");
    getAnalysis({ labId, startTime, endTime, ...dept });
  };

  useEffect(() => {
    getlablist();
    getAnalysis();
  }, []);

  return (
    <div className="page-main">
      <CustomBreadcrumb
        breads={[t("menu.system"), t("menu.system.statistic")]}
      />
      <div className="bg-white p-4 rounded mb-4">
        <div className="flex items-center justify-between">
          <span className="text-lg font-bold">
            {t("declare.item.Platform")}
          </span>
          <Form layout="inline" form={form} onValuesChange={onValuesChange}>
            <Space>
              <Form.Item name="_org">
                <DepartmentSelector />
              </Form.Item>
              <Form.Item name="time">
                <RangePicker
                  placeholder={[
                    t("declare.item.history"),
                    t("declare.item.sofar"),
                  ]}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
              <Form.Item initialValue="" name="labId">
                <Select style={{ width: 180 }}>
                  <Select.Option value="">
                    {t("declare.item.allexperiments")}
                  </Select.Option>
                  {lablist?.map((lab) => (
                    <Select.Option key={lab.id} value={lab.id}>
                      {lab.cName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
          </Form>
        </div>
        <Spin spinning={loading}>
          <Row gutter={[16, 16]} className="mt-4">
            <Col xl={8} md={12} sm={24}>
              <Card title={t("declare.item.view")}>
                <div className="h-16 flex items-center justify-center">
                  <img src={icon20} alt="" className="mr-8" />
                  <div className="text-3xl font-bold">
                    {statistic?.logCount || 0}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Card title={t("declare.item.Experimenters")}>
                <div className="h-16 flex items-center justify-center">
                  <img src={icon21} alt="" className="mr-8" />
                  <div className="text-3xl font-bold">
                    {statistic?.scoreUserCount || ""}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Card title={t("declare.item.Experiment")}>
                <div className="h-16 flex items-center justify-center">
                  <img src={icon22} alt="" className="mr-8" />
                  <div className="text-3xl font-bold">
                    {statistic?.scoreCount || 0}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Card title={t("declare.item.platformuser")}>
                <div className="h-16 flex items-center justify-center">
                  <img src={icon23} alt="" className="mr-8" />
                  <div className="text-3xl font-bold">
                    {statistic?.userCount || 0}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Card title={t("declare.item.platformex")}>
                <div className="h-16 flex items-center justify-center">
                  <img src={icon24} alt="" className="mr-8" />
                  <div className="text-3xl font-bold">
                    {statistic?.labCount || 0}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={8} md={12} sm={24}>
              <Card title={t("declare.item.Cumulative")}>
                <div className="h-16 flex items-center justify-center">
                  <img src={icon25} alt="" className="mr-8" />
                  <div className="text-3xl font-bold">
                    {durationFormat((statistic?.scoreUseTimeSum || 0) * 1000)}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default StatisticPage;
