import classNames from "classnames";
import { useMemo } from "react";

interface HighligntKeyNodeProps {
  label: string;
  keyword?: string; // 高亮词
  highlightClass?: string; // 高亮文本的className
}

const HighligntKeyNode: React.FC<HighligntKeyNodeProps> = ({
  label,
  keyword = "",
  highlightClass,
}) => {
  const decorativeLabel = useMemo(() => {
    const text = label;
    const index = text.indexOf(keyword);
    const prefix = text.substring(0, index);
    const suffix = text.slice(index + keyword.length);

    return index > -1 ? (
      <span>
        {prefix}
        {keyword && (
          <span
            className={classNames(
              "text-white bg-blue-500 inline-block px-1 rounded-sm",
              highlightClass
            )}
          >
            {keyword}
          </span>
        )}
        {suffix}
      </span>
    ) : (
      text
    );
  }, [label, keyword, highlightClass]);

  return <span>{decorativeLabel}</span>;
};

export default HighligntKeyNode;
