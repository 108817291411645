const i18n = {
  "en-US": {
    "menu.home": "Homepage",
    "menu.dashboard": "Dashboard",
    "menu.system": "System",
    "menu.system.statistic": "Statistic",
    "menu.system.authority": "Authority Managment",
    "menu.system.setting": "System Settings",
    "menu.system.notice": "Notification Managment",
    "menu.system.article": "Article Managment",
    "menu.system.qa": "Q&A",
    "menu.system.site": "Site Configuration",
    "menu.system.declare": "Declare Page Configuration",
    "menu.users": "Users",
    "menu.users.manage": "User Management",
    "menu.users.registers": "Register Management",
    "menu.experiment": "Simulation Experiment",
    "menu.experiment.manage": "Experiment Management",
    "menu.experiment.mine": "My experiment",
    "menu.experiment.marks": "Score Summary",
    "menu.exam": "Exams",
    "menu.exam.score": "Exam Scores",
    "menu.exam.lib": "Test Bank Management",
    "menu.exam.papers": "Test Paper Management",
    "menu.exam.manage": "Exam Management",
    "menu.exam.exam": "My Exam",
    "menu.exam.practice": "My Practice",
    "menu.exam.mistakes": "Notebook of Mistakes",
    "router.title.login": "Login",
    "router.title.register": "Register",
    "router.title.declare": "Portal",
    "router.title.mobile": "Mobile Qa Reply",
    "router.title.401": "未认证",
    "router.title.403": "无权访问",
    "router.title.404": "资源不存在",
    "header.item.profile": "Profile",
    "header.item.password": "Password",
    "header.item.rizhi": "MyLog",
    "header.item.question": "Questions",
    "header.item.logout": "Logout",
    "header.tips.online": "Online Questions and Answers",
    "header.tips.guide": "Start System Guiding",
    "header.tips.chatroom": "Chatroom",
    "footer.copyright":
      "Beijing VR Technologies co.,ltd ©2022 All right reserved.",
    "footer.support": "Technical Support By Beijing VR Technologies co.,ltd",
    "system.title": "Virtual Simulation Experiment Platform",
    "system.msg.welcome": "Welcome, ",
    "system.msg.logout.success": "You have successfully logged out.",
    "system.msg.switchlang": "Language switch to ",
    "system.msg.401": "Access token expired, Please relogin. code: 401",
    "system.msg.403": "Access Denied. code: 403",
    "system.msg.302": "Not logged in or login state expired. code: 302",
    "system.msg.savesuccess": "Success Saved",
    "system.msg.timeout": "Request Timeout",
    "system.tips.capslock": "Uppercase locked",
    "system.tips.loadingtip": "Loading, please wait",
    "system.confirm.ok": "Ok",
    "system.confirm.cancel": "Cancel",
    "user.attr.loginId": "Account",
    "user.attr.password": "Password",
    "user.attr.password2": "Confirm Password",
    "user.attr.name": "Name",
    "user.attr.sex": "Gender",
    "user.attr.mail": "E-mail",
    "user.attr.tel": "Tel",
    "user.attr.yid": "Department",
    "user.attr.zid": "Subject",
    "user.attr.bid": "Class",
    "user.attr.zwid": "Job",
    "user.attr.captchaCode": "Captcha",
    "user.attr.sex.male": "Male",
    "user.attr.sex.female": "Female",
    "user.attr.avatar": "My Avatar",
    "user.attr.changeavatar": "Click To Modify",
    "user.attr.savechange": "Save the changes",
    "user.attr.avatar.tip": "Supports JPG, PNG, GIF, size images within 5MB",
    "table.pagination.all": "",
    "table.pagination.total": " total",
    "table.index": "Index",
    "global.editor.placeholder": "Please enter the contents here",
    "global.back": "Back To Last Page",
    "global.backtohome": "Back To Index",
    "exception.page.401": "Unauthorized",
    "exception.page.403": "Access Denied",
    "exception.page.404": "Not Found",
  },
  "zh-CN": {
    "menu.home": "首页",
    "menu.dashboard": "仪表盘",
    "menu.system": "系统",
    "menu.system.statistic": "数据统计",
    "menu.system.authority": "权限管理",
    "menu.system.setting": "系统设置",
    "menu.system.notice": "通知管理",
    "menu.system.article": "文章管理",
    "menu.system.qa": "问答系统",
    "menu.system.site": "站点设置",
    "menu.system.declare": "申报页面设置",
    "menu.users": "用户",
    "menu.users.manage": "用户管理",
    "menu.users.registers": "注册审核",
    "menu.experiment": "仿真实验",
    "menu.experiment.manage": "实验管理",
    "menu.experiment.mine": "我的实验",
    "menu.experiment.marks": "成绩汇总",
    "menu.exam": "考试",
    "menu.exam.score": "成绩汇总",
    "menu.exam.lib": "题库管理",
    "menu.exam.papers": "试卷管理",
    "menu.exam.manage": "考试管理",
    "menu.exam.exam": "我的考试",
    "menu.exam.practice": "我的练习",
    "menu.exam.mistakes": "错题本",
    "router.title.login": "登录",
    "router.title.register": "注册",
    "router.title.declare": "首页",
    "router.title.mobile": "移动端回复提问",
    "router.title.401": "未认证",
    "router.title.403": "无权访问",
    "router.title.404": "资源不存在",
    "header.item.profile": "个人资料",
    "header.item.password": "修改密码",
    "header.item.rizhi": "登录日志",
    "header.item.question": "我的提问",
    "header.item.logout": "退出登录",
    "header.tips.online": "在线问答",
    "header.tips.guide": "打开系统使用引导",
    "header.tips.chatroom": "前往聊天室",
    "footer.copyright": "北京微瑞集智科技有限公司 ©2022 版权所有",
    "footer.support": "技术支持：北京微瑞集智科技有限公司",
    "system.title": "虚拟仿真实验平台",
    "system.msg.welcome": "欢迎你，",
    "system.msg.logout.success": "您已成功登出",
    "system.msg.switchlang": "语言切换至 ",
    "system.msg.401": "登录令牌过期，请重新登陆 code: 401",
    "system.msg.403": "无访问权限 code: 403",
    "system.msg.302": "未登录或登录状态过期 code: 302",
    "system.msg.savesuccess": "保存成功",
    "system.msg.timeout": "网络请求超时",
    "system.tips.capslock": "已开启大写锁定",
    "system.tips.loadingtip": "加载中，请稍候",
    "system.confirm.ok": "确定",
    "system.confirm.cancel": "取消",
    "user.attr.loginId": "账号",
    "user.attr.password": "密码",
    "user.attr.password2": "确认密码",
    "user.attr.name": "姓名",
    "user.attr.sex": "性别",
    "user.attr.mail": "邮箱",
    "user.attr.tel": "电话",
    "user.attr.yid": "院系",
    "user.attr.zid": "专业",
    "user.attr.bid": "班级",
    "user.attr.zwid": "职务",
    "user.attr.captchaCode": "验证码",
    "user.attr.sex.male": "男",
    "user.attr.sex.female": "女",
    "user.attr.avatar": "我的头像",
    "user.attr.changeavatar": "点击修改头像",
    "user.attr.savechange": "保存修改",
    "user.attr.avatar.tip": "支持 jpg、png、gif 格式，大小 5M 以内的图片",
    "table.pagination.all": "共",
    "table.pagination.total": "条",
    "table.index": "序号",
    "global.editor.placeholder": "请在此输入内容",
    "global.back": "返回上一页",
    "global.backtohome": "返回首页",
    "exception.page.401": "未认证",
    "exception.page.403": "抱歉，您无权访问此页面",
    "exception.page.404": "抱歉，您访问的内容不存在",
  },
};

export type defaultI18nKeys = keyof typeof i18n[SupportedLangType];

export default i18n;
