import req from "@/configs/axios";
import { downloadByBlob } from "@/utils/download";
import { type AxiosResponse } from "axios";

// 通过http请求下载
const downFileByRespons = (res: AxiosResponse) => {
  const { data, headers } = res;

  const reader = new FileReader();
  reader.readAsText(data);
  console.log(headers["content-disposition"]?.split("filename=")[1]);
  reader.onload = () =>
    downloadByBlob(data, headers["content-disposition"]?.split("filename=")[1]);
};

// 查询考试列表
const listAllByParams = (data: unknown) => {
  return req.post("ExamController/listAllByParams", data);
};

// 添加考试
const addExam = (data?: unknown) => {
  return req.post("ExamController/addExam", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 删除考试
const deleteExam = (data?: unknown) => {
  return req.post("ExamController/deleteExam", data);
};

// 编辑考试
const updateExam = (data?: unknown) => {
  return req.post("ExamController/updateExam", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 根据一组标签，查询各题型的计数
const countQuestionByTagId = (data: unknown) => {
  return req.post("ExamController/countQuestionByTagId", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 根据idList查题库
const listQuestionByIdList = (data: unknown) => {
  return req.post("ExamController/listQuestionByIdList", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 学生调用考试列表接口
const listAllByStudent = (data: unknown) => {
  return req.post("ExamController/listAllByStudent", data);
};

// 根据考试id获取题库
const listQuestionByExamId = (data: unknown) => {
  return req.post("ExamController/listQuestionByExamId", data);
};

// 查询自己的考试记录
const listExamScoreAllByLogin = (data: unknown) => {
  return req.post("ExamController/listExamScoreAllByLogin", data);
};

// 提交考试答题记录(交卷AES)
const addExamScoreByLogin = (data: unknown) => {
  return req.post("ExamController/addExamScoreByLogin", data);
};

// 考试成绩汇总
const listExamScoreAllByParams = (data: unknown) => {
  return req.post("ExamController/listExamScoreAllByParams", data);
};

// 导出考试答题记录excel
const listExamScoreAllByParamsExcel = (data?: unknown) => {
  return req
    .get("ExamController/listExamScoreAllByParamsExcel", data, {
      responseType: "blob",
    })
    .then(downFileByRespons);
};

export {
  listAllByParams,
  addExam,
  deleteExam,
  updateExam,
  countQuestionByTagId,
  listQuestionByIdList,
  listAllByStudent,
  listQuestionByExamId,
  listExamScoreAllByLogin,
  addExamScoreByLogin,
  listExamScoreAllByParams,
  listExamScoreAllByParamsExcel,
};
