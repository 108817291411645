import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPowerOff,
  faSun,
  faMoon as fasMoon,
  faCloudArrowUp,
  faTriangleExclamation,
  faArrowRotateRight,
  faBook,
  faBarsStaggered,
  faFileExcel,
  faFileCsv,
  faAnglesDown,
  faAnglesUp,
  faLanguage,
  faCircleXmark,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faSun as farSun,
  faQuestionCircle,
  faMoon,
  faComments,
  faHourglass,
  faSquare,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faPowerOff,
  faBell,
  faSun,
  farSun,
  faMoon,
  faQuestionCircle,
  fasMoon,
  faComments,
  faCloudArrowUp,
  faTriangleExclamation,
  faArrowRotateRight,
  faHourglass,
  faBook,
  faBarsStaggered,
  faFileExcel,
  faFileCsv,
  faAnglesDown,
  faAnglesUp,
  faLanguage,
  faCircleXmark,
  faCheck,
  faSquare
);
