import {
  BarsOutlined,
  CaretRightFilled,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileSyncOutlined,
  FormOutlined,
  LinkOutlined,
  LockOutlined,
  SettingOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  Form,
  Space,
  Input,
  Button,
  Tooltip,
  Table,
  Image,
  Modal,
  message,
  Upload,
  Alert,
  Steps,
  Drawer,
  Dropdown,
  Menu,
  Segmented,
  Select,
  Tag,
  notification,
  Popconfirm,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactElement,
  type RefObject,
} from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import DepartmentTree, {
  type NodeDataType,
} from "../../components/DepartmentTree";
import { downloadByURL } from "../../../utils/download";
import {
  addOneKc,
  addOneLab,
  deleteBatchLab,
  deleteOneKc,
  listAllKcByParams,
  queryLabList,
  updateOneKc,
  updateOneLab,
} from "../../../api/NewLabInfoController";
import useToggle from "../../../hooks/useToggle";
import type { UploadProps } from "antd";
import type { Rule } from "antd/lib/form";
import { useLocale } from "../../../hooks/useLocale";
import i18n from "./i18n";
import { setUserAndLabkey } from "../../../api/NewScoreController";
import LabImportTemplate from "../../../assets/template/LabImportTemplate.xls";
import useAuth from "../../../hooks/useAuth";
import { uploadLabExcel } from "../../../api/NewUpLoadController";
import Uploader from "./Uploader";
import ReportTemplate from "./ReportTemplate";
import AdvancedSetting from "./AdvancedSetting";
import SetupPanel from "./Setupanels";
import { RichDisplay } from "../../components/RichEditor";
import QuestionPicker, {
  type QuestionPickerRef,
} from "../../components/QuestionPicker";
import { type Question } from "../../exam/library";
import { useNavigate } from "react-router-dom";
import { addScoreVideo } from "../../../api/LabSettingController";
import useStartExp from "../../../hooks/useStartExp";

export interface Course {
  id: number;
  cName: string;
  cNote: string;
  nOrder?: number;
  nValid?: 0 | 1;
}

type ExperimentManageContextValues = {
  qRef: RefObject<QuestionPickerRef>;
  questionPickerOpen: boolean;
  setQuestionPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questionPicked: Question[];
  setQuestionPicked: React.Dispatch<React.SetStateAction<Question[]>>;
} | null;

const { confirm } = Modal;
const { Step } = Steps;
const { useForm } = Form;
const { Dragger } = Upload;

const formRules: { [key: string]: Rule[] } = {
  cName: [{ required: true, message: "请输入实验名称" }],
  nKcid: [{ required: true, message: "请选择所属课程" }],
};

const labTags: { [k: PropertyKey]: ReactElement } = {
  4: <Tag color="orange">加载器</Tag>,
  9: <Tag color="green">视频</Tag>,
};

const ExperimentManageContext =
  createContext<ExperimentManageContextValues>(null);

export const useExperimentManage = () => {
  const context = useContext(ExperimentManageContext);
  if (!context) {
    throw new Error("ExperimentManageContext不在父状态树中");
  }
  return context;
};

const ExperimentManagePage = () => {
  const { startExp } = useStartExp();
  const [tableData, setTableData] = useState<any[]>([]);
  const [courseMap, setCourseMap] = useState<Map<number, Course>>();
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [multipleEdit, setMultipleEdit] = useState(false);
  const [multipleSelectedRows, setMultipleSelectedRows] = useState<React.Key[]>(
    []
  );
  const [tableSiderShow, setTableSiderShow] = useToggle(false);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });
  const [entity, setEntity] = useState<any>();
  const [queryForm] = useForm();
  const { t } = useLocale(i18n);
  // 实验设置
  const [setupShow, setSetupShow] = useState(false);
  const [setupData, setSetupData] = useState<any>();
  const [questionPickerOpen, setQuestionPickerOpen] = useState<boolean>(false);
  const [questionPicked, setQuestionPicked] = useState<Question[]>([]);
  const qRef = useRef<QuestionPickerRef>(null);

  const onSearch = () => {
    setTablePagenation({ page: 1, rows: 10 });
  };

  const onEntitySelect = (node?: NodeDataType) => {
    const key = {
      1: "yid",
      2: "zid",
      3: "bid",
      4: "uid",
    };

    setEntity(node?.level ? { [key[node.level]]: node.id } : {});
  };

  const getTableData = useCallback(() => {
    setLoading(true);

    const payload = queryForm.getFieldsValue();
    payload.nKcid = payload.nKcid?.join() || undefined;

    queryLabList({
      ...tablePagenation,
      ...payload,
      ...entity,
    })
      .then(({ data }) => {
        if (data.code) {
          setTableData(data.data.rows);
          setTotal(data.data.records);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entity, queryForm, tablePagenation]);

  const getCourseList = () => {
    setCourseEditingKey("");
    listAllKcByParams().then(({ data }) => {
      if (data.code) {
        setCourseList(data.data);
        setCourseMap(new Map(data.data.map((x: Course) => [x.id, x])));
      }
    });
  };

  useEffect(() => {
    getTableData();
    getCourseList();
  }, [getTableData]);

  const tableRowSelection = {
    onChange: (selectedRowKeys: React.Key[], _selectedRows: any) => {
      setMultipleSelectedRows(selectedRowKeys);
    },
  };

  const cancelTabRowSelection = () => {
    setMultipleEdit(false);
    setMultipleSelectedRows([]);
  };

  const deleteTableRows = () => {
    if (multipleSelectedRows.length === 0) {
      message.error(t("experiment.item.selectlist"));
      return;
    }
    Modal.confirm({
      title: t("experiment.item.suredel"),
      content: `将删除选中的${multipleSelectedRows.length}个实验，确定吗？`,
      async onOk() {
        const { data } = await deleteBatchLab(multipleSelectedRows);
        if (data.code) {
          message.success(t("experiment.item.success"));
          getTableData();
        } else {
          data?.msg && message.error(data.msg);
        }
      },
    });
  };

  // 编辑实验
  const [editform] = useForm();
  const [editDrawerShow, setEditDrawerShow] = useState(false);
  const [editing, setEditing] = useState<any>();
  const [editPanelState, setEditPanelState] = useState<number | string>(1);
  const [editLoading, setEditLoading] = useState(false);

  const onPanelChange = (panel: number | string) => {
    if (panel === 1 && editing) editform.setFieldsValue(editing);
    setEditPanelState(panel);
  };

  const openToEdit = (rowData?: any) => {
    if (rowData) {
      rowData.nKcid = Number(rowData.nKcid);
      editform.setFieldsValue(rowData);
      setEditing(rowData);
    } else {
      setEditing(undefined);
    }
    setEditPanelState(1);
    setEditDrawerShow(true);
  };

  const commitEdit = () => {
    editform.validateFields().then(async (d) => {
      setEditLoading(true);
      if (editing) {
        // 更新
        d.id = editing.id;
        const { data } = await updateOneLab(d).finally(() => {
          setEditLoading(false);
        });
        if (data.code) {
          message.success(t("experiment.item.modifysuccess"));
          setEditDrawerShow(false);
          getTableData();
          return;
        }
        data?.msg && message.error(data.msg);
        return;
      }

      // 创建
      const { data } = await addOneLab(d).finally(() => {
        setEditLoading(false);
      });
      if (data.code) {
        message.success(t("experiment.item.addsuccess"));
        setEditDrawerShow(false);
        getTableData();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  // 批量导入实验
  const [importModalShow, setImportModalShow] = useState(false);
  const [experimentXlsxList, setExperimentXlsxList] = useState<any[]>();
  const [importUploading, setImportUploading] = useState(false);

  const closeImportModal = () => {
    setExperimentXlsxList([]);
    setImportModalShow(false);
  };

  const beforeUpload: UploadProps["beforeUpload"] = (file) => {
    const suffix = file.name.substring(file.name.lastIndexOf("."));
    if (!suffix.includes("xls")) {
      message.error(t("experiment.item.only"));
      return Upload.LIST_IGNORE;
    }
    setExperimentXlsxList([file]);
    return false;
  };

  const onFileRemove: UploadProps["onRemove"] = () => {
    setExperimentXlsxList([]);
  };

  const manuallyUpload = () => {
    if (!experimentXlsxList || experimentXlsxList?.length === 0) {
      message.error(t("experiment.item.selectfile"));
      return;
    }
    const formData = new FormData();
    formData.append("file", experimentXlsxList[0]);

    setImportUploading(true);
    uploadLabExcel(formData)
      .then(({ data }) => {
        if (!data.code) {
          data?.msg && message.error(data.msg);
          return;
        }
        closeImportModal();
        if (data.data.eorrRowNum > 0) {
          Modal.confirm({
            maskClosable: false,
            width: 800,
            title: t("experiment.item.incorrect"),
            content: (
              <>
                <Alert
                  className="mb-2"
                  type="warning"
                  showIcon
                  message={`你上传了${data.data.ExcelNum}条数据。其中${data.data.eorrRowNum}条数据错误，系统已自动修正。`}
                />
                <Table
                  size="small"
                  bordered
                  rowKey="excelRow"
                  scroll={{ y: 400 }}
                  dataSource={data.data.eorrList}
                  pagination={false}
                  rowClassName={(records) =>
                    records.exceldata ? "bg-red-200 hover:text-red-500" : ""
                  }
                  columns={[
                    {
                      title: t("experiment.item.errorline"),
                      dataIndex: "excelRow",
                      align: "center",
                      width: 60,
                    },
                    {
                      title: t("experiment.item.type"),
                      align: "center",
                      width: 60,
                      render: (_val, row) =>
                        row?.excelCFdata ? (
                          <Tag color="#ffc53d">
                            {t("experiment.item.repetition")}
                          </Tag>
                        ) : (
                          <Tag color="#f5222d">
                            {t("experiment.item.error")}
                          </Tag>
                        ),
                    },
                    {
                      title: t("experiment.item.errorcon"),
                      width: 360,
                      render: (_val, row) => row?.excelCFdata || row?.exceldata,
                    },
                  ]}
                />
              </>
            ),
            cancelText: t("experiment.item.close"),
          });
        } else {
          notification.success({
            message: t("experiment.item.impsuccess"),
            description: `你上传了${data.data.importNumber}条数据，系统已全部录入。`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(t("experiment.item.sorry"));
      })
      .finally(() => {
        setImportUploading(false);
      });
  };

  // 课程管理\练习初始值
  const [courseModalShow, setCourseModalShow] = useState(false);
  const [courseForm] = Form.useForm();
  const [courseEditingKey, setCourseEditingKey] = useState<React.Key>("");

  const isCourseEditing = (row: any) => row.id === courseEditingKey;

  const addCourse = () => {
    if (courseEditingKey) return;
    const lst = [...courseList];
    lst.unshift({
      id: -1,
      cName: "",
      cNote: "",
    });
    setCourseList(lst);
    setCourseEditingKey(-1);
  };

  const delCourse = (id: number) => {
    deleteOneKc({ id }).then(({ data }) => {
      if (data.code) {
        message.success(t("experiment.item.delsuccess"));
        getCourseList();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  const editCourse = (row: Course) => {
    setCourseEditingKey(row.id);
    courseForm.setFieldsValue(row);
  };

  const saveCourse = () => {
    const payload = courseForm.getFieldsValue();

    if (courseEditingKey !== -1) {
      payload.id = courseEditingKey;

      updateOneKc(payload).then(({ data }) => {
        if (data.code) {
          message.success(t("experiment.item.savesuccess"));
          getCourseList();
          return;
        }
        data?.msg && message.error(data.msg);
      });
      return;
    }

    addOneKc(payload).then(({ data }) => {
      if (data.code) {
        message.success(t("experiment.item.addsuccess"));
        getCourseList();
        return;
      }
      data?.msg && message.error(data.msg);
    });
  };

  return (
    <ExperimentManageContext.Provider
      value={{
        qRef,
        questionPickerOpen,
        setQuestionPickerOpen,
        questionPicked,
        setQuestionPicked,
      }}
    >
      <div className="page-main">
        <CustomBreadcrumb
          breads={[t("menu.experiment"), t("menu.experiment.manage")]}
        />
        <div className="flex">
          {tableSiderShow ? (
            <DepartmentTree
              depth={2}
              allowSearch
              onNodeSelect={onEntitySelect}
            />
          ) : null}
          <div className="overflow-hidden flex-grow">
            <div className="table-query-bar">
              <Form layout="inline" form={queryForm}>
                <Space wrap>
                  <Form.Item label={t("experiment.item.name")} name="search">
                    <Input
                      className="w-50"
                      allowClear
                      placeholder={t("experiment.item.entername")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("experiment.item.course")}
                    className="w-60"
                    name="nKcid"
                  >
                    <Select
                      placeholder={t("experiment.item.selectcourse")}
                      showSearch
                      showArrow
                      allowClear
                      maxTagCount="responsive"
                      mode="multiple"
                      filterOption={(input, option) =>
                        option?.label.includes(input) || false
                      }
                      options={courseList.map((course) => ({
                        label: course.cName,
                        value: course.id,
                      }))}
                    />
                  </Form.Item>
                  <Button onClick={() => queryForm.resetFields()}>
                    {t("experiment.item.remove")}
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onSearch}>
                    {t("experiment.item.query")}
                  </Button>
                </Space>
              </Form>
            </div>
            <div className="table-wrapper">
              <Space className="table-tool-bar">
                <Space>
                  <Tooltip title={t("experiment.item.tree")} placement="top">
                    <Button
                      icon={<ClusterOutlined />}
                      onClick={setTableSiderShow}
                    />
                  </Tooltip>
                  <Button type="primary" onClick={() => openToEdit()}>
                    {t("experiment.item.add")}
                  </Button>
                  <Button
                    onClick={() => {
                      setImportModalShow(true);
                    }}
                  >
                    {t("experiment.item.import")}
                  </Button>
                  <Button onClick={() => setCourseModalShow(true)}>
                    {t("experiment.item.management")}
                  </Button>
                </Space>
                <Space>
                  {multipleEdit ? (
                    <>
                      <Button
                        danger
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={deleteTableRows}
                      >
                        {t("experiment.item.delete")}
                      </Button>
                      <Button
                        icon={
                          <FontAwesomeIcon
                            icon={["fas", "arrow-rotate-right"]}
                            className="mr-2"
                          />
                        }
                        onClick={cancelTabRowSelection}
                      >
                        {t("experiment.item.cancel")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      icon={<FormOutlined />}
                      onClick={() => setMultipleEdit(true)}
                    >
                      {t("experiment.item.batchoperation")}
                    </Button>
                  )}
                </Space>
              </Space>
              <Table
                size="small"
                rowKey="id"
                bordered
                scroll={{ x: 1300 }}
                loading={loading}
                dataSource={tableData}
                rowSelection={
                  multipleEdit
                    ? { type: "checkbox", ...tableRowSelection }
                    : undefined
                }
                pagination={{
                  size: "default",
                  showQuickJumper: true,
                  showSizeChanger: true,
                  position: ["bottomCenter"],
                  current: tablePagenation.page,
                  pageSize: tablePagenation.rows,
                  total,
                  showTotal: (total) =>
                    `${t("table.pagination.all")}${total}${t(
                      "table.pagination.total"
                    )}`,
                  onChange: (page, rows) => {
                    setTablePagenation({ page, rows });
                  },
                }}
                columns={[
                  {
                    title: t("table.index"),
                    width: 50,
                    fixed: "left",
                    align: "center",
                    render: (_val, _row, index) => index + 1,
                  },
                  {
                    title: t("experiment.item.name"),
                    width: 270,
                    dataIndex: "cName",
                    fixed: "left",
                    ellipsis: true,
                    render: (val, row) => (
                      <>
                        {labTags?.[row?.nlabtype as PropertyKey]}
                        <RichDisplay html={val} className="inline-block" />
                      </>
                    ),
                  },
                  {
                    title: t("experiment.item.stucourse"),
                    dataIndex: "nKcid",
                    width: 120,
                    render: (val) =>
                      courseMap?.get(Number(val))?.cName ?? (
                        <span className="text-gray-300">
                          {t("experiment.item.unknown")}
                        </span>
                      ),
                  },
                  {
                    title: t("experiment.item.introduction"),
                    width: 350,
                    ellipsis: true,
                    dataIndex: "cNote",
                    render: (val) => <RichDisplay html={val} />,
                  },
                  {
                    title: t("experiment.item.cover"),
                    dataIndex: "cImgid",
                    align: "center",
                    width: 160,
                    render: (val) =>
                      val ? <Image width={140} src={val} /> : null,
                  },
                  {
                    title: t("experiment.item.startexperiment"),
                    align: "center",
                    width: 140,
                    render: (_val, row) =>
                      row.cZyhome ? (
                        row?.nlabtype === 9 ? (
                          <Button
                            icon={<VideoCameraOutlined />}
                            onClick={() =>
                              startExp(row.id, row.cZyhome, row.nlabtype)
                            }
                          >
                            {t("experiment.item.watchvideo")}
                          </Button>
                        ) : (
                          <Button
                            icon={<CaretRightFilled />}
                            onClick={() =>
                              startExp(row.id, row.cZyhome, row.nlabtype)
                            }
                          >
                            {t("experiment.item.start")}
                          </Button>
                        )
                      ) : (
                        <span className="text-gray-300">
                          {" "}
                          {t("experiment.item.notupload")}
                        </span>
                      ),
                  },
                  {
                    title: t("experiment.item.material"),
                    align: "center",
                    width: 120,
                    render: (_val, row) => (
                      <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        menu={{
                          items: [
                            {
                              key: 1,
                              label: t("experiment.item.handouts"),
                              icon: (
                                <FontAwesomeIcon
                                  icon={["fas", "bars-staggered"]}
                                />
                              ),
                              disabled: !row.cJyhome,
                            },
                            {
                              key: 2,
                              label: t("experiment.item.document"),
                              icon: <FontAwesomeIcon icon={["fas", "book"]} />,
                              disabled: !row.cHelpfilehome,
                            },
                          ],
                        }}
                      >
                        <Button>{t("experiment.item.view")}</Button>
                      </Dropdown>
                    ),
                  },
                  {
                    title: t("experiment.item.feedback"),
                    align: "center",
                    width: 130,
                    render: (_val, row) => (
                      <Button>{t("experiment.item.viewfeedback")}</Button>
                    ),
                  },
                  {
                    title: t("experiment.item.operation"),
                    width: 240,
                    fixed: "right",
                    align: "center",
                    render(text, row) {
                      return (
                        <Space>
                          <Button
                            size="small"
                            icon={<SettingOutlined />}
                            onClick={() => {
                              setSetupData(row);
                              setSetupShow(true);
                            }}
                          >
                            {t("experiment.item.Setting")}
                          </Button>
                          <Button size="small" onClick={() => openToEdit(row)}>
                            {t("experiment.item.edit")}
                          </Button>
                          <Button
                            size="small"
                            type="primary"
                            danger
                            onClick={() => {
                              confirm({
                                title: t("experiment.item.suredel"),
                                icon: <ExclamationCircleOutlined />,
                                content: "",
                                centered: true,
                                async onOk() {
                                  const { data } = await deleteBatchLab([
                                    row.id,
                                  ]);
                                  if (data.code) {
                                    message.success(
                                      t("experiment.item.success")
                                    );
                                    getTableData();
                                  } else {
                                    data?.msg && message.error(data.msg);
                                  }
                                },
                                onCancel() {},
                              });
                            }}
                          >
                            {t("experiment.item.delete")}
                          </Button>
                        </Space>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {/* 导入实验 */}
        <Modal
          title={t("experiment.item.batch")}
          destroyOnClose
          open={importModalShow}
          footer={null}
          maskClosable={false}
          width={430}
          onCancel={closeImportModal}
        >
          <Steps direction="vertical" size="small">
            <Step
              title={t("experiment.item.firststep")}
              icon={<CloudDownloadOutlined />}
              description={
                <>
                  {t("experiment.item.download")}
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => downloadByURL(LabImportTemplate)}
                  >
                    <>
                      <LinkOutlined />
                      {t("experiment.item.templatefile")}
                    </>
                  </Button>
                  。
                </>
              }
              status="process"
            />
            <Step
              title={t("experiment.item.fallin")}
              icon={<EditOutlined />}
              status="process"
              description={
                <>
                  {t("experiment.item.secondstep")}
                  <Alert
                    className="mt-2"
                    type="warning"
                    showIcon
                    message={t("experiment.item.ignored")}
                  />
                </>
              }
            />
            <Step
              title={t("experiment.item.thirdstep")}
              icon={<CloudUploadOutlined />}
              status="process"
              description={
                <>
                  {t("experiment.item.worksheet")}
                  <Dragger
                    className="mt-2"
                    maxCount={1}
                    fileList={experimentXlsxList}
                    beforeUpload={beforeUpload}
                    onRemove={onFileRemove}
                  >
                    <p className="ant-upload-drag-icon">
                      <FontAwesomeIcon
                        icon={["fas", "cloud-arrow-up"]}
                        size="3x"
                        className="text-gray-300"
                      />
                    </p>
                    <p className="ant-upload-text">
                      {t("experiment.item.drag")}
                    </p>
                  </Dragger>
                </>
              }
            />
          </Steps>
          <Button
            block
            type="primary"
            onClick={manuallyUpload}
            loading={importUploading}
          >
            {importUploading
              ? t("experiment.item.wait")
              : t("experiment.item.submitsheet")}
          </Button>
        </Modal>
        {/* 编辑实验 */}
        <Drawer
          width={800}
          title={
            editing?.id
              ? t("experiment.item.modifyinf")
              : t("experiment.item.add")
          }
          open={editDrawerShow}
          destroyOnClose
          footer={null}
          onClose={() => setEditDrawerShow(false)}
          extra={
            editing?.id ? (
              <Segmented
                size="middle"
                value={editPanelState}
                onChange={onPanelChange}
                options={[
                  {
                    value: 1,
                    label: t("experiment.item.basicinf"),
                    icon: <BarsOutlined className="mr-1" />,
                  },
                  {
                    value: 2,
                    label: t("experiment.item.template"),
                    icon: <FileSyncOutlined className="mr-1" />,
                  },
                  {
                    value: 3,
                    label: t("experiment.item.advanced"),
                    icon: <LockOutlined className="mr-1" />,
                  },
                ]}
              />
            ) : null
          }
        >
          {/* 基本信息 */}
          {editPanelState === 1 ? (
            <Form
              form={editform}
              labelAlign="left"
              labelCol={{ span: 4 }}
              preserve={false}
              wrapperCol={{ span: 20 }}
            >
              <div className="text-right mb-6">
                <Button type="primary" onClick={commitEdit}>
                  {t("experiment.item.savebasic")}
                </Button>
              </div>
              <Form.Item
                label={t("experiment.item.name")}
                name="cName"
                rules={formRules.cName}
              >
                <Input placeholder={t("experiment.item.entername")} />
              </Form.Item>
              <Form.Item
                label={t("experiment.item.stucourse")}
                name="nKcid"
                rules={formRules.nKcid}
              >
                <Select
                  placeholder={t("experiment.item.selcourse")}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label.includes(input) || false
                  }
                  options={courseList.map((course) => ({
                    label: course.cName,
                    value: course.id,
                  }))}
                />
              </Form.Item>
              <Form.Item label={t("experiment.item.introduction")} name="cNote">
                <Input.TextArea
                  rows={3}
                  placeholder={t("experiment.item.enterintroduction")}
                />
              </Form.Item>
              <Form.Item
                label={t("experiment.item.cover")}
                name="cImgid"
                valuePropName="src"
              >
                <Uploader keyName="labFile" />
              </Form.Item>
              <Form.Item
                label={t("experiment.item.icon")}
                name="iconUrl"
                valuePropName="src"
              >
                <Uploader keyName="labFile" />
              </Form.Item>
              {/* <Form.Item label="讲义">
              <MultiplyUploader />
            </Form.Item>
            <Form.Item label="文档">
              <MultiplyUploader />
            </Form.Item> */}
            </Form>
          ) : null}
          {/* 实验报告模板 */}
          {editPanelState === 2 ? <ReportTemplate labId={editing.id!} /> : null}

          {/* 高级设置 */}
          {editPanelState === 3 ? (
            <AdvancedSetting labId={editing.id!} />
          ) : null}
        </Drawer>
        {/* 课程管理 */}
        <Modal
          title={t("experiment.item.management")}
          destroyOnClose
          open={courseModalShow}
          width={700}
          footer={null}
          maskClosable={false}
          onCancel={() => setCourseModalShow(false)}
        >
          <Space className="table-tool-bar">
            <Button type="primary" onClick={addCourse}>
              {t("experiment.item.addcourse")}
            </Button>
          </Space>
          <Form component={false} form={courseForm} preserve={false}>
            <Table
              size="small"
              bordered
              rowKey="id"
              dataSource={courseList}
              scroll={{ y: 400 }}
              pagination={false}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={[
                {
                  title: t("experiment.item.coursename"),
                  dataIndex: "cName",
                  onCell: (row: any) => ({
                    ...row,
                    editing: isCourseEditing(row),
                    dataIndex: "cName",
                  }),
                },
                {
                  title: t("experiment.item.remarks"),
                  dataIndex: "cNote",
                  onCell: (row: any) => ({
                    ...row,
                    editing: isCourseEditing(row),
                    dataIndex: "cNote",
                  }),
                },
                {
                  title: t("experiment.item.operation"),
                  width: 150,
                  align: "center",
                  render: (_val, row) => {
                    const editing = isCourseEditing(row);
                    return editing ? (
                      <Space>
                        <Button
                          size="small"
                          type="primary"
                          onClick={saveCourse}
                        >
                          {t("experiment.item.save")}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            if (courseEditingKey === -1) {
                              const newlist = [...courseList];
                              newlist.shift();
                              setCourseList(newlist);
                            }
                            setCourseEditingKey("");
                          }}
                        >
                          {t("experiment.item.cancel")}
                        </Button>
                      </Space>
                    ) : (
                      <Space>
                        <Button
                          size="small"
                          onClick={() => editCourse(row)}
                          disabled={courseEditingKey !== ""}
                        >
                          {t("experiment.item.edit")}
                        </Button>
                        <Popconfirm
                          title={t("experiment.item.delcourse")}
                          onConfirm={() => delCourse(row.id)}
                          okText={t("experiment.item.confirm")}
                          cancelText={t("experiment.item.cancel")}
                        >
                          <Button size="small" type="primary" danger>
                            {t("experiment.item.delete")}
                          </Button>
                        </Popconfirm>
                      </Space>
                    );
                  },
                },
              ]}
            />
          </Form>
        </Modal>
        {/* 实验设置 */}
        <SetupPanel
          visible={setupShow}
          setVisible={setSetupShow}
          labData={setupData}
        />
        <QuestionPicker
          open={questionPickerOpen}
          onClose={() => setQuestionPickerOpen(false)}
          onChange={setQuestionPicked}
          picked={questionPicked}
          ref={qRef}
        />
      </div>
    </ExperimentManageContext.Provider>
  );
};

const EditableCell = (row: any) => {
  const { editing, children, dataIndex } = row;
  return (
    <td>
      {editing ? (
        <Form.Item
          name={dataIndex}
          noStyle
          rules={dataIndex === "cName" ? [{ required: true }] : undefined}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default ExperimentManagePage;
