import { notification } from "antd";
import {
  createContext,
  useCallback,
  useEffect,
  useState,
  type ReactNode,
} from "react";
import { listAllByParams } from "../api/NoticeController";
import useAuth from "../hooks/useAuth";
import {
  systemNotification,
  requestSystemNotificationPermission,
} from "../utils/notification";

type NoticeContextValues = {
  noticeLoading: boolean;
  noticeList?: Notice[];
  retry: (payload?: { page?: number; rows?: number; haveRead?: 0 | 1 }) => void;
  count?: {
    unread?: number;
    total?: number;
  };
} | null;

type TakeOut<T, R extends PropertyKey> = T extends { [key in R]?: infer X }
  ? X
  : never;

interface Notice {
  id: number;
  noticeTitle: string;
  noticeContent: string;
  haveRead: 0 | 1;
  createTime: string;
}

const NoticeContext = createContext<NoticeContextValues>(null);
NoticeContext.displayName = "NoticeContext";

const NoticeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ws, setWs] = useState<WebSocket | null>();
  const { user } = useAuth();
  const [noticeLoading, setNoticeLoading] = useState(false);
  const [noticeList, setNoticeList] = useState<Notice[]>();
  const [count, setCount] = useState<TakeOut<NoticeContextValues, "count">>();

  const createNoticeConnection = (userId: number) => {
    const ws = new WebSocket(
      `ws://${window.location.host}/webSocketByNotice?userId=${userId}`
    );

    // 连接错误
    ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err,
        "Reconnect will be attempted in 3 sec."
      );
      ws.close();
      // setTimeout(createNoticeConnection, 3000);
    };

    // 连接成功
    ws.onopen = (event) => {};

    // 收到消息
    ws.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        console.log("remote: ", data);
        setCount({
          total: data?.total,
          unread: data?.unread,
        });

        if (data.notice) {
          if (document.hidden) {
            systemNotification(document.title, data.notice.noticeContent);
          } else {
            notification.open({
              message: data.notice.noticeTitle,
              description: data.notice.noticeContent,
              duration: 3,
            });
          }
        }
      } catch (error) {
        console.log(message.data, error);
      }
    };

    // 连接端口
    ws.onclose = (e) => {
      console.warn("Socket is closed.");
    };

    return ws;
  };

  const getNoticeList = useCallback<TakeOut<NoticeContextValues, "retry">>(
    (payload) => {
      setNoticeLoading(true);
      listAllByParams({ ...payload, page: 1, rows: 9999 })
        .then(({ data }) => {
          if (data.code) {
            const { pager, total, unread } = data.data;
            setNoticeList(pager.rows);
            setCount({ total, unread });
            return;
          }
        })
        .finally(() => setNoticeLoading(false));
    },
    []
  );

  const connect = useCallback(() => {
    if (window.WebSocket && user?.id) {
      try {
        const ws = createNoticeConnection(user.id);
        if (ws) setWs(ws);
      } catch (err) {
        console.error(err);
      }
    } else {
      setWs((ws) => {
        ws?.close();
        return undefined;
      });
    }
  }, [user?.id]);

  useEffect(() => {
    connect();
    // getNoticeList({ haveRead: 0 });
    requestSystemNotificationPermission();
  }, [connect, getNoticeList]);

  return (
    <NoticeContext.Provider
      value={{
        count,
        noticeList,
        noticeLoading,
        retry: getNoticeList,
      }}
    >
      {children}
    </NoticeContext.Provider>
  );
};

export { NoticeContext, NoticeProvider };
