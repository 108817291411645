import { Col, Drawer, List, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { findnews, showNews } from "../../api/NewNewsController";
import { useLocale } from "../../hooks/useLocale";
import { RichDisplay } from "../components/RichEditor";

interface ArticalProps {
  contentText: string;
}

const ArticalList = ({ contentText }: ArticalProps) => {
  const [loading, setLoading] = useState(false);
  const [newsList, setNewsList] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const { t } = useLocale();
  const [detailVisible, setDetailVisible] = useState(false);
  const [articalInfo, setArticalInfo] = useState<any>();
  const [articalName, setArticalName] = useState<string>();
  const [createTime, setCreateTime] = useState<string>();
  const [artTitle, setArtTitle] = useState<any[]>([]);
  const [tablePagenation, setTablePagenation] = useState({
    page: 1,
    rows: 10,
  });

  const getNewsList = useCallback(async () => {
    setLoading(true);
    const { data } = await showNews({
      ...tablePagenation,
      idList: artTitle,
    });
    if (data.code) {
      setLoading(false);
      setNewsList(data.data.rows);
      setTotal(data.data.records);
    }
    setLoading(false);
  }, [tablePagenation, artTitle]);

  useEffect(() => {
    try {
      const { articalTitle } = JSON.parse(contentText);
      setArtTitle(articalTitle.toString());
    } catch (err) {}
  }, [contentText]);

  useEffect(() => {
    getNewsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagenation, artTitle]);

  const showDetailDrawer = async (id: any) => {
    setDetailVisible(true);
    const { data } = await findnews({ id: id });
    if (data.code) {
      setArticalInfo(data.data.info);
      setArticalName(data.data.name);
      setCreateTime(data.data.updateDate);
    }
  };

  const onClose = () => {
    setDetailVisible(false);
  };

  return (
    <div className="articalList mb-10">
      <List
        itemLayout="vertical"
        rowKey="id"
        loading={loading}
        className="news-list border-solid border-1 border-gray-200 p-4 mt-5"
        dataSource={newsList}
        pagination={{
          size: "default",
          showQuickJumper: true,
          showSizeChanger: true,
          position: "bottom",
          total,
          current: tablePagenation.page,
          pageSize: tablePagenation.rows,
          showTotal: (total) =>
            `${t("table.pagination.all")} ${total} ${t(
              "table.pagination.total"
            )}`,
          onChange: (page, rows) => {
            setTablePagenation({ page, rows });
          },
        }}
        renderItem={(item) => (
          <List.Item>
            <Row gutter={10} align="middle" className="bbs-item cursor-pointer">
              <Col
                span={19}
                className="bbsList-content"
                style={{ padding: "0px 20px 10px" }}
              >
                <div
                  className="text-base text-blue-400"
                  onClick={() => {
                    showDetailDrawer(item.id);
                  }}
                >
                  {item.name}
                </div>
              </Col>
              <Col
                span={5}
                className="bbsList-content"
                style={{ padding: "0px 20px 10px" }}
              >
                <div>{item.updateDate}</div>
              </Col>
            </Row>
          </List.Item>
        )}
      />

      <Drawer
        title={
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <div>{articalName}</div>
            <div className="text-sm">{createTime}</div>
          </div>
        }
        width="60%"
        placement="right"
        onClose={onClose}
        open={detailVisible}
      >
        <div className="bg-white py-8 px-5 rounded">
          <RichDisplay html={articalInfo} />
        </div>
      </Drawer>
    </div>
  );
};
export default ArticalList;
